import { NgClass, NgStyle } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel, MatPrefix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppType, ENVIRONMENT, Environment, WebButtonPosition, Widget, WidgetMeta, WidgetOwner, WidgetType } from '@domains';
import { WidgetsService } from '@rspl-api';
import {
  CardComponent,
  FxFlexDirective,
  FxLayoutAlignDirective,
  FxLayoutDirective,
  FxLayoutGapDirective,
  Responsive,
  ResponsiveService,
} from '@rspl-ui';
import { ColorPickerModule } from 'ngx-color-picker';
import { take } from 'rxjs';

@Component({
  selector: 'app-web-button',
  templateUrl: './web-button.component.html',
  styleUrls: ['./web-button.component.scss'],
  imports: [
    FormsModule,
    FxLayoutDirective,
    FxLayoutGapDirective,
    FxFlexDirective,
    ReactiveFormsModule,
    CardComponent,
    MatSlideToggle,
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    MatButtonToggleGroup,
    MatButtonToggle,
    MatIcon,
    MatPrefix,
    MatInput,
    ColorPickerModule,
    FxLayoutAlignDirective,
    MatButton,
    NgStyle,
    NgClass,
  ],
})
export class WebButtonComponent extends Responsive implements OnInit {
  @Input() ownerType: WidgetOwner;
  @Input() ownerId: string;
  widget?: Widget;
  form: FormGroup<{
    meta: FormGroup<{
      enabled: FormControl<boolean>;
      poweredBy: FormControl<boolean>;
      webBtnBackgroundColor: FormControl<string>;
      webBtnFontColor: FormControl<string>;
      webBtnPosition: FormControl<WebButtonPosition>;
      webBtnCollapsable: FormControl<boolean>;
      webBtnDismissible: FormControl<boolean>;
    }>;
    widgetType: FormControl<WidgetType>;
  }>;
  positions = WebButtonPosition;
  isCaptain: boolean;
  isCharity: boolean;
  isProd: boolean;
  position?: string;
  widgetTypes = WidgetType;
  types = [
    { value: WidgetType.BUTTON_V1, text: 'Button V1' },
    { value: WidgetType.BUTTON_V2, text: 'Button V2' },
    { value: WidgetType.BUTTON_V3, text: 'Button V3' },
  ];

  constructor(
    private snackBar: MatSnackBar,
    private widgetService: WidgetsService,
    public override responsiveService: ResponsiveService,
    @Inject(ENVIRONMENT) private environment: Environment,
  ) {
    super(responsiveService);
    this.isCaptain = [AppType.CAPTAIN, AppType.ZENDESK].includes(environment.app);
    this.isCharity = environment.app === AppType.CHARITY;
    this.isProd = environment.isProductionBuild;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.widgetService
      .filter({
        owner_id: this.ownerId,
        owner_type: this.ownerType,
      })
      .pipe(take(1))
      .subscribe((res: { totalResults: number; results: Array<Widget> }) => {
        const widget = res?.results?.find((w) => [WidgetType.BUTTON_V1, WidgetType.BUTTON_V2, WidgetType.BUTTON_V3].includes(w.widgetType));
        if (!widget) {
          this.widgetService
            .create({
              ownerId: this.ownerId,
              ownerType: this.ownerType,
              widgetType: WidgetType.BUTTON_V3,
              sourceUrl: `https://widget.resupplyapp.com/${this.environment.isProductionBuild ? 'prod' : 'stage'}/button_v3.js`,
              meta: {
                webBtnBackgroundColor: '#2c3c66',
                webBtnFontColor: '#ffffff',
                webBtnPosition: WebButtonPosition.RIGHT,
                enabled: true,
                poweredBy: true,
                webBtnCollapsable: false,
                webBtnDismissible: false,
              },
            } as Widget)
            .pipe(take(1))
            .subscribe((widget) => {
              this.init(widget);
            });
        } else {
          this.init(widget);
        }
      });
  }

  init(widget: Widget): void {
    this.widget = widget;
    this.form = new FormGroup({
      meta: new FormGroup({
        enabled: new FormControl(this.widget.meta?.enabled),
        poweredBy: new FormControl(this.widget.meta?.poweredBy),
        webBtnBackgroundColor: new FormControl(this.widget.meta?.webBtnBackgroundColor, [Validators.required]),
        webBtnFontColor: new FormControl(this.widget.meta?.webBtnFontColor, [Validators.required]),
        webBtnPosition: new FormControl(this.widget.meta?.webBtnPosition, [Validators.required]),
        webBtnCollapsable: new FormControl(this.widget.meta?.webBtnCollapsable),
        webBtnDismissible: new FormControl(this.widget.meta?.webBtnDismissible),
      }),
      widgetType: new FormControl(this.widget.widgetType, [Validators.required]),
    });
    setTimeout(() => {
      this.position = this.meta?.get('webBtnPosition').value;
    });
  }

  widgetTypeChanged() {
    this.position = undefined;
    setTimeout(() => {
      this.position = this.meta?.get('webBtnPosition').value;
    });
  }

  save() {
    this.widget.meta = new WidgetMeta(this.form.getRawValue().meta);
    if (this.isCaptain) {
      this.widget.widgetType = this.widgetType.value;
      this.widget.sourceUrl = `https://widget.resupplyapp.com/${
        this.environment.isProductionBuild ? 'prod' : 'stage'
      }/${this.widget.widgetType}.js`;
    }
    this.widgetService
      .update(this.widget.id, this.widget)
      .pipe(take(1))
      .subscribe((widget) => (this.widget = widget));
  }

  get meta(): FormGroup<{
    enabled: FormControl<boolean>;
    poweredBy: FormControl<boolean>;
    webBtnBackgroundColor: FormControl<string>;
    webBtnFontColor: FormControl<string>;
    webBtnPosition: FormControl<WebButtonPosition>;
    webBtnCollapsable: FormControl<boolean>;
    webBtnDismissible: FormControl<boolean>;
  }> {
    return this.form.get('meta') as FormGroup<{
      enabled: FormControl<boolean>;
      poweredBy: FormControl<boolean>;
      webBtnBackgroundColor: FormControl<string>;
      webBtnFontColor: FormControl<string>;
      webBtnPosition: FormControl<WebButtonPosition>;
      webBtnCollapsable: FormControl<boolean>;
      webBtnDismissible: FormControl<boolean>;
    }>;
  }

  get enabled(): FormControl<boolean> {
    return this.meta.get('enabled') as FormControl<boolean>;
  }

  get poweredBy(): FormControl<boolean> {
    return this.meta.get('poweredBy') as FormControl<boolean>;
  }

  get widgetType(): FormControl<WidgetType> {
    return this.form.get('widgetType') as FormControl<WidgetType>;
  }

  webBtnBackgroundColorChanged(): void {
    if (!(this.meta.get('webBtnBackgroundColor').value?.trim()?.length > 0)) {
      this.meta.get('webBtnBackgroundColor').setValue(WidgetMeta.INITIAL_WEB_BTN_BACKGROUND_COLOR);
    }
  }

  get webBtnBackgroundColor(): FormControl<string> {
    return this.meta.get('webBtnBackgroundColor') as FormControl<string>;
  }

  webBtnFontColorChanged(): void {
    if (!(this.meta.get('webBtnFontColor').value?.trim()?.length > 0)) {
      this.meta.get('webBtnFontColor').setValue(WidgetMeta.INITIAL_WEB_BTN_FONT_COLOR);
    }
  }

  get webBtnFontColor(): FormControl<string> {
    return this.meta.get('webBtnFontColor') as FormControl<string>;
  }

  webBtnPositionChanged(): void {
    if (!(this.meta.get('webBtnPosition').value?.trim()?.length > 0)) {
      this.meta.get('webBtnPosition').setValue(WidgetMeta.INITIAL_WEB_BTN_POSITION);
    }
    this.position = this.meta.get('webBtnPosition').value;
  }

  get webBtnPosition(): FormControl<WebButtonPosition> {
    return this.meta.get('webBtnPosition') as FormControl<WebButtonPosition>;
  }

  get webBtnCollapsable(): FormControl<boolean> {
    return this.meta.get('webBtnCollapsable') as FormControl<boolean>;
  }

  get webBtnDismissible(): FormControl<boolean> {
    return this.meta.get('webBtnDismissible') as FormControl<boolean>;
  }

  get codeSnippet() {
    return `<script type="text/javascript" src="https://widget.resupplyapp.com/${
      this.isProd ? 'prod' : 'stage'
    }/loader.js" widget_code="${this.widget.widgetCode}"></script>`;
  }

  copy() {
    navigator.clipboard.writeText(this.codeSnippet).then(() =>
      this.snackBar.open('Code snippet has been copied to clipboard successfully!', null, {
        duration: 1500,
        panelClass: 'primary',
      }),
    );
  }

  collapseBtn() {
    const el = document.getElementById('rspl-partner-button');
    if (this.webBtnDismissible.value && (!this.webBtnCollapsable.value || el.classList.contains('collapsed'))) {
      el.remove();
    } else if (this.webBtnCollapsable.value && !el.classList.contains('collapsed')) {
      el.classList.add('collapsed');
    }
  }

  disableScroll() {
    document.body.style.overflow = 'hidden';
  }

  enableScroll() {
    document.body.style.overflow = 'initial';
  }
}
