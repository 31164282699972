<div
  class="file-container"
  [ngClass]="{ error: error, disabled: disabled }"
  (click)="selectImage(file)"
  fxLayout="column"
  fxLayoutAlign="center center"
>
  @if (!isUploading) {
    <button class="primary-btn block-btn" type="button">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
        <span>Upload File</span>
      </div>
    </button>
  }
  <input #file type="file" [accept]="accept" (change)="upload($event)" />
  @if (isUploading) {
    <mat-spinner />
  }
</div>
