import { AsyncPipe } from '@angular/common';
import { Component, HostListener, Input, OnInit, output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'rspl-searchable-select',
  templateUrl: './searchable-select.component.html',
  styleUrls: ['./searchable-select.component.scss'],
  imports: [
    MatFormField,
    MatIcon,
    MatLabel,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    AsyncPipe,
    MatInput,
    MatButtonModule,
    MatSuffix,
  ],
})
export class SearchableSelectComponent implements OnInit {
  filterControl = new FormControl('');
  filteredOptions: Observable<string[]>;
  @Input() options: string[];

  filterChange = output<string>();

  ngOnInit() {
    this.filteredOptions = this.filterControl.valueChanges.pipe(
      startWith(null),
      map((value) => this._filter(value || '')),
    );
  }

  selectFilter(option: string): void {
    this.filterChange.emit(option);
    this.filterControl.setValue(option);
  }

  clearSearch(event: any) {
    event.stopPropagation();
    this.filterControl.setValue('');
    this.filterChange.emit('');
  }

  onInputChange(event: any) {
    if (!event.target.value) {
      this.filterChange.emit('');
      this.filterControl.setValue('');
    }
  }

  onBlur(event: any) {
    const optionExist = this.options.find((option) => option.toLowerCase() === event.target.value.toLowerCase());
    if (!optionExist) {
      this.filterChange.emit('');
      this.filterControl.setValue('');
    } else {
      this.filterChange.emit(optionExist);
      this.filterControl.setValue(optionExist);
    }
  }

  @HostListener('document:keyup.Enter', ['$event'])
  handleEnter(event: any) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.options.includes(event.target.value)) {
      this.filterControl.setValue('');
      this.filterChange.emit('');
    } else {
      this.filterChange.emit(event.target.value);
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) => option.toLowerCase().includes(filterValue));
  }
}
