import { autoserializeAs } from 'cerialize';

import { LeadPhoneType } from './lead-phone-type';

export class CallDetails {
  @autoserializeAs('address_recording') addressRecording: string;
  @autoserializeAs('email_recording') emailRecording: string;
  @autoserializeAs('name_recording') nameRecording: string;
  @autoserializeAs('special_recording') specialRecording: string;
  @autoserializeAs('fallback_recording') fallbackRecording: string;
  @autoserializeAs('sms_sent') smsSent: boolean;
  @autoserializeAs('hang_up') hangUp: boolean;
  @autoserializeAs('phone_type') phoneType: LeadPhoneType;
  @autoserializeAs('zendesk_ticket') zendeskTicket?: string;
  @autoserializeAs('call_sid') callSid?: string;

  constructor(payload: ICallDetails) {
    this.addressRecording = payload?.addressRecording;
    this.emailRecording = payload?.emailRecording;
    this.nameRecording = payload?.nameRecording;
    this.specialRecording = payload?.specialRecording;
    this.fallbackRecording = payload?.fallbackRecording;
    this.smsSent = payload?.smsSent;
    this.hangUp = payload?.hangUp;
    this.phoneType = payload?.phoneType;
    this.zendeskTicket = payload?.zendeskTicket;
    this.callSid = payload?.callSid;
  }
}
export interface ICallDetails {
  addressRecording: string;
  emailRecording: string;
  nameRecording: string;
  specialRecording: string;
  fallbackRecording: string;
  smsSent: boolean;
  hangUp: boolean;
  phoneType: LeadPhoneType;
  zendeskTicket?: string;
  callSid?: string;
}
