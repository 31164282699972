import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders } from '@angular/core';

import { RSPL_VERSION_CONFIG, RsplVersionConfig } from './rspl-version-config';
import { VersionCheckInterceptor } from './version-check/version-check.interceptor';

export const RSPL_APP_VERSION = new InjectionToken<string>('rspl-app-version');

export const provideRsplVersion = (config: RsplVersionConfig): EnvironmentProviders => {
  return makeEnvironmentProviders([
    { provide: RSPL_VERSION_CONFIG, useValue: config },
    { provide: HTTP_INTERCEPTORS, useClass: VersionCheckInterceptor, multi: true },
  ]);
};
