import { Inject, Injectable } from '@angular/core';
import { AppType, ENVIRONMENT, Environment, User, UserMeta } from '@domains';
import { LocalStorageService, UploadFileService, UsersService } from '@rspl-api';
import { BehaviorSubject, map, Observable, of, take } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ColumnConfig, TableConfig } from './table-config';

export const RSPL_UI_SDK_TABLE_FIND_PARAMS = 'RSPL-UI-SDK-TABLE-FIND-PARAMS';

@Injectable({
  providedIn: 'root',
})
export class TableService {
  private editMode = false;
  private editModeSubject = new BehaviorSubject<boolean>(this.editMode);
  editMode$ = this.editModeSubject.asObservable();

  private findParams: {
    [key: string]: { [key: string]: any };
  } = {};
  private findParamsSubject: BehaviorSubject<{
    [key: string]: { [key: string]: any };
  }>;
  findParams$: Observable<{
    [key: string]: { [key: string]: any };
  }>;

  private configurations: {
    [key: string]: { [key: string]: ColumnConfig } | null;
  } = {};
  private configurationsSubject = new BehaviorSubject<{
    [key: string]: { [key: string]: ColumnConfig } | null;
  }>(this.configurations);
  configurations$ = this.configurationsSubject.asObservable();

  constructor(
    @Inject(ENVIRONMENT) protected appConfig: Environment,
    private uploadService: UploadFileService,
    private userService: UsersService,
    private localStorage: LocalStorageService,
  ) {
    const params = this.localStorage.getItem(RSPL_UI_SDK_TABLE_FIND_PARAMS);
    this.findParams = params ? JSON.parse(params) : {};
    this.findParamsSubject = new BehaviorSubject<{
      [key: string]: { [key: string]: any };
    }>(this.findParams);
    this.findParams$ = this.findParamsSubject.asObservable();
    this.findParamsSubject.next(this.findParams);
  }

  setEditMode(isEditing: boolean) {
    this.editMode = isEditing;
    this.editModeSubject.next(this.editMode);
  }

  saveFindParams(id: string, findParams: any) {
    this.findParams = {
      ...this.findParams,
    };
    this.findParams[id] = findParams;
    this.localStorage.setItem(RSPL_UI_SDK_TABLE_FIND_PARAMS, JSON.stringify(this.findParams));
    this.findParamsSubject.next(this.findParams);
  }

  getFindParams(id: string): Observable<{ [key: string]: any }> {
    return this.findParams$.pipe(map((fps) => fps[id]));
  }

  loadConfigurations(configuration?: { [key: string]: { [key: string]: ColumnConfig } }) {
    this.configurations = configuration || {};
    this.configurationsSubject.next(this.configurations);
  }

  getConfiguration(id: string): Observable<{ [key: string]: ColumnConfig }> {
    return this.findParams$.pipe(map((fps) => fps[TableConfig.getConfigId(id)]));
  }

  saveConfiguration(id: string, columns: { [key: string]: ColumnConfig } | null) {
    const configurations = { ...this.configurations };
    configurations[id] = null;
    if (columns) {
      const config: { [key: string]: { index: number; hidden: boolean } } = {};
      Object.keys(columns).forEach((k) => {
        config[k] = {
          index: columns[k].index,
          hidden: !!columns[k].hidden,
        };
      });
      this.configurations[id] = config;
    }
    this.configurationsSubject.next(this.configurations);
    this.uploadConfiguration().subscribe();
  }

  private uploadConfiguration(): Observable<boolean> {
    const user = new User(JSON.parse(this.localStorage.getItem('rspl-user') || '{}'), this.appConfig.app);
    if (!user.id) return of(false);
    const file: File = new File(
      [new Blob([JSON.stringify(this.configurations)])],
      `user-${user.id}${this.appConfig.app === AppType.ZENDESK ? '-zd' : ''}-config.json`,
    );

    return this.uploadService.uploadFile(file, true).pipe(
      switchMap((uploadResponse: { url: string }) => {
        if (!user.meta?.tableConfiguration || user.meta.tableConfiguration !== uploadResponse.url) {
          const meta = new UserMeta(
            {
              ...user.meta,
              onboardingCompleted: true,
            },
            this.appConfig.app,
          );
          meta.tableConfiguration = uploadResponse.url;
          return this.userService.updateUserMeta(user.id || '', meta).pipe(
            map(() => true),
            take(1),
          );
        }
        return of(true);
      }),
      take(1),
    );
  }
}
