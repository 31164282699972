import { CdkScrollable } from '@angular/cdk/scrolling';
import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';

@Component({
  selector: 'rspl-version-warning',
  templateUrl: './version-warning.component.html',
  imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatDialogActions, MatButton],
})
export class VersionWarningComponent {
  public constructor(public reference: MatDialogRef<VersionWarningComponent>) {}

  public onChoice(choice: boolean): void {
    this.reference.close(choice);
  }
}
