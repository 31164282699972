<div class="filterable-list-wrapper" fxLayout="column">
  <div class="filter">
    <input [(ngModel)]="filter" matInput autocomplete="off" placeholder="Search..." />
  </div>
  <ul>
    @for (elem of filteredData; track elem; let i = $index) {
      <ng-container [ngTemplateOutlet]="elementTemplate" [ngTemplateOutletContext]="{ element: elem, index: i }"></ng-container>
    }
    @if (filteredData.length === 0) {
      <li class="no-results" fxLayoutAlign="center center">No results</li>
    }
  </ul>
</div>
