export enum NotificationTarget {
  current_user = 'current_user',
  donation_donor = 'donation.donor',
  lead_donor = 'lead.donor',
  donation_partner_primary_contact = 'donation.partner.primary_contact',
  donation_partner_billing_contact = 'donation.partner.billing_contact',
  donation_charity_primary_contact = 'donation.charity.primary_contact',
  donation_charity_driver_contact = 'donation.charity.driver_contact',
  donation_charity_website_contact = 'donation.charity.website_contact',
  donation_vehicle_driver_contact = 'donation.vehicle.driver_contact',
  donation_store_primary_contact = 'donation.store.primary_contact',
  donor = 'donor',
  partner_primary_contact = 'partner.primary_contact',
  partner_billing_contact = 'partner.billing_contact',
  user = 'user',
  donation_driver_users = 'donation.driver_users',
  donation_partner_users = 'donation.partner_users',
  partner_partner_users = 'partner.partner_users',
  partner_driver_users = 'partner.driver_users',
  previous_store_primary_contact = 'previous_store.primary_contact',
  previous_partner_primary_contact = 'previous_partner.primary_contact',
  budget_sponsorship_billing_contact = 'budget.sponsorship.billing_contact',
}
