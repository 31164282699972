import { HistoryEvent } from '@domains';
import { createAction, props } from '@ngrx/store';

export enum ScheduleHistoryActionType {
  GetScheduleHistory = '[Schedule History] Get Schedule History',
  GetScheduleHistorySuccess = '[Schedule History] Get Schedule History Success',
}

export const getScheduleHistory = createAction(ScheduleHistoryActionType.GetScheduleHistory, props<{ itemIds: string[] }>());
export const getScheduleHistorySuccess = createAction(
  ScheduleHistoryActionType.GetScheduleHistorySuccess,
  props<{ history: Array<HistoryEvent> }>(),
);
