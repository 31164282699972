import { NgStyle } from '@angular/common';
import { Component, HostBinding, Input, OnInit, output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel, MatPrefix, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { Charity, CharityStore, Market } from '@domains';
import { select, Store } from '@ngrx/store';
import { Destructible, FxFlexDirective } from '@rspl-ui';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { getStores } from '../../store/charity-store/charity-store.actions';
import { selectStores } from '../../store/charity-store/charity-store.selectors';

@Component({
  selector: 'app-pick-stores',
  templateUrl: './pick-stores.component.html',
  styleUrls: ['./pick-stores.component.scss'],
  imports: [
    MatFormField,
    FxFlexDirective,
    MatLabel,
    MatIcon,
    MatPrefix,
    NgStyle,
    MatSelect,
    MatInput,
    FormsModule,
    ReactiveFormsModule,
    MatButton,
    MatCheckbox,
    MatOption,
    MatSuffix,
  ],
})
export class PickStoresComponent extends Destructible implements OnInit {
  @HostBinding('style.display') display = 'flex';
  timeout;

  // tslint:disable-next-line:variable-name
  _currentCharityStoreIds: string[] = [];
  readonly selectedCharityStores = output<any[]>();

  markets: { [key: string]: Market } = {};
  charities: { [key: string]: Charity } = {};

  @Input() set marketsList(marketsList: Market[]) {
    this.markets = {};
    marketsList?.forEach((m) => (this.markets[m.id?.toString()] = m));
  }

  @Input()
  set currentCharityStoreIds(currentCharityStoreIds: string[]) {
    this._currentCharityStoreIds = currentCharityStoreIds;
    this.setCharityStores();
  }

  get currentCharityStoreIds(): string[] {
    return this._currentCharityStoreIds;
  }

  @Input() disabled = false;

  selected = [];
  filter = new FormControl<string>('');

  public charityStores$: Observable<CharityStore[]>;
  public charityStores: Array<CharityStore>;
  public charityStoreGroups: {
    [key: string]: {
      [key: string]: Array<CharityStore>;
    };
  } = {};
  // tslint:disable-next-line:variable-name
  _selectedMarkets: Array<string> = [];
  @Input() set selectedMarkets(selectedMarkets: Array<string>) {
    this._selectedMarkets = selectedMarkets?.map((id) => id?.toString());
    this.marketUpdated();
    this._filter(this.filter.value, this.charityStores);
  }

  get selectedMarkets(): Array<string> {
    return this._selectedMarkets;
  }

  charityStoreIds: {
    [key: string]: {
      [key: string]: Array<CharityStore>;
    };
  } = {};
  marketIds: string[] = [];
  charityIds: { [key: string]: Array<string> } = {};
  filteredOptions: Observable<{
    [key: string]: {
      [key: string]: Array<CharityStore>;
    };
  }>;
  visibleCharityStores: Array<CharityStore> = new Array<CharityStore>();
  filterTimeout;

  constructor(private store: Store) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(getStores({ findParams: { 'expand[]': ['charity'], per_page: 2000 } }));
    this.charityStores$ = this.store.pipe(select(selectStores), takeUntil(this.destroy$));
    this.charityStores$.subscribe((charityStores) => {
      this.charityStores = charityStores;
      this.groupCharityStores();
      this.setCharityStores();
      this._filter(this.filter.value, this.charityStores);
    });
  }

  private setCharityStores(): void {
    if (this.currentCharityStoreIds.length > 0 && this.charityStores?.length) {
      this.selected = [...this.currentCharityStoreIds];
    } else {
      this.selected = [];
    }
  }

  private _filter(
    value: string,
    charityStores: Array<CharityStore>,
  ): {
    [key: string]: {
      [key: string]: Array<CharityStore>;
    };
  } {
    let res: Array<CharityStore>;
    if (value) {
      const filterValue = value.toLowerCase();
      res = [...(charityStores || [])].filter(
        (option) =>
          (this.selectedMarkets?.length === 0 ||
            (!!option.charity.marketId && this.selectedMarkets?.includes(option.charity.marketId?.toString()))) &&
          (option.name.toLowerCase().includes(filterValue) ||
            option.charity.name.toLowerCase().includes(filterValue) ||
            (option.charity.marketId && this.markets[option.charity.marketId]?.name.toLowerCase().includes(filterValue))),
      );
    } else {
      res = [...(charityStores || [])].filter(
        (option) =>
          this.selectedMarkets?.length === 0 ||
          (!!option.charity.marketId && this.selectedMarkets?.includes(option.charity.marketId?.toString())),
      );
    }
    this.charityStoreIds = {};
    this.charityIds = {};
    res?.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    res.forEach((s: CharityStore) => {
      if (!this.charityStoreIds[s.charity.marketId?.toString()]) {
        this.charityStoreIds[s.charity.marketId?.toString()] = {};
      }
      if (!this.charityStoreIds[s.charity.marketId?.toString()][s.charity.id]) {
        this.charityStoreIds[s.charity.marketId?.toString()][s.charity.id] = new Array<CharityStore>();
      }
      this.charityStoreIds[s.charity.marketId?.toString()][s.charity.id].push(s);
    });
    Object.keys(this.charityStoreIds).forEach((market) =>
      Object.keys(this.charityStoreIds[market]).forEach((charity) =>
        this.charityStoreIds[market][charity].sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
      ),
    );
    this.marketIds = Object.keys(this.charityStoreIds).sort((m1, m2) =>
      this.markets[m1]?.name > this.markets[m2]?.name ? 1 : this.markets[m2]?.name > this.markets[m1]?.name ? -1 : 0,
    );
    Object.keys(this.charityStoreIds || {}).forEach(
      (csId) =>
        (this.charityIds[csId] = Object.keys(this.charityStoreIds[csId]).sort((c1, c2) =>
          this.charities[c1].name > this.charities[c2].name ? 1 : this.charities[c2].name > this.charities[c1].name ? -1 : 0,
        )),
    );
    this.visibleCharityStores = new Array<CharityStore>();
    Object.keys(this.charityStoreIds).forEach((m) => {
      Object.keys(this.charityStoreIds[m]).forEach((p) => {
        this.charityStoreIds[m][p].forEach((x) => this.visibleCharityStores.push(x));
      });
    });
    return this.charityStoreIds;
  }

  public charityStoresSelected(value?: any): void {
    this.selected = [...(value || [])];
    this.selectedCharityStores.emit(this.selected);
  }

  clear(event: MouseEvent): void {
    this.selected = [];
    this.charityStoresSelected();
    event.stopPropagation();
  }

  focusFilter(filterInput: HTMLInputElement, $event: boolean): void {
    if ($event) {
      filterInput.focus();
    } else {
      this.filter.patchValue('');
      this._filter(this.filter.value, this.charityStores);
    }
  }

  private groupCharityStores(): void {
    this.charityStoreGroups = {};
    this.charities = {};
    this.charityStores.forEach((s) => {
      this.charityStoreGroups[s.charity.marketId?.toString()] = this.charityStoreGroups[s.charity.marketId?.toString()] || {};
      this.charityStoreGroups[s.charity.marketId?.toString()][s.charity.id] =
        this.charityStoreGroups[s.charity.marketId?.toString()][s.charity.id] || [];
      this.charityStoreGroups[s.charity.marketId?.toString()][s.charity.id].push(s);
      this.charities[s.charity.id] = s.charity;
    });
  }

  private marketUpdated(): void {
    if (this.selectedMarkets?.length > 0) {
      this.charityStoresSelected([
        ...(this.charityStores || []).filter((t) => this.selectedMarkets?.includes(t.charity.marketId?.toString())).map((t) => t.id),
      ]);
    } else {
      this.charityStoresSelected();
    }
  }

  toggleAll(): void {
    const visibleCharityStores = this.visibleCharityStores;
    if (this.selected.length !== visibleCharityStores.length) {
      this.charityStoresSelected(visibleCharityStores.map((x) => x.id));
    } else {
      this.charityStoresSelected();
    }
  }

  isMarketSelected(marketId): boolean {
    return !Object.keys(this.charityStoreGroups[marketId]).find((p) =>
      this.charityStoreGroups[marketId][p].find((x) => !this.selected.includes(x.id)),
    );
  }

  toggleMarket(marketId): void {
    const shouldSelect = !this.isMarketSelected(marketId);
    Object.keys(this.charityStoreGroups[marketId]).forEach((p) =>
      this.charityStoreGroups[marketId][p].forEach((x) => {
        if (shouldSelect && !this.selected.includes(x.id)) {
          this.selected.push(x.id);
        }
        if (!shouldSelect && this.selected.includes(x.id)) {
          this.selected.splice(this.selected.indexOf(x.id), 1);
        }
      }),
    );
    this.charityStoresSelected(this.selected);
  }

  isCharitySelected(marketId, charityId): boolean {
    return !this.charityStoreGroups[marketId][charityId].find((x) => !this.selected.includes(x.id));
  }

  toggleCharity(marketId, charityId): void {
    const shouldSelect = !this.isCharitySelected(marketId, charityId);
    this.charityStoreGroups[marketId][charityId].forEach((x) => {
      if (shouldSelect && !this.selected.includes(x.id)) {
        this.selected.push(x.id);
      }
      if (!shouldSelect && this.selected.includes(x.id)) {
        this.selected.splice(this.selected.indexOf(x.id), 1);
      }
    });
    this.charityStoresSelected(this.selected);
  }

  filterDelayed(): void {
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }
    this.filterTimeout = setTimeout(() => this._filter(this.filter.value, this.charityStores), 150);
  }

  get marketsCount(): number {
    return Object.values(this.markets || {}).filter((x) => !!x).length;
  }
}
