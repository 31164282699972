<div class="default-filters">
  @if (smallWindowSizes.includes(windowSize)) {
    <mat-accordion>
      <mat-expansion-panel [disabled]="editMode">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Filters
            @if (!isEmpty) {
              <mat-icon class="has-filters-flag">circle</mat-icon>
            }
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container [ngTemplateOutlet]="filters"></ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  }
  @if (!smallWindowSizes.includes(windowSize)) {
    <div class="filters-wrapper">
      <ng-container [ngTemplateOutlet]="filters"></ng-container>
    </div>
  }

  <ng-template #filters>
    @if (form) {
      <form [formGroup]="form" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="left" fxLayout.lt-md="column" fxLayoutGap.lt-md="0">
        @if (isCaptain) {
          <div fxFlex="32" fxLayout="row" fxLayoutGap="1">
            <mat-button-toggle-group fxFlex="100">
              <mat-button-toggle fxFlex="50" [disabled]="editMode || disabled" [checked]="!inactive" (click)="setInactive(false)">
                Active
              </mat-button-toggle>
              <mat-button-toggle fxFlex="50" [disabled]="editMode || disabled" [checked]="inactive" (click)="setInactive(true)">
                Inactive
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        }
        <div fxFlex="32" fxLayout="column" fxLayoutGap="1">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Search by ID</mat-label>
            <input formControlName="id" matInput autocomplete="off" />
          </mat-form-field>
        </div>
        <div fxFlex="32" fxLayout="column" fxLayoutGap="1">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Search by Name</mat-label>
            <input formControlName="name" matInput autocomplete="off" />
          </mat-form-field>
        </div>

        @if (!smallWindowSizes.includes(windowSize)) {
          <button
            class="clear-filters-btn"
            mat-icon-button
            (click)="resetForm()"
            title="Clear all filters"
            type="button"
            [disabled]="editMode || isEmpty || disabled"
          >
            <mat-icon>delete</mat-icon>
          </button>
        }
        @if (smallWindowSizes.includes(windowSize)) {
          <button
            mat-raised-button
            (click)="resetForm()"
            title="Clear all filters"
            type="button"
            [disabled]="editMode || isEmpty || disabled"
          >
            Clear all filters
          </button>
        }
      </form>
    }
  </ng-template>
</div>
