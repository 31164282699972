<h2 mat-dialog-title>Request reschedule</h2>
<div mat-dialog-content>
  <div fxLayout="row" fxLayoutGap="20px">
    <p>
      Are you sure you want to request reschedule for this donation ({{ data?.donationCode }})? You may lose this job and this might affect
      your score.
    </p>
  </div>
  <div fxLayout="column" fxLayoutGap="20px">
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Reason</mat-label>
      <mat-hint>Please choose a reason why you are declining</mat-hint>
      <mat-select
        matInput
        [formControl]="reason"
        panelClass="pick-us-state"
        disableOptionCentering="true"
        (selectionChange)="setSelectedReason()"
      >
        @for (option of reasons; track option) {
          <mat-option [value]="option">
            {{ option.reason }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    @if (selectedReason?.children) {
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>Sub-Reason</mat-label>
        <mat-select matInput [formControl]="subReason" panelClass="pick-us-state" disableOptionCentering="true">
          @for (option of selectedReason?.children; track option) {
            <mat-option [value]="option">
              {{ option }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    }
    <mat-form-field fxFlex appearance="outline">
      <mat-label>Notes</mat-label>
      <textarea [formControl]="additionalText" matInput></textarea>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end">
  <button mat-button (click)="onChoice(false)">Keep Job</button>
  <button mat-raised-button color="warn" (click)="onChoice(true)">Request Reschedule</button>
</div>
