import { autoserialize } from 'cerialize';

import { CourierType } from './courier-type';
import { Pricing } from './pricing';

export class Quote implements IQuote {
  @autoserialize courier: CourierType;
  @autoserialize quote_id: string;
  @autoserialize store_id: string;
  @autoserialize expires_at: string | null;
  @autoserialize quote_type: string;
  @autoserialize total_amount: number; //original quote
  @autoserialize discount_amount: number;

  constructor(payload: IQuote) {
    this.courier = payload?.courier;
    this.quote_id = payload?.quote_id;
    this.store_id = payload?.store_id;
    this.expires_at = payload?.expires_at;
    this.quote_type = payload?.quote_type;
    this.total_amount = payload?.total_amount;
    this.discount_amount = payload?.discount_amount;
  }

  public static OnSerialized(instance: Quote, json: Quote): void {
    json.total_amount = json.total_amount !== null ? json.total_amount * 100 : null;
    json.discount_amount = json.discount_amount !== null ? json.discount_amount * 100 : null;
  }

  public static OnDeserialized(instance: Quote): void {
    instance.total_amount = instance.total_amount ? Pricing.formatPrice(instance.total_amount / 100) : null;
    instance.discount_amount = instance.discount_amount ? Pricing.formatPrice(instance.discount_amount / 100) : null;
  }
}

export interface IQuote {
  courier: CourierType;
  quote_id: string;
  store_id: string;
  expires_at: string | null;
  quote_type: string;
  total_amount: number;
  discount_amount: number;
}
