<rspl-loader />
<mat-sidenav-container
  id="sidenavContainer"
  autosize
  class="sidebar-container {{ appType }}"
  [hasBackdrop]="false"
  [ngStyle]="{ 'padding-top.px': headerHeight }"
  [ngClass]="{ onboarding: !onboardingCompleted }"
  #container
>
  <mat-sidenav
    fixedInViewport
    [fixedTopGap]="52"
    #drawer
    class="main-sidenav"
    #sidenav
    mode="over"
    [opened]="isSmallWindowSize || !stickyMenu || environment.isZendesk ? false : true"
    [ngClass]="{ expanded: menuExpanded || !stickyMenu || isSmallWindowSize, sticky: stickyMenu && !isSmallWindowSize }"
    (closedStart)="isSmallWindowSize || !stickyMenu ? undefined : drawer.open()"
    (openedChange)="isSmallWindowSize || !stickyMenu ? undefined : openChanged()"
  >
    <div class="main-navigation" fxLayout="column">
      @if (auth) {
        <ng-container [ngTemplateOutlet]="Menu"></ng-container>
      }
    </div>
  </mat-sidenav>
  <mat-sidenav-content [ngStyle]="{ 'min-height': 'calc(100vh - ' + headerHeight + 'px)' }">
    <div class="backdrop" [ngClass]="{ visible: menuExpanded || (drawer.opened && !stickyMenu) }" (click)="backdropClick()"></div>

    <mat-toolbar class="header {{ appType }}">
      <mat-toolbar-row [style]="isSmallWindowSize || environment.isZendesk ? '' : 'padding-left: 2px;'">
        @if (isStaging || isDemo || isDev) {
          <div class="header-staging">
            {{ buildType }}
          </div>
        }
        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100" class="header-toolbar">
          <div class="main-menu" fxLayout="row" fxLayoutAlign="start center">
            @if (onboardingCompleted && !isZendesk) {
              <button
                (click)="isSmallWindowSize || !stickyMenu ? drawer.toggle() : (menuExpanded = !menuExpanded)"
                [disabled]="editMode"
                class="icon-menu"
                [ngClass]="{
                  opened: isSmallWindowSize || !stickyMenu ? drawer.opened : menuExpanded,
                }"
              >
                <div class="bar bar1"></div>
                <div class="bar bar2"></div>
                <div class="bar bar3"></div>
                @if (showMenuFlag) {
                  <div class="menu-flag"></div>
                }
              </button>
            }
            <a [routerLink]="editMode || isZendesk ? null : rootPath" class="app-logo">
              <mat-icon svgIcon="header-logo" />
            </a>
          </div>
          <div fxLayout="row" fxLayoutGap="20px"></div>
          <div class="account-menu">
            @if ((isCharity || isTpl) && zendesk) {
              <a
                mat-icon-button
                matTooltip="Help Center"
                [matTooltipShowDelay]="500"
                target="_blank"
                [href]="zendesk"
                class="help-center-icon"
              >
                <mat-icon>help_outline</mat-icon>
              </a>
            }
            <button mat-icon-button [matMenuTriggerFor]="menu" [disabled]="editMode" aria-label="Account">
              <mat-icon svgIcon="user-icon" />
            </button>
            <mat-menu #menu="matMenu" class="user-menu">
              <h4>Account</h4>
              <div fxLayout="row" fxLayoutAlign="start start" class="user-header" fxLayoutGap="10px">
                @if (auth.name) {
                  <span class="user-img" fxLayoutAlign="center center">
                    {{ auth.name[0] }}
                  </span>
                }
                <div fxLayout="column" fxLayoutAlign="start start" class="name-wrapper">
                  <p class="name">{{ auth.name }}</p>
                  <p class="email">{{ auth.email }}</p>
                </div>
              </div>
              @if (onboardingCompleted && !isZendesk) {
                <a mat-menu-item [routerLink]="['/', 'users', 'profile']">
                  <mat-icon>manage_accounts</mat-icon>
                  <span>Account settings</span>
                </a>
              }
              @if ((isTpl || isCharity) && onboardingCompleted) {
                <hr />
              }
              @if ((isTpl || isCharity) && onboardingCompleted) {
                <h4>
                  {{ isTpl ? 'Partner' : 'Charity' }}
                </h4>
              }
              @if ((isTpl || isCharity) && onboardingCompleted) {
                <div fxLayout="row" fxLayoutAlign="start start" class="organization-header" fxLayoutGap="10px">
                  @if (isTpl || (isCharity && !charity?.logo)) {
                    <span class="organization-img" fxLayoutAlign="center center">
                      {{ (partner || charity)?.name[0] }}
                    </span>
                  }
                  @if (charity?.logo) {
                    <div class="logo-wrapper" fxLayoutAlign="center center">
                      <img class="logo" [src]="charity.logo" />
                    </div>
                  }
                  <div fxLayout="column" fxLayoutAlign="center start" class="name">
                    <p>{{ (partner || charity)?.name }}</p>
                  </div>
                </div>
              }
              @if ((isTpl || isCharity) && onboardingCompleted) {
                <a mat-menu-item [routerLink]="isStore ? ['/', 'stores', 'settings'] : ['/', 'settings']">
                  <mat-icon>settings</mat-icon>
                  <span>{{ isTpl ? 'Partner' : 'Charity' }} Settings</span>
                </a>
              }
              <hr />
              <div fxLayout="column">
                <h4>Zoom</h4>
                <div fxLayout="row" class="user-zoom">
                  <button mat-button (click)="updateZoomLevelDown($event)">
                    <mat-icon>zoom_out</mat-icon>
                  </button>
                  <span class="zoom-level" fxLayoutAlign="center center">{{ zoomLevel }}%</span>
                  <button mat-button (click)="updateZoomLevelUp($event)">
                    <mat-icon>zoom_in</mat-icon>
                  </button>
                </div>
              </div>
              <hr />
              <div fxLayout="column">
                <h4>Theme</h4>
                <div fxLayout="row" class="user-theme" fxFlex="100">
                  <button
                    class="light"
                    mat-button
                    [fxFlex]="100 / 3"
                    [ngClass]="{ active: !autoTheme && theme === themes.LIGHT }"
                    (click)="setTheme($event, themes.LIGHT)"
                  >
                    <div fxLayout="column" fxLayoutAlign="center center">
                      <mat-icon svgIcon="theme-dark" class="theme-background" />
                      <mat-icon svgIcon="theme-light" />
                      <span class="theme-title">Light</span>
                    </div>
                  </button>
                  <button class="auto" mat-button [fxFlex]="100 / 3" [ngClass]="{ active: autoTheme }" (click)="setTheme($event)">
                    <div fxLayout="column" fxLayoutAlign="center center">
                      <mat-icon svgIcon="theme-dark" class="theme-background" />
                      <mat-icon svgIcon="theme-auto" />
                      <span class="theme-title">Auto</span>
                    </div>
                  </button>
                  <button
                    class="dark"
                    mat-button
                    [fxFlex]="100 / 3"
                    [ngClass]="{ active: !autoTheme && theme === themes.DARK }"
                    (click)="setTheme($event, themes.DARK)"
                  >
                    <div fxLayout="column" fxLayoutAlign="center center">
                      <mat-icon svgIcon="theme-dark" class="theme-background" />
                      <mat-icon svgIcon="theme-dark" />
                      <span class="theme-title">Dark</span>
                    </div>
                  </button>
                </div>
              </div>
              @if (!isSmallWindowSize) {
                <hr />
              }
              @if (!isSmallWindowSize) {
                <div fxLayout="column">
                  <h4>Sidebar Menu</h4>
                  <div fxLayout="row" class="menu-type" fxFlex="100">
                    <button class="light" mat-button fxFlex="50" [ngClass]="{ active: stickyMenu }" (click)="setStickyMenu($event, true)">
                      <div fxLayout="column" fxLayoutAlign="center center">
                        <mat-icon>first_page</mat-icon>
                        <span class="theme-title">Dock</span>
                      </div>
                    </button>
                    <button class="auto" mat-button fxFlex="50" [ngClass]="{ active: !stickyMenu }" (click)="setStickyMenu($event, false)">
                      <div fxLayout="column" fxLayoutAlign="center center">
                        <mat-icon>keyboard_double_arrow_left</mat-icon>
                        <span class="theme-title">Hide</span>
                      </div>
                    </button>
                  </div>
                </div>
              }
              <hr />
              <button mat-menu-item (click)="logout()">
                <mat-icon>logout</mat-icon>
                <span>Sign Out</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </mat-toolbar-row>
      @if (pageHeaderPosition && onboardingCompleted) {
        <mat-toolbar-row
          [ngStyle]="{
            width: 'calc(100vw - ' + pageHeaderPosition.toolbar.reduceWidthBy + 'px)',
            'margin-left.px': pageHeaderPosition.toolbar.left,
            'margin-top.px': -52 * pageHeaderPosition.scrollPercent,
            'height.px': pageHeaderPosition.toolbar.height,
            'padding.px': (isSmallWindowSize ? 15 : 30) * (1 - pageHeaderPosition.scrollPercent),
          }"
        >
          <div
            #pageHeaderDiv
            class="page-header"
            fxLayout="row"
            fxLayoutAlign="space-between"
            fxFlex="100"
            [ngStyle]="{
              'min-height.px': isSmallWindowSize
                ? undefined
                : pageHeaderPosition.toolbar.height + (1 - pageHeaderPosition.scrollPercent) * (75 - pageHeaderPosition.toolbar.height),
            }"
          >
            <div
              class="page-title-wrapper"
              fxLayout="row"
              fxLayoutAlign="start start"
              [ngStyle]="{
                'margin-bottom': pageHeaderPosition.scrollPercent === 1 ? 0 : undefined,
              }"
            >
              @if (pageHeader?.hasBackButton) {
                <button
                  matTooltip="Back"
                  [matTooltipShowDelay]="500"
                  [ngStyle]="{
                    'margin-top.px': 5 * pageHeaderPosition.scrollPercent,
                    'border-color': pageHeaderPosition.back['border-color'],
                    scrolled: pageHeaderPosition.buttons.scrolled,
                  }"
                  class="back-button"
                  mat-icon-button
                  (click)="backService.navigateTo(router, pageHeader?.back, pageHeader?.queryParams); backdropClick()"
                  [disabled]="editMode"
                >
                  <mat-icon
                    [ngStyle]="{
                      color: pageHeaderPosition.back.color,
                    }"
                  >
                    arrow_back
                  </mat-icon>
                </button>
              }
              <div class="title" [ngStyle]="{ 'max-width': pageHeaderPosition.title.width }">
                <h1
                  [ngStyle]="{
                    'margin-top.px': 11 * pageHeaderPosition.scrollPercent,
                    filter: pageHeaderPosition.title.filter,
                    'font-size': pageHeaderPosition.title['font-size'],
                  }"
                  [innerHTML]="pageHeader?.pageTitle"
                ></h1>
                <p
                  [ngStyle]="{
                    opacity: 1 - pageHeaderPosition.scrollPercent,
                    'min-height.px': 20 * (pageHeaderPosition.scrollPercent > 0.8 ? 1 - pageHeaderPosition.scrollPercent : 1),
                    'height.px': 20 * (pageHeaderPosition.scrollPercent > 0.8 ? 1 - pageHeaderPosition.scrollPercent : 1),
                  }"
                >
                  {{ pageHeader?.subtitle }}
                </p>
              </div>
            </div>
            @if (!smallWindowSizes.includes(windowSize)) {
              <div
                class="actions"
                fxLayout="row wrap"
                fxLayoutAlign="end"
                [ngStyle]="{
                  'margin-top.px': pageHeaderPosition.scrollPercent * 10,
                }"
              >
                @for (action of pageHeader?.actions; track action) {
                  <ng-container
                    [ngTemplateOutlet]="!!action.link ? Link : !!action.externalLink ? ExternalLink : Button"
                    [ngTemplateOutletContext]="{ action: action }"
                  ></ng-container>
                }
              </div>
            }
            @if (smallWindowSizes.includes(windowSize) && visibleActionsCnt > 0) {
              <div class="actions">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  class="more-options"
                  [ngStyle]="{
                    'margin-top.px': 5 * pageHeaderPosition.scrollPercent,
                    'border-color': pageHeaderPosition.back['border-color'],
                    'background-color': pageHeaderPosition.buttons['background-color'],
                    scrolled: pageHeaderPosition.buttons.scrolled,
                  }"
                  fxLayoutAlign="center center"
                  [disabled]="isSmallWindowSize || !stickyMenu ? sidenav.opened : menuExpanded"
                >
                  <mat-icon
                    [ngStyle]="{
                      color: pageHeaderPosition.back.color,
                    }"
                  >
                    more_vert
                  </mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  @for (action of pageHeader?.actions; track action) {
                    <ng-container
                      [ngTemplateOutlet]="!!action.link ? DropdownLink : !!action.externalLink ? DropdownExternalLink : DropdownButton"
                      [ngTemplateOutletContext]="{ action: action }"
                    ></ng-container>
                  }
                </mat-menu>
              </div>
            }
          </div>
        </mat-toolbar-row>
      }
    </mat-toolbar>

    <div
      class="content"
      [ngClass]="{ 'captain-app': isCaptain, zendesk: isZendesk }"
      [style]="isSmallWindowSize || environment.isZendesk || !stickyMenu ? '' : 'padding-left: 95px'"
    >
      @if (isStaging) {
        <div class="staging-bg"></div>
      }
      @if (isDev && !isTest) {
        <div class="dev-bg"></div>
      }
      @if (isTest) {
        <div class="test-bg"></div>
      }
      <div [style.zoom]="zoom">
        @if (navigating) {
          <div fxFlex="1" fxLayoutAlign="center center" style="height: calc(100vh - 200px)">
            <div style="width: 100px; height: 100px; position: relative">
              <mat-spinner />
              <mat-icon svgIcon="rspl-logo-small" style="position: absolute; left: 25px; top: 25px; height: 50px; width: 50px" />
            </div>
          </div>
        } @else {
          <router-outlet />
        }
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #ButtonContent let-action="action">
  @if (action.icon && pageHeaderPosition.buttons.showIcon) {
    <mat-icon
      [ngStyle]="{ opacity: pageHeaderPosition.buttons.iconOpacity }"
      [matTooltip]="action.actionTitle"
      [matTooltipShowDelay]="500"
      [svgIcon]="action.icon.name"
    >
      {{ action.icon }}
    </mat-icon>
  }
  <span
    style="overflow: hidden; display: flex"
    [ngStyle]="{
      opacity: pageHeaderPosition.buttons.txtOpacity,
      'font-size': (1 - pageHeaderPosition.scrollPercent) * 14 + 'px',
    }"
  >
    {{ action.actionTitle }}
  </span>
</ng-template>
<ng-template #Button let-action="action">
  @if (!action.hidden || !action.hidden()) {
    <button
      mat-raised-button
      [ngClass]="{
        hidden: !!action.hidden && !!action.hidden(),
        scrolled: pageHeaderPosition.buttons.showIcon,
      }"
      class="{{ action.cssClass ? action.cssClass() : '' }}"
      [ngStyle]="{
        'border-color': pageHeaderPosition.buttons['border-color'],
        padding: pageHeaderPosition.buttons.padding,
        'background-color': pageHeaderPosition.buttons['background-color'],
      }"
      (click)="action.actionMethod()"
      [disabled]="(isSmallWindowSize || !stickyMenu ? sidenav.opened : menuExpanded) || (!!action.disabled && !!action.disabled())"
    >
      <ng-container [ngTemplateOutlet]="ButtonContent" [ngTemplateOutletContext]="{ action: action }"></ng-container>
    </button>
  }
</ng-template>
<ng-template #Link let-action="action">
  @if (!action.hidden || !action.hidden()) {
    <a
      mat-raised-button
      [ngClass]="{
        hidden: !!action.hidden && !!action.hidden(),
        scrolled: pageHeaderPosition.buttons.showIcon,
      }"
      class="{{ action.cssClass ? action.cssClass() : '' }}"
      [ngStyle]="{
        'border-color': pageHeaderPosition.buttons['border-color'],
        padding: pageHeaderPosition.buttons.padding,
        'background-color': pageHeaderPosition.buttons['background-color'],
      }"
      [routerLink]="(isSmallWindowSize || !stickyMenu ? sidenav.opened : menuExpanded) ? null : action.link"
      [disabled]="(isSmallWindowSize || !stickyMenu ? sidenav.opened : menuExpanded) || (!!action.disabled && !!action.disabled())"
    >
      <ng-container [ngTemplateOutlet]="ButtonContent" [ngTemplateOutletContext]="{ action: action }"></ng-container>
    </a>
  }
</ng-template>
<ng-template #ExternalLink let-action="action">
  @if (!action.hidden || !action.hidden()) {
    <a
      mat-raised-button
      [ngClass]="{
        hidden: !!action.hidden && !!action.hidden(),
        scrolled: pageHeaderPosition.buttons.showIcon,
      }"
      class="{{ action.cssClass ? action.cssClass() : '' }}"
      [ngStyle]="{
        'border-color': pageHeaderPosition.buttons['border-color'],
        padding: pageHeaderPosition.buttons.padding,
        'background-color': pageHeaderPosition.buttons['background-color'],
      }"
      [href]="(isSmallWindowSize || !stickyMenu ? sidenav.opened : menuExpanded) ? null : action.externalLink"
      [target]="action.target || '_blank'"
      [disabled]="(isSmallWindowSize || !stickyMenu ? sidenav.opened : menuExpanded) || (!!action.disabled && !!action.disabled())"
    >
      <ng-container [ngTemplateOutlet]="ButtonContent" [ngTemplateOutletContext]="{ action: action }"></ng-container>
    </a>
  }
</ng-template>
<ng-template #DropdownButton let-action="action">
  @if (!action.hidden || !action.hidden()) {
    <button
      mat-menu-item
      [ngClass]="{ hidden: !!action.hidden && !!action.hidden() }"
      (click)="action.actionMethod()"
      [disabled]="(isSmallWindowSize || !stickyMenu ? sidenav.opened : menuExpanded) || (!!action.disabled && !!action.disabled())"
      class="{{ action.cssClass ? action.cssClass() : '' }}"
    >
      {{ action.actionTitle }}
    </button>
  }
</ng-template>
<ng-template #DropdownLink let-action="action">
  @if (!action.hidden || !action.hidden()) {
    <a
      mat-menu-item
      [ngClass]="{ hidden: !!action.hidden && !!action.hidden() }"
      [routerLink]="(isSmallWindowSize || !stickyMenu ? sidenav.opened : menuExpanded) ? null : action.link"
      [disabled]="(isSmallWindowSize || !stickyMenu ? sidenav.opened : menuExpanded) || (!!action.disabled && !!action.disabled())"
      class="{{ action.cssClass ? action.cssClass() : '' }}"
    >
      {{ action.actionTitle }}
    </a>
  }
</ng-template>
<ng-template #DropdownExternalLink let-action="action">
  @if (!action.hidden || !action.hidden()) {
    <a
      mat-menu-item
      [ngClass]="{ hidden: !!action.hidden && !!action.hidden() }"
      [href]="(isSmallWindowSize || !stickyMenu ? sidenav.opened : menuExpanded) ? null : action.externalLink"
      [disabled]="(isSmallWindowSize || !stickyMenu ? sidenav.opened : menuExpanded) || (!!action.disabled && !!action.disabled())"
      class="{{ action.cssClass ? action.cssClass() : '' }}"
      [target]="action.target || '_blank'"
    >
      {{ action.actionTitle }}
    </a>
  }
</ng-template>

<ng-template #MenuItemContent let-menuItem="menuItem">
  <mat-icon [svgIcon]="menuItem.svgIcon">{{ menuItem.icon }}</mat-icon>
  <span>{{ menuItem.title() }}</span>
  @if (menuItem.counter && (this[menuItem.counter] || 0) > 0) {
    <div class="badge-wrapper" fxFlex fxLayoutAlign="end center">
      <span class="badge" fxLayoutAlign="center center">{{ this[menuItem.counter] > 99 ? '99+' : this[menuItem.counter] }}</span>
    </div>
  }
  @if (menuItem.subtitle && menuItem.subtitle.show({ partner: partner }) && menuItem.subtitle.isWarning) {
    <mat-icon class="badge-warn" color="warn" [matTooltipShowDelay]="1000" [matTooltip]="menuItem.subtitle.value">warning</mat-icon>
  }
</ng-template>
<ng-template #SingleMenuItem let-menuItem="menuItem" let-isSubmenu="isSubmenu">
  @if (
    menuItem.route &&
    (!menuItem.visible ||
      menuItem.visible({
        partner: partner,
        charity: charity,
        onboardingCompleted: onboardingCompleted,
        permissions: auth?.permissions,
        roles: auth.authorization.roles,
      }))
  ) {
    <a
      class="nav-item"
      fxLayout="row"
      [ngClass]="{ disabled: menuItem.smallScreenDisabled || editMode, 'sub-menu': isSubmenu }"
      fxLayoutAlign="start center"
      [routerLink]="menuItem.route"
      routerLinkActive="active"
      [matTooltip]="
        isSubmenu || menuExpanded || isSmallWindowSize
          ? undefined
          : menuItem.title() +
            (menuItem.subtitle && menuItem.subtitle.show({ partner: partner }) ? menuItem.subtitle.value : '') +
            (menuItem.counter && (this[menuItem.counter] || 0) > 0
              ? ' (' + (this[menuItem.counter] > 99 ? '99+' : this[menuItem.counter]) + ')'
              : '')
      "
      [matTooltipShowDelay]="300"
      matTooltipPosition="right"
      (click)="backdropClick()"
    >
      @if (!menuItem.children) {
        <ng-container [ngTemplateOutlet]="MenuItemContent" [ngTemplateOutletContext]="{ menuItem: menuItem }"></ng-container>
      }
    </a>
  }
  @if (
    menuItem.externalLink &&
    (!menuItem.visible ||
      menuItem.visible({
        partner: partner,
        charity: charity,
        onboardingCompleted: onboardingCompleted,
        permissions: auth?.permissions,
        roles: auth.authorization.roles,
      }))
  ) {
    <a
      class="nav-item"
      fxLayout="row"
      [ngClass]="{ disabled: menuItem.smallScreenDisabled || editMode, 'sub-menu': isSubmenu }"
      fxLayoutAlign="start center"
      target="_blank"
      [href]="menuItem.externalLink"
      [matTooltip]="
        isSubmenu || menuExpanded || isSmallWindowSize
          ? undefined
          : menuItem.title() +
            (menuItem.subtitle && menuItem.subtitle.show({ partner: partner }) ? menuItem.subtitle.value : '') +
            (menuItem.counter && (this[menuItem.counter] || 0) > 0
              ? ' (' + (this[menuItem.counter] > 99 ? '99+' : this[menuItem.counter]) + ')'
              : '')
      "
      [matTooltipShowDelay]="300"
      matTooltipPosition="right"
      (click)="backdropClick()"
    >
      @if (!menuItem.children) {
        <ng-container [ngTemplateOutlet]="MenuItemContent" [ngTemplateOutletContext]="{ menuItem: menuItem }"></ng-container>
      }
    </a>
  }
</ng-template>
<ng-template #Menu>
  <mat-accordion>
    @for (menuItem of environment.menu; track menuItem) {
      @if (!menuItem.children) {
        <ng-container
          [ngTemplateOutlet]="SingleMenuItem"
          [ngTemplateOutletContext]="{ menuItem: menuItem, isSubmenu: false }"
        ></ng-container>
      }
      @if (menuItem.children) {
        @if (
          !isSmallWindowSize &&
          (!menuItem.visible ||
            menuItem.visible({
              partner: partner,
              charity: charity,
              onboardingCompleted: onboardingCompleted,
              permissions: auth?.permissions,
              roles: auth.authorization.roles,
            })) &&
          !menuExpanded &&
          stickyMenu
        ) {
          <a
            class="nav-item"
            fxLayout="row"
            fxLayoutAlign="start center"
            (click)="subMenuTrigger.openMenu()"
            [ngClass]="{
              disabled: editMode,
              active: isMenuExpanded(menuItem.expanded, currentPath),
              expanded: subMenuTrigger.menuOpen,
            }"
            [matTooltip]="
              menuExpanded || isSmallWindowSize
                ? undefined
                : menuItem.title() +
                  (menuItem.subtitle && menuItem.subtitle.show({ partner: partner }) ? menuItem.subtitle.value : '') +
                  (menuItem.counter && (this[menuItem.counter] || 0) > 0
                    ? ' (' + (this[menuItem.counter] > 99 ? '99+' : this[menuItem.counter]) + ')'
                    : '')
            "
            [matTooltipShowDelay]="300"
            matTooltipPosition="right"
          >
            <mat-icon [svgIcon]="menuItem.svgIcon">{{ menuItem.icon }}</mat-icon>
            <span>{{ menuItem.title() }}</span>
            <div #subMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="subMenu"></div>
          </a>
        }
        <mat-menu #subMenu="matMenu" class="side-menu-submenu" xPosition="after" [overlapTrigger]="true">
          <h3>{{ menuItem.title() }}</h3>
          @for (subMenuItem of menuItem.children; track subMenuItem) {
            <ng-container
              [ngTemplateOutlet]="SingleMenuItem"
              [ngTemplateOutletContext]="{
                menuItem: subMenuItem,
                isSubmenu: true,
              }"
            ></ng-container>
          }
        </mat-menu>
        @if (
          (!menuItem.visible ||
            menuItem.visible({
              partner: partner,
              charity: charity,
              onboardingCompleted: onboardingCompleted,
              permissions: auth?.permissions,
              roles: auth.authorization.roles,
            })) &&
          (isSmallWindowSize || menuExpanded || !stickyMenu)
        ) {
          <mat-expansion-panel
            #configExpansionPanel
            [class.mat-elevation-z0]="true"
            [expanded]="isMenuExpanded(menuItem.expanded, currentPath)"
          >
            <mat-expansion-panel-header class="nav-item">
              <a fxLayout="row" fxLayoutAlign="start center">
                <mat-icon [svgIcon]="menuItem.svgIcon">{{ menuItem.icon }}</mat-icon>
                <span>{{ menuItem.title() }}</span>
              </a>
            </mat-expansion-panel-header>

            @for (subMenuItem of menuItem.children; track subMenuItem) {
              <ng-container
                [ngTemplateOutlet]="SingleMenuItem"
                [ngTemplateOutletContext]="{
                  menuItem: subMenuItem,
                  isSubmenu: true,
                }"
              ></ng-container>
            }
          </mat-expansion-panel>
        }
      }
    }
  </mat-accordion>
</ng-template>
