import { NgTemplateOutlet } from '@angular/common';
import { Component, Input, TemplateRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { FxLayoutAlignDirective, FxLayoutDirective } from '@rspl-ui';

@Component({
  selector: 'app-filterable-list',
  templateUrl: './filterable-list.component.html',
  styleUrls: ['./filterable-list.component.scss'],
  imports: [FxLayoutDirective, MatInput, FormsModule, NgTemplateOutlet, FxLayoutAlignDirective],
})
export class FilterableListComponent {
  @Input() additionalData: any;
  #data: Array<any>;
  @Input() set data(data: Array<any>) {
    this.#data = data;
    this.filterData();
  }
  get data(): Array<any> {
    return this.#data;
  }
  @Input() elementTemplate: TemplateRef<any>;
  @Input() filterFn: (elements: Array<any>, filter: string, additionalData?: any) => Array<any> = (elements, filter) =>
    filter?.length > 0 ? elements?.filter((e) => e['name'].includes(filter)) : elements;
  filteredData: Array<any>;

  #filter = '';
  set filter(filter: string) {
    this.#filter = filter;

    this.filterData();
  }
  get filter(): string {
    return this.#filter;
  }

  filterData() {
    this.filteredData = this.filterFn ? this.filterFn(this.data, this.filter, this.additionalData) : this.data;
  }
}
