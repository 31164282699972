import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, Environment, Organization } from '@domains';
import { Deserialize } from 'cerialize';

import { BaseApiService } from '../base-api.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService extends BaseApiService<Organization> {
  override deserialize(data: any): Organization {
    const res = new Organization({
      ...Deserialize(data, Organization),
    });
    if (res.logo && !res.logo.startsWith('http')) {
      res.logo = this.config.urls.baseUrl + res.logo;
    }
    res.setInitialValue();
    return res;
  }

  constructor(
    @Inject(ENVIRONMENT) override config: Environment,
    override http: HttpClient,
  ) {
    super(config, http, 'organizations', Organization, 'Organization');
  }
}
