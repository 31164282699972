import { autoserialize, autoserializeAs } from 'cerialize';

import { DonationDiscount } from './donation-discount';
import { Specification } from './specification';
import { SponsorshipAlgorithm } from './sponsorship-algorithm';

export class Pricing {
  @autoserialize small: number;
  @autoserialize medium: number;
  @autoserialize large: number;
  @autoserialize xlarge: number;
  @autoserialize staircases: number;
  @autoserialize elevator: number;
  @autoserialize disassembly: number;
  @autoserialize base: number;
  @autoserialize recycling: number;
  @autoserializeAs('fuel_fee') fuelFee: number | null;
  @autoserializeAs('cancellation_fee') cancellationFee: number | null;
  @autoserializeAs('booking_fee') bookingFee: number | null;
  @autoserialize courier_fee: number | null;

  constructor(payload?: IPricing) {
    this.base = payload?.base || 0;
    this.small = payload?.small || 0;
    this.medium = payload?.medium || 0;
    this.large = payload?.large || 0;
    this.xlarge = payload?.xlarge || 0;
    this.staircases = payload?.staircases || 0;
    this.elevator = payload?.elevator || null;
    this.disassembly = payload?.disassembly || 0;
    this.recycling = payload?.recycling || 0;
    this.fuelFee = payload?.fuelFee || null;
    this.cancellationFee = payload?.cancellationFee || null;
    this.bookingFee = payload?.bookingFee || null;
    this.courier_fee = payload?.courier_fee || null;
  }

  public static OnSerialized(instance: Pricing, json: any): void {
    Pricing.pricingDolarsToCents(json);
  }

  public static OnDeserialized(instance: Pricing): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    instance = Pricing.pricingCentsToDolars(instance);
  }

  public static pricingDolarsToCents(pricing: any): any {
    Object.keys(pricing)
      .filter((x) => !['fuelFee', 'fuel_fee'].includes(x))
      .forEach((key) => {
        pricing[key] = pricing[key] !== null ? this.formatPrice(pricing[key] * 100) : null;
      });
    if (pricing.fuelFee) pricing.fuelFee = pricing.fuelFee !== null ? pricing.fuelFee / 100 : null;
    if (pricing.fuel_fee) pricing.fuel_fee = pricing.fuel_fee !== null ? pricing.fuel_fee / 100 : null;
    return pricing;
  }

  public static pricingCentsToDolars(pricing: any): any {
    Object.keys(pricing)
      .filter((x) => !['fuelFee', 'fuel_fee'].includes(x))
      .forEach((key) => {
        pricing[key] = pricing[key] !== null ? this.formatPrice(pricing[key] / 100) : null;
      });
    pricing.fuelFee = pricing.fuelFee !== null ? this.formatPrice(pricing.fuelFee * 100) : null;
    return pricing;
  }

  public static formatPrice(price: number): number {
    return Math.round(price * 100) / 100;
  }

  public static getTotalBasePrice(spec: Specification, pricing: Pricing): number {
    const price =
      pricing.base +
      pricing.small * (spec.small || 0) +
      pricing.medium * (spec.medium || 0) +
      pricing.large * (spec.large || 0) +
      pricing.xlarge * (spec.xlarge || 0) +
      pricing.staircases * (spec.staircases || 0) +
      pricing.elevator * (spec.elevator || 0) +
      pricing.disassembly * (spec.disassembly || 0) +
      pricing.recycling * (spec.recycling || 0);
    return price;
  }

  public static getTotalBasePriceWithFuelFee(spec: Specification, pricing: Pricing) {
    if (!spec) return 0;
    const price = this.getTotalBasePrice(spec, pricing);
    return price + ((pricing.fuelFee || 0) / 100) * price;
  }

  public static getTotalPrice(spec: Specification, pricing: Pricing, gratuity?: number): number {
    const price = this.getTotalBasePrice(spec, pricing);
    return price + ((pricing.fuelFee || 0) / 100) * price + (gratuity ?? 0);
  }

  public static getTotalPriceFormatted(spec: Specification, pricing: Pricing, gratuity?: number): number {
    return this.formatPrice(this.getTotalPrice(spec, pricing) + (gratuity || 0));
  }

  public static calculateSponsoredAmount(
    specification?: Specification,
    pricing?: Pricing,
    discount?: DonationDiscount,
    sponsorship_algorithm?: SponsorshipAlgorithm,
    donatableSmall?: number,
  ): number {
    if (!specification || !pricing || !discount || !sponsorship_algorithm) return 0;
    const total = this.getTotalBasePriceWithFuelFee(specification, pricing);

    if (sponsorship_algorithm.version === 'fixed') {
      return total - Math.max(total - discount.max, sponsorship_algorithm.min_quote);
    } else {
      return Math.min(
        Math.min(Math.min(discount?.maxSmallSpecification, donatableSmall) * discount?.small, discount?.max),
        total - sponsorship_algorithm?.min_quote,
      );
    }
  }
}

export interface IPricing {
  small: number;
  medium: number;
  large: number;
  xlarge: number;
  staircases: number;
  elevator: number;
  disassembly: number;
  base: number;
  recycling: number;
  fuelFee: number | null;
  cancellationFee: number | null;
  bookingFee: number | null;
  courier_fee: number | null;
}
