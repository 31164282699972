import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Address, CharityStore, ENVIRONMENT, Environment, FindJourneyParams, HistoryEvent, Journey, JourneyCustomStop } from '@domains';
import { Deserialize, Serialize } from 'cerialize';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { BaseApiService } from '../base-api.service';
import { StoresService } from './stores.service';

@Injectable({
  providedIn: 'root',
})
export class JourneysService extends BaseApiService<Journey> {
  public constructor(
    @Inject(ENVIRONMENT) override config: Environment,
    override http: HttpClient,
    private storesService: StoresService,
  ) {
    super(config, http, 'journeys', Journey, 'Journey');
  }

  override deserialize(data: any): Journey {
    const res = new Journey(Deserialize(data, Journey));
    res.jobs = data.jobs?.map((job: any) => {
      return new JourneyCustomStop({ ...Deserialize(job, JourneyCustomStop), ...Deserialize(job, Address) });
    });
    res.setInitialValue();
    return res;
  }

  override serialize(item: Journey) {
    return {
      ...Serialize(
        {
          ...item,
          jobs: item.jobs?.map((job) => ({ ...Serialize(job, JourneyCustomStop), ...Serialize(job, Address) })),
        },
        Journey,
      ),
    };
  }

  getJourney(params?: FindJourneyParams): Observable<Journey | undefined> {
    return this.http
      .post<any[]>(this.config.urls.baseUrl + `/journeys/`, {
        ...params,
      })
      .pipe(
        map((response) => {
          return this.deserialize(response);
        }),
      );
  }

  optimizeJourney(journeyId: string): Observable<Journey | undefined> {
    return this.http.post<any[]>(this.config.urls.baseUrl + `/journeys/` + journeyId + `/optimize`, {}).pipe(
      map((response) => {
        return this.deserialize(response);
      }),
    );
  }

  recalculateJourney(journeyId: string, store_id: string): Observable<Journey | undefined> {
    return this.http
      .post<Journey>(this.config.urls.baseUrl + `/journeys/${journeyId}/calculate`, {
        store_id,
      })
      .pipe(
        map((response: any) => {
          return new Journey(Deserialize(response, Journey));
        }),
      );
  }

  getJourneyStores(journeyId?: string | null): Observable<{ store: CharityStore; distance: number }[] | undefined> {
    if (!journeyId) {
      return of([]);
    }
    return this.http
      .post<{ id: string; type: string; distance: number }[]>(this.config.urls.baseUrl + `/journeys/${journeyId}/stores`, {})
      .pipe(
        map((res) =>
          res.map((r) => ({
            id: r.id.toString(),
            distance: r.distance,
            type: r.type,
          })),
        ),
        switchMap((res) => {
          return this.storesService.filter({ 'id[]': res.map((x) => x.id) }).pipe(
            map((result) =>
              result.results
                .map((s) => ({
                  store: s,
                  distance: res.find((x) => x.id === s.id)?.distance || 0,
                }))
                .sort((a, b) => a.distance - b.distance),
            ),
          );
        }),
      );
  }

  override history(ids: string[]): Observable<Array<HistoryEvent>> {
    return this.http
      .get<HistoryEvent[]>(this.config.urls.baseUrl + '/events', {
        params: {
          expand: 'user',
          target_type: 'Journey',
          'target_id[]': ids,
          order: 'created_at',
          order_direction: 'desc',
        },
      })
      .pipe(
        map((response: any) => {
          return response?.map((r: any) => new HistoryEvent(Deserialize(r, HistoryEvent)));
        }),
      );
  }
}
