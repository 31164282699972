import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HISTORY_CONFIGURATION, HistoryComponent, HistoryConfig, HistoryEntity } from '@rspl-ui';

import { BaseContainerComponent } from '../base-container/base-container.component';

@Component({
  selector: 'app-schedule-history',
  templateUrl: './schedule-history.component.html',
  styleUrls: ['./schedule-history.component.scss'],
  imports: [BaseContainerComponent, HistoryComponent],
})
export class ScheduleHistoryComponent implements OnInit {
  config?: HistoryConfig;

  constructor(public route: ActivatedRoute) {}

  ngOnInit(): void {
    this.config = {
      itemIds: [this.route.snapshot.params['id']],
      ...HISTORY_CONFIGURATION[HistoryEntity.SCHEDULE],
    };
  }
}
