import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { XmilesService } from '@rspl-api';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import * as fromActions from './xmile.actions';

@Injectable()
export class XmileEffects {
  createXmile = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.createXmile),
      mergeMap((payload) =>
        this.service.create(payload.xmile).pipe(
          map((result) => {
            return fromActions.createXmileSuccess({
              xmile: result,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  updateXmile = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updateXmile),
      mergeMap((payload) => {
        return this.service.update(payload.xmile.id, payload.xmile).pipe(
          map((result) => {
            return fromActions.updateXmileSuccess({
              xmile: result,
            });
          }),
          catchError(() => [fromActions.updateXmileFail]),
        );
      }),
    );
  });

  getXmiles = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getXmiles),
      switchMap((params) =>
        this.service.filter(params.findParams).pipe(
          map((results) => {
            return fromActions.getXmilesSuccess({
              xmiles: results.results,
              resultsCount: results.totalResults,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  getXmile = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getXmile),
      switchMap((params) =>
        this.service.find(params.id, (params.expand?.length || 0) > 0, params.expand).pipe(
          map((result) => {
            return fromActions.getXmileSuccess({
              xmile: result,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  deleteXmile = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.deleteXmile),
      switchMap((params) =>
        this.service.delete(params.xmileId).pipe(
          map(() => {
            return fromActions.deleteXmileSuccess({
              xmileId: params.xmileId,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  restoreXmile = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.restoreXmile),
      switchMap((params) =>
        this.service.restore(params.xmileId).pipe(
          map(() => {
            return fromActions.restoreXmileSuccess({
              xmileId: params.xmileId,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private service: XmilesService,
  ) {}
}
