import { CurrencyPipe } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { ENVIRONMENT, Environment, ISpecificationItem, ItemLabel, ItemMode, SpecificationItemType } from '@domains';
import { debounceTime, Subject } from 'rxjs';

import { FxLayoutAlignDirective, FxLayoutDirective } from '../../flex-layout';
import { Responsive, ResponsiveService } from '../../responsive';

@Component({
  selector: 'rspl-specification-item',
  templateUrl: './specification-item.component.html',
  styleUrls: ['./specification-item.component.scss'],
  imports: [FxLayoutDirective, FxLayoutAlignDirective, MatIconButton, MatIcon, MatInput, FormsModule, CurrencyPipe],
})
export class SpecificationItemComponent extends Responsive implements ISpecificationItem, OnDestroy, OnInit {
  @Input() type: SpecificationItemType;
  @Input() pricing: number;
  @Input() quantity: number;
  @Input() showPricing: boolean;
  @Input() showPrices: boolean;
  @Input() showItem: boolean;
  @Input() donatableSmall: number;
  @Input() nonDonatableSmall: number;
  @Input() splitSmall: boolean;
  @Input() readonly: boolean;
  @Input() isCalculatingSponsorship: boolean;
  @Input() mode: ItemMode = ItemMode.items;
  @Input() set useDebounce(useDebounce: boolean) {
    if (this.debouncer) {
      this.debouncer.complete();
    }
    this.debouncer = new Subject<number>();
    if (useDebounce) {
      this.debouncer.pipe(debounceTime(this.debounceTimeValue)).subscribe((value) => this.quantityChanged.emit(value));
    } else {
      this.debouncer.subscribe((value) => this.quantityChanged.emit(value));
    }
  }
  isCaptain: boolean;
  isDonor: boolean;

  readonly quantityChanged = output<number>();

  get label(): string {
    return this.labelText();
  }

  private readonly debounceTimeValue = 300;
  private debouncer: Subject<number>;

  constructor(
    public override responsiveService: ResponsiveService,
    @Inject(ENVIRONMENT) private config: Environment,
  ) {
    super(responsiveService);
    this.isCaptain = this.config.isCaptain;
    this.isDonor = this.config.isDonor;
  }

  override ngOnInit() {
    super.ngOnInit();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.debouncer.complete();
  }

  labelText() {
    return ItemLabel[this.type];
  }

  addItem(): void {
    this.quantity = Number(this.quantity) + 1;
    this.debouncer.next(this.quantity);
  }

  removeItem(): void {
    this.quantity = Number(this.quantity) - 1;
    this.debouncer.next(this.quantity);
  }

  handleQuantityChange(event: any) {
    const value = Number(event.target.value.replace(/[^0-9]/g, ''));
    event.target.value = value;

    if (value !== this.quantity) {
      this.debouncer.next(value);
    }

    this.quantity = value;
  }

  focusInput(event: any) {
    event.target.select();
  }

  preventEvent(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  get disabledIfItems(): boolean {
    return (
      this.mode == ItemMode.items &&
      [SpecificationItemType.medium, SpecificationItemType.large, SpecificationItemType.xlarge].includes(this.type)
    );
  }
}
