import { DatePipe, NgClass } from '@angular/common';
import { Component, Input, output } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Charity, Donation } from '@domains';
import { FxFlexDirective, FxLayoutAlignDirective, FxLayoutDirective } from '@rspl-ui';
import { NgxPrintDirective } from 'ngx-print';

import { FxLayoutGapDirective } from '../flex-layout/fx-layout-gap.directive';

@Component({
  selector: 'rspl-tax-receipt',
  templateUrl: './tax-receipt.component.html',
  styleUrls: ['./tax-receipt.component.scss'],
  imports: [
    NgxPrintDirective,
    FormsModule,
    ReactiveFormsModule,
    FxLayoutDirective,
    FxLayoutGapDirective,
    FxFlexDirective,
    FxLayoutAlignDirective,
    NgClass,
    MatFormField,
    MatLabel,
    MatInput,
    DatePipe,
  ],
})
export class TaxReceiptComponent {
  form = new FormGroup({
    items: new FormArray([
      new FormGroup({
        itemName: new FormControl(''),
        estimatedValue: new FormControl(0),
      }),
      new FormGroup({
        itemName: new FormControl(''),
        estimatedValue: new FormControl(0),
      }),
      new FormGroup({
        itemName: new FormControl(''),
        estimatedValue: new FormControl(0),
      }),
      new FormGroup({
        itemName: new FormControl(''),
        estimatedValue: new FormControl(0),
      }),
    ]),
    total: new FormControl(0, Validators.max(5000)),
  });
  labels: string[] = [];

  readonly validChanged = output<boolean>();
  @Input() charity?: Charity | null;
  @Input() canEdit = false;
  #donation?: Donation;
  @Input() set donation(donation: Donation | undefined) {
    this.#donation = donation;
    if (this.donation) {
      this.setLabels();
      this.itemsArray.clear();
      const sum = donation.isPartnerPickup
        ? (donation?.adjustedSpecification?.small || 0) +
          (donation?.adjustedSpecification?.medium || 0) +
          (donation?.adjustedSpecification?.large || 0) +
          (donation?.adjustedSpecification?.xlarge || 0)
        : donation.specification.small;
      for (let i = 0; i < sum; i++) {
        this.itemsArray.push(
          new FormGroup({
            itemName: new FormControl(''),
            estimatedValue: new FormControl(0),
          }),
        );
      }
    }
  }

  get donation(): Donation | undefined {
    return this.#donation;
  }

  get itemsArray(): FormArray<
    FormGroup<{
      itemName: FormControl<string | null>;
      estimatedValue: FormControl<number | null>;
    }>
  > {
    return this.form.get('items') as FormArray<
      FormGroup<{
        itemName: FormControl<string | null>;
        estimatedValue: FormControl<number | null>;
      }>
    >;
  }

  get total(): FormControl<number> {
    return this.form.get('total') as FormControl<number>;
  }

  getControl(
    group: FormGroup<{
      itemName: FormControl<string | null>;
      estimatedValue: FormControl<number | null>;
    }> | null,
    control: string,
  ): FormControl<string> {
    return group?.get(control) as FormControl<string>;
  }

  get items(): FormGroup<{
    itemName: FormControl<string | null>;
    estimatedValue: FormControl<number | null>;
  }>[] {
    return this.itemsArray.controls as FormGroup<{
      itemName: FormControl<string | null>;
      estimatedValue: FormControl<number | null>;
    }>[];
  }

  updateTotal(): void {
    let total = 0;
    this.items.forEach((item) => (total += Number(item.get('estimatedValue')?.value)));
    this.total.patchValue(total);
    this.validChanged.emit(total <= 5000);
  }

  private setLabels(): void {
    this.labels = [];
    if (!this.donation.isPartnerPickup) {
      for (let i = 0; i < this.donation.specification.small; i++) {
        this.labels.push('Small Item');
      }
      return;
    }
    for (let i = 0; i < (this.donation?.adjustedSpecification.small || 0); i++) {
      this.labels.push('Small Item');
    }
    for (let i = 0; i < (this.donation?.adjustedSpecification.medium || 0); i++) {
      this.labels.push('Medium Item');
    }
    for (let i = 0; i < (this.donation?.adjustedSpecification.large || 0); i++) {
      this.labels.push('Large Item');
    }
    for (let i = 0; i < (this.donation?.adjustedSpecification.xlarge || 0); i++) {
      this.labels.push('Extra Large Item');
    }
  }
}
