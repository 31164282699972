import { Injectable } from '@angular/core';
import { LoaderState } from '@domains';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loaderSubject = new Subject<LoaderState>();
  private state: LoaderState = { show: false, width: 0, time: 0 };
  loaderState = this.loaderSubject.asObservable();
  hideTime = 0;
  hideCnt = 0;

  constructor() {
    this.hide();
  }

  /**
   * Shows progress bar and calculates percentage that should be shown by
   * calculating how many executed requests have been completed
   *
   * @param cnt
   * Number of pending requests
   *
   * @param max
   * Number of executed requests
   *
   */
  show(cnt: number, max: number): void {
    this.state = {
      show: true,
      width: max === cnt ? this.random() : ((max - cnt) / max) * 100,
      time: this.time(),
    };
    this.loaderSubject.next(this.state);
  }

  /**
   * Shows progress bar using already calculated percentage.
   * Used for tracking file upload progress.
   *
   * @param percent
   * Precalculated percentage
   */
  showPercent(percent: number): void {
    if (percent > 100) {
      percent = 100;
    } else if (percent < 0) {
      percent = 0;
    }
    this.state = {
      show: true,
      width: percent,
      time: this.time(),
    };
    this.loaderSubject.next(this.state);
    if (percent === 100) {
      this.hide();
    }
  }

  /**
   * Hides progress bar.
   * If executed multiple times only the last one will take effect.
   * Hiding is done in timeout so the animation can be executed.
   *
   */
  hide(): void {
    this.hideTime = this.state.time;
    this.hideCnt++;
    setTimeout(() => {
      this.hideCnt--;
      if (this.hideCnt === 0 && this.hideTime === this.state.time) {
        this.state = { show: false, width: 0, time: this.time() };
        this.loaderSubject.next(this.state);
      }
    }, 1200);
  }

  time(): number {
    return new Date().getTime();
  }

  random(): number {
    return Math.floor(Math.random() * (60 - 30 + 1) + 30);
  }
}
