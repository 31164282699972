import { autoserialize } from 'cerialize';

export class Courier {
  @autoserialize courier?: string;
  @autoserialize driver_name?: string;
  @autoserialize external_id?: string;
  @autoserialize vehicle_make?: string;
  @autoserialize vehicle_type?: string;
  @autoserialize external_type?: string;
  @autoserialize vehicle_color?: string;
  @autoserialize vehicle_model?: string;
  @autoserialize public_phone_number?: string; //this number should be used for creating the link for calling the driver
  @autoserialize private_phone_number?: string;
  @autoserialize dropoff_verification_url?: string;
  @autoserialize pickup_verification_url?: string;
  @autoserialize tracking_url?: string;

  constructor(payload?: ICourier) {
    this.courier = payload?.courier;
    this.driver_name = payload?.driver_name;
    this.external_id = payload?.external_id;
    this.vehicle_make = payload?.vehicle_make;
    this.vehicle_type = payload?.vehicle_type;
    this.external_type = payload?.external_type;
    this.vehicle_color = payload?.vehicle_color;
    this.vehicle_model = payload?.vehicle_model;
    this.public_phone_number = payload?.public_phone_number;
    this.private_phone_number = payload?.private_phone_number;
    this.dropoff_verification_url = payload?.dropoff_verification_url;
    this.pickup_verification_url = payload?.pickup_verification_url;
    this.tracking_url = payload?.tracking_url;
  }
}

export interface ICourier {
  courier?: string;
  driver_name?: string;
  external_id?: string;
  vehicle_make?: string;
  vehicle_type?: string;
  external_type?: string;
  vehicle_color?: string;
  vehicle_model?: string;
  public_phone_number?: string;
  private_phone_number?: string;
  dropoff_verification_url?: string;
  pickup_verification_url?: string;
  tracking_url?: string;
}
