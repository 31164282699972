import { CurrencyPipe } from '@angular/common';
import { autoserialize, autoserializeAs } from 'cerialize';

import { AppType } from './app-type';
import { BaseDonationLead, IBaseDonationLead } from './base-donation-lead';
import { Contact, IContact } from './contact';
import { DonationCharityState } from './donation-charity-state';
import { DonationContent, IDonationContent } from './donation-content';
import { DonationDonorState } from './donation-donor-state';
import { DonationMeta, IDonationMeta } from './donation-meta';
import { DonationPartnerState } from './donation-partner-state';
import { DonationPaymentType } from './donation-payment';
import { DonationReview, IDonationReview } from './donation-review';
import { FeeType } from './fee-type';
import { IPaymentDetails, PaymentDetails } from './payment-details';
import { Pricing } from './pricing';
import { ISpecification, Specification } from './specification';
import { ISurvey, Survey } from './survey';
import { IXmile, Xmile } from './xmile';

export enum DonationVersion {
  _1_0 = '1.0',
  _1_1 = '1.1',
}

export class Donation extends BaseDonationLead implements IDonation {
  @autoserialize version?: DonationVersion;

  @autoserializeAs('donation_code') donationCode?: string | null;

  @autoserializeAs('partner_state') partnerState: DonationPartnerState;
  @autoserializeAs('charity_state') charityState: DonationCharityState;
  @autoserializeAs('donor_state') donorState: DonationDonorState;

  @autoserializeAs(PaymentDetails, 'discount_payment_details')
  discountPaymentDetails?: PaymentDetails | null; // total taken from charity
  @autoserializeAs(PaymentDetails, 'payment_details')
  paymentDetails?: PaymentDetails | null; //total taken donor
  @autoserialize gratuity: number;
  @autoserializeAs('donor_signature') donorSignature?: string | null;

  @autoserializeAs(Specification, 'adjusted_specification')
  adjustedSpecification: Specification;
  @autoserializeAs('discounted_specification')
  discountedSpecification?: Specification;

  @autoserialize photos?: string[] | null;

  @autoserialize content?: DonationContent[] | null;

  @autoserializeAs('accepted_content') acceptedContent?: DonationContent[] | null;
  @autoserializeAs('accepted_small_items') acceptedSmallItems?: number | null;
  @autoserializeAs(Contact, 'dock_contact') dockContact?: Contact | null;
  @autoserializeAs('dock_signature') dockSignature?: string | null;
  @autoserializeAs('charity_survey') charitySurvey?: Survey;

  @autoserializeAs('donation_notes') donationNotes?: string | null;

  @autoserialize review?: DonationReview | null;

  @autoserialize meta?: DonationMeta | null;

  @autoserializeAs('request_eta') requestEta?: boolean | null;
  @autoserialize eta?: string | null;
  @autoserializeAs('eta_note') etaNote?: string | null;

  @autoserializeAs('route_order') order?: number | null;

  @autoserialize exception: boolean;
  @autoserialize resolved?: boolean | null;
  @autoserializeAs('reschedule_available') rescheduleAvailable?: boolean;

  @autoserializeAs('termination_reason') terminationReason?: string | null;
  @autoserializeAs('termination_note') terminationNote?: string | null;
  @autoserializeAs('donation_description') donationDescription?: string | null;
  @autoserializeAs('partner_comment') partnerComment?: string | null;
  @autoserializeAs('specification_description') specificationDescription?: string | null;

  @autoserializeAs('extra_mile') extraMile?: Xmile | null;
  @autoserializeAs('extra_mile_id') extraMileId?: string | null;

  @autoserializeAs('marketing_source') marketingSource?: string | null;

  @autoserializeAs('application_fee_amount') applicationFeeAmount: number;

  constructor(payload: IDonation) {
    super(payload);

    this.version = payload?.version;

    this.donationCode = payload?.donationCode;

    this.partnerState = payload?.partnerState;
    this.donorState = payload?.donorState;
    this.charityState = payload?.charityState;

    this.discountPaymentDetails = payload?.discountPaymentDetails ? new PaymentDetails(payload?.discountPaymentDetails) : undefined;
    this.paymentDetails = payload?.paymentDetails ? new PaymentDetails(payload?.paymentDetails) : undefined;
    this.gratuity = payload?.gratuity;
    this.donorSignature = payload?.donorSignature;

    this.adjustedSpecification = new Specification(payload?.adjustedSpecification);
    this.discountedSpecification = payload?.discountedSpecification ? new Specification(payload.discountedSpecification) : undefined;

    this.photos = payload?.photos;

    this.content = payload?.content?.map((x) => new DonationContent(x));

    this.acceptedContent = payload?.acceptedContent?.map((x) => new DonationContent(x));
    this.acceptedSmallItems = payload?.acceptedSmallItems;
    this.dockContact = payload?.dockContact ? new Contact(payload?.dockContact) : undefined;
    this.dockSignature = payload?.dockSignature;
    this.charitySurvey = payload?.charitySurvey;

    this.donationNotes = payload?.donationNotes;

    this.review = payload?.review ? new DonationReview(payload?.review) : undefined;

    this.meta = new DonationMeta(payload?.meta);

    this.requestEta = payload?.requestEta;
    this.eta = payload?.eta;
    this.etaNote = payload?.etaNote;

    this.order = payload?.order;

    this.exception = payload?.exception;
    this.resolved = payload?.resolved;
    this.rescheduleAvailable = !!payload?.rescheduleAvailable;

    this.terminationReason = payload?.terminationReason;
    this.terminationNote = payload?.terminationNote;
    this.donationDescription = payload?.donationDescription;
    this.partnerComment = payload?.partnerComment;
    this.specificationDescription = payload?.specificationDescription;

    this.extraMile = payload?.extraMile ? new Xmile(payload.extraMile) : undefined;
    this.extraMileId = payload?.extraMileId?.toString() || this.extraMile?.id;

    this.marketingSource = payload?.marketingSource;

    this.applicationFeeAmount = payload?.applicationFeeAmount;
  }

  public static OnSerialized(instance: Donation, json: any): void {
    json.gratuity = json.gratuity !== null ? Pricing.formatPrice(json.gratuity * 100) : null;
  }

  public static OnDeserialized(instance: Donation): void {
    instance.gratuity = instance.gratuity !== null ? Pricing.formatPrice(instance.gratuity / 100) : null;
  }

  hasAdjustedSpec(): boolean {
    return (
      this.adjustedSpecification &&
      (this.adjustedSpecification.xlarge > 0 ||
        this.adjustedSpecification.large > 0 ||
        this.adjustedSpecification.medium > 0 ||
        this.adjustedSpecification.small > 0 ||
        this.adjustedSpecification.recycling > 0 ||
        this.adjustedSpecification.staircases > 0 ||
        this.adjustedSpecification.elevator > 0 ||
        this.adjustedSpecification.disassembly > 0)
    );
  }

  resupplyFee(appType: AppType, currency: CurrencyPipe, showType = false): string {
    let resupplyFee;
    if (this.paymentDetails?.application_fee) {
      resupplyFee = this.paymentDetails.application_fee + (this.discountPaymentDetails?.application_fee || 0);
    } else if (this.fee && this.payment.paymentType === DonationPaymentType.offline) {
      resupplyFee = this.payment.stripeApplicationFee / 100;
    } else if (resupplyFee === undefined) {
      resupplyFee = this.calculateResupplyFee(appType);
    }
    return resupplyFee ? currency.transform(resupplyFee) + (showType ? ` (${this.getFee()?.feeType || FeeType.Flat})` : '') : '/';
  }

  stripeFee(appType: AppType, currency: CurrencyPipe): string | null {
    let stripeFee;
    if (this.paymentDetails?.stripe_fee) stripeFee = this.paymentDetails.stripe_fee + (this.discountPaymentDetails?.stripe_fee || 0);
    if (stripeFee === undefined) {
      stripeFee = this.calculateStripeFee(appType);
    }
    return stripeFee ? currency.transform(stripeFee) : '/';
  }

  netAmount(appType: AppType, currency: CurrencyPipe): string | null {
    let netAmount;
    if (this.paymentDetails?.net_amount) netAmount = this.paymentDetails.net_amount + (this.discountPaymentDetails?.net_amount || 0);
    if (netAmount === undefined) {
      netAmount = this.calculateNetAmount(appType);
    }
    return netAmount ? currency.transform(netAmount) : '/';
  }

  calculateResupplyFee(appType: AppType): number | undefined {
    //TODO include the new type
    //used on payments screen when listing donations
    let resupplyFee: number | undefined;
    if (this.payment.paymentType === DonationPaymentType.cancellation && [AppType.CAPTAIN, AppType.ZENDESK].includes(appType)) {
      resupplyFee = this.pricing.cancellationFee ? Math.round((this.pricing.cancellationFee / 2) * 100) / 100 : 0;
    } else if (
      this.payment.paymentType &&
      [DonationPaymentType.manual_payment, DonationPaymentType.offline].includes(this.payment.paymentType) &&
      !this.fee &&
      [AppType.CAPTAIN, AppType.ZENDESK].includes(appType)
    ) {
      const total = this.finalQuote - this.gratuity;
      const fee = this.getFee();
      if (fee?.feeType === FeeType.BracketsV1) {
        if (total < 150) {
          resupplyFee = Number(((total * (fee.level1 || 0)) / 100).toFixed(2));
        } else if (total < 300) {
          resupplyFee = Number(((total * (fee.level2 || 0)) / 100).toFixed(2));
        } else {
          resupplyFee = Number(((total * (fee.level3 || 0)) / 100).toFixed(2));
        }
      } else if (fee?.feeType === FeeType.BracketsV2) {
        if (total < 150) {
          resupplyFee = Number(((total * (fee.level1 || 0)) / 100).toFixed(2));
        } else if (total < 500) {
          resupplyFee = Number(((total * (fee.level2 || 0)) / 100).toFixed(2));
        } else {
          resupplyFee = Number(((total * (fee.level3 || 0)) / 100).toFixed(2));
        }
      } else {
        resupplyFee = 30;
      }
    } else if (this?.hasAdjustedSpec()) {
      resupplyFee = this.payment.stripeApplicationFee / 100;
    } else {
      resupplyFee = undefined;
    }
    return resupplyFee;
  }

  calculateStripeFee(appType: AppType): number | undefined {
    let stripeFee;
    if (this.payment.paymentType === DonationPaymentType.cancellation && [AppType.CAPTAIN, AppType.ZENDESK].includes(appType)) {
      stripeFee = this.pricing.cancellationFee ? Math.round(((this.pricing.cancellationFee * 2.9 + 30) / 100) * 100) / 100 : 0;
    } else if (this?.hasAdjustedSpec()) {
      const total = Pricing.getTotalPrice(this.adjustedSpecification, this.pricing, this.gratuity);
      stripeFee = ((Math.round(total * 100) / 100) * 2.9 + 30) / 100;
    } else {
      stripeFee = undefined;
    }
    return stripeFee ? Number(stripeFee.toFixed(2)) : undefined;
  }

  calculateNetAmount(
    appType: AppType,
    resupplyFee: number = this.calculateResupplyFee(appType) || 0,
    stripeFee: number = this.calculateStripeFee(appType) || 0,
  ): number | undefined {
    let netAmount;
    if (this.payment.paymentType === DonationPaymentType.cancellation) {
      netAmount = this.pricing.cancellationFee ? this.pricing.cancellationFee - resupplyFee - stripeFee : 0;
    } else if (this?.hasAdjustedSpec()) {
      netAmount =
        this.adjustedSpecification && this.pricing
          ? Pricing.getTotalPriceFormatted(this.adjustedSpecification, this.pricing, this.gratuity) - resupplyFee - stripeFee
          : 0;
    } else {
      netAmount = undefined;
    }
    return netAmount ? Number(netAmount?.toFixed(2)) : undefined;
  }

  get finalQuote() {
    if (this.paymentDetails?.total_amount) return this.paymentDetails.total_amount + (this.discountPaymentDetails?.total_amount || 0);
    return Pricing.getTotalPriceFormatted(this.adjustedSpecification, this.pricing, this.gratuity);
  }

  get finalQuoteFuelFee() {
    return Pricing.formatPrice(this.finalQuote - this.finalQuote / (1 + this.pricing.fuelFee / 100));
  }

  get finalSponsoredAmount(): number {
    if (this.discountPaymentDetails?.total_amount >= 0) return this.discountPaymentDetails.total_amount;
    return Pricing.calculateSponsoredAmount(
      this.adjustedSpecification,
      this.pricing,
      this.discount,
      this.sponsorship_algorithm,
      this.discountedSpecification?.small,
    );
  }

  get total(): number {
    return this.hasAdjustedSpec() ? this.finalQuote : this.originalQuote;
  }

  get fuelFee(): number {
    return this.hasAdjustedSpec() ? this.finalQuoteFuelFee : this.originalQuoteFuelFee;
  }

  get totalSponsoredAmount(): number {
    return this.hasAdjustedSpec() ? this.finalSponsoredAmount : this.originalSponsoredAmount;
  }

  get donatableSmall(): number {
    if (this.discount?.valid) {
      return this.hasAdjustedSpec() ? this.discountedSpecification?.small : this.specification.small;
    } else {
      return 0;
    }
  }

  get bookingFee(): number {
    return this.pricing.bookingFee;
  }

  get isPaymentAuthorizedOrZeroCharge(): boolean {
    return (
      this.payment?.authCompleted || (this.paymentDetails?.total_amount === 0 && this.donorState === DonationDonorState.quote_accepted)
    );
  }
}

export interface IDonation extends IBaseDonationLead {
  version?: DonationVersion;

  donationCode?: string | null;

  partnerState: DonationPartnerState;
  charityState: DonationCharityState;
  donorState: DonationDonorState;

  discountPaymentDetails?: IPaymentDetails | null;
  paymentDetails?: IPaymentDetails | null;
  gratuity: number;
  donorSignature?: string | null;

  adjustedSpecification: ISpecification;
  discountedSpecification?: ISpecification;

  photos?: string[] | null;

  content?: IDonationContent[] | null;

  acceptedContent?: IDonationContent[] | null;
  acceptedSmallItems?: number | null;
  dockContact?: IContact | null;
  dockSignature?: string | null;
  charitySurvey?: ISurvey;

  donationNotes?: string | null;

  review?: IDonationReview | null;

  meta?: IDonationMeta | null;

  requestEta?: boolean | null;
  eta?: string | null;
  etaNote?: string | null;

  order?: number | null;

  exception: boolean;
  resolved?: boolean | null;
  rescheduleAvailable?: boolean;

  terminationReason?: string | null;
  terminationNote?: string | null;
  donationDescription?: string | null;
  partnerComment?: string | null;
  specificationDescription?: string | null;

  extraMileId?: string | null;
  extraMile?: IXmile | null;

  marketingSource?: string | null;

  applicationFeeAmount: number;
}
