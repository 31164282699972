import { FindParams, Organization } from '@domains';
import { createAction, props } from '@ngrx/store';

export enum OrganizationActionType {
  CreateOrganization = '[Organization] Create Organization',
  CreateOrganizationSuccess = '[Organization] Create Organization Success',

  UpdateOrganization = '[Organization] Update Organization',
  UpdateOrganizationSuccess = '[Organization] Update Organization Success',
  UpdateOrganizationFail = '[Organization] Update Organization Fail',

  ClearOrganizationsFindParams = '[Charity] Clear Organizations Find Params',
  GetOrganizations = '[Organization] Get Organizations',
  GetOrganizationsSuccess = '[Organization] Get Organizations Success',

  GetOrganization = '[Organization] Get Organization',
  GetOrganizationSuccess = '[Organization] Get Organization Success',

  DeleteOrganization = '[Organization] Delete Organization',
  DeleteOrganizationSuccess = '[Organization] Delete Organization Success',

  RestoreOrganization = '[Organization] Restore Organization',
  RestoreOrganizationSuccess = '[Organization] Restore Organization Success',
}

export const createOrganization = createAction(OrganizationActionType.CreateOrganization, props<{ organization: Organization }>());
export const createOrganizationSuccess = createAction(
  OrganizationActionType.CreateOrganizationSuccess,
  props<{ organization: Organization }>(),
);

export const updateOrganization = createAction(OrganizationActionType.UpdateOrganization, props<{ organization: Organization }>());
export const updateOrganizationSuccess = createAction(
  OrganizationActionType.UpdateOrganizationSuccess,
  props<{ organization: Organization }>(),
);
export const updateOrganizationFail = createAction(OrganizationActionType.UpdateOrganizationFail, props<{ organization: Organization }>());

export const clearOrganizationsFindParams = createAction(OrganizationActionType.ClearOrganizationsFindParams);
export const getOrganizations = createAction(OrganizationActionType.GetOrganizations, props<{ findParams: FindParams; keep?: boolean }>());
export const getOrganizationsSuccess = createAction(
  OrganizationActionType.GetOrganizationsSuccess,
  props<{ organizations: Array<Organization>; resultsCount: number }>(),
);

export const getOrganization = createAction(
  OrganizationActionType.GetOrganization,
  props<{ id: string; keep?: boolean; expand?: string[] }>(),
);
export const getOrganizationSuccess = createAction(OrganizationActionType.GetOrganizationSuccess, props<{ organization: Organization }>());

export const deleteOrganization = createAction(OrganizationActionType.DeleteOrganization, props<{ organizationId: string }>());
export const deleteOrganizationSuccess = createAction(
  OrganizationActionType.DeleteOrganizationSuccess,
  props<{ organizationId: string }>(),
);

export const restoreOrganization = createAction(OrganizationActionType.RestoreOrganization, props<{ organizationId: string }>());
export const restoreOrganizationSuccess = createAction(
  OrganizationActionType.RestoreOrganizationSuccess,
  props<{ organizationId: string }>(),
);
