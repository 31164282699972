import { DonationDonorState } from './donation-donor-state';
import { DonationPartnerState } from './donation-partner-state';
import { FindParams } from './find-params';

export interface FindDonationsParams extends FindParams {
  charity_id?: string;
  partner_id?: string;
  vehicle_id?: string;
  date_after?: string;
  date_before?: string;
  date?: string;
  donor_match?: string;
  partner_state?: DonationPartnerState;
  donor_state?: DonationDonorState;
  'partner_state[]'?: DonationPartnerState[];
  'donor_state[]'?: DonationDonorState[];
  donation_code?: string;
  code_match?: string;
  email_match?: string;
  'donor.phone'?: string;
  active?: boolean;
}
