import { autoserialize } from 'cerialize';

import { JourneyStopType } from './journey-stop-type';

export class JourneyStop {
  @autoserialize type: JourneyStopType;
  @autoserialize id: string;
  @autoserialize order: number;

  constructor(payload: IJourneyStop) {
    this.type = payload.type;
    this.id = payload.id.toString();
    this.order = payload.order;
  }
}

export interface IJourneyStop {
  type: JourneyStopType;
  id: string;
  order: number;
}
