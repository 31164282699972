import { autoserialize } from 'cerialize';

export class TruckMeta {
  @autoserialize isTruckReady: boolean;

  constructor(payload?: ITruckMeta) {
    this.isTruckReady = !!payload?.isTruckReady;
  }
}

export interface ITruckMeta {
  isTruckReady: boolean;
}
