import { FindNotificationsParams, HistoryEvent, Notification } from '@domains';
import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './notification.actions';

export interface NotificationsState {
  notifications: Array<Notification>;
  notificationsTotalCount: number;
  notification?: Notification;
  findParams: FindNotificationsParams;
  history: Array<HistoryEvent>;
}

const initialState: NotificationsState = {
  notifications: new Array<Notification>(),
  notificationsTotalCount: 0,
  notification: undefined,
  findParams: JSON.parse(localStorage.getItem('NotificationFindParams') || '{}'),
  history: new Array<HistoryEvent>(),
};

const reducer = createReducer(
  initialState,

  on(fromActions.createNotificationSuccess, (state, action: any) => ({ ...state, notification: action.notification })),
  on(fromActions.updateNotificationSuccess, (state, action: any) => ({ ...state, notification: action.notification })),

  on(fromActions.getNotifications, (state, { findParams }) => {
    const newValue = { ...state.findParams };
    newValue[window.location.pathname] = findParams;
    return { ...state, notifications: [], findParams: newValue };
  }),
  on(fromActions.getNotificationsSuccess, (state, { notifications, resultsCount }) => ({
    ...state,
    notifications,
    notificationsTotalCount: resultsCount,
  })),
  on(fromActions.clearNotificationsFindParams, (state) => {
    const newValue = { ...state.findParams };
    newValue[window.location.pathname] = {};
    return { ...state, findParams: newValue };
  }),

  on(fromActions.getNotification, (state, { keep }) => ({ ...state, notification: keep ? state.notification : null })),
  on(fromActions.getNotificationSuccess, (state, action: any) => ({ ...state, notification: action.notification })),
  on(fromActions.getNotificationHistorySuccess, (state, action: any) => ({ ...state, history: action.history })),
);

export function notificationsReducer(state: NotificationsState | undefined, action: Action) {
  return reducer(state, action);
}
