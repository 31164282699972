import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, Environment, HistoryEvent } from '@domains';
import { Deserialize } from 'cerialize';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScheduleHistoryService {
  public constructor(
    @Inject(ENVIRONMENT) private config: Environment,
    private http: HttpClient,
  ) {}

  history(ids: string[]): Observable<Array<HistoryEvent>> {
    return this.http
      .get<HistoryEvent[]>(this.config.urls.baseUrl + '/events', {
        params: {
          expand: 'user',
          target_type: 'Schedule',
          'target_id[]': ids,
          order: 'created_at',
          order_direction: 'desc',
        },
      })
      .pipe(
        map((response: any) => {
          return response?.map((r: any) => new HistoryEvent(Deserialize(r, HistoryEvent)));
        }),
      );
  }
}
