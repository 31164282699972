import { AvailabilityStats, FindAvailabilityParams, FindScheduleParams, FindTruckParams, Schedule, Truck } from '@domains';
import { createAction, props } from '@ngrx/store';

export enum TruckActionType {
  CreateTruck = '[Truck] Create Truck',
  CreateTruckSuccess = '[Truck] Create Truck Success',

  UpdateTruck = '[Truck] Update Truck',
  UpdateTruckSuccess = '[Truck] Update Truck Success',

  ClearTrucksFindParams = '[Truck] Clear Trucks Find Params',
  GetTrucks = '[Truck] Get Trucks',
  GetTrucksSuccess = '[Truck] Get Trucks Success',

  GetStateTrucks = '[Truck] Get State Trucks',
  GetStateTrucksSuccess = '[Truck] Get State Trucks Success',

  GetTruckAvailabilityStats = '[Truck] Get Truck Availability Stats',
  GetTruckAvailabilityStatsSuccess = '[Truck] Get Truck Availability Stats Success',

  GetTruck = '[Truck] Get Truck',
  GetTruckSuccess = '[Truck] Get Truck Success',

  DeleteTruck = '[Truck] Delete Truck',
  DeleteTruckSuccess = '[Truck] Delete Truck Success',

  RestoreTruck = '[Truck] Restore Truck',
  RestoreTruckSuccess = '[Truck] Restore Truck Success',

  CreateTruckSchedule = '[Truck Schedule] Create Truck Schedule',
  CreateTruckScheduleSuccess = '[Truck Schedule] Create Truck Schedule Success',

  UpdateTruckSchedule = '[Truck Schedule] Update Truck Schedule',
  UpdateTruckScheduleSuccess = '[Truck Schedule] Update Truck Schedule Success',

  GetTruckSchedules = '[Truck Schedule] Get Truck Schedules',
  GetTruckSchedulesSuccess = '[Truck Schedule] Get Truck Schedules Success',

  GetTruckSchedule = '[Truck Schedule] Get Truck Schedule',
  GetTruckScheduleSuccess = '[Truck Schedule] Get Truck Schedule Success',

  DeleteTruckSchedule = '[Truck Schedule] Delete Truck Schedule',
  DeleteTruckScheduleSuccess = '[Truck Schedule] Delete Truck Schedule Success',
}

export const createTruck = createAction(TruckActionType.CreateTruck, props<{ truck: Truck }>());
export const createTruckSuccess = createAction(TruckActionType.CreateTruckSuccess, props<{ truck: Truck }>());

export const updateTruck = createAction(TruckActionType.UpdateTruck, props<{ truck: Truck }>());
export const updateTruckSuccess = createAction(TruckActionType.UpdateTruckSuccess, props<{ truck: Truck }>());

export const getTrucks = createAction(TruckActionType.GetTrucks, props<{ findParams: FindTruckParams }>());
export const getTrucksSuccess = createAction(TruckActionType.GetTrucksSuccess, props<{ trucks: Array<Truck>; resultsCount: number }>());
export const clearTrucksFindParams = createAction(TruckActionType.ClearTrucksFindParams);

export const getStateTrucks = createAction(TruckActionType.GetStateTrucks, props<{ findParams: FindTruckParams }>());
export const getStateTrucksSuccess = createAction(TruckActionType.GetStateTrucksSuccess, props<{ trucks: Array<Truck> }>());

export const getTruckAvailabilityStats = createAction(
  TruckActionType.GetTruckAvailabilityStats,
  props<{ findParams: FindAvailabilityParams }>(),
);
export const getTruckAvailabilityStatsSuccess = createAction(
  TruckActionType.GetTruckAvailabilityStatsSuccess,
  props<{ stats: Array<AvailabilityStats> }>(),
);

export const getTruck = createAction(TruckActionType.GetTruck, props<{ id: string; keep?: boolean }>());
export const getTruckSuccess = createAction(TruckActionType.GetTruckSuccess, props<{ truck: Truck }>());

export const deleteTruck = createAction(TruckActionType.DeleteTruck, props<{ truckId: string }>());
export const deleteTruckSuccess = createAction(TruckActionType.DeleteTruckSuccess, props<{ truckId: string }>());

export const restoreTruck = createAction(TruckActionType.RestoreTruck, props<{ truckId: string }>());
export const restoreTruckSuccess = createAction(TruckActionType.RestoreTruckSuccess, props<{ truckId: string }>());

export const createTruckSchedule = createAction(TruckActionType.CreateTruckSchedule, props<{ parentId: string; schedule: Schedule }>());
export const createTruckScheduleSuccess = createAction(TruckActionType.CreateTruckScheduleSuccess, props<{ schedule: Schedule }>());

export const updateTruckSchedule = createAction(TruckActionType.UpdateTruckSchedule, props<{ parentId: string; schedule: Schedule }>());
export const updateTruckScheduleSuccess = createAction(TruckActionType.UpdateTruckScheduleSuccess, props<{ schedule: Schedule }>());

export const getTruckSchedules = createAction(
  TruckActionType.GetTruckSchedules,
  props<{ parentId: string; findParams: FindScheduleParams }>(),
);
export const getTruckSchedulesSuccess = createAction(TruckActionType.GetTruckSchedulesSuccess, props<{ schedules: Array<Schedule> }>());

export const getTruckSchedule = createAction(TruckActionType.GetTruckSchedule, props<{ truckId: string; scheduleId: string }>());
export const getTruckScheduleSuccess = createAction(TruckActionType.GetTruckScheduleSuccess, props<{ schedule: Schedule }>());

export const deleteTruckSchedule = createAction(TruckActionType.DeleteTruckSchedule, props<{ truckId: string; scheduleId: string }>());
export const deleteTruckScheduleSuccess = createAction(TruckActionType.DeleteTruckScheduleSuccess, props<{ scheduleId: string }>());
