import { NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelDescription,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { HistoryChange, HistoryEvent, WindowSize } from '@domains';
import {
  CharityService,
  DonationsService,
  JourneysService,
  LeadsService,
  LocalStorageService,
  MarketsService,
  OrganizationsService,
  PartnerService,
  PhoneConfigurationService,
  ScheduleHistoryService,
  StoresService,
  TrucksService,
  UsersService,
  XmilesService,
} from '@rspl-api';
import { CardComponent, FxLayoutAlignDirective, FxLayoutDirective, FxLayoutGapDirective } from '@rspl-ui';
import { Observable, Subscription, takeUntil } from 'rxjs';

import { Responsive, ResponsiveService } from '../responsive';
import { SearchableSelectComponent } from '../searchable-select';
import { HistoryService } from './history.service';
import { HistoryConfig } from './history-config';
import { HistoryEntity } from './history-entity';

@Component({
  selector: 'rspl-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  imports: [
    CardComponent,
    FxLayoutDirective,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatExpansionPanelDescription,
    MatButtonModule,
    NgTemplateOutlet,
    SearchableSelectComponent,
    MatIcon,
    NgStyle,
    FxLayoutAlignDirective,
    FxLayoutGapDirective,
  ],
})
export class HistoryComponent extends Responsive {
  history: Array<HistoryEvent> = new Array<HistoryEvent>();
  item: any;
  smallWindows: WindowSize[] = [WindowSize.XS, WindowSize.SM];
  changes: HistoryChange[] = [];
  listSelector$?: Subscription;
  itemSelector$?: Subscription;

  filteredChanges: HistoryChange[] = this.changes;
  selectedFilter: string = '';

  sort: 'desc' | 'asc';
  sortedChanges: HistoryChange[] = this.filteredChanges;

  initialEvent: string;
  initialValue: { [key: string]: string };

  filterOptions: string[];

  #config!: HistoryConfig;
  @Input() set config(config: HistoryConfig) {
    this.#config = config;
    if (config.itemIds) {
      this.listSelector$?.unsubscribe();
      this.itemSelector$?.unsubscribe();
      this.item = undefined;
      this.listSelector$ = this.service
        ?.history(config.itemIds)
        .pipe(takeUntil(this.destroy$))
        .subscribe((history) => {
          this.history = history.filter((h) => Object.keys(h.changes).length > 0);
          this.historyService
            .mapChanges(this.history, this.config.users, this.config.replaceFields, this.config.fieldTypes, this.config.ignoreFields)
            .subscribe((res) => {
              this.changes = res;
              this.filteredChanges = res;
              if (this.config.initialEvent) {
                this.initialEvent = this.historyService.getEvent(this.config.initialEvent);
                this.initialValue = this.changes
                  .find((h) => h.event === this.initialEvent)
                  ?.fields?.reduce((obj, item) => Object.assign(obj, { [item.name]: item.newValue }), {});
              }
              let fields = [];
              this.changes.forEach((change) => {
                fields = [...fields, ...change.fields.map((field) => field.name)];
              });

              this.filterOptions = [...new Set(fields)].sort();
              this.sortChanges();
            });
        });
      if (this.config.shouldGetItem)
        this.itemSelector$ = this.service
          ?.find?.(config.itemIds[0])
          .pipe(takeUntil(this.destroy$))
          .subscribe((item) => {
            this.item = item;
          });
    }
  }

  changeSort() {
    this.sort = this.sort === 'asc' ? 'desc' : 'asc';
    this.localStorage.setItem('RSPL_HISTORY_SORT', this.sort);
    this.sortChanges();
  }

  sortChanges() {
    if (this.sort === 'desc') {
      this.sortedChanges = [...this.filteredChanges];
    } else {
      this.sortedChanges = [...this.filteredChanges].reverse();
    }
  }

  filterChange(event: string): void {
    if (!event) {
      this.filteredChanges = this.changes;
    } else {
      this.filteredChanges = this.changes
        .filter((change) => {
          return change.fields.find((field) => {
            return field.name === event;
          });
        })
        .map((change) => ({
          ...change,
          fields: change.fields.filter((field) => {
            return field.name === event;
          }),
        }));
    }
    this.sortChanges();
  }

  get config(): HistoryConfig {
    return this.#config;
  }

  get service():
    | {
        find?(id: string): Observable<any>;
        history(ids: string[]): Observable<Array<HistoryEvent>>;
      }
    | undefined {
    switch (this.config.entity) {
      case HistoryEntity.CHARITY:
        return this.charityService;
      case HistoryEntity.DONATION:
        return this.donationsService;
      case HistoryEntity.LEAD:
        return this.leadsService;
      case HistoryEntity.JOURNEY:
        return this.journeyService;
      case HistoryEntity.MARKET:
        return this.marketService;
      case HistoryEntity.PARTNER:
        return this.partnerService;
      case HistoryEntity.SCHEDULE:
        return this.scheduleHistoryService;
      case HistoryEntity.STORE:
        return this.storeService;
      case HistoryEntity.TRUCK:
        return this.truckService;
      case HistoryEntity.USER:
        return this.userService;
      case HistoryEntity.XMILE:
        return this.xmileService;
      case HistoryEntity.ORGANIZATION:
        return this.organizationsService;
      case HistoryEntity.PHONE_CONFIGURATION:
        return this.phoneConfigurationService;
      default:
        return undefined;
    }
  }

  constructor(
    public override responsiveService: ResponsiveService,
    private localStorage: LocalStorageService,
    private historyService: HistoryService,
    private donationsService: DonationsService,
    private leadsService: LeadsService,
    private charityService: CharityService,
    private partnerService: PartnerService,
    private storeService: StoresService,
    private truckService: TrucksService,
    private scheduleHistoryService: ScheduleHistoryService,
    private xmileService: XmilesService,
    private marketService: MarketsService,
    private userService: UsersService,
    private journeyService: JourneysService,
    private organizationsService: OrganizationsService,
    private phoneConfigurationService: PhoneConfigurationService,
  ) {
    super(responsiveService);
    this.sort = (this.localStorage.getItem('RSPL_HISTORY_SORT') as 'asc' | 'desc') || 'asc';
  }
}
