<input
  [placeholder]="label"
  #inputElement
  matInput
  type="text"
  readonly
  [value]="fullAddress || ''"
  [ngClass]="{ invalid: showError }"
  (click)="openAddress()"
  (keydown.enter)="openAddress()"
/>
<mat-icon svgIcon="location" />
@if (showError && inputElement.value.trim().length === 0) {
  <span class="error">
    <mat-icon>info_outline</mat-icon>
    Enter your address to see available times.
  </span>
}

<ng-template #addressDialog>
  <div fxLayout="column" fxLayoutGap="10px" class="addr-dialog" matDialogContent>
    <div fxLayout="row" fxLayoutGap="10px" fxLayout.lt-md="column">
      <div fxLayout="column" fxLayoutGap="10px" fxFlex="50">
        <mat-form-field appearance="outline" class="addr-input">
          <mat-label>Street</mat-label>
          <input
            [formControl]="street"
            #inputElement
            matInput
            [matAutocomplete]="auto"
            (input)="getAddressesDelayed()"
            autocomplete="off"
          />
          <mat-autocomplete #auto="matAutocomplete" class="addr-autocomplete">
            @if (loading) {
              <mat-option disabled class="loading">
                <div fxFlex="100" fxLayoutAlign="center center">
                  <mat-spinner [diameter]="20" />
                </div>
              </mat-option>
            }
            @if (!loading && addresses.length === 0 && (street.value?.length || 0) > 0) {
              <mat-option disabled class="loading">No matches</mat-option>
            }
            @for (addr of addresses; track addr; let i = $index) {
              <mat-option [value]="addr.street" (onSelectionChange)="select(addr, $event)">
                <div fxLayout="column" fxLayoutAlign="center center">
                  <mat-icon>location_pin</mat-icon>
                </div>
                <div fxLayout="column" fxLayoutAlign="start" fxFlex>
                  <p>
                    {{ addr.street }}
                    {{ addr.secondary && '#' + addr.secondary }}
                  </p>
                  <p>{{ addr.city }}, {{ addr.state }} {{ addr.zip }}, US</p>
                </div>
              </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>

        <div fxLayout="row" fxLayout.lt-md="column" class="errors">
          @if (street.dirty && street.invalid) {
            <mat-hint class="error">Address is required</mat-hint>
          }
          @if (showPopupError && (currentAddress?.street?.length || 0) === 0) {
            <mat-hint class="error">You must select one of the offered addresses</mat-hint>
          }
          @if (showPopupError && (currentAddress?.street?.length || 0) > 0) {
            <mat-hint class="error">We need a more accurate address so we can detect your City, State & Zip code</mat-hint>
          }
        </div>
        <mat-form-field appearance="outline" fxFlex="20" fxFlex.lt-md="30">
          <mat-label>Apartment No.</mat-label>
          <input type="text" matInput [formControl]="secondary" />
        </mat-form-field>
        @if (currentAddress && !isSmallWindowSize) {
          <div fxLayout="column" fxLayoutGap="10px" class="selected-address">
            <p>Selected address:</p>
            <div>
              <label><b>Street:</b></label>
              <span>{{ currentAddress.street }}</span>
            </div>
            <div>
              <label><b>Apartment Number:</b></label>
              <span>{{ secondary.value || '/' }}</span>
            </div>
            <div>
              <label>City:</label>
              <span>{{ currentAddress.city }}</span>
            </div>
            <div>
              <label>State:</label>
              <span>{{ currentAddress.state }}</span>
            </div>
            <div>
              <label>ZIP:</label>
              <span>{{ currentAddress.zip }}</span>
            </div>
          </div>
        }
      </div>
      <div fxFlex="50" class="map-wrapper">
        <mgl-map
          #map
          [style]="'mapbox://styles/mapbox/light-v9'"
          [fitBounds]="fitBounds"
          [fitBoundsOptions]="{ linear: true }"
          [accessToken]="mapBoxAccessToken"
          [dragPan]="isAdjustingPin"
          [scrollZoom]="isAdjustingPin"
          [touchPitch]="isAdjustingPin"
          [touchZoomRotate]="isAdjustingPin"
          [doubleClickZoom]="isAdjustingPin"
        >
          @if (pinLngLat) {
            <mgl-marker [lngLat]="pinLngLat" [draggable]="isAdjustingPin" (markerDragEnd)="onPinDrag($event)"></mgl-marker>
          }
        </mgl-map>
        @if (allowAdjustingPin) {
          <div class="map-buttons">
            @if (!isAdjustingPin && pinLngLat) {
              <button class="primary-btn filled-btn" (click)="isAdjustingPin = true">Adjust pin</button>
            }
            @if (isAdjustingPin) {
              <button class="primary-btn" (click)="cancelAdjustingPin()">Cancel</button>
            }
            @if (isAdjustingPin) {
              <button class="primary-btn filled-btn" (click)="saveAdjustingPin()">Save</button>
            }
          </div>
        }
        @if (allowAdjustingPin) {
          <mat-hint>
            Adjust the marker on the map if it doesn't precisely match your location. This helps improve address data quality.
          </mat-hint>
        }
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutAlign.lt-md="center" matDialogActions>
    <button class="submit-button primary-page-btn" (click)="confirmAddress()">Confirm</button>
  </div>
</ng-template>
