import { Address, FindParams, Schedule } from '@domains';
import { Action, ActionCreator, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';

import { AppState } from '../../store/app-state.interface';

export interface ScheduleConfig {
  parentType: 'Store' | 'Truck';
  findAction: ((props: { parentId: string; findParams: FindParams }) => { findParams: FindParams } & Action<any>) & Action<any>;
  findActionSuccess: ActionCreator<string, (props: { schedules: Schedule[] }) => { schedules: Schedule[] } & Action<any>>;
  scheduleSelector: MemoizedSelector<AppState, any[], DefaultProjectorFn<any[]>>;
  createAction: ActionCreator<
    string,
    (props: { parentId: string; schedule: Schedule }) => { parentId: string; schedule: Schedule } & Action<string>
  >;
  createActionSuccess: ActionCreator<string, (props: { schedule: Schedule }) => { schedule: Schedule } & Action<string>>;
  updateAction: ActionCreator<
    string,
    (props: { parentId: string; schedule: Schedule }) => { parentId: string; schedule: Schedule } & Action<string>
  >;
  updateActionSuccess: ActionCreator<string, (props: { schedule: Schedule }) => { schedule: Schedule } & Action<string>>;
  defaultCreatedSuccess?: () => void;
  showTime: boolean;
  showCapacity: boolean;
  zonesPerDay: boolean;
  onlyDefault?: boolean;
  parentId?: string;
  partnerId?: string;
  charityId?: string;
  address?: Address;
  halfHourIntervals?: boolean;
}
