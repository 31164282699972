import { NgStyle } from '@angular/common';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { LoaderState } from '@domains';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LoaderService } from './loader.service';

@Component({
  selector: 'rspl-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  imports: [NgStyle],
})
export class LoaderComponent implements OnInit, OnDestroy {
  show = false;
  showTimeout: any;
  widthTimeout: any;
  percentInterval: any;
  percentTimeout: any;

  width = '0%';
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private loaderService: LoaderService,
    private zone: NgZone,
  ) {}

  ngOnInit(): void {
    this.loaderService.loaderState.pipe(takeUntil(this.destroy$)).subscribe((state: LoaderState) => {
      if (this.showTimeout) {
        clearTimeout(this.showTimeout);
      }
      if (this.widthTimeout) {
        clearTimeout(this.widthTimeout);
      }
      const show = this.show;
      if (state.show) {
        this.show = state.show;
      } else {
        this.showTimeout = setTimeout(
          () => (this.show = state.show),
          550, // wait for animation to finish before hiding loader
        );
      }
      if (!show && this.show) {
        this.width = '1%';
        this.widthTimeout = setTimeout(
          () =>
            this.zone.run(() => {
              this.setWidth(state.width);
            }),
          100, // wait for loader to appear so the first animation would be visible
        );
      } else if (this.show) {
        this.zone.run(() => {
          if (state.show) {
            this.setWidth(state.width);
          }
        });
      }
    });
  }

  private setWidth(width: number): void {
    const oldWidth = this.width;
    if (this.percentInterval) {
      clearInterval(this.percentInterval);
    }
    if (this.percentTimeout) {
      clearTimeout(this.percentTimeout);
    }
    this.width = width + '%';
    if (width < 95) {
      this.percentTimeout = setTimeout(
        () =>
          (this.percentInterval = setInterval(() => {
            const percent = Number(this.width.split('%')[0]) + 1;
            if (percent < 95) {
              this.width = percent + '%';
            } else {
              clearInterval(this.percentInterval);
            }
          }, 300)),
        oldWidth === '1%' ? 50 : 50,
      );
    } else {
      this.width = width + '%';
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
