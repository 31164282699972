import { autoserialize, autoserializeAs } from 'cerialize';

import { Contact } from './contact';

export class PartnerReferences {
  @autoserialize stripe?: string;
  @autoserialize essentialz?: string;
  @autoserialize salesforce?: string;
  @autoserializeAs(Contact, 'resupply_v1') resupplyV1?: number;

  constructor(payload?: IPartnerReferences) {
    this.stripe = payload?.stripe;
    this.essentialz = payload?.essentialz;
    this.salesforce = payload?.salesforce;
    this.resupplyV1 = payload?.resupplyV1;
  }
}

export interface IPartnerReferences {
  stripe?: string;
  essentialz?: string;
  salesforce?: string;
  resupplyV1?: number;
}
