import { FindJourneyParams, Journey } from '@domains';
import { Action, createReducer, on } from '@ngrx/store';
import * as moment from 'moment';

import * as fromActions from './journey.actions';

export interface JourneysState {
  journey: Journey;
  findParams: FindJourneyParams;
}

const initialState: JourneysState = {
  journey: null,
  findParams: localStorage.getItem('JourneysFindParams')
    ? JSON.parse(localStorage.getItem('JourneysFindParams'))
    : {
        date: moment().format('YYYY-MM-DD'),
      },
};

const reducer = createReducer(
  initialState,
  on(fromActions.getJourney, (state, { findParams }) => {
    localStorage.setItem('JourneysFindParams', JSON.stringify(findParams));
    return { ...state, journey: null, findParams };
  }),
  on(fromActions.getJourneySuccess, (state, action: any) => ({
    ...state,
    journey: action.journey,
  })),
  on(fromActions.updateJourneySuccess, (state, action: any) => ({
    ...state,
    journey: action.journey,
  })),
  on(fromActions.optimizeJourneySuccess, (state, action: any) => ({
    ...state,
    journey: action.journey,
  })),
);

export function journeysReducer(state: JourneysState | undefined, action: Action) {
  return reducer(state, action);
}
