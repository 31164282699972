import { CdkScrollable } from '@angular/cdk/scrolling';
import { Component, Inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { FxLayoutAlignDirective, FxLayoutDirective } from '@rspl-ui';

export interface InformationDialogData {
  title: string;
  message: string;
  button?: string;
}

@Component({
  selector: 'rspl-information-dialog',
  templateUrl: './information-dialog.component.html',
  imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatDialogActions, FxLayoutDirective, FxLayoutAlignDirective, MatButton],
})
export class InformationDialogComponent {
  public constructor(
    public reference: MatDialogRef<InformationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InformationDialogData,
  ) {}

  public dismiss(): void {
    this.reference.close();
  }
}
