<div class="file-container" (click)="selectImage()" fxLayout="column" fxLayoutAlign="center center">
  @if (!imgResultAfterCompress && !currentImageUrl && !isUploading) {
    <button class="primary-btn block-btn">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
        <span>Add Photo</span>
        <mat-icon>camera_alt</mat-icon>
      </div>
    </button>
  }
  <input #file type="file" accept="image/*" />
  @if (!imgResultAfterCompress && currentImageUrl) {
    <img [src]="currentImageUrl" />
  }
  @if (imgResultAfterCompress && !isUploading) {
    <img [src]="imgResultAfterCompress" />
  }
  @if (isUploading) {
    <mat-spinner />
  }
  @if (!isUploading && fail) {
    <mat-icon class="retry" (click)="retry($event)">refresh</mat-icon>
  }
  @if (!isUploading && fail) {
    <mat-icon class="fail">cancel</mat-icon>
  }
  @if (!isUploading && success) {
    <mat-icon class="success">check_circle</mat-icon>
  }
</div>
