export class HistoryChange {
  event?: string;
  time?: string | null;
  user?: string;
  oneValue?: boolean;
  fields?: {
    name?: string;
    oldValue?: string | null;
    newValue?: string | null;
  }[];
  lng?: number;
  lat?: number;
}
