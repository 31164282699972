import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PhoneConfigurationState } from './phone-configuration.reducers';

// tslint:disable: max-line-length
export const phoneConfigurationFeatureStateSelector = createFeatureSelector<PhoneConfigurationState>('phoneConfiguration');

export const selectPhoneConfigurations = createSelector(
  phoneConfigurationFeatureStateSelector,
  (state: PhoneConfigurationState) => state.phoneConfigurations,
);
export const selectPhoneConfiguration = createSelector(
  phoneConfigurationFeatureStateSelector,
  (state: PhoneConfigurationState) => state.phoneConfiguration,
);
export const selectPhoneConfigurationsTotalCount = createSelector(
  phoneConfigurationFeatureStateSelector,
  (state: PhoneConfigurationState) => state.phoneConfigurationsTotalCount,
);
