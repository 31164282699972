import { FindParams, Market } from '@domains';
import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './market.actions';

export interface MarketsState {
  markets: Array<Market>;
  marketsTotalCount: number;
  market: Market;
  marketsForFeatureFlags: Array<Market>;
  marketsForFeatureFlagsTotalCount: number;
  findParamsForFeatureFlags: { [key: string]: FindParams };
}

const initialState: MarketsState = {
  markets: new Array<Market>(),
  marketsTotalCount: 0,
  market: new Market(),
  marketsForFeatureFlags: new Array<Market>(),
  marketsForFeatureFlagsTotalCount: 0,
  findParamsForFeatureFlags: JSON.parse(localStorage.getItem('MarketForFeatureFlagsFindParams') || '{}'),
};

const reducer = createReducer(
  initialState,

  on(fromActions.createMarketSuccess, (state, action: any) => ({ ...state, market: action.market })),
  on(fromActions.updateMarketSuccess, (state, action: any) => ({ ...state, market: action.market })),

  on(fromActions.getMarkets, (state) => {
    return { ...state, markets: [] };
  }),
  on(fromActions.getMarketsSuccess, (state, { markets, resultsCount }) => ({ ...state, markets, marketsTotalCount: resultsCount })),
  on(fromActions.clearMarketsFindParams, (state) => {
    return { ...state };
  }),

  on(fromActions.getMarket, (state, { keep }) => ({ ...state, market: keep ? state.market : null })),
  on(fromActions.getMarketSuccess, (state, action: any) => ({ ...state, market: action.market })),

  on(fromActions.getMarketsForFeatureFlags, (state, { findParams, keep }) => {
    const newValue = { ...state.findParamsForFeatureFlags };
    newValue[window.location.pathname] = findParams;
    return {
      ...state,
      marketsForFeatureFlagsTotalCount: keep ? state.marketsForFeatureFlagsTotalCount : 0,
      marketsForFeatureFlags: keep ? state.marketsForFeatureFlags : [],
      findParamsForFeatureFlags: newValue,
    };
  }),
  on(fromActions.getMarketsForFeatureFlagsSuccess, (state, { resultsCount, results }) => ({
    ...state,
    marketsForFeatureFlagsTotalCount: resultsCount,
    marketsForFeatureFlags: results,
  })),
  on(fromActions.clearMarketsForFeatureFlagsFindParams, (state) => {
    const newValue = { ...state.findParamsForFeatureFlags };
    newValue[window.location.pathname] = {};
    return { ...state, findParamsForFeatureFlags: newValue };
  }),

  on(fromActions.updateForFeatureFlagsMarketSuccess, (state, action: any) => {
    const marketsForFeatureFlags = [...state.marketsForFeatureFlags];
    const i = marketsForFeatureFlags.findIndex((x) => x.id === action.market.id);
    marketsForFeatureFlags[i] = action.market;
    return { ...state, marketsForFeatureFlags };
  }),
);

export function marketReducer(state: MarketsState | undefined, action: Action) {
  return reducer(state, action);
}
