import { HistoryEvent } from '@domains';
import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './schedule-history.actions';

export interface ScheduleHistoryState {
  history: Array<HistoryEvent>;
}

const initialState: ScheduleHistoryState = {
  history: new Array<HistoryEvent>(),
};
const reducer = createReducer(
  initialState,
  on(fromActions.getScheduleHistorySuccess, (state, action: any) => ({
    ...state,
    history: action.history,
  })),
);

export function scheduleHistoryReducer(state: ScheduleHistoryState | undefined, action: Action) {
  return reducer(state, action);
}
