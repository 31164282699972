<mat-form-field
  appearance="outline"
  class="pick-lookup"
  (click)="open()"
  [ngClass]="{
    'mat-form-field-invalid': showError && ((!multiple && !selectedItem) || (multiple && (selectedItems?.length || 0) === 0)),
  }"
>
  <mat-label>{{ label }}</mat-label>
  @if (!isLoading && showSearchIcon) {
    <mat-icon matPrefix [ngStyle]="{ opacity: disabled ? 0.5 : 1 }" class="search-icon">search</mat-icon>
  }
  @if (isLoading) {
    <mat-spinner matPrefix [diameter]="24" class="prefix-spinner" />
  }
  <mat-select
    matInput
    [formControl]="multiple ? formControlMultiple : formControl"
    (selectionChange)="itemSelected()"
    (openedChange)="focusFilter(filterInput, $event)"
    panelClass="pick-lookup"
    disableOptionCentering="true"
    [required]="required"
    [multiple]="multiple"
    (keydown.enter)="firstLoadFinished && totalResults > 0 ? onOpenTry($event) : undefined"
    (keyup.enter)="firstLoadFinished && totalResults > 0 ? onOpenTry($event) : undefined"
    (keydown.arrow-down)="onOpenTry($event)"
    (keyup.arrow-down)="onOpenTry($event)"
    (keydown.arrow-up)="onOpenTry($event)"
    (keyup.arrow-up)="onOpenTry($event)"
    [panelWidth]="null"
  >
    <div class="lookup-filter-wrapper">
      <input
        type="text"
        placeholder="Search..."
        matInput
        class="lookup-filter"
        autocomplete="off"
        #filterInput
        [formControl]="filterControl"
        (keydown.space)="$event.stopPropagation()"
        (keydown.enter)="$event.stopPropagation()"
      />
      @if ((filterControl.value?.length || 0) > 0) {
        <mat-icon matSuffix (click)="clearSearch($event)">close</mat-icon>
      }
      <ng-content></ng-content>
    </div>
    <div class="lookup-scroll-wrapper" fxLayout="column">
      @if (showSelectAll && multiple && (totalResults || 0) > 0) {
        <button mat-button class="select-all btn-block" (click)="toggleAll()">
          {{ formControlMultiple.value?.length !== totalResults ? 'Select All' : 'Deselect All' }}
        </button>
      }
      <ng-template #Group let-group="group" let-index="index">
        @if (group.children?.length > 0) {
          <div class="level-{{ index }}">
            {{ group.item?.name || 'UNASSIGNED' }}
          </div>
        }
        @if (group.children?.length === 0) {
          <mat-option class="level-{{ index }}" [value]="group.item" [disabled]="disableOption && disableOption(group.item)">
            {{ !group.children && formatLabel ? formatLabel(group.item) : group.item.name }}
            {{ disableOption && disableOption(group.item) ? disabledLabel : '' }}
          </mat-option>
        }
        @for (subGroup of group.children; track subGroup) {
          <ng-container [ngTemplateOutlet]="Group" [ngTemplateOutletContext]="{ group: subGroup, index: index + 1 }"></ng-container>
        }
      </ng-template>
      @for (group of grouped; track group) {
        <ng-container [ngTemplateOutlet]="Group" [ngTemplateOutletContext]="{ group: group, index: 0 }"></ng-container>
      }
      @if (grouped.length === 0) {
        @for (option of results; track option; let i = $index) {
          <mat-option
            [value]="option"
            [disabled]="disableOption && disableOption(option)"
            [ngClass]="{ focused: focusedIndex === i }"
            [attr.data-index]="i"
          >
            {{ formatLabel ? formatLabel(option) : option.name }}
            {{ disableOption && disableOption(option) ? disabledLabel : '' }}
          </mat-option>
        }
      }
      @if (!!selectedItem && !isSelectedIncluded) {
        <mat-option [value]="selectedItem" class="hidden">
          {{ selectedItem.name }}
        </mat-option>
      }

      @if (totalResults === 0 && !isLoading) {
        <div class="no-matches" fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="10px">
          <p>No Matches</p>
        </div>
      }
      @if (isLoading) {
        <div fxLayoutAlign="center center" class="spinner-wrapper">
          <mat-spinner [diameter]="30" />
        </div>
      }
      @if (!isLoading && (totalResults || 0) > (results?.length || 0)) {
        <div fxLayoutAlign="center center" class="load-more-wrapper">
          <button
            class="load-more-button"
            mat-raised-button
            (click)="loadMore($event)"
            (keydown.enter)="onOpenTry($event)"
            (keyup.enter)="loadMore($event)"
          >
            Load More
          </button>
        </div>
      }
      @if (onAddNew && !isLoading && (totalResults || 0) === (results?.length || 0)) {
        <div fxLayoutAlign="center center" class="load-more-wrapper">
          @if (onAddNew) {
            <button
              fxFlex
              class="add-new-button"
              mat-raised-button
              (click)="addNew()"
              (keydown.enter)="onOpenTry($event)"
              (keyup.enter)="addNew()"
            >
              {{ addNewLabel }}
            </button>
          }
        </div>
      }
    </div>
  </mat-select>
  @if ((!required && formControl.value) || (formControlMultiple.value?.length || 0) > 0) {
    <mat-icon matSuffix (click)="clear($event)">close</mat-icon>
  }
</mat-form-field>
