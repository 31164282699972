import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TrucksService } from '@rspl-api';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import * as fromActions from './truck.actions';

@Injectable()
export class TruckEffects {
  createTruck = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.createTruck),
      mergeMap((payload) =>
        this.service.create(payload.truck).pipe(
          map((result) => {
            return fromActions.createTruckSuccess({
              truck: result,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  updateTruck = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updateTruck),
      mergeMap((payload) =>
        this.service.update(payload.truck.id, payload.truck).pipe(
          map((result) => {
            return fromActions.updateTruckSuccess({
              truck: result,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  getTrucks = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getTrucks),
      switchMap((params) =>
        this.service.filter(params.findParams).pipe(
          map((results) => {
            return fromActions.getTrucksSuccess({
              trucks: results.results,
              resultsCount: results.totalResults,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  getStateTrucks = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getStateTrucks),
      switchMap((params) =>
        this.service.filter(params.findParams).pipe(
          map((results) => {
            return fromActions.getStateTrucksSuccess({
              trucks: results.results,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  getTruckAvailabilityStats = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getTruckAvailabilityStats),
      switchMap((params) =>
        this.service.getAvailableTrucks(params.findParams).pipe(
          map((results) => {
            return fromActions.getTruckAvailabilityStatsSuccess({
              stats: results,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  getTruck = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getTruck),
      switchMap((params) =>
        this.service.find(params.id).pipe(
          map((result) => {
            return fromActions.getTruckSuccess({
              truck: result,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  deleteTruck = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.deleteTruck),
      switchMap((params) =>
        this.service.delete(params.truckId).pipe(
          map(() => {
            return fromActions.deleteTruckSuccess({
              truckId: params.truckId,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  restoreTruck = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.restoreTruck),
      switchMap((params) =>
        this.service.restore(params.truckId).pipe(
          map(() => {
            return fromActions.restoreTruckSuccess({
              truckId: params.truckId,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  // Schedule

  createTruckSchedule = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.createTruckSchedule),
      mergeMap((payload) =>
        this.service.createSchedule(payload.parentId, payload.schedule).pipe(
          map((result) => {
            return fromActions.createTruckScheduleSuccess({
              schedule: result,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  updateTruckSchedule = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updateTruckSchedule),
      mergeMap((payload) =>
        this.service.updateSchedule(payload.parentId, payload.schedule).pipe(
          map((result) => {
            return fromActions.updateTruckScheduleSuccess({
              schedule: result,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  getTruckSchedules = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getTruckSchedules),
      switchMap((params) =>
        this.service.getSchedules(params.parentId, params.findParams).pipe(
          map((results) => {
            return fromActions.getTruckSchedulesSuccess({
              schedules: results,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  getTruckSchedule = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getTruckSchedule),
      switchMap((params) =>
        this.service.getSchedule(params.truckId, params.scheduleId).pipe(
          map((result) => {
            return fromActions.getTruckScheduleSuccess({
              schedule: result,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  deleteTruckSchedule = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.deleteTruckSchedule),
      switchMap((params) =>
        this.service.deleteSchedule(params.truckId, params.scheduleId).pipe(
          map(() => {
            return fromActions.deleteTruckScheduleSuccess({
              scheduleId: params.scheduleId,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private service: TrucksService,
  ) {}
}
