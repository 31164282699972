import { CdkScrollable } from '@angular/cdk/scrolling';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Zone } from '@domains';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Destructible, DisableDoubleClickDirective, FxFlexDirective, FxLayoutAlignDirective, FxLayoutDirective } from '@rspl-ui';
import { takeUntil } from 'rxjs/operators';

import { AppState } from '../../../store/app-state.interface';
import { createZone, createZoneSuccess } from '../../../store/zone/zone.actions';

export interface SaveZoneData {
  title: string;
  zone: Zone;
}

@Component({
  selector: 'app-save-zone-dialog',
  templateUrl: './save-zone-dialog.component.html',
  styleUrls: ['./save-zone-dialog.component.scss'],
  imports: [
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    MatFormField,
    FxFlexDirective,
    MatLabel,
    MatInput,
    FormsModule,
    MatDialogActions,
    FxLayoutDirective,
    FxLayoutAlignDirective,
    MatButton,
    DisableDoubleClickDirective,
  ],
})
export class SaveZoneDialogComponent extends Destructible implements OnInit {
  public constructor(
    public reference: MatDialogRef<SaveZoneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SaveZoneData,
    private store: Store<AppState>,
    private actions: Actions,
  ) {
    super();
  }

  ngOnInit(): void {
    this.actions.pipe(ofType(createZoneSuccess), takeUntil(this.destroy$)).subscribe((res) => this.close(res.zone));
  }

  save(): void {
    this.store.dispatch(createZone({ zone: this.data.zone }));
  }

  public close(res?: Zone): void {
    this.reference.close(res);
  }
}
