import { autoserialize, autoserializeAs } from 'cerialize';

import { AnomalySourceType } from './anomaly-source-type';
import { BaseDTO, IBaseDTO } from './base-dto';
import { Donation, IDonation } from './donation';

export enum IssueCode {
  all_partners_declined = 'all_partners_declined',
  donor_same_day_reschedule = 'donor_same_day_reschedule',
  donation_time_slot_expired = 'donation_time_slot_expired',
  partner_unassigned = 'partner_unassigned',
  store_unassigned = 'store_unassigned',
  donor_cancels_in24_hours = 'donor_cancels_in24_hours',
  store_outside_partner_zone = 'store_outside_partner_zone',
  manual_donation_not_accepted = 'manual_donation_not_accepted',
  charity_unassigned = 'charity_unassigned',
}

export const issueDescriptions = {};
issueDescriptions[IssueCode.all_partners_declined] = 'All Available haulers Declined/Auto-Declined';
issueDescriptions[IssueCode.donor_same_day_reschedule] = 'Same day donor Reschedule';
issueDescriptions[IssueCode.donation_time_slot_expired] = 'Donations Not Started and End of Time Slot Passed';
issueDescriptions[IssueCode.partner_unassigned] = 'Charity Assigned, Partner Unassigned';
issueDescriptions[IssueCode.store_unassigned] = 'Charity Assigned, Store Unassigned';
issueDescriptions[IssueCode.donor_cancels_in24_hours] = 'Donor Cancels Within 24 Hours of Submission';
issueDescriptions[IssueCode.store_outside_partner_zone] = 'Store Address Not Inside Partner Zone';
issueDescriptions[IssueCode.manual_donation_not_accepted] = 'Manually Assigned Donations Not Accepted on the Day of Donation';
issueDescriptions[IssueCode.charity_unassigned] = 'Charity Unassigned';

export class Issue implements IIssue {
  @autoserialize code?: string;
  @autoserialize done?: boolean;

  constructor(payload?: IIssue) {
    this.code = payload?.code;
    this.done = !!payload?.done;
  }

  get description(): string {
    return issueDescriptions[this.code];
  }
}

export interface IIssue {
  done?: boolean;
  code?: string;
}

export class Anomaly extends BaseDTO implements IAnomaly {
  @autoserialize id?: string;
  @autoserializeAs('source_id') sourceId?: string;
  @autoserializeAs('source') source?: Donation;
  @autoserializeAs('source_type') sourceType?: AnomalySourceType;
  @autoserializeAs('ticket_id') ticketId?: string;
  @autoserializeAs('issues') issues?: Array<Issue>;
  @autoserialize done?: boolean;

  constructor(payload?: IAnomaly) {
    super(payload);
    this.id = payload?.id?.toString();
    this.done = !!payload?.done;
    this.source = payload?.source ? new Donation(payload.source) : undefined;
    this.sourceId = this.source?.id || payload?.sourceId?.toString();
    this.sourceType = payload?.sourceType;
    this.ticketId = payload?.ticketId?.toString();
    this.issues = payload?.issues ? payload?.issues.map((issue) => new Issue(issue)) : [];
  }
}

export interface IAnomaly extends IBaseDTO {
  id?: string;
  sourceId?: string;
  source?: IDonation;
  sourceType?: AnomalySourceType;
  ticketId?: string;
  issues?: IIssue[];
  done?: boolean;
}
