import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { Charity, SplitScreen } from '@domains';
import { FxFlexDirective, FxLayoutAlignDirective, FxLayoutDirective } from '@rspl-ui';

import { FxLayoutGapDirective } from '../../flex-layout/fx-layout-gap.directive';
import { InformationDialogComponent } from '../../information-dialog';
import { Responsive, ResponsiveService } from '../../responsive';
import { AboutResupplyDialogComponent } from '../about-resupply-dialog/about-resupply-dialog.component';

@Component({
  selector: 'rspl-split-screen-v3a',
  templateUrl: './split-screen-v3a.component.html',
  styleUrl: './split-screen-v3a.component.scss',
  imports: [FxLayoutDirective, FxFlexDirective, FxLayoutAlignDirective, FxLayoutGapDirective, MatIcon, NgClass],
})
export class SplitScreenV3aComponent extends Responsive {
  @Input() charity: Charity;
  @Input() config: SplitScreen;
  @Input() goToPrimary: () => void;
  @Input() goToFree: () => void;

  constructor(
    public override responsiveService: ResponsiveService,
    private dialog: MatDialog,
  ) {
    super(responsiveService);
  }

  get shortText() {
    return (
      this.isSmallWindowSize && this.config?.text?.length > 500 ? this.config?.text?.substring(0, 500) + '...' : this.config?.text || ''
    ).replaceAll('\n', '</br>');
  }

  get text() {
    return this.config?.text?.replaceAll('\n', '</br>');
  }

  showText() {
    this.dialog.open(InformationDialogComponent, {
      width: '90vw',
      height: '80vh',
      data: {
        title: this.config.title,
        message: this.config.text?.replaceAll('\n', '</br>'),
        button: 'I understand',
      },
    });
  }

  showVideo() {
    this.dialog.open(AboutResupplyDialogComponent, {
      width: '690px',
      maxWidth: '90vw',
      maxHeight: '80vh',
    });
  }
}
