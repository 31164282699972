import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  BaseDonationLead,
  ButtonActivity,
  Donation,
  DonationDiscount,
  DonationStateByCodeDTO,
  DonationStateDTO,
  ENVIRONMENT,
  Environment,
  HistoryEvent,
  InputActivity,
  PageActivity,
  Pricing,
  Quote,
  ReceiptResponse,
  SponsorshipAlgorithm,
  User,
  WeekAvailabilityDto,
} from '@domains';
import { Deserialize, Serialize } from 'cerialize';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

import { BaseApiService } from '../base-api.service';

@Injectable({
  providedIn: 'root',
})
export class DonationsService extends BaseApiService<Donation> {
  constructor(
    @Inject(ENVIRONMENT) override config: Environment,
    override http: HttpClient,
  ) {
    super(
      config,
      http,
      'donations',
      Donation,
      'Donation',
      ['charity', 'partner', 'store', 'vehicle'],
      ['charity', 'partner', 'store', 'extra_mile', 'vehicle'],
    );
  }

  override deserialize(data: any): Donation {
    const discount = data?.discount ? new DonationDiscount(Deserialize(data.discount, DonationDiscount)) : undefined;
    const sponsorship_algorithm = data?.sponsorship_algorithm
      ? new SponsorshipAlgorithm(Deserialize(data.sponsorship_algorithm, SponsorshipAlgorithm))
      : undefined;
    const quote = data?.quote ? new Quote(Deserialize(data.quote, Quote)) : undefined;
    const res = new Donation({
      ...Deserialize(
        {
          ...data,
        },
        Donation,
      ),
      ...Deserialize(
        {
          ...data,
        },
        BaseDonationLead,
      ),
      discount,
      sponsorship_algorithm,
      quote,
    });

    if (res.charity?.logo && !res.charity?.logo.startsWith('http')) {
      res.charity.logo = this.config.urls.baseUrl + res.charity?.logo;
    }
    if (res.charity?.meta?.splitScreenConfig && !res.charity?.meta.splitScreenConfig.startsWith('http')) {
      res.charity.meta.splitScreenConfig = this.config.urls.baseUrl + res.charity.meta.splitScreenConfig;
    }
    res.setInitialValue();
    return res;
  }

  override serialize(item: Donation) {
    const res = {
      ...Serialize(item, Donation),
      ...Serialize(
        {
          ...item,
        },
        BaseDonationLead,
      ),
    };
    delete res.pricing;
    delete res.payment;
    delete res.fee;
    delete res.charity_state;
    delete res.donor_state;
    delete res.partner_state;
    delete res.marketing_source;
    delete res.quote;
    delete res.sponsorship_algorithm;
    return res;
  }

  convertLeadToDonation(lead_id: string): Observable<Donation> {
    return this.http
      .post<Donation>(this.config.urls.baseUrl + '/donations/submit/', {
        lead_id,
      })
      .pipe(
        map((result: Donation) => {
          return this.deserialize(result);
        }),
      );
  }

  getPricing(): Observable<Pricing> {
    return this.http.get<Pricing>(this.config.urls.baseUrl + '/donations/pricing').pipe(
      map((pricing: Pricing) => {
        return new Pricing(Deserialize(pricing, Pricing));
      }),
    );
  }

  getDeclinedHistory(donationId: string): Observable<Array<HistoryEvent>> {
    return this.http
      .get<HistoryEvent[]>(this.config.urls.baseUrl + '/events', {
        params: {
          expand: 'user',
          target_type: 'Donation',
          target_id: donationId,
          order: 'created_at',
          order_direction: 'desc',
          event_type: 'donation_partner_decline',
        },
      })
      .pipe(
        map((response: any) => {
          return response?.map(
            (r: any) =>
              new HistoryEvent({
                ...Deserialize(r, HistoryEvent),
                user: r.user ? Deserialize(r.user, User) : undefined,
              }),
          );
        }),
      );
  }

  getPaymentReceipt(donationCode: string): Observable<string> {
    return this.http.get<ReceiptResponse>(this.config.urls.baseUrl + '/donations/code/' + donationCode + '/checkout/receipt').pipe(
      map((response: ReceiptResponse) => {
        return new ReceiptResponse(Deserialize(response, ReceiptResponse)).url || '';
      }),
    );
  }

  getDonationByCode(donationCode: string, expand: string[] = ['charity']): Observable<Donation> {
    return this.http
      .get<Donation>(this.config.urls.baseUrl + `/donations/code/` + donationCode, {
        params: {
          'expand[]': expand,
        },
      })
      .pipe(
        map((donation: Donation) => {
          return this.deserialize(donation);
        }),
      );
  }

  updateDonationState(donationId: any, state: DonationStateDTO): Observable<Donation> {
    return this.http
      .post<Donation>(this.config.urls.baseUrl + '/donations/' + donationId + '/state', Serialize(state, DonationStateDTO))
      .pipe(
        map((result: Donation) => {
          return this.deserialize(result);
        }),
      );
  }

  requestDonationCancellation(donationId: string): Observable<Donation> {
    return this.http.post<Donation>(this.config.urls.baseUrl + '/donations/' + donationId + '/request_cancel', {}).pipe(
      switchMap(() => {
        return this.find(donationId);
      }),
    );
  }

  refund(
    donationId: string,
    type: 'payment' | 'application_fee' | 'cancellation_fee' | 'booking_fee' | 'courier',
    amount?: number,
  ): Observable<Donation> {
    return this.http.post<Donation>(this.config.urls.baseUrl + '/donations/' + donationId + '/refund', {
      refund_type: type,
      ...(amount ? { amount: Math.round(Number(amount * 100)) } : {}),
    });
  }

  charge(donationId: string, type: 'offline' | 'manual', fee?: number): Observable<Donation> {
    return this.http.post<Donation>(this.config.urls.baseUrl + '/donations/' + donationId + '/payment/' + type, {
      resupply_fee: fee,
    });
  }

  floatPartner(donationId: string, resupplyFee?: number, partnerFee?: boolean): Observable<Donation> {
    let payload = {};
    if (resupplyFee) {
      payload = { resupply_fee: resupplyFee * 100 };
    } else if (partnerFee) {
      payload = { partner_fee: partnerFee };
    } else {
      payload = {};
    }
    return this.http.post<Donation>(this.config.urls.baseUrl + '/donations/' + donationId + '/payment/float', payload);
  }

  getFloatFees(donationId: string, resupplyFee?: number, partnerFee?: boolean): Observable<Donation> {
    let payload = {};
    if (resupplyFee) {
      payload = { resupply_fee: resupplyFee * 100 };
    } else if (partnerFee) {
      payload = { partner_fee: partnerFee };
    } else {
      payload = {};
    }
    return this.http.post<Donation>(this.config.urls.baseUrl + '/donations/' + donationId + '/payment/float/details', payload);
  }

  getAvailability(
    week?: number,
    year?: number,
    charityId?: string,
    zip?: string,
    partnerId?: string | null,
    partnerIds?: string[],
  ): Observable<WeekAvailabilityDto> {
    const params = {
      ...(zip ? { zip } : {}),
      ...(year ? { year } : {}),
      ...(week ? { week } : {}),
      ...(charityId ? { charity_id: charityId } : {}),
      ...(partnerId ? { partner_id: partnerId } : {}),
      ...(partnerIds ? { 'partner_id[]': partnerIds } : {}),
    };
    return this.http.get<WeekAvailabilityDto>(this.config.urls.baseUrl + `/donations/availability`, {
      params,
    });
  }

  getDefaultPricing(): Observable<Pricing> {
    return this.http.get<Pricing>(this.config.urls.baseUrl + `/donations/pricing`).pipe(
      map((pricing: Pricing) => {
        return new Pricing(Deserialize(pricing, Pricing));
      }),
    );
  }

  updateDonationByCode(donation: Donation): Observable<Donation> {
    return this.http
      .put<Donation>(this.config.urls.baseUrl + `/donations/code/${donation.donationCode}`, this.serialize(donation))
      .pipe(map((donation) => this.deserialize(donation)));
  }

  updateDonationStateByCode(donationCode: string, state: DonationStateByCodeDTO): Observable<Donation> {
    return this.http
      .post<Donation>(this.config.urls.baseUrl + `/donations/code/${donationCode}/state`, Serialize(state, DonationStateByCodeDTO))
      .pipe(map((donation) => this.serialize(donation)));
  }

  reviewDonation(donationCode: string, review: { rating: number | null; comment: string | null }): Observable<Donation> {
    return this.http
      .post<Donation>(this.config.urls.baseUrl + `/donations/code/${donationCode}/review`, {
        review,
      })
      .pipe(map((response) => this.deserialize(response)));
  }

  createPaymentIntent(donation: Donation): Observable<{ client_secret: string; stripe_account: string }> {
    return this.http.post<{ client_secret: string; stripe_account: string }>(
      `${this.config.urls.baseUrl}/donations/code/${donation.donationCode}/payment`,
      {},
    );
  }

  findDonorDonations(
    email_or_phone: string | null,
    last4?: string | null,
  ): Observable<{
    code?: string;
    success?: boolean;
    count?: number;
  }> {
    return this.http.post<{
      code?: string;
      success?: boolean;
      count?: number;
    }>(this.config.urls.baseUrl + `/donations/code/`, {
      email_or_phone,
      last4,
    });
  }

  createDonationActivity(
    donationId: string | undefined | null,
    type: PageActivity | ButtonActivity | InputActivity,
    value?: any,
    prefix = '',
    origin?: string,
  ): Observable<any> {
    if (!donationId) {
      console.log('ERROR');
      return of().pipe(take(1));
    }
    if (Object.values(PageActivity).includes(type as PageActivity)) {
      return this.createDonationPageActivity(donationId, type as PageActivity, value, prefix, origin).pipe(take(1));
    } else if (Object.values(ButtonActivity).includes(type as ButtonActivity)) {
      return this.createDonationButtonActivity(donationId, type as ButtonActivity, value, prefix).pipe(take(1));
    } else if (Object.values(InputActivity).includes(type as InputActivity)) {
      return this.createDonationInputActivity(donationId, type as InputActivity, value, prefix).pipe(take(1));
    }
    return of().pipe(take(1));
  }

  report(params?: { [param: string]: any }): Observable<{
    results: Array<Donation>;
    totalResults: number;
  }> {
    return this.http
      .get(this.config.urls.baseUrl + `/donations/report`, {
        params: {
          ...(this.expandFilter.length > 0 ? { 'expand[]': this.expandFilter } : {}),
          ...params,
        },
        observe: 'response',
      })
      .pipe(
        map((response: any) => {
          return {
            results: response.body?.map((x: any) => this.deserialize(x)),
            totalResults: Number(response.headers.get('Total-Count')),
          };
        }),
        catchError(this.handleError),
      );
  }

  private createDonationPageActivity(
    donationId: string,
    page: PageActivity,
    value?: any,
    prefix?: string,
    origin?: string,
  ): Observable<any> {
    return this.http.post<any>(this.config.urls.baseUrl + `/donations/${donationId}/activity`, {
      page: prefix + page,
      value,
      origin,
    });
  }

  private createDonationButtonActivity(donationId: string, button: ButtonActivity, value?: any, prefix?: string): Observable<any> {
    return this.http.post<any>(this.config.urls.baseUrl + `/donations/${donationId}/activity`, {
      button: prefix + button,
      value,
    });
  }

  private createDonationInputActivity(donationId: string, input: InputActivity, value?: any, prefix?: string): Observable<any> {
    return this.http.post<any>(this.config.urls.baseUrl + `/donations/${donationId}/activity`, {
      input: prefix + input,
      value,
    });
  }
}
