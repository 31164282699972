import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

import { anomalyReducer } from './anomaly/anomaly.reducers';
import { AppState } from './app-state.interface';
import { budgetReducer } from './budget/budget.reducers';
import { charityReducer } from './charity/charity.reducers';
import { storesReducer } from './charity-store/charity-store.reducers';
import { donationReducer } from './donation/donation.reducers';
import { itemReducer } from './item/item.reducers';
import { journeysReducer } from './journey/journey.reducers';
import { marketReducer } from './market/market.reducers';
import { notificationsReducer } from './notification/notification.reducers';
import { organizationReducer } from './organization/organization.reducers';
import { pageReducer } from './page/page.reducers';
import { partnerReducer } from './partner/partner.reducers';
import { phoneConfigurationReducer } from './phone-configuration/phone-configuration.reducers';
import { scheduleHistoryReducer } from './schedule-history/schedule-history.reducers';
import { trpReducer } from './trp/trp.reducers';
import { trucksReducer } from './truck/truck.reducers';
import { usersReducer } from './user/user.reducers';
import { xmileReducer } from './xmile/xmile.reducers';
import { zoneReducer } from './zone/zone.reducers';

export function getReducers() {
  return {
    donation: donationReducer,
    charity: charityReducer,
    user: usersReducer,
    store: storesReducer,
    partner: partnerReducer,
    item: itemReducer,
    truck: trucksReducer,
    zone: zoneReducer,
    page: pageReducer,
    market: marketReducer,
    xmile: xmileReducer,
    trp: trpReducer,
    scheduleHistory: scheduleHistoryReducer,
    notification: notificationsReducer,
    journey: journeysReducer,
    organization: organizationReducer,
    phoneConfiguration: phoneConfigurationReducer,
    anomaly: anomalyReducer,
    budget: budgetReducer,
  };
}

export const reducerToken = new InjectionToken<ActionReducerMap<AppState>>('Reducers');
export const reducerProvider = [{ provide: reducerToken, useFactory: getReducers }];
