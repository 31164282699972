import { Directive, HostListener, OnDestroy, OnInit, output } from '@angular/core';
import { Subject } from 'rxjs';
import { buffer, debounceTime, filter, map } from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[rsplDoubleClick]',
  standalone: true,
})
export class DoubleClickDirective implements OnInit, OnDestroy {
  private click$ = new Subject<MouseEvent>();

  readonly rsplDoubleClick = output<MouseEvent>();

  @HostListener('click', ['$event'])
  onClick(event: any): void {
    this.click$.next(event);
  }

  ngOnInit(): void {
    this.click$
      .pipe(
        buffer(this.click$.pipe(debounceTime(250))),
        filter((list) => list.length === 2),
        map((list) => list[1]),
      )
      .subscribe((event) => this.rsplDoubleClick.emit(event));
  }

  ngOnDestroy(): void {
    this.click$.complete();
  }
}
