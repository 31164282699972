import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, Environment, Widget } from '@domains';

import { BaseApiService } from '../base-api.service';

@Injectable({
  providedIn: 'root',
})
export class WidgetsService extends BaseApiService<Widget> {
  constructor(
    @Inject(ENVIRONMENT) override config: Environment,
    override http: HttpClient,
  ) {
    super(config, http, 'widgets', Widget, 'Widget');
  }
}
