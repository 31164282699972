import { Directive, ElementRef, HostBinding, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { WindowSize } from '@domains'; // Import your WindowSize enum
import { Subscription } from 'rxjs';

import { ResponsiveService } from '../responsive'; // Import your ResponsiveService

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[fxLayoutAlign]',
  standalone: true,
})
export class FxLayoutAlignDirective implements OnInit, OnDestroy {
  @Input('fxLayoutAlign') defaultAlign: string; // Default alignment
  @Input('fxLayoutAlign.gt-lg') fxLayoutAlignGtLg: string;
  @Input('fxLayoutAlign.gt-md') fxLayoutAlignGtMd: string;
  @Input('fxLayoutAlign.gt-sm') fxLayoutAlignGtSm: string;
  @Input('fxLayoutAlign.gt-xs') fxLayoutAlignGtXs: string;
  @Input('fxLayoutAlign.lt-xl') fxLayoutAlignLtXl: string;
  @Input('fxLayoutAlign.lt-lg') fxLayoutAlignLtLg: string;
  @Input('fxLayoutAlign.lt-md') fxLayoutAlignLtMd: string;
  @Input('fxLayoutAlign.lt-sm') fxLayoutAlignLtSm: string;
  @Input('fxLayoutAlign.md') fxLayoutAlignMd: string;
  @Input('fxLayoutAlign.sm') fxLayoutAlignSm: string;
  @Input('fxLayoutAlign.lg') fxLayoutAlignLg: string;
  @Input('fxLayoutAlign.xl') fxLayoutAlignXl: string;
  @Input('fxLayoutAlign.xs') fxLayoutAlignXs: string;

  private layoutChanges$: Subscription;

  @HostBinding('style.display') display = 'flex';
  @HostBinding('style.justify-content') justifyContent: string;
  @HostBinding('style.align-items') alignItems: string;

  constructor(
    private responsiveService: ResponsiveService,
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    // Subscribe to layout changes from the ResponsiveService and reapply alignment on breakpoint change
    this.layoutChanges$ = this.responsiveService.size.subscribe((size) => {
      this.applyAlignment(size);
    });
  }

  ngOnDestroy() {
    if (this.layoutChanges$) {
      this.layoutChanges$.unsubscribe();
    }
  }

  private applyAlignment(size: WindowSize) {
    let alignValue = this.defaultAlign;

    // Handle exact match breakpoints
    if (this.hasValue(this.fxLayoutAlignXs) && size === WindowSize.XS) {
      alignValue = this.fxLayoutAlignXs;
    } else if (this.hasValue(this.fxLayoutAlignSm) && size === WindowSize.SM) {
      alignValue = this.fxLayoutAlignSm;
    } else if (this.hasValue(this.fxLayoutAlignMd) && size === WindowSize.MD) {
      alignValue = this.fxLayoutAlignMd;
    } else if (this.hasValue(this.fxLayoutAlignLg) && size === WindowSize.LG) {
      alignValue = this.fxLayoutAlignLg;
    } else if (this.hasValue(this.fxLayoutAlignXl) && size === WindowSize.XL) {
      alignValue = this.fxLayoutAlignXl;
    }

    // Handle 'greater than' breakpoints (gt-*)
    if (this.hasValue(this.fxLayoutAlignGtLg) && size === WindowSize.XL) {
      alignValue = this.fxLayoutAlignGtLg;
    } else if (this.hasValue(this.fxLayoutAlignGtMd) && [WindowSize.XL, WindowSize.LG].includes(size)) {
      alignValue = this.fxLayoutAlignGtMd;
    } else if (this.hasValue(this.fxLayoutAlignGtSm) && [WindowSize.XL, WindowSize.LG, WindowSize.MD].includes(size)) {
      alignValue = this.fxLayoutAlignGtSm;
    } else if (this.hasValue(this.fxLayoutAlignGtXs) && [WindowSize.XL, WindowSize.LG, WindowSize.MD, WindowSize.SM].includes(size)) {
      alignValue = this.fxLayoutAlignGtXs;
    }

    // Handle 'less than' breakpoints (lt-*)
    if (this.hasValue(this.fxLayoutAlignLtSm) && size === WindowSize.XS) {
      alignValue = this.fxLayoutAlignLtSm;
    } else if (this.hasValue(this.fxLayoutAlignLtMd) && [WindowSize.XS, WindowSize.SM].includes(size)) {
      alignValue = this.fxLayoutAlignLtMd;
    } else if (this.hasValue(this.fxLayoutAlignLtLg) && [WindowSize.XS, WindowSize.SM, WindowSize.MD].includes(size)) {
      alignValue = this.fxLayoutAlignLtLg;
    } else if (this.hasValue(this.fxLayoutAlignLtXl) && [WindowSize.XS, WindowSize.SM, WindowSize.MD, WindowSize.LG].includes(size)) {
      alignValue = this.fxLayoutAlignLtXl;
    }

    // Apply parsed alignment values to justify-content and align-items
    const { justify, align } = this.parseAlignment(alignValue);
    this.justifyContent = this.getJustifyContent(justify);
    this.alignItems = this.getAlignItems(align);
  }

  private parseAlignment(alignment: string) {
    const [justify, align] = alignment ? alignment.split(' ') : ['start', 'stretch'];
    return { justify, align };
  }

  private getJustifyContent(value: string) {
    switch (value) {
      case 'start':
      case 'flex-start':
        return 'flex-start';
      case 'center':
        return 'center';
      case 'end':
      case 'flex-end':
        return 'flex-end';
      case 'space-around':
        return 'space-around';
      case 'space-between':
        return 'space-between';
      default:
        return 'flex-start';
    }
  }

  private getAlignItems(value: string) {
    switch (value) {
      case 'start':
      case 'flex-start':
        return 'flex-start';
      case 'center':
        return 'center';
      case 'end':
      case 'flex-end':
        return 'flex-end';
      case 'stretch':
        return 'stretch';
      case 'baseline':
        return 'baseline';
      default:
        return 'stretch';
    }
  }

  private hasValue(value: string | number): boolean {
    return value !== undefined && value !== null;
  }
}
