import { NgClass } from '@angular/common';
import { Component, Input, output } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatChipGrid, MatChipInput, MatChipInputEvent, MatChipRemove, MatChipRow } from '@angular/material/chips';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FxLayoutDirective, FxLayoutGapDirective, Responsive, ResponsiveService } from '@rspl-ui';

@Component({
  selector: 'app-zip-picker',
  templateUrl: './zip-picker.component.html',
  styleUrls: ['./zip-picker.component.scss'],
  imports: [
    FxLayoutDirective,
    FxLayoutGapDirective,
    MatFormField,
    NgClass,
    MatLabel,
    MatChipGrid,
    MatChipRow,
    MatChipRemove,
    MatIcon,
    MatChipInput,
    MatSuffix,
    MatButton,
  ],
})
export class ZipPickerComponent extends Responsive {
  @Input() label = 'Zip codes:';
  @Input() day: string;
  @Input() disabled = false;
  readonly scheduleUpdated = output<string[]>();
  @Input() zips: string[] = [];
  @Input() required = true;
  @Input() showErrorMessage = false;

  constructor(
    private snackBar: MatSnackBar,
    public override responsiveService: ResponsiveService,
  ) {
    super(responsiveService);
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.zips.push(value);
      this.zips = [...new Set(this.zips)];
    }

    event.input.value = '';
    this.scheduleUpdated.emit(this.zips);
  }

  remove(zip: string): void {
    const index = this.zips.indexOf(zip);

    if (index >= 0) {
      this.zips.splice(index, 1);
      this.zips = [...new Set(this.zips)];
    }
    this.scheduleUpdated.emit(this.zips);
  }

  paste(event: ClipboardEvent): void {
    event.preventDefault();
    event.clipboardData
      .getData('Text')
      .split(/;|,|\n/) // Splits it when a SEMICOLON or COMMA or NEWLINE
      .forEach((value) => {
        if (value.trim()) {
          this.zips.push(value.trim());
        }
      });
    this.zips = [...new Set(this.zips)];
    this.scheduleUpdated.emit(this.zips);
  }

  copy(): void {
    navigator.clipboard.writeText(this.zips.join(', ')).then(() =>
      this.snackBar.open('Zips have been copied to clipboard successfully!', null, {
        duration: 1500,
        panelClass: 'primary',
      }),
    );
  }
}
