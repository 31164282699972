<mat-form-field appearance="outline" fxFlex>
  <mat-label>{{ label }}</mat-label>
  <input
    [formControl]="street"
    #inputElement
    matInput
    [matAutocomplete]="auto"
    (input)="getAddressesDelayed()"
    (focus)="getAddressesInitial()"
    autocomplete="off"
  />
  @if (hasIcon) {
    <mat-icon matPrefix>search</mat-icon>
  }
  <rspl-input-audio matSuffix [audio]="audio" />
  <mat-autocomplete #auto="matAutocomplete" class="addr-autocomplete">
    @if (loading) {
      <mat-option disabled class="loading" fxLayoutAlign="center center">
        <div fxFlex="100" fxLayoutAlign="center center">
          <mat-spinner [diameter]="20" />
        </div>
      </mat-option>
    }
    @if (!loading && addresses.length === 0 && (street.value?.length || 0) > 0) {
      <mat-option disabled class="loading" fxLayoutAlign="center center">No matches</mat-option>
    }
    @for (addr of addresses; track addr; let i = $index) {
      <mat-option [value]="addr.street" (onSelectionChange)="select(addr, $event)">
        <div fxLayout="row">
          <div fxLayout="column" fxLayoutAlign="center center">
            <mat-icon>location_pin</mat-icon>
          </div>
          <div fxLayout="column" fxLayoutAlign="start" fxFlex>
            <p>
              {{ addr.street }}
              {{ addr.secondary && '#' + addr.secondary }}
            </p>
            <p>{{ addr.city }}, {{ addr.state }} {{ addr.zip }}, US</p>
          </div>
        </div>
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
