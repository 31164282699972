<section class="ngx-json-viewer">
  @for (segment of segments; track segment) {
    <section [ngClass]="['segment', 'segment-type-' + segment.type]">
      <section
        (click)="toggle(segment)"
        [ngClass]="{
          'segment-main': true,
          expandable: isExpandable(segment),
          expanded: segment.expanded,
          clickable: !isExpandable(segment),
        }"
      >
        @if (isExpandable(segment)) {
          <div class="toggler"></div>
        }
        <span class="segment-key">{{ segment.key }}</span>
        @if (!isExpandable(segment)) {
          <span class="segment-separator">:</span>
        }
        @if (!segment.expanded || !isExpandable(segment)) {
          <span class="segment-value">
            {{ ['object', 'array'].includes(segment.type || '') ? '' : segment.description }}
          </span>
        }
      </section>
      @if (segment.expanded && isExpandable(segment)) {
        <section class="children">
          @if (segment.type) {
            <rspl-json-viewer
              [json]="segment.value"
              [expanded]="expanded"
              [depth]="depth"
              [_currentDepth]="_currentDepth + 1"
              [path]="
                path + (parentType === 'array' ? '[' + segment.key + ']' : this.parentType === 'object' ? '.' + segment.key : segment.key)
              "
              [parentType]="segment.type"
              (onSelect)="onSelect.emit($event)"
            ></rspl-json-viewer>
          }
        </section>
      }
    </section>
  }
</section>
