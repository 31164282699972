<div fxLayout="row" class="week-picker" fxLayoutAlign="center center">
  <span fxLayout="row" fxLayoutAlign="center" (click)="moveWeek(-1)">
    <mat-icon>arrow_back_ios</mat-icon>
  </span>
  <p fxLayout="column" fxFlex fxLayoutAlign="center center">
    {{ weekStart.toDate() | date: 'MMM d, y' }} - {{ weekEnd.toDate() | date: 'MMM d, y' }}
  </p>
  <span fxLayout="row" fxLayoutAlign="center" (click)="moveWeek(1)">
    <mat-icon>arrow_forward_ios</mat-icon>
  </span>
</div>
