<app-base-container [page]="true" pageTitle="History">
  <div class="filters-wrapper">
    <form fxLayout="row" fxLayout.lt-md="column" [formGroup]="form" fxLayoutGap="20px">
      <div fxFlex="40">
        <mat-form-field appearance="outline" fxLayout="column" fxFlex>
          <mat-label>Entity</mat-label>
          <mat-select matInput formControlName="entity">
            @for (en of entities; track en) {
              <mat-option [value]="en">
                {{ en }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="40">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Id</mat-label>
          <input formControlName="id" matInput />
        </mat-form-field>
      </div>
      <div fxFlex="10">
        <button mat-raised-button (click)="fetch()" [disabled]="form.invalid" class="primary-btn">Fetch</button>
      </div>
    </form>
  </div>
  @if (!!history()?.item) {
    <h2>{{ history()?.item.name }}</h2>
  }
  @if (config) {
    <rspl-history [config]="config" />
  }
</app-base-container>
