import { createFeatureSelector, createSelector } from '@ngrx/store';

import { MarketsState } from './market.reducers';

// tslint:disable: max-line-length
export const marketsFeatureStateSelector = createFeatureSelector<MarketsState>('market');

export const selectMarkets = createSelector(marketsFeatureStateSelector, (state: MarketsState) => state.markets);
export const selectMarketsTotalCount = createSelector(marketsFeatureStateSelector, (state: MarketsState) => state.marketsTotalCount);

export const selectMarket = createSelector(marketsFeatureStateSelector, (state: MarketsState) => state.market);

export const selectMarketsForFeatureFlags = createSelector(
  marketsFeatureStateSelector,
  (state: MarketsState) => state.marketsForFeatureFlags,
);
export const selectMarketsForFeatureFlagsTotalCount = createSelector(
  marketsFeatureStateSelector,
  (state: MarketsState) => state.marketsForFeatureFlagsTotalCount,
);
export const selectMarketsForFeatureFlagsFindParams = createSelector(
  marketsFeatureStateSelector,
  (state: MarketsState) => state.findParamsForFeatureFlags,
);
