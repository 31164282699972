<ng-container [ngTemplateOutlet]="isDriver ? DriverTemplate : DefaultTemplate"></ng-container>
<ng-template #DriverTemplate>
  <div class="driver-login login-wrapper" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="20px" (keyup.enter)="login()">
    <img src="assets/images/rspl_logo.png" alt="" />
    <h2>Login to Your driver account</h2>

    @if (form) {
      <form [formGroup]="form">
        <div class="form-row" fxLayout="column" [ngClass]="{ invalid: showErrors && email.errors }">
          <label>Username</label>
          <input type="email" formControlName="email" placeholder="Your email" />
          @if (showErrors && email.errors && email.errors['required']) {
            <span class="error-message">Email is required</span>
          }
          @if (showErrors && email.errors && email.errors['email']) {
            <span class="error-message">Email is not valid</span>
          }
        </div>
        <div class="form-row" fxLayout="column" [ngClass]="{ invalid: showErrors && password.errors }">
          <div fxLayout="row" fxLayoutAlign="space-between">
            <label>Password</label>
            <a [href]="resetPasswordUrl" target="_blank">Forgot password?</a>
          </div>
          <input [type]="showPassword ? 'text' : 'password'" formControlName="password" placeholder="Your password" />
          <button
            mat-icon-button
            (click)="showPassword = !showPassword"
            matSuffix
            class="password-toggle"
            [ngClass]="{ invisible: !showPassword }"
          >
            <mat-icon>remove_red_eye</mat-icon>
          </button>
          @if (showErrors && password.errors && password.errors['required']) {
            <span class="error-message">Password is required</span>
          }
        </div>
        <div fxLayoutAlign="end">
          <button class="primary-btn block-btn" type="submit" (click)="login()" [ladda]="isSubmitting">Sign In</button>
        </div>
        @if (support) {
          <p>
            If you're having trouble logging in you can contact support via following number:
            <a href="tel:{{ support }}">{{ support }}</a>
          </p>
        }
      </form>
    }
  </div>
</ng-template>
<ng-template #DefaultTemplate>
  <div class="login-wrapper {{ app }}" fxLayoutAlign="center center">
    <rspl-card [cardTitle]="isDriver ? 'Login to Your driver account' : 'Sign In to Your ' + app + ' Portal'" class="login-card p4">
      @if (form) {
        <form [formGroup]="form">
          <mat-card-content fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="30px" fxLayoutGap.xs="0" fxPosition="static">
            <div fxFlex="50" fxFlex.xs="100" class="login-form-wrapper">
              <div fxLayout="column">
                <mat-form-field appearance="outline">
                  <mat-label>Email</mat-label>
                  <input formControlName="email" type="email" matInput />
                </mat-form-field>
              </div>
              <div fxLayout="column">
                <mat-form-field appearance="outline">
                  <mat-label>Password</mat-label>
                  <input [type]="showPassword ? 'text' : 'password'" formControlName="password" matInput />
                  <button
                    type="button"
                    mat-icon-button
                    (click)="showPassword = !showPassword"
                    matSuffix
                    class="password-toggle"
                    [ngClass]="{ invisible: !showPassword }"
                  >
                    <mat-icon>remove_red_eye</mat-icon>
                  </button>
                </mat-form-field>
                <a class="forgot-password-link" [href]="resetPasswordUrl" target="_blank">Forgot password?</a>
              </div>

              <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center">
                <button
                  type="submit"
                  mat-raised-button
                  rsplDisableDoubleClick
                  (oneClick)="login()"
                  [ladda]="isSubmitting"
                  class="primary-btn filled-btn"
                >
                  Sign In
                </button>
                <!--            <div class="hidden-xs" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center" *ngIf="app === appTypes.CHARITY">-->
                <!--              <span class="text-center">- or create an account to start receiving Resupply pick-ups in as little as 1 week! - </span>-->
                <!--              <button type="button" mat-raised-button routerLink="/onboarding" class="primary-btn">Sign Up</button>-->
                <!--            </div>-->
                @if (app === appTypes.TPL) {
                  <div class="hidden-xs" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center">
                    <span class="text-center">- or create an account -</span>
                    <button type="button" mat-raised-button routerLink="/onboarding" class="primary-btn">Sign Up</button>
                  </div>
                }
              </div>

              @if (isTPL && support) {
                <p class="support">
                  If you're having trouble logging in you can contact support via following number:
                  <a href="tel:{{ support }}">{{ support }}</a>
                </p>
              }
            </div>
            <mat-icon fxFlex svgIcon="rspl-logo" />
          </mat-card-content>
        </form>
      }
    </rspl-card>
  </div>
</ng-template>
