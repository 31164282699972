import { FindParams } from './find-params';
import { NotificationTarget } from './notification-target';
import { NotificationTargetType } from './notification-target-type';
import { NotificationTrigger } from './notification-trigger';

export interface FindNotificationsParams extends FindParams {
  trigger?: NotificationTrigger;
  target?: NotificationTarget;
  target_type?: NotificationTargetType;
  inactive?: boolean;
  push_or_sms?: boolean;
}
