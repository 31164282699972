import { FindParamsDefault, Partner } from '@domains';
import { createAction, props } from '@ngrx/store';

export enum PartnerActionType {
  ClearPartnersFindParams = '[Captain] Clear Partners Find Params',
  GetPartners = '[Partner] Get Partners',
  GetPartnersSuccess = '[Partner] Get Partners Success',

  GetPartner = '[Partner] Get Partner',
  GetPartnerSuccess = '[Partner] Get Partner Success',

  CreatePartner = '[Partner] Create Partner',
  CreatePartnerSuccess = '[Partner] Create Partner Success',

  UpdatePartner = '[Partner] Update Partner',
  UpdatePartnerSuccess = '[Partner] Update Partner Success',
  UpdatePartnerFail = '[Partner] Update Partner Fail',

  DeletePartner = '[Partner] Delete Partner',
  DeletePartnerSuccess = '[Partner] Delete Partner Success',

  RestorePartner = '[Partner] Restore Partner',
  RestorePartnerSuccess = '[Partner] Restore Partner Success',

  ClearPartnersForFeatureFlagsFindParams = '[Captain] Clear Partners For Feature Flags Find Params',
  GetPartnersForFeatureFlags = '[Partner] Get Partners For Feature Flags',
  GetPartnersForFeatureFlagsSuccess = '[Partner] Get Partners For Feature Flags Success',

  UpdateForFeatureFlagsPartner = '[Partner] Update For Feature Flags Partner',
  UpdateForFeatureFlagsPartnerSuccess = '[Partner] Update For Feature Flags Partner Success',
  UpdateForFeatureFlagsPartnerFail = '[Partner] Update For Feature Flags Partner Fail',
}

export const clearPartnersFindParams = createAction(PartnerActionType.ClearPartnersFindParams);
export const getPartners = createAction(PartnerActionType.GetPartners, props<{ findParams: FindParamsDefault; keep?: boolean }>());
export const getPartnersSuccess = createAction(
  PartnerActionType.GetPartnersSuccess,
  props<{
    resultsCount: number;
    results: Array<Partner>;
  }>(),
);

export const getPartner = createAction(PartnerActionType.GetPartner, props<{ id: string; expand?: string[] }>());
export const getPartnerSuccess = createAction(PartnerActionType.GetPartnerSuccess, props<{ partner: Partner }>());

export const createPartner = createAction(PartnerActionType.CreatePartner, props<{ partner: Partner }>());
export const createPartnerSuccess = createAction(PartnerActionType.CreatePartnerSuccess, props<{ partner: Partner }>());

export const updatePartner = createAction(PartnerActionType.UpdatePartner, props<{ partner: Partner }>());
export const updatePartnerSuccess = createAction(PartnerActionType.UpdatePartnerSuccess, props<{ partner: Partner }>());
export const updatePartnerFail = createAction(PartnerActionType.UpdatePartnerFail);

export const deletePartner = createAction(PartnerActionType.DeletePartner, props<{ partnerId: string }>());
export const deletePartnerSuccess = createAction(PartnerActionType.DeletePartnerSuccess, props<{ partnerId: string }>());

export const restorePartner = createAction(PartnerActionType.RestorePartner, props<{ partnerId: string }>());
export const restorePartnerSuccess = createAction(PartnerActionType.RestorePartnerSuccess, props<{ partnerId: string }>());

export const clearPartnersForFeatureFlagsFindParams = createAction(PartnerActionType.ClearPartnersForFeatureFlagsFindParams);
export const getPartnersForFeatureFlags = createAction(
  PartnerActionType.GetPartnersForFeatureFlags,
  props<{ findParams: FindParamsDefault; keep?: boolean }>(),
);
export const getPartnersForFeatureFlagsSuccess = createAction(
  PartnerActionType.GetPartnersForFeatureFlagsSuccess,
  props<{
    resultsCount: number;
    results: Array<Partner>;
  }>(),
);

export const updateForFeatureFlagsPartner = createAction(PartnerActionType.UpdateForFeatureFlagsPartner, props<{ partner: Partner }>());
export const updateForFeatureFlagsPartnerSuccess = createAction(
  PartnerActionType.UpdateForFeatureFlagsPartnerSuccess,
  props<{ partner: Partner }>(),
);
export const updateForFeatureFlagsPartnerFail = createAction(
  PartnerActionType.UpdateForFeatureFlagsPartnerFail,
  props<{ partner: Partner }>(),
);
