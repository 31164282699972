import { autoserialize, autoserializeAs } from 'cerialize';

import { AppType } from './app-type';
import { BaseDTO, IBaseDTO } from './base-dto';
import { Charity, ICharity } from './charity';
import { IPartner, Partner } from './partner';
import { Role } from './role';
import { IUserMeta, UserMeta } from './user-meta';
import { IUserPermissions, UserPermissions } from './user-permissions';

export class User extends BaseDTO implements IUser {
  @autoserialize id?: string;
  @autoserializeAs('created_at') createdAt?: string;
  @autoserializeAs('updated_at') updatedAt?: string;
  @autoserializeAs('discarded_at') discardedAt?: string;
  @autoserializeAs('charity_id') charityId?: string;
  @autoserializeAs('partner_id') partnerId?: string;
  @autoserializeAs('store_id') storeId?: string;
  @autoserializeAs('vehicle_id') vehicleId?: string;
  @autoserialize email?: string;
  @autoserialize identity: string;
  @autoserialize password?: string;
  @autoserialize verified: boolean;
  @autoserialize override name: string;
  @autoserialize phone: string;
  @autoserialize roles: Role[];
  @autoserialize title?: string;
  @autoserialize meta?: UserMeta;
  @autoserialize permissions?: UserPermissions;
  @autoserializeAs(Partner, 'partner') partner?: Partner | null;
  @autoserializeAs(Charity, 'charity') charity?: Charity | null;

  constructor(userPayload?: IUser, appType?: AppType) {
    super(userPayload);

    this.id = userPayload?.id;
    this.storeId = userPayload?.storeId?.toString();
    this.vehicleId = userPayload?.vehicleId?.toString();
    this.createdAt = userPayload?.createdAt;
    this.updatedAt = userPayload?.updatedAt;
    this.discardedAt = userPayload?.discardedAt;
    this.name = userPayload?.name || '';
    this.email = userPayload?.email;
    this.identity = userPayload?.identity || '';
    this.password = userPayload?.password;
    this.verified = !!userPayload?.verified;
    this.phone = userPayload?.phone || '';
    this.roles = userPayload?.roles || [];
    this.title = userPayload?.title;
    this.meta = new UserMeta(userPayload?.meta, appType);
    this.permissions = new UserPermissions(userPayload?.permissions);
    this.partner = userPayload?.partner ? new Partner(userPayload?.partner) : undefined;
    this.charity = userPayload?.charity ? new Charity(userPayload?.charity) : undefined;
    this.charityId = userPayload?.charityId?.toString() || this.charity?.id;
    this.partnerId = userPayload?.partnerId?.toString() || this?.partner?.id;
  }
}

export interface IUser extends IBaseDTO {
  id?: string;
  charityId?: string;
  partnerId?: string;
  storeId?: string;
  vehicleId?: string;
  createdAt?: string;
  updatedAt?: string;
  discardedAt?: string;
  email?: string;
  identity?: string;
  password?: string;
  verified?: boolean;
  name: string;
  phone: string;
  roles: Role[];
  title?: string;
  meta?: IUserMeta;
  permissions?: IUserPermissions;
  partner?: IPartner | null;
  charity?: ICharity | null;
}
