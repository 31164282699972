import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, Environment, Xmile } from '@domains';

import { BaseApiService } from '../base-api.service';

@Injectable({
  providedIn: 'root',
})
export class XmilesService extends BaseApiService<Xmile> {
  constructor(
    @Inject(ENVIRONMENT) override config: Environment,
    override http: HttpClient,
  ) {
    super(config, http, 'extra_miles', Xmile, 'ExtraMile');
  }
}
