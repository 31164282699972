import { Injectable } from '@angular/core';
import { WindowSize } from '@domains';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  size: BehaviorSubject<any> = new BehaviorSubject(WindowSize.XL);

  constructor() {
    this.setWindowSize(window.innerWidth);
  }

  setWindowSize(size: number): void {
    let windowSize: WindowSize;
    if (size <= 599) {
      windowSize = WindowSize.XS;
    } else if (size <= 959) {
      windowSize = WindowSize.SM;
    } else if (size <= 1279) {
      windowSize = WindowSize.MD;
    } else if (size <= 1919) {
      windowSize = WindowSize.LG;
    } else {
      windowSize = WindowSize.XL;
    }
    this.size.next(windowSize);
  }
}
