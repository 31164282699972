import { CurrencyPipe, NgStyle } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { AppType, ENVIRONMENT, Environment, Quote, Specification } from '@domains';

import { Designable, DesignService } from '../designable';
import { ResponsiveService } from '../responsive';

@Component({
  selector: 'rspl-courier-quote',
  templateUrl: './courier-quote.component.html',
  styleUrls: ['./courier-quote.component.scss'],
  imports: [NgStyle, CurrencyPipe],
})
export class CourierQuoteComponent extends Designable {
  @Input() quote!: Quote;
  @Input() specification!: Specification;
  @Input() discountValid?: boolean;

  isCharityApp: boolean;
  isCaptainApp: boolean;
  isDonorApp: boolean;
  app: AppType;

  constructor(
    @Inject(ENVIRONMENT) private config: Environment,
    override designService: DesignService,
    override responsiveService: ResponsiveService,
  ) {
    super(designService, responsiveService);
    this.isCharityApp = this.config.app === AppType.CHARITY;
    this.isDonorApp = this.config.app === AppType.DONOR;
    this.isCaptainApp = this.config.app === AppType.CAPTAIN || this.config.app === AppType.ZENDESK;
    this.app = this.config.app;
  }
}
