import { autoserialize, autoserializeAs } from 'cerialize';

export class Pricing2 {
  @autoserialize name: string;
  @autoserialize small: number;
  @autoserialize medium: number;
  @autoserialize large: number;
  @autoserialize xlarge: number;
  @autoserialize staircases: number;
  @autoserialize elevator: number;
  @autoserialize disassembly: number;
  @autoserialize base: number;
  @autoserialize recycling: number;
  @autoserializeAs('fuel_fee') fuelFee: number | null;
  @autoserializeAs('cancellation_fee') cancellationFee: number | null;
  @autoserializeAs('booking_fee') bookingFee: number | null;
  @autoserialize courier_fee: number | null;

  constructor(payload?: IPricing2) {
    this.name = payload?.name || '';
    this.base = payload?.base || 0;
    this.small = payload?.small || 0;
    this.medium = payload?.medium || 0;
    this.large = payload?.large || 0;
    this.xlarge = payload?.xlarge || 0;
    this.staircases = payload?.staircases || 0;
    this.elevator = payload?.elevator || null;
    this.disassembly = payload?.disassembly || 0;
    this.recycling = payload?.recycling || 0;
    this.fuelFee = payload?.fuelFee || null;
    this.cancellationFee = payload?.cancellationFee || null;
    this.bookingFee = payload?.bookingFee || null;
    this.courier_fee = payload?.courier_fee || null;
  }

  public static OnSerialized(instance: Pricing2, json: any): void {
    Pricing2.pricingDolarsToCents(json);
  }

  public static OnDeserialized(instance: Pricing2): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    instance = Pricing2.pricingCentsToDolars(instance);
  }

  public static pricingDolarsToCents(pricing: any): any {
    Object.keys(pricing)
      .filter((x) => !['fuelFee', 'fuel_fee', 'name'].includes(x))
      .forEach((key) => {
        pricing[key] = pricing[key] !== null ? Math.round(pricing[key] * 100) : null;
      });
    if (pricing.fuelFee) pricing.fuelFee = pricing.fuelFee !== null ? pricing.fuelFee / 100 : null;
    if (pricing.fuel_fee) pricing.fuel_fee = pricing.fuel_fee !== null ? pricing.fuel_fee / 100 : null;
    return pricing;
  }

  public static pricingCentsToDolars(pricing: any): any {
    Object.keys(pricing)
      .filter((x) => !['fuelFee', 'fuel_fee', 'name'].includes(x))
      .forEach((key) => {
        pricing[key] = pricing[key] !== null ? pricing[key] / 100 : null;
      });
    pricing.fuelFee = pricing.fuelFee !== null ? this.formatPrice(pricing.fuelFee * 100) : null;
    return pricing;
  }

  public static formatPrice(price: number): number {
    return Math.round(price * 100) / 100;
  }
}

export interface IPricing2 {
  name: string;
  small: number;
  medium: number;
  large: number;
  xlarge: number;
  staircases: number;
  elevator: number;
  disassembly: number;
  base: number;
  recycling: number;
  fuelFee: number | null;
  cancellationFee: number | null;
  bookingFee: number | null;
  courier_fee: number | null;
}
