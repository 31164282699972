import { autoserialize, autoserializeAs } from 'cerialize';

import { BaseDTO, IBaseDTO } from './base-dto';
import { ScreeningMeta } from './screening-meta';

export enum ScreeningQuestion {
  ZIPS = 'zips',
  DISASSEMBLY = 'disassembly',
  LEAD_TIME = 'lead_time',
  FLOOR = 'floor',
  RESTRICTED_ITEMS = 'restricted_items',
  DAMAGED = 'damaged',
}

export class Screening extends BaseDTO implements IScreening {
  @autoserialize id?: string;
  @autoserialize zips: string[];
  @autoserialize disassembly: boolean | undefined;
  @autoserializeAs('lead_time') leadTime: string | undefined;
  @autoserializeAs('charity_id') charityId?: string;
  @autoserialize floor?: string;
  @autoserializeAs('restricted_items') restrictedItems: string[];
  @autoserializeAs('restricted_item_urls') restrictedItemUrls: {
    url: string;
    name: string;
  }[];
  @autoserializeAs(ScreeningMeta, 'meta') meta?: ScreeningMeta;
  @autoserializeAs('hidden_fields') hiddenFields: ScreeningQuestion[];

  constructor(payload?: IScreening) {
    super(payload);
    this.id = payload?.id?.toString();
    this.zips = payload?.zips ? [...(payload?.zips || [])] : [];
    this.disassembly = payload?.disassembly;
    this.leadTime = payload?.leadTime;
    this.charityId = payload?.charityId?.toString();
    this.floor = payload?.floor;
    this.restrictedItems = payload?.restrictedItems ? [...(payload?.restrictedItems || [])] : [];
    this.restrictedItemUrls = payload?.restrictedItemUrls ? [...(payload?.restrictedItemUrls || [])] : [];
    this.meta = new ScreeningMeta(payload?.meta);
    this.hiddenFields = payload?.hiddenFields ? [...(payload?.hiddenFields || [])] : [];
  }
}

export interface IScreening extends IBaseDTO {
  id?: string;
  zips?: string[];
  disassembly?: boolean;
  leadTime?: string;
  floor?: string;
  restrictedItems?: string[];
  restrictedItemUrls?: { url: string; name: string }[];
  charityId?: string;
  meta?: ScreeningMeta;
  hiddenFields: ScreeningQuestion[];
}
