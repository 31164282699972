import { createFeatureSelector, createSelector } from '@ngrx/store';

import { OrganizationState } from './organization.reducers';

// tslint:disable: max-line-length
export const organizationsFeatureStateSelector = createFeatureSelector<OrganizationState>('organization');

export const selectOrganizations = createSelector(organizationsFeatureStateSelector, (state: OrganizationState) => state.organizations);
export const selectOrganizationsTotalCount = createSelector(
  organizationsFeatureStateSelector,
  (state: OrganizationState) => state.organizationsTotalCount,
);

export const selectOrganization = createSelector(organizationsFeatureStateSelector, (state: OrganizationState) => state.organization);
