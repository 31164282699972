<table class="static-table full-width {{ app }}" cellspacing="0">
  <thead>
    <tr>
      <th>Items</th>
      <th [ngStyle]="{ 'text-align': design2 ? 'center' : 'right' }">Quantity</th>
      @if (!isCharityApp) {
        <th style="text-align: right">Price</th>
      }
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Bags/boxes of clothes/housewares</td>
      <td [ngStyle]="{ 'text-align': design2 ? 'center' : 'right' }">
        <span>
          {{ specification.small }}
        </span>
      </td>
      @if (!isCharityApp) {
        <td></td>
      }
    </tr>
    @if (!isCharityApp) {
      <tr class="total" class="primary-row top-border-row">
        <td colspan="2">Total</td>
        <td style="text-align: right">
          @if (isDonorApp) {
            <span>{{ quote.total_amount - quote.discount_amount | currency }}</span>
            @if (quote.discount_amount > 0) {
              <span class="discounted">{{ quote.total_amount | currency }}</span>
            }
          } @else {
            <span>{{ quote.total_amount | currency }}</span>
          }
        </td>
      </tr>
      @if (discountValid && quote.discount_amount > 0 && isCaptainApp) {
        <tr class="total" class="primary-row">
          <td colspan="2">Charity Pays</td>
          <td style="text-align: right">
            {{ quote.discount_amount | currency }}
          </td>
        </tr>
        <tr class="total" class="primary-row">
          <td colspan="2">Donor Pays</td>
          <td style="text-align: right">
            {{ quote.total_amount - (quote.discount_amount || 0) | currency }}
          </td>
        </tr>
      }
    }
  </tbody>
</table>
