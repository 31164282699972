import { Donation, Lead } from '@domains';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DonationState } from './donation.reducers';

// tslint:disable: max-line-length
export const donationFeatureStateSelector = createFeatureSelector<DonationState>('donation');

export const selectDonations = createSelector(donationFeatureStateSelector, (state: DonationState) => state.donations as Donation[]);
export const selectLeads = createSelector(donationFeatureStateSelector, (state: DonationState) => state.donations as Lead[]);
export const selectDonationsTotalCount = createSelector(donationFeatureStateSelector, (state: DonationState) => state.donationsTotalCount);
export const selectPendingDonationsCount = createSelector(
  donationFeatureStateSelector,
  (state: DonationState) => state.pendingDonationsCount,
);

export const selectDonation = createSelector(donationFeatureStateSelector, (state: DonationState) =>
  state.donation ? (state.donation as Donation) : undefined,
);
export const selectLead = createSelector(donationFeatureStateSelector, (state: DonationState) =>
  state.donation ? (state.donation as Lead) : undefined,
);
export const selectPricing = createSelector(donationFeatureStateSelector, (state: DonationState) => state.pricing);
export const selectHistory = createSelector(donationFeatureStateSelector, (state: DonationState) => state.history);
export const selectDeclinedHistory = createSelector(donationFeatureStateSelector, (state: DonationState) => state.declinedHistory);
