<mat-form-field appearance="outline" fxFlex="100">
  <mat-label>{{ title }}</mat-label>
  <input
    matInput
    [matDatepicker]="picker"
    (click)="picker.open()"
    [value]="dateToEdit ? dateToEdit : null"
    (dateChange)="onDateSelected($event)"
    readonly
    [disabled]="disabled"
  />
  <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="disabled" />
  <mat-datepicker #picker [disabled]="disabled" />
</mat-form-field>
