import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, Environment } from '@domains';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HealthCheckService {
  public constructor(
    @Inject(ENVIRONMENT) private config: Environment,
    private http: HttpClient,
  ) {}

  healthCheck(): Observable<any> {
    return this.http.get(this.config.urls.baseUrl + `/health_check`).pipe(
      map((response: any) => {
        return response;
      }),
    );
  }
}
