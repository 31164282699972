<ng-template #Button let-action="action">
  <button
    mat-raised-button
    [ngClass]="{ hidden: !!action.hidden && !!action.hidden() }"
    class="{{ action.cssClass ? action.cssClass() : '' }}"
    (click)="action.actionMethod()"
    [disabled]="!!action.disabled && !!action.disabled()"
    type="button"
  >
    {{ action.actionTitle }}
  </button>
</ng-template>
<ng-template #Link let-action="action">
  <a
    mat-raised-button
    [ngClass]="{ hidden: !!action.hidden && !!action.hidden() }"
    class="{{ action.cssClass ? action.cssClass() : '' }}"
    [routerLink]="action.link"
    [disabled]="!!action.disabled && !!action.disabled()"
  >
    {{ action.actionTitle }}
  </a>
</ng-template>
<ng-template #DropdownButton let-action="action">
  <button
    mat-menu-item
    [ngClass]="{ hidden: !!action.hidden && !!action.hidden() }"
    (click)="action.actionMethod()"
    [disabled]="!!action.disabled && !!action.disabled()"
    class="{{ action.cssClass ? action.cssClass() : '' }}"
    type="button"
  >
    {{ action.actionTitle }}
  </button>
</ng-template>
<ng-template #DropdownLink let-action="action">
  <a
    mat-menu-item
    [ngClass]="{
      hidden: !!action.hidden && !!action.hidden(),
      disabled: !!action.disabled && !!action.disabled(),
    }"
    [routerLink]="action.link"
    class="{{ action.cssClass ? action.cssClass() : '' }}"
  >
    {{ action.actionTitle }}
  </a>
</ng-template>
<div class="card-wrapper">
  <div class="card-header" fxLayout="row" fxLayoutAlign="space-between">
    <div>
      @if (cardTitle) {
        <h2 class="card-title" (click)="onTitleClick.emit()">
          @if (!cardTitleLink) {
            <span fxLayout="row" fxLayoutGap="5px">
              @if (cardTitleDescription) {
                <mat-icon [matTooltip]="cardTitleDescription" matTooltipShowDelay="500" style="margin-top: 3px; min-width: 25px">
                  info
                </mat-icon>
              }
              <span>{{ cardTitle }}</span>
            </span>
          }
          @if (cardTitleLink) {
            <a [routerLink]="cardTitleLink" fxLayout="row" fxLayoutGap="5px">
              @if (cardTitleDescription) {
                <mat-icon [matTooltip]="cardTitleDescription" matTooltipShowDelay="500" style="margin-top: 3px; min-width: 25px">
                  info
                </mat-icon>
              }
              <span>{{ cardTitle }}</span>
            </a>
          }
        </h2>
      }
      @if (cardSubTitle) {
        <span class="subtitle">{{ cardSubTitle }}</span>
      }
    </div>
    @if (!smallWindowSizes.includes(windowSize)) {
      <div class="actions" fxLayout="row wrap" fxLayoutAlign="end">
        @for (action of actions; track action) {
          <ng-container [ngTemplateOutlet]="!!action.link ? Link : Button" [ngTemplateOutletContext]="{ action: action }"></ng-container>
        }
      </div>
    }
    @if (smallWindowSizes.includes(windowSize) && visibleActionsCnt > 0) {
      <div class="actions" fxLayoutGap="10px">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          @for (action of actions; track action) {
            <ng-container
              [ngTemplateOutlet]="!!action.link ? DropdownLink : DropdownButton"
              [ngTemplateOutletContext]="{ action: action }"
            ></ng-container>
          }
        </mat-menu>
      </div>
    }
  </div>
  <ng-content></ng-content>
</div>
