import { autoserialize, autoserializeAs } from 'cerialize';

export class AvailabilityStats {
  @autoserialize id: string;
  @autoserialize capacity: number;
  @autoserializeAs('donations_count') donationsCount: number;
  @autoserialize closed: boolean;
  @autoserializeAs('in_zone') inZone: boolean;

  constructor(payload: IAvailabilityStats) {
    this.id = payload?.id?.toString();
    this.capacity = payload?.capacity;
    this.donationsCount = payload?.donationsCount;
    this.closed = payload?.closed;
    this.inZone = payload?.inZone;
  }
}

export interface IAvailabilityStats {
  id: string;
  capacity: number;
  donationsCount: number;
  closed: boolean;
  inZone: boolean;
}
