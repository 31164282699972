import { animate, state, style, transition, trigger } from '@angular/animations';
import { CdkDrag, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { CurrencyPipe, DatePipe, DecimalPipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, Input, OnInit, output, TemplateRef, ViewChild, viewChild } from '@angular/core';
import { MatAnchor, MatButton, MatIconAnchor, MatIconButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
} from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { AppType, ENVIRONMENT, Environment, FindParams, PageAction } from '@domains';
import { LocalStorageService } from '@rspl-api';
import {
  DoubleClickDirective,
  FxFlexDirective,
  FxLayoutAlignDirective,
  FxLayoutDirective,
  Responsive,
  ResponsiveService,
  UtilityService,
} from '@rspl-ui';
import { MatTableExporterModule } from 'mat-table-exporter';
import * as moment from 'moment';
import { take, takeUntil, tap } from 'rxjs/operators';

import { ColumnConfigComponent } from '../config/column-config.component';
import { BaseFilterComponent } from '../filter/base-filter.component';
import { PagedDataSource } from './paged.datasource';
import { TableService } from './table.service';
import { ColumnAction, ColumnConfig, ColumnType, TableConfig } from './table-config';

@Component({
  selector: 'rspl-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms ease-in')),
      transition('collapsed <=> expanded', animate('225ms ease-out')),
    ]),
    trigger('rowExpand', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(90deg)' })),
      transition('rotated => default', animate('100ms ease-in-out')),
      transition('default => rotated', animate('100ms ease-in-out')),
    ]),
  ],
  imports: [
    MatIconButton,
    MatTooltip,
    MatIcon,
    MatIconAnchor,
    RouterLink,
    MatButton,
    MatAnchor,
    MatMenuItem,
    NgClass,
    NgStyle,
    FxLayoutAlignDirective,
    NgTemplateOutlet,
    MatTable,
    MatSort,
    MatTableExporterModule,
    CdkDropList,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    CdkDrag,
    MatCellDef,
    MatCell,
    MatSlideToggle,
    MatSelect,
    MatOption,
    FxLayoutDirective,
    FxFlexDirective,
    MatMenuTrigger,
    MatMenu,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    DoubleClickDirective,
    MatPaginator,
    DecimalPipe,
    CurrencyPipe,
    DatePipe,
  ],
})
export class TableComponent extends Responsive implements OnInit {
  protected isCaptain;
  #config!: TableConfig;
  #filters?: BaseFilterComponent<any>;
  private reloadDataTimeout: any;
  editMode = false;
  protected globalEditMode = false;
  private tmpColumns: {
    [key: string]: ColumnConfig;
  } = {};
  protected defaultColumns?: {
    [key: string]: ColumnConfig;
  };
  private afterEdit?: boolean;
  protected minHeight?: number;
  protected columnTypes = ColumnType;

  #loadingIndicator?: ElementRef;
  hasData = false;
  protected pageSize = 100;
  protected expandedElement: any;
  readonly onSetTableActions = output<PageAction[]>();

  @ViewChild('loadingIndicator') set loadingIndicator(loadingIndicator: ElementRef) {
    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = this.table?.nativeElement.getBoundingClientRect();
    if (!loadingIndicator && !!this.#loadingIndicator) {
      const newPosition = (elemRect?.top ?? 60) - bodyRect.top - 60;
      if (document.documentElement.scrollTop > newPosition) {
        document.documentElement.scrollTop = newPosition;
      }
    } else if (!!loadingIndicator && !this.#loadingIndicator) {
      this.calculateMinHeight();
    }
    this.#loadingIndicator = loadingIndicator;
  }

  @Input() expandTemplate?: TemplateRef<any>;
  @Input() columnTemplates?: { [key: string]: TemplateRef<any> } = {};
  @Input() noRecordsTemplate: TemplateRef<any> | null = null;

  @Input() set filters(filters: BaseFilterComponent<any> | undefined) {
    this.#filters = filters;
    this.filters?.filtersChangedEvent.subscribe(() => {
      if (this.paginator) {
        this.paginator.pageIndex = 0;
      }
      this.requestDataLoad();
    });
  }

  get filters(): BaseFilterComponent<any> | undefined {
    return this.#filters;
  }

  get tableConfigColumns(): PageAction[] {
    return [
      new PageAction({
        actionTitle: 'Configure',
        icon: 'settings',
        disabled: () => this.globalEditMode,
        hidden: () => !this.config.configure || this.editMode,
        actionMethod: () => this.editColumns(),
      }),
      new PageAction({
        actionTitle: 'Save',
        icon: 'save',
        hidden: () => !this.config.configure || !this.editMode,
        actionMethod: () => this.saveEditColumns(),
      }),
      new PageAction({
        actionTitle: 'Cancel',
        icon: 'cancel',
        hidden: () => !this.config.configure || !this.editMode,
        actionMethod: () => this.cancelEditColumns(),
      }),
      new PageAction({
        actionTitle: 'Columns',
        icon: 'view_column',
        hidden: () => !this.config.configure || !this.editMode,
        actionMethod: () => this.openColumnConfigDialog(),
      }),
      new PageAction({
        actionTitle: 'Reset',
        icon: 'restart_alt',
        hidden: () => !this.config.configure || !this.editMode,
        actionMethod: () => this.resetColumns(),
      }),
    ];
  }

  @Input() set config(config: TableConfig) {
    if (!config) {
      return;
    }
    if (!this.defaultColumns && config?.columns) {
      const defaultColumns: { [key: string]: ColumnConfig } = {};
      Object.keys(config.columns).forEach((x: string) => (defaultColumns[x] = new ColumnConfig(config.columns[x])));
      this.defaultColumns = defaultColumns;
    }
    this.tableService.configurations$.pipe(take(1)).subscribe((tableConfigs) => {
      const configId = TableConfig.getConfigId(config.id);
      let savedColumns = tableConfigs[configId + this.getSize()];
      const savedColumnsReverse = tableConfigs[configId + this.getSize(true)];
      if (!savedColumns) {
        if (savedColumnsReverse && this.areSameConfigs(savedColumnsReverse, {})) {
          this.tableService.saveConfiguration(configId + this.getSize(true), null);
          savedColumns = {};
        } else {
          savedColumns = { ...(savedColumnsReverse || {}) };
        }
      } else {
        savedColumns = { ...savedColumns };
      }
      this.#config = {
        ...new TableConfig(config, savedColumns, this.localStorage),
        tableActions: [
          ...(config?.tableActions?.map((x) => new PageAction(x)) || []),
          new PageAction({
            actionTitle: 'Export',
            icon: 'download',
            hidden: () => !this.config.export || this.editMode,
            actionMethod: () => {
              this.export();
            },
          }),
          new PageAction({
            actionTitle: 'Refresh',
            icon: 'refresh',
            hidden: () => !this.config.refresh || this.editMode,
            actionMethod: () => {
              this.requestDataLoad();
            },
          }),
          ...this.tableConfigColumns,
        ],
      };
      setTimeout(() => {
        if (this.config.tableActions) this.onSetTableActions.emit(this.config.tableActions);
      });
      this.sortColumns();
      this.setDisplayedColumns();
      this.paginator.pageSize = this.config.pagination?.pageSize || this.paginator.pageSize;
      this.paginator.pageIndex = this.config.pagination?.pageIndex || this.paginator.pageIndex;
      if (this.sort) {
        this.sort.active = this.config.sort?.field || 'created_at';
        this.sort.direction = this.config.sort?.direction || 'desc';
      }
      if (!this.config.hasFilters && !this.filters) {
        this.requestDataLoad();
      }
      if (this.config.localFilter) {
        this.createPagedDataSource();
      }
    });
  }

  get config(): TableConfig {
    return this.#config;
  }

  protected resultsCount = 0;
  protected selectedEntity: any;

  #table!: ElementRef;
  @ViewChild(MatTable, { read: ElementRef }) set table(table: ElementRef) {
    this.#table = table;
    if (this.table) {
      this.calculateMinHeight();
    }
  }

  get table(): ElementRef {
    return this.#table;
  }

  readonly exporter = viewChild<any>('exporter');
  readonly tableContainer = viewChild<ElementRef>('tableContainer');

  pagedDataSource?: PagedDataSource<any>;

  #paginator!: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) set paginator(paginator: MatPaginator) {
    this.#paginator = paginator;
    if (this.paginator?.page) {
      this.paginator.page
        .pipe(
          tap(() => this.requestDataLoad()),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  get paginator(): MatPaginator {
    return this.#paginator;
  }

  #sort?: MatSort;
  @ViewChild('MatSort') set sort(sort: MatSort | undefined) {
    this.#sort = sort;
    if (this.sort?.sortChange) {
      this.sort.sortChange
        .pipe(
          tap(() => {
            if (this.paginator) {
              this.paginator.pageIndex = 0;
            }
            this.requestDataLoad();
          }),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  get sort(): MatSort | undefined {
    return this.#sort;
  }

  protected columns?: { field: string; index: number }[];
  protected displayedColumns: string[] = [];
  #displayedColumnFilters: string[] = [];
  get displayedColumnFilters(): string[] {
    return this.#displayedColumnFilters;
  }

  protected previousIndex?: number;
  protected fixedLayout = window.innerWidth > 1000;
  isLoading = false;

  constructor(
    public router: Router,
    protected util: UtilityService,
    public override responsiveService: ResponsiveService,
    public tableService: TableService,
    @Inject(ENVIRONMENT) protected appConfig: Environment,
    private localStorage: LocalStorageService,
    public dialog?: MatDialog,
  ) {
    super(responsiveService);
    this.isCaptain = this.appConfig.app === AppType.CAPTAIN;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.tableService.editMode$.pipe(takeUntil(this.destroy$)).subscribe((editMode) => (this.globalEditMode = editMode));
  }

  @HostListener('window:resize', ['$event'])
  protected onResize(): void {
    this.fixedLayout = window.innerWidth - 40 > this.displayedColumns.length * 160;
  }

  requestDataLoad(): void {
    if (this.editMode) {
      return;
    }
    if (this.afterEdit) {
      this.afterEdit = false;
      return;
    }
    if (this.reloadDataTimeout) {
      clearTimeout(this.reloadDataTimeout);
    }
    this.reloadDataTimeout = setTimeout(() => this.reloadData(), 100);
  }

  private reloadData(): void {
    const searchProps: { findParams: FindParams } = this.getSearchProps();
    searchProps.findParams = this.util.cleanObjectFromNull(searchProps.findParams);
    if (this.config?.findAction) {
      this.pagedDataSource?.startLoading();
      if (this.config.configId && this.config.updateFilters) {
        this.tableService.saveFindParams(this.config.configId, searchProps.findParams);
      }
      this.config.findAction(searchProps.findParams);
      if (!this.pagedDataSource) {
        this.createPagedDataSource();
      }
      this.pagedDataSource?.startLoading();
    }
  }

  protected getSearchProps(): { findParams: FindParams } {
    return {
      findParams: {
        page: this.paginator?.pageIndex + 1,
        per_page: this.paginator?.pageSize,
        order: this.util.camelCaseToSnakeCase(this.sort?.active || this.config.sort?.field || 'created_at'),
        order_direction: this.sort?.direction || this.config.sort?.direction,
        ...(this.filters?.getFiltersValue?.() || {}),
        ...this.config?.fixedFilters,
      },
    };
  }

  editColumns(): void {
    this.editMode = true;
    this.sortColumns();
    this.setDisplayedColumns();
    this.tmpColumns = {};
    Object.keys(this.config.columns).forEach((k) => (this.tmpColumns[k] = { ...this.config.columns[k] }));
    this.tableService.setEditMode(this.editMode);
  }

  saveEditColumns(): void {
    this.editMode = false;
    this.afterEdit = true;
    const c: { [key: string]: ColumnConfig } = {};
    this.columns?.forEach((x: { field: string; index: number }) => (c[x.field] = { ...this.config.columns[x.field], index: x.index }));
    this.tmpColumns = {};
    Object.keys(this.config.columns).forEach((k) => (this.tmpColumns[k] = { ...this.config.columns[k] }));
    this.tableService.configurations$.pipe(take(1)).subscribe((tableConfigs) => {
      const savedConfig = tableConfigs[this.config.configId + this.getSize()];
      const savedReverseConfig = tableConfigs[this.config.configId + this.getSize(true)];
      const shouldSave =
        !savedConfig && savedReverseConfig && this.areSameConfigs(c, savedReverseConfig)
          ? null // SAME AS OTHER CONFIG
          : this.areSameConfigs(c, this.defaultColumns || {})
            ? {} // DEFAULT CONFIG
            : c; // CUSTOM CONFIG
      if (!savedConfig || !this.areSameConfigs(shouldSave || {}, savedConfig)) {
        this.tableService.saveConfiguration(
          this.config.configId + this.getSize(),
          savedReverseConfig && this.areSameConfigs(shouldSave || {}, savedReverseConfig) ? null : shouldSave,
        );
      }
      this.tableService.setEditMode(this.editMode);
    });
  }

  cancelEditColumns(): void {
    this.editMode = false;
    this.afterEdit = true;
    Object.keys(this.config.columns).forEach((x) => {
      this.config.columns[x].index = this.tmpColumns[x].index;
      this.config.columns[x].hidden = this.tmpColumns[x].hidden;
    });
    this.sortColumns();
    this.setDisplayedColumns();
    this.tableService.setEditMode(this.editMode);
  }

  resetColumns(): void {
    Object.keys(this.config.columns).forEach((x) => {
      if (this.defaultColumns) {
        this.config.columns[x].index = this.defaultColumns[x].index;
        this.config.columns[x].hidden = this.defaultColumns[x].hidden;
      }
    });
    this.sortColumns();
    this.setDisplayedColumns();
  }

  private getSize(reverse = false): 'LG' | 'SM' {
    if (this.smallWindowSizes.includes(this.windowSize)) {
      return reverse ? 'LG' : 'SM';
    }
    return reverse ? 'SM' : 'LG';
  }

  private areSameConfigs(c1: { [key: string]: ColumnConfig }, c2: { [key: string]: ColumnConfig }): boolean {
    const keys = [...Object.keys(c1), ...Object.keys(c2)];
    for (const key of keys) {
      if (!(c1[key] && c2[key]) || c1[key].hidden !== c2[key].hidden || c1[key].index !== c2[key].index) {
        return false;
      }
    }
    return true;
  }

  private sortColumns(): void {
    this.columns = Object.keys(this.config.columns)
      .sort((a, b) => {
        return this.config.columns[a].index - this.config.columns[b].index;
      })
      .map((field, index) => {
        return { field, index };
      });
  }

  protected highlight(row: any): void {
    this.selectedEntity = row;
  }

  export(): void {
    const exporter = this.exporter();
    exporter.hiddenColumns = (this.config.columnActions?.length || 0) > 0 ? [this.displayedColumns.length - 1] : [];
    exporter.exportTable('csv', {
      fileName: this.config.id + ' ' + moment(new Date()).format('YYYY-MM-DD'),
    });
  }

  protected getValue(element: any, column: string): any {
    return this.config.columns[column].value?.(element) || this.deepValue(element, column);
  }

  protected deepValue(obj: any, path: string): any {
    const parts = path.split('.');
    for (let i = 0, len = parts.length; i < len; i++) {
      if (!obj) {
        return null;
      }
      obj = obj[parts[i]];
    }
    return obj;
  }

  protected setDisplayedColumns(): void {
    this.displayedColumns = [];
    if (this.config.expandable) {
      this.displayedColumns.push('expand');
    }
    this.columns
      ?.filter((x) => !this.config.columns[x.field].hidden)
      .forEach((column, index) => {
        this.displayedColumns[index + (this.config.expandable ? 1 : 0)] = column.field;
      });
    if ((this.config.columnActions?.length || 0) > 0 && !this.displayedColumns.includes('actions')) {
      this.displayedColumns.push('actions');
    }
    this.onResize();
  }

  protected dropListDropped(event: any): void {
    if (
      event &&
      this.columns &&
      this.config.columns[this.columns[event.previousIndex].field].editable &&
      this.config.columns[this.columns[event.currentIndex].field].editable
    ) {
      this.columns = this.columns.sort((a, b) =>
        this.displayedColumns.includes(a.field) && this.displayedColumns.includes(b.field)
          ? 0
          : this.displayedColumns.includes(a.field)
            ? -1
            : 1,
      );
      moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
      this.columns.forEach((x, i) => {
        x.index = i;
        this.config.columns[x.field].index = i;
      });
      this.setDisplayedColumns();
    }
  }

  openColumnConfigDialog(): void {
    const configDialog = this.dialog?.open(ColumnConfigComponent, {
      width: '500px',
      panelClass: 'table-columns-dialog',
      data: {
        config: this.config.columns,
        columns: this.columns,
      },
    });
    configDialog
      ?.afterClosed()
      .pipe(take(1))
      .subscribe((data) => {
        if (data && this.columns) {
          this.config.columns = { ...data };
          [...this.columns].forEach((x) => {
            if (this.config.columns[x.field].hidden && this.columns) {
              moveItemInArray(
                this.columns,
                this.columns.findIndex((y) => x.field === y.field),
                this.columns.length - 1,
              );
            }
            this.config.columns[x.field].index = this.columns?.findIndex((y) => x.field === y.field) || -1;
          });
          this.setDisplayedColumns();
        }
      });
  }

  protected getVisibleColumnIconActions(x: any): ColumnAction[] {
    return this.config?.columnActions?.filter((y) => (!y.hidden || !y.hidden(x)) && !!y.icon) || [];
  }

  protected getVisibleColumnTextActions(x: any): ColumnAction[] {
    return this.config?.columnActions?.filter((y) => (!y.hidden || !y.hidden(x)) && !y.icon) || [];
  }

  protected getVisibleColumnActions(x: any): ColumnAction[] {
    return this.config?.columnActions?.filter((y) => !y.hidden || !y.hidden(x)) || [];
  }

  protected getPhoneValue(element: any, column: string): string {
    let val: string = this.getValue(element, column);
    if (val?.length > 0) {
      val = `(${val.slice(0, 3)}) ${val.slice(3, 6)} ${val.slice(6, 10)}`;
    }
    return val;
  }

  private createPagedDataSource(): void {
    this.pagedDataSource = new PagedDataSource(this.config.listSelector, this.config.localFilter);
    this.pagedDataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe((loading) => (this.isLoading = loading));
    this.pagedDataSource.hasData$.pipe(takeUntil(this.destroy$)).subscribe((hasData) => (this.hasData = hasData));
    this.config.countSelector.pipe(takeUntil(this.destroy$)).subscribe((x) => (this.resultsCount = x));
  }

  private calculateMinHeight() {
    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = this.table?.nativeElement.getBoundingClientRect();
    const minHeight = window.innerHeight - ((elemRect?.top ?? 0) - bodyRect.top + (this.isCaptain ? 120 : 190));
    setTimeout(() => {
      this.minHeight = minHeight > 300 ? minHeight : 300;
    });
  }

  scrollHorizontal(direction: 'left' | 'right') {
    const tableContainer = this.tableContainer();
    if (!tableContainer) return;
    const ths = [...tableContainer.nativeElement.querySelectorAll('th')];
    const firstVisibleTh = Math.min(
      Math.max(
        0,
        ths.findIndex((th) => this.isScrolledIntoView(th)),
      ),
      ths.length + 1,
    );
    if ((direction === 'left' && firstVisibleTh === 0) || (direction === 'right' && firstVisibleTh === ths.length + 1)) return;

    tableContainer.nativeElement.scrollBy({
      left:
        this.calculatePosition(ths[firstVisibleTh + (direction === 'left' ? -1 : 1)]).elemLeft -
        ((direction === 'left' && firstVisibleTh === 1) || (direction === 'right' && firstVisibleTh === 0) ? 0 : 30),
      top: 0,
      behavior: 'smooth',
    });
    // this.tableContainer.nativeElement.scrollLeft += this.calculatePosition(
    //   ths[firstVisibleTh + (direction === 'left' ? -1 : 1)]
    // ).elemLeft;
  }

  isScrolledIntoView(el: any) {
    const { elemRight, elemLeft } = this.calculatePosition(el);
    // Only completely visible elements return true:
    const isVisible = elemLeft >= 0 && elemRight <= this.tableContainer()?.nativeElement.clientWidth;
    // Partially visible elements return true:
    //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  }

  calculatePosition(el: any) {
    const parentPos = this.tableContainer()?.nativeElement.getBoundingClientRect();
    const childPos = el.getBoundingClientRect();

    const elemRight = childPos.right - parentPos.right;
    const elemLeft = childPos.left - parentPos.left;
    return { elemRight, elemLeft };
  }

  onToggleChange(conf: ColumnConfig, $event: any, element: any) {
    if (conf.onChange) conf.onChange($event, element);
  }

  onSelectChange(conf: ColumnConfig, $event: any, element: any) {
    if (conf.onChange) conf.onChange($event, element);
  }

  toggleValue(conf: ColumnConfig, element: any): boolean {
    if (conf.value) return conf.value(element);
    return false;
  }

  selectValue(conf: ColumnConfig, element: any): any {
    if (conf.value) return conf.value(element);
    return null;
  }

  disabled(conf: ColumnConfig, element: any): boolean {
    if (conf.disabled) return conf.disabled(element);
    return false;
  }

  templateContext(conf: ColumnConfig, element: any): any {
    if (conf.templateContext) return conf.templateContext(element);
    return {};
  }
}
