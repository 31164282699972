import { autoserialize, autoserializeAs } from 'cerialize';

import { Role } from './role';

export class AuthorizationModel {
  @autoserialize roles: Role[];
  @autoserializeAs('auth') auth?: string;
  @autoserialize refresh?: string;

  constructor(payload: IAuthorizationModel) {
    this.roles = payload?.roles || [];
    this.auth = payload?.auth;
    this.refresh = payload?.refresh;
  }
}

export interface IAuthorizationModel {
  roles?: Role[];
  auth?: string;
  refresh?: string;
}
