@if (design) {
  <ng-container [ngTemplateOutlet]="DESIGN_2"></ng-container>
}
<ng-template #DESIGN_2>
  <div fxLayout="column" fxFlex="100" fxLayoutAlign="start center">
    <div fxLayout="row" fxLayoutAlign="space-between" style="margin-top: 20px" class="buttons" fxLayoutGap="10px">
      <button
        class="btn-week"
        (click)="previousWeek()"
        [ngClass]="{
          disabled: disabled || !week || !year || (minWeek >= week && minYear === year) || minYear > year || !isLoaded || !zip,
        }"
      >
        Previous Week
      </button>
      <button class="btn-week" [ngClass]="{ disabled: disabled || !isLoaded || !zip }" (click)="nextWeek()">Next Week</button>
    </div>
    <div class="days-wrapper {{ app }} {{ showError ? 'error' : '' }}" fxLayout="row" fxLayoutAlign="space-between center">
      @if ((dates?.length ?? 0) > 0) {
        <div fxLayout="row" fxLayout.lt-md="row wrap" fxLayoutAlign="center" fxLayoutGap="10px" fxLayoutGap.lt-md="5px">
          @for (day of dates; track day.date; let i = $index) {
            <div
              class="day"
              [ngClass]="{
                disabled:
                  disabled ||
                  disableDates.includes(day.date) ||
                  !isLoaded ||
                  !zip ||
                  beforeMin(day.date) ||
                  (isDonor && !day.pm && !day.am),
                'hidden-xs hidden-sm': i === 6 && (!zip || beforeMin(day.date) || (isDonor && !day.pm && !day.am)),
                'no-zip': !zip,
                loaded: isLoaded,
              }"
              fxFlex.lt-md="calc(25% - 5px)"
            >
              <span class="day-name">{{ day.date | date: 'EEE' }}</span>
              <span class="date">{{ day.date | date: 'MMM d' }}</span>
              <div class="part-of-day" fxLayoutAlign="center center" fxLayout="column">
                <button
                  [ngClass]="{
                    'filled-btn': day.date === selectedDate.date && selectedDate.partOfDay === partsOfDay.am,
                    available: isLoaded && zip && day.am && !beforeMin(day.date),
                    unavailable: isLoaded && zip && !day.am,
                    disabled: beforeMin(day.date) || disabled || disableDates.includes(day.date),
                  }"
                  [disabled]="disabled || !zip || beforeMin(day.date) || (isDonor && !day.am) || disableDates.includes(day.date)"
                  [title]="!zip ? 'Enter Address' : day.am && !beforeMin(day.date) ? 'Available' : 'Unavailable'"
                  type="button"
                  (click)="selectDate(day.date, partsOfDay.am)"
                >
                  AM: 08-12
                </button>
                <button
                  [ngClass]="{
                    'filled-btn': day.date === selectedDate.date && selectedDate.partOfDay === partsOfDay.pm,
                    available: isLoaded && zip && day.pm && !beforeMin(day.date),
                    unavailable: isLoaded && zip && !day.pm,
                    disabled: beforeMin(day.date) || disabled || disableDates.includes(day.date),
                  }"
                  [disabled]="disabled || !zip || beforeMin(day.date) || (isDonor && !day.pm) || disableDates.includes(day.date)"
                  [title]="day.pm && !beforeMin(day.date) ? 'Available' : 'Unavailable'"
                  type="button"
                  (click)="selectDate(day.date, partsOfDay.pm)"
                >
                  PM: 12-04
                </button>
              </div>
            </div>
          }
        </div>
      }
    </div>
    @if ((isSmallWindowSize && isDonor) || showSelectedDate) {
      <ng-container [ngTemplateOutlet]="SelectedDate"></ng-container>
    }
    @if (!isDonor) {
      <ng-container [ngTemplateOutlet]="SelectedDate"></ng-container>
    }

    @if (!isDonor && !selectedAvailable && !pastDate) {
      <p>
        <rspl-info-box
          type="warning"
          message="This date is not available according to the current availability setup! It can be used regardlessly, but needs to be approved by your supervisor"
        ></rspl-info-box>
      </p>
    }
    @if (isCaptain && outOfZone && zip) {
      <p>
        <rspl-info-box
          type="important"
          header="Donor Out of Zone"
          message="This donor is outside our standard service area. While you can submit the pickup request, please note that
        completion is not guaranteed. We’ll let the donor know that we will check for any available pickup dates and
        update them accordingly."
        ></rspl-info-box>
      </p>
    }
  </div>
  <ng-template #SelectedDate>
    <p class="selected-date-label date" fxLayoutAlign="center center">
      Selected Date:
      <span [ngClass]="{ error: showError }">
        {{ selectedDate.date ? (selectedDate.date | date: 'MMM d, y') : 'Please select date' }}
      </span>
    </p>
    @if (selectedDate?.date) {
      <p class="selected-date-label time" fxLayoutAlign="center center">
        Selected Time:
        {{ selectedDate.partOfDay }}
        <span>
          {{ selectedDate.partOfDay === partsOfDay.am ? '08:00 AM - 12:00 PM' : '' }}
          {{ selectedDate.partOfDay === partsOfDay.pm ? '12:00 PM - 04:00 PM' : '' }}
        </span>
      </p>
    }
  </ng-template>
  <ng-template #OutOfZone>
    <h2 mat-dialog-title>
      <div fxLayout="row" fxLayoutGap="10px">
        <div>
          <mat-icon svgIcon="warning" />
        </div>
        <div fxLayout="column" fxLayoutAlign="center start">
          @if (!isCaptain) {
            <span>It looks like you are just outside of our pick up area.</span>
          }
          @if (isCaptain) {
            <span>Donor is out of zone</span>
          }
        </div>
      </div>
    </h2>
    <div mat-dialog-content>
      @if (!isCaptain) {
        <p>
          Please
          <b>select a date</b>
          and a member of our veteran-lead support team will review your request to determine if a near by hauler can conduct your pick up.
        </p>
      }
      @if (isCaptain) {
        <p>
          This donor is outside our standard service area. While you can submit the pickup request, please note that completion is not
          guaranteed. We’ll let the donor know that we will check for any available pickup dates and update them accordingly.
        </p>
      }
      <div fxLayout="row" fxLayoutAlign="center center">
        <button class="primary-btn filled-btn" (click)="outOfZoneDialog?.close()" cdkFocusInitial>I understand.</button>
      </div>
    </div>
  </ng-template>
</ng-template>
