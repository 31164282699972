<button
  [class]="'theme-icon app-' + app"
  [ngClass]="{ auto: autoTheme }"
  (click)="toggleTheme()"
  mat-icon-button
  [matTooltip]="autoTheme ? 'Use System Theme' : theme === themes.LIGHT ? 'Light Theme' : 'Dark Theme'"
  [matTooltipShowDelay]="500"
>
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-icon>invert_colors</mat-icon>
    @if (autoTheme) {
      <mat-icon class="auto-theme">hdr_auto</mat-icon>
    }
  </div>
</button>
