import { Injectable, NgZone } from '@angular/core';
import { Design } from '@domains';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DesignService {
  designs = Design;
  design?: Design;
  private designSubject = new BehaviorSubject<Design | undefined>(undefined);
  public design$ = this.designSubject.asObservable();

  constructor(private zone: NgZone) {}

  setDesign(design: Design) {
    this.design = design;
    this.designSubject.next(this.design);
    for (const d of Object.values(Design)) {
      document.body.classList.remove(d);
    }
    document.body.classList.add(this.design);
  }
}
