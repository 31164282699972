import { autoserialize, autoserializeAs } from 'cerialize';

import { Contact } from './contact';
import { Pricing } from './pricing';

export enum SponsorshipState {
  ACTIVE = 'active',
  PAUSED = 'paused',
  DISABLED = 'disabled',
}
export enum SponsorshipAlgorithmVersion {
  STATIC_V1 = 'static_v1',
  EFFICIENT_V1 = 'efficient_v1',
  FIXED = 'fixed',
}

export enum SponsorshipStripeStatus {
  REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
  SUCCESS = 'succeeded',
}

export class Sponsorship implements ISponsorship {
  @autoserializeAs('charity_id') charityId?: string;
  @autoserialize id: string;
  @autoserialize zips?: string[];
  @autoserialize state: SponsorshipState;
  @autoserialize amount?: number;
  @autoserializeAs('max_small') maxSmall?: number;
  @autoserializeAs('stripe_status')
  stripeStatus: SponsorshipStripeStatus | null;
  @autoserializeAs('algorithm_version')
  algorithmVersion?: SponsorshipAlgorithmVersion;
  @autoserializeAs('algorithm_min_quote') algorithmMinQuote?: number;
  @autoserializeAs('algorithm_max_discount') algorithmMaxDiscount?: number;
  @autoserializeAs('email_notification') emailNotification?: boolean;
  @autoserializeAs('billing_contact') billingContact?: Contact;

  constructor(payload?: ISponsorship) {
    this.charityId = payload?.charityId;
    this.id = payload?.id;
    this.zips = payload?.zips;
    this.state = payload?.state;
    this.amount = payload?.amount;
    this.maxSmall = payload?.maxSmall;
    this.stripeStatus = payload?.stripeStatus;
    this.algorithmVersion = payload?.algorithmVersion;
    this.algorithmMinQuote = payload?.algorithmMinQuote;
    this.algorithmMaxDiscount = payload?.algorithmMaxDiscount;
    this.emailNotification = payload?.emailNotification;
    this.billingContact = payload?.billingContact ? new Contact(payload.billingContact) : undefined;
  }

  public static OnSerialized(instance: Sponsorship, json: any): void {
    json.amount = json.amount !== null ? Pricing.formatPrice(json.amount * 100) : null;
    json.max_small = json.max_small !== null ? Pricing.formatPrice(json.max_small * 100) : null;
    json.algorithm_min_quote = json.algorithm_min_quote !== null ? Pricing.formatPrice(json.algorithm_min_quote * 100) : null;
    json.algorithm_max_discount = json.algorithm_max_discount !== null ? Pricing.formatPrice(json.algorithm_max_discount * 100) : null;
  }

  public static OnDeserialized(instance: Sponsorship): void {
    instance.amount = instance.amount !== null ? Pricing.formatPrice(instance.amount / 100) : null;
    instance.maxSmall = instance.maxSmall !== null ? Pricing.formatPrice(instance.maxSmall / 100) : null;
    instance.algorithmMaxDiscount =
      instance.algorithmMaxDiscount !== null ? Pricing.formatPrice(instance.algorithmMaxDiscount / 100) : null;
    instance.algorithmMinQuote = instance.algorithmMinQuote !== null ? Pricing.formatPrice(instance.algorithmMinQuote / 100) : null;
  }
}
export interface ISponsorship {
  charityId?: string;
  id?: string;
  zips?: string[];
  state?: SponsorshipState;
  amount?: number;
  maxSmall?: number;
  stripeStatus?: SponsorshipStripeStatus;
  algorithmVersion?: SponsorshipAlgorithmVersion;
  algorithmMinQuote?: number;
  algorithmMaxDiscount?: number;
  emailNotification?: boolean;
  billingContact?: Contact;
}
