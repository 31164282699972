import { NgTemplateOutlet } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { AppType, ENVIRONMENT, Environment, FindParamsDefault } from '@domains';
import { FxFlexDirective, FxLayoutAlignDirective, FxLayoutDirective, FxLayoutGapDirective, ResponsiveService } from '@rspl-ui';

import { TableService } from '../table/table.service';
import { BaseFilterComponent } from './base-filter.component';

@Component({
  selector: 'rspl-default-filter',
  templateUrl: './default-filter.component.html',
  styleUrls: ['./default-filter.component.scss'],
  imports: [
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatIcon,
    NgTemplateOutlet,
    FormsModule,
    FxLayoutDirective,
    FxLayoutGapDirective,
    FxLayoutAlignDirective,
    ReactiveFormsModule,
    FxFlexDirective,
    MatButtonToggleGroup,
    MatButtonToggle,
    MatFormField,
    MatLabel,
    MatInput,
    MatIconButton,
    MatButton,
  ],
})
export class DefaultFilterComponent
  extends BaseFilterComponent<{
    id: FormControl<string | null>;
    name: FormControl<string | null>;
    inactive: FormControl<boolean>;
  }>
  implements OnInit
{
  @Input() fieldName: 'name' | 'name_match' = 'name';
  isCaptain: boolean;
  inactive = false;

  constructor(
    public override responsiveService: ResponsiveService,
    public override tableService: TableService,
    @Inject(ENVIRONMENT) protected config: Environment,
  ) {
    super(responsiveService, tableService);
    this.isCaptain = this.config.app === AppType.CAPTAIN;
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  initForm(params: any) {
    this.form = new FormGroup({
      id: new FormControl(params?.id || ''),
      name: new FormControl(params?.name_match || params?.name || ''),
      inactive: new FormControl(params?.inactive || false),
    });
  }

  resetForm(): void {
    this.form.setValue({
      id: '',
      name: '',
      inactive: false,
    });
  }

  getFiltersValue(): { [key: string]: any } {
    const filters: FindParamsDefault = {};
    if (this.form.get('inactive')?.value) {
      filters.inactive = true;
    }
    if (this.form.get('name')?.value) {
      filters[this.fieldName] = this.form.get('name')?.value || undefined;
    }
    if (this.form.get('id')?.value) {
      filters['id'] = this.form.get('id')?.value || undefined;
    }
    this.inactive = !!this.form.get('inactive')?.value;
    return filters;
  }

  get isEmpty(): boolean {
    return (
      JSON.stringify(this.form?.value || '{}') ===
      JSON.stringify({
        id: '',
        name: '',
        inactive: false,
      })
    );
  }

  setInactive(inactive: boolean): void {
    this.form.get('inactive')?.setValue(inactive);
  }
}
