import { autoserialize, autoserializeAs } from 'cerialize';

import { BaseDTO, IBaseDTO } from './base-dto';
import { Charity, ICharity } from './charity';
import { CharityStore, ICharityStore } from './store';

export class PhoneConfiguration extends BaseDTO {
  @autoserialize id?: string;
  @autoserializeAs('charity_id') charityId?: string | null;
  @autoserializeAs(Charity, 'charity') charity?: Charity | null;
  @autoserializeAs('store_id') storeId?: string | null;
  @autoserializeAs(CharityStore, 'store') store?: CharityStore | null;
  @autoserializeAs('created_at') createdAt?: string | null;
  @autoserializeAs('updated_at') updatedAt?: string | null;
  @autoserializeAs('discarded_at') discardedAt?: string | null;
  @autoserializeAs('phone_number') phoneNumber?: string | null;
  @autoserializeAs('flow_type') flowType?: string | null;
  @autoserializeAs('welcome_recording') welcomeRecording?: string | null;
  @autoserializeAs('gather_recording') gatherRecording?: string | null;
  @autoserializeAs('scheduling_recording') schedulingRecording?: string | null;
  @autoserializeAs('route_back_phone') routeBackPhone?: string | null;
  @autoserializeAs('route_back_to_forwarded_from') routeBackToForwardedFrom?: boolean | null;
  @autoserializeAs('scheduling_phone_number') schedulingPhoneNumber?: string | null;
  @autoserializeAs('zendesk_phone') zendeskPhone?: string | null;
  @autoserialize notes?: string | null;
  @autoserialize forwarding?: PhoneConfigurationForwarding[];
  formattedPhoneNumber = '';
  useDefaultZendeskPhone: boolean;

  constructor(payload?: IPhoneConfiguration) {
    super(payload);
    this.id = payload?.id?.toString();
    this.charity = payload?.charity ? new Charity(payload?.charity) : undefined;
    this.charityId = payload?.charityId?.toString() || this.charity?.id || null;
    this.store = payload?.store ? new CharityStore(payload?.store) : undefined;
    this.storeId = payload?.storeId?.toString() || this.store?.id || null;
    this.createdAt = payload?.createdAt;
    this.updatedAt = payload?.updatedAt;
    this.discardedAt = payload?.discardedAt;
    this.phoneNumber = payload?.phoneNumber;
    this.flowType = payload?.flowType?.toString() || null;
    this.welcomeRecording = payload?.welcomeRecording;
    this.gatherRecording = payload?.gatherRecording;
    this.schedulingRecording = payload?.schedulingRecording;
    this.routeBackPhone = payload?.routeBackPhone;
    this.schedulingPhoneNumber = payload?.schedulingPhoneNumber;
    this.zendeskPhone = payload?.zendeskPhone;
    this.useDefaultZendeskPhone = !this.zendeskPhone;
    this.notes = payload?.notes;
    this.routeBackToForwardedFrom = payload?.routeBackToForwardedFrom;
    this.forwarding = payload?.forwarding ? payload?.forwarding.map((x) => new PhoneConfigurationForwarding(x)) : undefined;

    if (this.phoneNumber) {
      if (this.phoneNumber.slice(0, 2) === '+1') {
        this.formattedPhoneNumber = this.phoneNumber.slice(2);
      }
    }
  }
}

export interface IPhoneConfiguration extends IBaseDTO {
  id?: string;
  charityId?: string | null;
  charity?: ICharity | null;
  storeId?: string | null;
  store?: ICharityStore | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  discardedAt?: string | null;
  phoneNumber?: string | null;
  flowType?: string | null;
  welcomeRecording?: string | null;
  gatherRecording?: string | null;
  schedulingRecording?: string | null;
  routeBackPhone?: string | null;
  routeBackToForwardedFrom?: boolean | null;
  schedulingPhoneNumber?: string | null;
  zendeskPhone?: string | null;
  notes?: string | null;
  forwarding?: PhoneConfigurationForwarding[];
}

export class PhoneConfigurationForwarding {
  @autoserialize id?: string | null;
  @autoserialize type?: string | null;
  @autoserialize phone?: string | null;

  constructor(payload?: IPhoneConfigurationForwarding) {
    this.id = payload?.id?.toString();
    this.type = payload?.type;
    this.phone = payload?.phone;
  }
}

export interface IPhoneConfigurationForwarding {
  id?: string | null;
  type?: string | null;
  phone?: string | null;
}

export const PhoneConfigurationFlowTypes = [
  {
    value: '0',
    text: 'Flow Type 1',
  },
  {
    value: '1',
    text: 'Flow Type 2',
  },
  {
    value: '2',
    text: 'Flow Type 3',
  },
  {
    value: '3',
    text: 'Flow Type 4',
  },
  {
    value: '4',
    text: 'Flow Type 5',
  },
  {
    value: '5',
    text: 'Flow Type 6',
  },
  {
    value: '6',
    text: 'Special Flow 0',
  },
  {
    value: '7',
    text: 'Flow Type 7',
  },
];

export const flowDefaultRecordings = {
  flow0: {
    welcomeRecording: 'https://phone-configurations-6199.twil.io/default_welcome_message.mp3',
    gatherRecording: 'https://phone-configurations-6199.twil.io/default_flow1_options.mp3',
  },
  flow1: {
    welcomeRecording: 'https://phone-configurations-6199.twil.io/default_welcome_message.mp3',
    gatherRecording: 'https://phone-configurations-6199.twil.io/default_flow2_options.mp3',
  },
  flow2: {
    welcomeRecording: 'https://phone-configurations-6199.twil.io/default_welcome_message.mp3',
    gatherRecording: 'https://phone-configurations-6199.twil.io/default_flow3_options.mp3',
    schedulingRecording: 'https://phone-configurations-6199.twil.io/default_flow3_secondary.mp3',
  },
  flow3: {
    welcomeRecording: 'https://phone-configurations-6199.twil.io/default_welcome_message.mp3',
    gatherRecording: 'https://phone-configurations-6199.twil.io/default_flow4_options.mp3',
    schedulingRecording: 'https://phone-configurations-6199.twil.io/default_flow4_secondary.mp3',
  },
  flow4: {
    welcomeRecording: 'https://phone-configurations-6199.twil.io/default_welcome_message.mp3',
    gatherRecording: 'https://phone-configurations-6199.twil.io/default_flow5_options.mp3',
  },
  flow5: {
    welcomeRecording: 'https://phone-configurations-6199.twil.io/default_welcome_message.mp3',
    gatherRecording: 'https://phone-configurations-6199.twil.io/default_flow6_options.mp3',
  },
  flow6: {
    welcomeRecording: 'https://phone-configurations-6199.twil.io/default_flow0_message.mp3',
    gatherRecording: 'https://phone-configurations-6199.twil.io/default_flow0_options.mp3',
  },
  flow7: {
    welcomeRecording: null,
    gatherRecording: null,
  },
};

export const PhoneConfigurationFlowTypeDescriptions = [
  {
    image: 'assets/images/flow-1.png',
    desc: [
      'Flow 1 Audio',
      'Press 1 for live scheduling, press 2 for SMS scheduling, press 3 for SMS support, press 4 to go back to charity',
      'Straight to live operator (zendesk live phone number)',
      'Link to SMS with a link to donor flow',
      'Charity Routeback phone number option',
    ],
  },
  {
    image: 'assets/images/flow-2.png',
    desc: [
      'Flow 2 Audio',
      'Press 1 for live scheduling, press 2 for SMS scheduling, press 3 for SMS support',
      'Straight to live operator (zendesk live phone number)',
      'Link to SMS with a link to Donor flow',
    ],
  },
  {
    image: 'assets/images/flow-3.png',
    desc: [
      'Flow 3 Audio',
      'Press 1 for scheduling, press 2 for SMS support, press 3 to go back to charity',
      'Press 1 for live operator, press 2 for SMS, press 3 for voicemail, press 4 for SMS support, press 5 to go back to charity',
      'Straight to live operator (zendesk live phone number)',
      'Link to SMS with a link to donor flow',
      'Straight to voicemail (zendesk voicemail phone number)',
      'Charity Routeback phone number option',
    ],
  },
  {
    image: 'assets/images/flow-4.png',
    desc: [
      'Flow 4 Audio',
      'Press 1 for scheduling, press 2 for SMS support',
      'Press 1 for live operator, press 2 for SMS, press 3 for voicemail, press 4 for SMS support',
      'Straight to live operator (zendesk live phone number)',
      'Link to SMS with a link to donor flow',
      'Straight to voicemail (zendesk voicemail phone number)',
    ],
  },
  {
    image: 'assets/images/flow-5.png',
    desc: [
      'Flow 5 Audio',
      'Press 1 for voicemail scheduling, press 2 for SMS scheduling, press 3 for SMS support',
      'Straight to voicemail (zendesk voicemail phone number)',
      'Link to SMS with a link to Donor flow',
      'Charity Routeback phone number option',
    ],
  },
  {
    image: 'assets/images/flow-6.png',
    desc: [
      'Flow 6 Audio',
      'Press 1 for voicemail scheduling, press 2 for SMS scheduling, press 3 for SMS support',
      'Straight to voicemail (zendesk voicemail phone number)',
      'Link to SMS with a link to Donor flow',
    ],
  },
  {
    image: 'assets/images/flow-7.png',
    desc: [
      'Flow 0 Audio',
      'Press 1 to go back to charity, press 2 for scheduling',
      'Charity Routeback phone number option',
      'Scheduling connect call to one of the scheduling flows',
    ],
  },
  {
    image: 'assets/images/flow-8.png',
    desc: ['Forward to Zendesk'],
  },
  {
    image: null,
    desc: [],
  },
];
