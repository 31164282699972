import { autoserialize } from 'cerialize';

import { AppType } from './app-type';

export class UserMeta {
  @autoserialize lastSubmittedStep?: number;
  @autoserialize onboardingCompleted: boolean;
  @autoserialize onboardingStoreId?: string;
  @autoserialize onboardingTruckId?: string;
  @autoserialize onboardingScheduleId?: string;
  @autoserialize tableConfig?: string;
  @autoserialize zdTableConfig?: string;
  private appType?: AppType;

  constructor(payload?: IUserMeta, appType?: AppType) {
    this.lastSubmittedStep = payload?.lastSubmittedStep;
    this.onboardingCompleted = payload?.onboardingCompleted !== false;
    this.onboardingStoreId = payload?.onboardingStoreId;
    this.onboardingTruckId = payload?.onboardingTruckId;
    this.onboardingScheduleId = payload?.onboardingScheduleId;
    this.tableConfig = payload?.tableConfig;
    this.zdTableConfig = payload?.zdTableConfig;
    this.appType = appType;
  }

  get tableConfiguration(): string | undefined {
    return this.appType && this.appType === AppType.ZENDESK ? this.zdTableConfig : this.tableConfig;
  }

  set tableConfiguration(tableConfiguration: string | undefined) {
    if (this.appType && this.appType === AppType.ZENDESK) {
      this.zdTableConfig = tableConfiguration;
    } else {
      this.tableConfig = tableConfiguration;
    }
  }
}

export interface IUserMeta {
  lastSubmittedStep?: number;
  onboardingCompleted: boolean;
  onboardingStoreId?: string;
  onboardingTruckId?: string;
  onboardingScheduleId?: string;
  tableConfig?: string;
  zdTableConfig?: string;
}
