import { Component, OnDestroy, OnInit } from '@angular/core';
import { WindowSize } from '@domains';
import { takeUntil } from 'rxjs/operators';

import { Destructible } from '../destructible/destructible';
import { ResponsiveService } from './responsive.service';

@Component({
  template: '',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class Responsive extends Destructible implements OnInit, OnDestroy {
  windowSize!: WindowSize;
  windowSizes = WindowSize;
  onWindowResize?: () => void;
  resizeTimeout: any;
  smallWindowSizes: WindowSize[] = [WindowSize.XS, WindowSize.SM];
  windowInnerWidth: number;

  constructor(public responsiveService: ResponsiveService) {
    super();
  }

  ngOnInit(): void {
    this.responsiveService.size.pipe(takeUntil(this.destroy$)).subscribe((size) => {
      this.windowInnerWidth = window.innerWidth;
      this.windowSize = size;
      if (this.onWindowResize) {
        if (this.resizeTimeout) {
          clearTimeout(this.resizeTimeout);
        }
        this.resizeTimeout = setTimeout(() => {
          if (this.onWindowResize) this.onWindowResize();
        }, 100);
      }
    });
  }

  get isSmallWindowSize(): boolean {
    return !!this.windowSize && this.smallWindowSizes.includes(this.windowSize);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
