import { autoserialize } from 'cerialize';

export class DonationContent implements IDonationContent {
  @autoserialize name?: string | null;
  @autoserialize photo?: string | null;

  constructor(payload?: IDonationContent) {
    this.name = payload?.name;
    this.photo = payload?.photo;
  }
}

export interface IDonationContent {
  name?: string | null;
  photo?: string | null;
}
