import { autoserialize, autoserializeAs } from 'cerialize';

import { DayCapacity, IDayCapacity } from './day-capacity';
import { DayWorkingHours, IDayWorkingHours } from './day-working-hours';
import { DayZone, IDayZone } from './day-zone';
import { ScheduleLeadTime } from './schedule-lead-time';

export class Schedule {
  @autoserialize id?: string;
  @autoserialize week?: number;
  @autoserialize year?: number;
  @autoserializeAs(DayCapacity, 'monday_capacity') mondayCapacity: DayCapacity;
  @autoserializeAs(DayWorkingHours, 'monday_time') mondayTime: DayWorkingHours;
  @autoserializeAs(DayZone, 'monday_zone') mondayZone: DayZone;
  @autoserializeAs(DayCapacity, 'tuesday_capacity') tuesdayCapacity: DayCapacity;
  @autoserializeAs(DayWorkingHours, 'tuesday_time') tuesdayTime: DayWorkingHours;
  @autoserializeAs(DayZone, 'tuesday_zone') tuesdayZone: DayZone;
  @autoserializeAs(DayCapacity, 'wednesday_capacity') wednesdayCapacity: DayCapacity;
  @autoserializeAs(DayWorkingHours, 'wednesday_time') wednesdayTime: DayWorkingHours;
  @autoserializeAs(DayZone, 'wednesday_zone') wednesdayZone: DayZone;
  @autoserializeAs(DayCapacity, 'thursday_capacity') thursdayCapacity: DayCapacity;
  @autoserializeAs(DayWorkingHours, 'thursday_time') thursdayTime: DayWorkingHours;
  @autoserializeAs(DayZone, 'thursday_zone') thursdayZone: DayZone;
  @autoserializeAs(DayCapacity, 'friday_capacity') fridayCapacity: DayCapacity;
  @autoserializeAs(DayWorkingHours, 'friday_time') fridayTime: DayWorkingHours;
  @autoserializeAs(DayZone, 'friday_zone') fridayZone: DayZone;
  @autoserializeAs(DayCapacity, 'saturday_capacity') saturdayCapacity: DayCapacity;
  @autoserializeAs(DayWorkingHours, 'saturday_time') saturdayTime: DayWorkingHours;
  @autoserializeAs(DayZone, 'saturday_zone') saturdayZone: DayZone;
  @autoserializeAs(DayCapacity, 'sunday_capacity') sundayCapacity: DayCapacity;
  @autoserializeAs(DayWorkingHours, 'sunday_time') sundayTime: DayWorkingHours;
  @autoserializeAs(DayZone, 'sunday_zone') sundayZone: DayZone;
  @autoserializeAs(ScheduleLeadTime, 'lead_time') leadTime?: ScheduleLeadTime;

  constructor(payload?: ISchedule) {
    this.id = payload?.id?.toString();
    this.week = payload?.week;
    this.year = payload?.year;
    this.mondayCapacity = new DayCapacity(payload?.mondayCapacity);
    this.mondayTime = new DayWorkingHours(payload?.mondayTime);
    this.mondayZone = new DayZone(payload?.mondayZone);
    this.tuesdayCapacity = new DayCapacity(payload?.tuesdayCapacity);
    this.tuesdayTime = new DayWorkingHours(payload?.tuesdayTime);
    this.tuesdayZone = new DayZone(payload?.tuesdayZone);
    this.wednesdayCapacity = new DayCapacity(payload?.wednesdayCapacity);
    this.wednesdayTime = new DayWorkingHours(payload?.wednesdayTime);
    this.wednesdayZone = new DayZone(payload?.wednesdayZone);
    this.thursdayCapacity = new DayCapacity(payload?.thursdayCapacity);
    this.thursdayTime = new DayWorkingHours(payload?.thursdayTime);
    this.thursdayZone = new DayZone(payload?.thursdayZone);
    this.fridayCapacity = new DayCapacity(payload?.fridayCapacity);
    this.fridayTime = new DayWorkingHours(payload?.fridayTime);
    this.fridayZone = new DayZone(payload?.fridayZone);
    this.saturdayCapacity = new DayCapacity(payload?.saturdayCapacity);
    this.saturdayTime = new DayWorkingHours(payload?.saturdayTime);
    this.saturdayZone = new DayZone(payload?.saturdayZone);
    this.sundayCapacity = new DayCapacity(payload?.sundayCapacity);
    this.sundayTime = new DayWorkingHours(payload?.sundayTime);
    this.sundayZone = new DayZone(payload?.sundayZone);
    this.leadTime = payload?.leadTime;
  }
}

export interface ISchedule {
  id?: string;
  week?: number;
  year?: number;
  mondayCapacity?: IDayCapacity;
  mondayTime?: IDayWorkingHours;
  mondayZone?: IDayZone;
  tuesdayCapacity?: DayCapacity;
  tuesdayTime?: IDayWorkingHours;
  tuesdayZone?: DayZone;
  wednesdayCapacity?: IDayCapacity;
  wednesdayTime?: IDayWorkingHours;
  wednesdayZone?: IDayZone;
  thursdayCapacity?: IDayCapacity;
  thursdayTime?: IDayWorkingHours;
  thursdayZone?: IDayZone;
  fridayCapacity?: IDayCapacity;
  fridayTime?: IDayWorkingHours;
  fridayZone?: IDayZone;
  saturdayCapacity?: IDayCapacity;
  saturdayTime?: IDayWorkingHours;
  saturdayZone?: IDayZone;
  sundayCapacity?: IDayCapacity;
  sundayTime?: IDayWorkingHours;
  sundayZone?: IDayZone;
  leadTime?: ScheduleLeadTime;
}
