import { CdkScrollable } from '@angular/cdk/scrolling';
import { Component, Inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatSelect } from '@angular/material/select';
import {
  Donation,
  DONOR_CPT_CANCEL_REASONS,
  ENVIRONMENT,
  Environment,
  PARTNER_CANCEL_REASONS,
  PARTNER_CPT_CANCEL_REASONS,
  Reason,
} from '@domains';
import * as moment from 'moment';

import { FxFlexDirective, FxLayoutAlignDirective, FxLayoutDirective, FxLayoutGapDirective } from '../flex-layout';

@Component({
  selector: 'rspl-cancel-donation-dialog',
  templateUrl: './cancel-donation-dialog.component.html',
  styleUrls: ['./cancel-donation-dialog.component.scss'],
  imports: [
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    FxLayoutDirective,
    FxLayoutGapDirective,
    MatLabel,
    MatRadioGroup,
    FormsModule,
    MatRadioButton,
    MatFormField,
    FxFlexDirective,
    MatHint,
    MatSelect,
    ReactiveFormsModule,
    MatOption,
    MatInput,
    MatDialogActions,
    FxLayoutAlignDirective,
    MatButton,
  ],
})
export class CancelDonationDialogComponent {
  reason: FormControl<Reason>;
  subReason: FormControl<string>;
  selectedReason: Reason;
  additionalText: FormControl<string>;
  sameDay: boolean;
  asDonor = false;
  chargeCancellation = false;
  bookingFeeRefund = true;
  isCaptain: boolean;
  reasons: Reason[];

  public constructor(
    public reference: MatDialogRef<CancelDonationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Donation,
    @Inject(ENVIRONMENT) private environment: Environment,
  ) {
    this.isCaptain = this.environment.isCaptain;
    this.reason = new FormControl<Reason>(null, Validators.required);
    this.subReason = new FormControl<string>(null, Validators.required);
    this.additionalText = new FormControl<string>(null, Validators.required);
    this.sameDay = moment(data.date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');

    this.reasons = this.isCaptain ? PARTNER_CPT_CANCEL_REASONS : PARTNER_CANCEL_REASONS;
    this.bookingFeeRefund = this.data.isPartnerPickup;
  }

  public onChoice(choice: boolean): void {
    let reason = '';
    let note = '';
    if (choice) {
      this.reason.updateValueAndValidity();
      this.subReason.updateValueAndValidity();
      this.reason.markAllAsTouched();
      this.subReason.markAllAsTouched();
      this.additionalText.updateValueAndValidity();
      this.additionalText.markAllAsTouched();
      if (this.reason.invalid || this.subReason.invalid || this.additionalText.invalid) {
        return;
      }

      reason =
        (this.sameDay ? '[Same Day] ' : '[Prior Notice] ') +
        this.reason.value.reason +
        (this.subReason.value ? ' : ' + this.subReason.value : '');
      note = this.additionalText.value;
    }

    this.reference.close(
      choice
        ? {
            reason,
            note,
            asDonor: this.asDonor,
            ...(this.asDonor ? { chargeCancellation: this.chargeCancellation } : {}),
            ...(!this.asDonor && this.data.payment.bookingIntent ? { bookingFeeRefund: this.bookingFeeRefund } : {}),
          }
        : null,
    );
  }

  setSelectedReason(): void {
    this.selectedReason = this.reason.value;
    if (this.selectedReason.children) {
      this.subReason.setValidators(Validators.required);
      this.subReason.updateValueAndValidity();
    } else {
      this.subReason.clearValidators();
    }
  }

  asDonorChange(): void {
    if (this.asDonor) {
      this.reasons = DONOR_CPT_CANCEL_REASONS;
    } else {
      this.reasons = this.isCaptain ? PARTNER_CPT_CANCEL_REASONS : PARTNER_CANCEL_REASONS;
    }
  }
}
