<rspl-card [cardTitle]="cardTitle">
  @if (showItems) {
    <div fxFlex fxLayout="row">
      <mat-button-toggle-group fxFlex="100" [(ngModel)]="mode" (change)="changeMode()">
        <mat-button-toggle fxFlex="50" value="items">Items</mat-button-toggle>
        <mat-button-toggle fxFlex="50" value="spec">Spec</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  }
  @if (mode === 'items') {
    <rspl-card class="add-item-wrapper">
      <div class="add-item" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <rspl-item-lookup
          [currentId]="newItem.item?.id"
          (onItemSelected)="selectItem($event)"
          label="Select an Item"
          addNewLabel="Create new Item"
          [onAddNew]="onAddNewItem.bind(this)"
          class="item-lookup"
          [shouldSort]="false"
        ></rspl-item-lookup>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
          <div fxLayout="row" fxLayoutAlign="end center">
            @if (!readonly) {
              <button type="button" mat-icon-button (click)="newItem.quantity = newItem.quantity - 1" [disabled]="newItem.quantity <= 1">
                <mat-icon>remove</mat-icon>
              </button>
            }
            <input
              type="tel"
              [(ngModel)]="newItem.quantity"
              (keyup)="updateItemQuantity($event)"
              (focus)="focusInput($event)"
              matInput
              class="item-quantity"
            />
            @if (!readonly) {
              <button type="button" mat-icon-button (click)="newItem.quantity = newItem.quantity + 1">
                <mat-icon>add</mat-icon>
              </button>
            }
          </div>
          <div fxFLex fxLayoutAlign="center center">
            <button
              id="addItemButton"
              mat-raised-button
              class="primary-btn filled-btn"
              [disabled]="!newItem.item || newItem.quantity < 1"
              (click)="addToItems()"
              (keydown.enter)="preventEvent($event)"
              (keyup.enter)="addToItems()"
            >
              ADD
            </button>
          </div>
        </div>
      </div>
    </rspl-card>
  }
  @if ((isCaptain || isDonor) && showBasePrice) {
    <div class="estimate-item" fxLayout="row" fxLayoutAlign="space-between center">
      <span class="label">Base Price</span>
      @if (showPrices) {
        <div fxLayout="row" fxLayoutAlign="end center">
          <span class="price-value">{{ pricing?.base | currency }}</span>
        </div>
      }
    </div>
  }
  @for (type of specificationItemTypeArray; track type; let i = $index) {
    <rspl-specification-item
      [type]="type"
      [mode]="mode"
      [splitSmall]="splitSmall"
      [showPricing]="showPricing"
      [showPrices]="showPrices"
      [readonly]="readonly"
      [quantity]="specificationItemQuantity(type)"
      [pricing]="specificationItemPrice(type)"
      [showItem]="isItemVisible(type)"
      (quantityChanged)="specificationItemValueChange(type, $event)"
      [useDebounce]="useDebounce"
      [isCalculatingSponsorship]="isCalculatingSponsorship"
    />
  }
  @if (isPhoneLead && showPrices && leadOrDonation?.discount?.valid) {
    <div fxFlex="100" fxLayoutAlign="space-between center" class="sponsored-message">
      <b>This pickup qualifies for a charity sponsorship</b>
      <mat-icon (click)="showSponsorshipPopup()" color="primary">info</mat-icon>
    </div>
  }
  @if ((isCaptain || isDonor) && pricing?.fuelFee) {
    <div class="estimate-item" fxLayout="row" fxLayoutAlign="space-between center">
      <span class="label">
        Fuel Fee
        @if (showPricing) {
          <span class="price">({{ pricing?.fuelFee }}%)</span>
        }
      </span>
      <div fxLayout="row" fxLayoutAlign="end center">
        @if (pricing && showPrices) {
          <span class="price-value">{{ fuelFee | currency }}</span>
        }
      </div>
    </div>
  }
  @if (showPrice) {
    <mat-divider />
  }
  @if (showPrice) {
    <div fxLayout="row" fxLayoutAlign="space-between">
      <h2>Estimated{{ isCaptain ? ' Pickup' : '' }} Cost:</h2>
      @if (!isCalculatingSponsorship) {
        <h2>
          @if (sponsoredAmount) {
            <span>
              {{ !pricing ? '' : (totalCostEstimate - sponsoredAmount | currency) }}
            </span>
          }
          <span [ngClass]="{ strikethrough: sponsoredAmount }">
            {{ !pricing ? '' : (totalCostEstimate | currency) }}
          </span>
        </h2>
      } @else {
        <div fxLayout="row" fxLayoutAlign="end start">
          <mat-spinner [diameter]="20" />
        </div>
      }
    </div>
  }
  @if (isCaptain && showPrice && pricing && pricing?.bookingFee) {
    <div fxLayout="row" fxLayoutAlign="space-between">
      <h2>Booking Fee:</h2>
      <h2>{{ pricing.bookingFee | currency }}</h2>
    </div>
  }
  @if (showPrice && isCaptain && sponsoredAmount) {
    <mat-divider />
  }
  @if (isCaptain && sponsoredAmount) {
    <div fxLayout="row" fxLayoutAlign="space-between">
      <h2>Charity sponsorship amount:</h2>
      @if (!isCalculatingSponsorship) {
        <h2>{{ sponsoredAmount | currency }}</h2>
      } @else {
        <div fxLayout="row" fxLayoutAlign="end start">
          <mat-spinner [diameter]="20" />
        </div>
      }
    </div>
  }
  @if (showPrice && isCaptain) {
    <mat-divider />
  }
  @if (isCaptain && showPrice && pricing && pricing?.bookingFee && totalCostEstimate) {
    <div fxLayout="row" fxLayoutAlign="space-between">
      <h2>Estimated Total Cost of Service:</h2>
      @if (!isCalculatingSponsorship) {
        <h2>
          {{ (pricing.bookingFee || 0) + totalCostEstimate - sponsoredAmount | currency }}
        </h2>
      } @else {
        <div fxLayout="row" fxLayoutAlign="end start">
          <mat-spinner [diameter]="20" />
        </div>
      }
    </div>
  }
  @if (items.length > 0) {
    <rspl-card style="margin-bottom: 10px; display: block">
      <div fxLayout="row wrap" fxLayoutGap="5px">
        @for (item of items; track item; let i = $index) {
          <div class="added-item" fxLayoutAlign="start center">
            <span>{{ item.quantity }} x</span>
            <span>
              {{ item.item.name }}
            </span>
            <button mat-icon-button (click)="removeFromItems(i)">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        }
      </div>
    </rspl-card>
  }

  <div fxLayout="row" fxLayoutAlign="space-between">
    @if (showErrors && ((showSmall && specification.isEmptyBig()) || (!showSmall && specification.isEmpty()))) {
      <p class="mat-error">Quote can not be empty</p>
    }
  </div>
  <ng-content></ng-content>
</rspl-card>
<ng-template #SponsorshipDialogTemplate>
  <h2 mat-dialog-title>A note about charity-sponsored pickups</h2>
  <div mat-dialog-content>
    <p>
      {{ charity?.name || 'Selected charity' }} has chosen to sponsor a portion of this donor’s pickup fee. The final sponsored quote is
      subject to change based on the hauler's assessment of the quality, quantity, and type of items on-site.
      <br />
      @if (leadOrDonation.sponsorship_algorithm?.version !== 'fixed') {
        This sponsorship applies only to bags or boxes of clothes and housewares. Other items are not eligible, and removing any bags or
        boxes will affect their sponsorship amount.
      }
    </p>
  </div>
  <div mat-dialog-actions fxFlex="100" fxLayout="column" fxLayoutAlign="end end">
    <button mat-raised-button class="primary-btn filled-btn" (click)="sponsorshipDialog?.close()" cdkFocusInitial>Okay</button>
  </div>
</ng-template>
