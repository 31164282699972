import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { FxLayoutAlignDirective, FxLayoutDirective } from '@rspl-ui';

@Component({
  selector: 'rspl-input-audio',
  templateUrl: './input-audio.component.html',
  styleUrls: ['./input-audio.component.scss'],
  imports: [NgClass, MatIconButton, MatIcon, FxLayoutDirective, FxLayoutAlignDirective],
})
export class InputAudioComponent {
  showControls = false;
  @Input() audio?: string;
  @Input() isSuffix = true;

  toggle(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.showControls = !this.showControls;
  }
}
