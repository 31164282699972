<input
  [placeholder]="label"
  #inputElement
  matInput
  type="text"
  readonly
  [value]="showForm ? fullAddress : ''"
  [ngClass]="{ invalid: showError }"
  (click)="openAddress()"
  (keydown.enter)="openAddress()"
/>
<mat-icon svgIcon="location" />
@if (showError && inputElement.value.trim().length === 0) {
  <span class="input-error">
    <mat-icon>info_outline</mat-icon>
    Enter your address to see available times.
  </span>
}

<ng-template #addressDialog>
  <form fxLayout="column" class="addr-dialog" matDialogContent [formGroup]="form">
    <div fxLayout="column">
      <div fxLayout="row" fxLayout.lt-md="column" fxFlex="100" fxLayoutGap="0" fxLayoutGap.gt-sm="10px" style="width: 100%">
        <div fxLayout="column" fxFlex="80" fxFlex.lt-md="70">
          <mat-form-field appearance="outline" class="addr-input">
            <mat-label>Street</mat-label>
            <input
              formControlName="street"
              #inputElement
              matInput
              [matAutocomplete]="auto"
              (input)="getAddressesDelayed()"
              autocomplete="off"
            />
            <mat-autocomplete #auto="matAutocomplete" class="addr-autocomplete">
              @if (loading) {
                <mat-option disabled class="loading" fxFlex fxLayoutAlign="center center">
                  <div fxFlex="100" fxLayoutAlign="center center">
                    <mat-spinner [diameter]="20" />
                  </div>
                </mat-option>
              }
              @if (!loading && addresses.length === 0 && (form.get('street')?.value?.length || 0) > 0) {
                <mat-option disabled class="loading">No matches</mat-option>
              }
              @for (addr of addresses; track addr; let i = $index) {
                <mat-option [value]="addr.street" (onSelectionChange)="select(addr, $event)">
                  <div fxLayout="row">
                    <div fxLayout="column" fxLayoutAlign="center center">
                      <mat-icon>location_pin</mat-icon>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="start" fxFlex>
                      <p>
                        {{ addr.street }}
                        {{ addr.secondary && '#' + addr.secondary }}
                      </p>
                      <p>{{ addr.city }}, {{ addr.state }} {{ addr.zip }}, US</p>
                    </div>
                  </div>
                </mat-option>
              }
            </mat-autocomplete>
            @if (form.touched && !showForm && !address.state) {
              <mat-error class="error error-msg">You must select one of the offered addresses</mat-error>
            }
            @if (form.get('street')?.touched && showForm && (form.get('street')?.errors || {})['required']) {
              <mat-error class="error">Street is required</mat-error>
            }
            @if (form.get('street')?.touched && showForm && (form.get('street')?.errors || {})['pattern']) {
              <mat-error class="error">Street is not valid</mat-error>
            }
          </mat-form-field>
        </div>

        <mat-form-field
          appearance="outline"
          fxFlex="20"
          fxFlex.lt-md="30"
          style="margin-bottom: 15px"
          [ngStyle]="{
            'max-width': isSmallWindowSize ? '100%' : '20%',
          }"
        >
          <mat-label>Apt. No.</mat-label>
          <input type="text" matInput formControlName="secondary" />
        </mat-form-field>
      </div>
      @if (!showForm) {
        <a class="enter-manually" (click)="showForm = true">Can't find your address? Click here to enter it manually.</a>
      }
      @if (showForm) {
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>City</mat-label>
          <input type="text" matInput formControlName="city" (input)="isManualAddress = true" />
        </mat-form-field>
      }
      @if (showForm) {
        <div fxLayout="row" fxLayout.lt-md="column" class="errors">
          @if (form.get('city')?.touched && showForm && (form.get('city')?.errors || {})['required']) {
            <mat-hint class="error">City is required</mat-hint>
          }
          @if (form.get('city')?.touched && showForm && (form.get('city')?.errors || {})['pattern']) {
            <mat-hint class="error">City is not valid</mat-hint>
          }
        </div>
      }
      @if (showForm) {
        <rspl-us-states-selector
          [required]="true"
          [stateToEdit]="form.get('state')?.value"
          fxFlex="100"
          (selectedState)="setSelectedState($event)"
          [showError]="form.get('state')?.touched && showForm && (form.get('state')?.errors || {})['required']"
        ></rspl-us-states-selector>
      }
      @if (showForm) {
        <div fxLayout="row" fxLayout.lt-md="column" class="errors">
          @if (form.get('state')?.touched && showForm && (form.get('state')?.errors || {})['required']) {
            <mat-hint class="error">State is required</mat-hint>
          }
        </div>
      }
      @if (showForm) {
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>Zip</mat-label>
          <input type="text" matInput formControlName="zip" (input)="isManualAddress = true" />
        </mat-form-field>
      }
      @if (showForm) {
        <div fxLayout="row" fxLayout.lt-md="column" class="errors">
          @if (form.get('zip')?.touched && showForm && (form.get('zip')?.errors || {})['required']) {
            <mat-hint class="error">Zip is required</mat-hint>
          }
          @if (form.get('zip')?.touched && showForm && (form.get('zip')?.errors || {})['pattern']) {
            <mat-hint class="error">Zip is not valid</mat-hint>
          }
        </div>
      }
    </div>
  </form>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutAlign.lt-md="center" matDialogActions>
    <button class="submit-button primary-page-btn" (click)="confirmAddress()">Confirm</button>
  </div>
</ng-template>
