import { FindJourneyParams, Journey } from '@domains';
import { createAction, props } from '@ngrx/store';

export enum JourneysActionType {
  GetJourney = '[Journey] Get Journey',
  GetJourneySuccess = '[Journey] Get Journey Success',

  UpdateJourney = '[Journey] Update Journey',
  UpdateJourneySuccess = '[Journey] Update Journey Success',
  UpdateJourneyFail = '[Journey] Update Journey Fail',

  OptimizeJourney = '[Journey] Optimize Journey',
  OptimizeJourneySuccess = '[Journey] Optimize Journey Success',
  OptimizeJourneyFail = '[Journey] Optimize Journey Fail',
}

export const getJourney = createAction(JourneysActionType.GetJourney, props<{ findParams: FindJourneyParams }>());
export const getJourneySuccess = createAction(JourneysActionType.GetJourneySuccess, props<{ journey: Journey }>());

export const updateJourney = createAction(JourneysActionType.UpdateJourney, props<{ journey: Journey }>());
export const updateJourneySuccess = createAction(JourneysActionType.UpdateJourneySuccess, props<{ journey: Journey }>());
export const updateJourneyFail = createAction(JourneysActionType.UpdateJourneyFail);

export const optimizeJourney = createAction(JourneysActionType.OptimizeJourney, props<{ journeyId: string }>());
export const optimizeJourneySuccess = createAction(JourneysActionType.OptimizeJourneySuccess, props<{ journey: Journey }>());
export const optimizeJourneyFail = createAction(JourneysActionType.OptimizeJourneyFail);
