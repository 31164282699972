<mat-form-field appearance="outline" fxFlex class="pick-us-state" fxFlex="100">
  <mat-label>State</mat-label>

  <mat-select
    matInput
    [formControl]="stateControl"
    (selectionChange)="stateSelected()"
    (openedChange)="focusFilter(filterInput, $event)"
    panelClass="pick-us-state"
    disableOptionCentering="true"
    [required]="required"
  >
    <input
      type="text"
      placeholder="Search..."
      matInput
      class="picker-filter"
      autocomplete="off"
      name="us-state-picker-filter"
      #filterInput
      [formControl]="stateFilterControl"
      (keydown.space)="$event.stopPropagation()"
    />

    @for (option of filteredOptions | async; track option) {
      <mat-option [value]="option">
        {{ option.name }}
      </mat-option>
    }
  </mat-select>
  @if (!required && stateControl.value) {
    <mat-icon matSuffix (click)="clear($event)">close</mat-icon>
  }
</mat-form-field>
