import { CurrencyPipe, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, Inject, Input, input, OnChanges, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { AppType, Donation, ENVIRONMENT, Environment, Lead, Pricing, Specification } from '@domains';

import { Designable, DesignService } from '../designable';
import { FxFlexDirective, FxLayoutAlignDirective, FxLayoutDirective } from '../flex-layout';
import { ResponsiveService } from '../responsive';
import { DiscountInfoDialogComponent } from './discount-info-dialog/discount-info-dialog.component';

@Component({
  selector: 'rspl-donation-estimate',
  templateUrl: './donation-estimate.component.html',
  styleUrls: ['./donation-estimate.component.scss'],
  imports: [
    NgStyle,
    FormsModule,
    FxLayoutDirective,
    FxLayoutAlignDirective,
    MatProgressSpinner,
    NgTemplateOutlet,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    FxFlexDirective,
    MatIcon,
    CurrencyPipe,
  ],
})
export class DonationEstimateComponent extends Designable implements OnChanges {
  @Input() pricing!: Pricing;

  initialSpecification!: Specification;
  #specification!: Specification;
  @Input() set specification(specification: Specification) {
    this.#specification = specification;
    this.initialSpecification = new Specification({ ...specification });
  }
  get specification(): Specification {
    return this.#specification;
  }

  type = input.required<'initial' | 'adjusted'>();

  #leadOrDonation!: Lead | Donation;
  @Input() set leadOrDonation(leadOrDonation: Lead | Donation) {
    this.#leadOrDonation = leadOrDonation;
    this.sponsoredAmount = leadOrDonation.totalSponsoredAmount;
  }

  get leadOrDonation(): Lead | Donation {
    return this.#leadOrDonation;
  }

  @Input() showZeros = true;
  @Input() gratuity?: number;
  @Input() bookingFee?: number | null;
  @Input() showPrices = true;
  @Input() showUnitPrices = true;
  @Input() collapsable = false;
  @Input() disabled = false;
  @Input() editable = false;
  @Input() totalLabel = 'Total';
  @Input() bookingLabel = 'Booking Fee';
  @Input() showBookingFee: 'regular' | 'title' | 'none' = 'title';
  @Input() showTodaysCharges = false;
  @Input() showDiscount = false;
  @Input() includeBookingInTotal = false;
  @Input() isCalculating = false;
  readonly onSpecificationChange = output();
  public donationPrice?: number;
  public fuelFee?: number;

  app: AppType;
  sponsoredAmount = 0;

  constructor(
    @Inject(ENVIRONMENT) private config: Environment,
    protected dialog: MatDialog,
    override designService: DesignService,
    override responsiveService: ResponsiveService,
  ) {
    super(designService, responsiveService);
    this.app = this.config.app;
  }

  ngOnChanges(): void {
    this.updatePrice();
  }

  updatePrice() {
    if (this.showPrices && this.pricing) {
      this.donationPrice = Pricing.getTotalPriceFormatted(this.specification, this.pricing, this.gratuity);
      this.fuelFee = this.pricing.fuelFee ? (Pricing.getTotalBasePrice(this.specification, this.pricing) * this.pricing.fuelFee) / 100 : 0;
    }
    this.sponsoredAmount = this.leadOrDonation.totalSponsoredAmount;
  }

  onKeyUp() {
    if (this.leadOrDonation.discount?.valid) {
      this.onSpecificationChange.emit();
    } else {
      this.updatePrice();
    }
  }

  checkValue(event: any) {
    const val = Number(event.target.value.replaceAll(/[^\d]/g, ''));
    return isNaN(val) || val === undefined ? 0 : val;
  }

  focusInput(event: any) {
    event.target.select();
  }

  public get isChanged() {
    return !this.initialSpecification.isSameAs(this.specification);
  }

  showDiscountInfoDialog() {
    this.dialog.open(DiscountInfoDialogComponent, {
      panelClass: 'discount-info-dialog',
      data: {
        charityName: this.leadOrDonation.charity?.name,
        lead: this.leadOrDonation,
      },
    });
  }
}
