import { autoserializeAs } from 'cerialize';

export class DayCapacity {
  @autoserializeAs('am_capacity') amCapacity: number;
  @autoserializeAs('pm_capacity') pmCapacity: number;

  constructor(capacityPayload?: IDayCapacity) {
    this.amCapacity =
      capacityPayload?.amCapacity !== undefined && (capacityPayload?.amCapacity === 0 || (capacityPayload?.amCapacity || 0) > 0)
        ? capacityPayload?.amCapacity
        : 20;
    this.pmCapacity =
      capacityPayload?.pmCapacity !== undefined && (capacityPayload?.pmCapacity === 0 || (capacityPayload?.pmCapacity || 0) > 0)
        ? capacityPayload?.pmCapacity
        : 20;
  }
}

export interface IDayCapacity {
  amCapacity?: number;
  pmCapacity?: number;
}
