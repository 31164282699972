import { Component, Input, OnInit, output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Responsive, ResponsiveService } from '@rspl-ui';
import { debounceTime, take, takeUntil } from 'rxjs/operators';

import { TableService } from '../table/table.service';

@Component({
  template: '',
})
export abstract class BaseFilterComponent<
    T extends {
      [K in keyof T]: AbstractControl<any>;
    },
  >
  extends Responsive
  implements OnInit
{
  @Input() set tableId(tableId: string) {
    if (!tableId) return;
    this.tableService
      .getFindParams(tableId)
      .pipe(take(1))
      .subscribe((params: any) => {
        this.initForm(params || {});
      });
  }

  filtersChangedEvent = output<void>();
  editMode = false;
  hasApply = false;
  skipChange = false;
  #disabled?: boolean;
  @Input() set disabled(disabled: boolean) {
    if (this.#disabled !== undefined) this.skipChange = true;
    this.#disabled = disabled;
    if (this.disabled) {
      this.form?.disable();
    } else {
      this.form.enable();
    }
  }
  get disabled(): boolean {
    return !!this.#disabled;
  }

  #form!: FormGroup<T>;
  set form(form: FormGroup<T>) {
    this.#form = form;
    if (this.editMode) {
      this.form?.disable();
    } else {
      this.form?.enable();
    }
    if (!this.hasApply) {
      this.form?.valueChanges?.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe(() => {
        if (this.skipChange) {
          this.skipChange = false;
        } else if (!this.hasApply) {
          this.filtersChangedEvent.emit();
        }
      });
    }
  }

  get form(): FormGroup<T> {
    return this.#form;
  }

  protected constructor(
    public override responsiveService: ResponsiveService,
    public tableService: TableService,
  ) {
    super(responsiveService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.tableService.editMode$.pipe(takeUntil(this.destroy$)).subscribe((editMode) => {
      this.editMode = editMode;
      if (this.editMode) {
        this.form?.disable();
      } else {
        this.form?.enable();
      }
    });
  }

  public apply(): void {
    this.filtersChangedEvent.emit();
  }

  abstract getFiltersValue(): { [key: string]: any };

  abstract get isEmpty(): boolean;

  abstract initForm(data: any): void;

  abstract resetForm(): void;
}
