import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  camelCaseToSnakeCase(s: string): string {
    if (s) {
      return s.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
    } else {
      return s;
    }
  }

  cleanObjectFromNull(obj: any): any {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    }
    return obj;
  }

  removeNestedObjectWithAllEmptyValues(obj: any, ignore: string[] = []): any {
    Object.keys(obj)
      .filter((k) => !ignore.includes(k))
      .forEach((k) => {
        if (obj[k] && typeof obj[k] === 'object') {
          if (!Object.values(obj[k]).some((x) => x !== null && x !== '' && x !== undefined)) {
            delete obj[k];
          }
        }
      });
    return obj;
  }
}
