export enum SpecificationItemType {
  xlarge = 'xlarge',
  large = 'large',
  medium = 'medium',
  small = 'small',
  donatableSmall = 'donatableSmall',
  nonDonatableSmall = 'nonDonatableSmall',
  staircases = 'staircases',
  elevator = 'elevator',
  disassembly = 'disassembly',
  recycling = 'recycling',
}

export enum ItemMode {
  items = 'items',
  spec = 'spec',
}

export enum ItemLabel {
  donatableSmall = 'Donatable Bags/boxes of clothes/housewares',
  nonDonatableSmall = 'Non Donatable Bags/boxes of clothes/housewares',
  small = 'Bags/boxes of clothes/housewares',
  medium = 'Medium items',
  large = 'Large items',
  xlarge = 'Extra large items',
  staircases = 'Staircases',
  elevator = 'Elevator',
  disassembly = 'Disassembly',
  recycling = 'Recycling',
}

export interface ISpecificationItem {
  showItem?: boolean;
  showPricing?: boolean;
  showPrices?: boolean;
  showPrice?: boolean;
  splitSmall?: boolean;
  donatableSmall?: number;
  nonDonatableSmall?: number;
  readonly?: boolean;
  isCaptain?: boolean;
  type: SpecificationItemType;
  pricing: number;
  quantity: number;
  mode?: ItemMode;
}
