import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BudgetsService } from '@rspl-api';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import * as fromActions from './budget.actions';

@Injectable()
export class BudgetEffects {
  createBudget = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.createBudget),
      mergeMap((payload) =>
        this.service.create(payload.budget).pipe(
          map((result) => {
            return fromActions.createBudgetSuccess({
              budget: result,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  updateBudget = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updateBudget),
      mergeMap((payload) => {
        return this.service.update(payload.budget.id, payload.budget).pipe(
          map((result) => {
            return fromActions.updateBudgetSuccess({
              budget: result,
            });
          }),
          catchError(() => [fromActions.updateBudgetFail]),
        );
      }),
    );
  });

  getBudgets = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getBudgets),
      switchMap((params) =>
        this.service.filter(params.findParams).pipe(
          map((results) => {
            return fromActions.getBudgetsSuccess({
              budgets: results.results,
              resultsCount: results.totalResults,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  getBudget = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getBudget),
      switchMap((params) =>
        this.service.find(params.id, (params.expand?.length || 0) > 0, params.expand).pipe(
          map((result) => {
            return fromActions.getBudgetSuccess({
              budget: result,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  deleteBudget = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.deleteBudget),
      switchMap((params) =>
        this.service.delete(params.budgetId).pipe(
          map(() => {
            return fromActions.deleteBudgetSuccess({
              budgetId: params.budgetId,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  restoreBudget = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.restoreBudget),
      switchMap((params) =>
        this.service.restore(params.budgetId).pipe(
          map(() => {
            return fromActions.restoreBudgetSuccess({
              budgetId: params.budgetId,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private service: BudgetsService,
  ) {}
}
