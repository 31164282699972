import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ENVIRONMENT, Environment, User } from '@domains';
import { LocalStorageService } from '@rspl-api';

@Injectable({ providedIn: 'root' })
export class OnboardingGuard {
  constructor(
    private router: Router,
    private localStorage: LocalStorageService,
    @Inject(ENVIRONMENT) private config: Environment,
  ) {}

  canActivate(): boolean {
    let res: boolean;
    if (this.localStorage.getItem('rspl-user')) {
      const user = new User(JSON.parse(this.localStorage.getItem('rspl-user') || '{}'), this.config.app);
      if (!user.meta || user.meta.onboardingCompleted) {
        res = true;
      } else {
        res = false;
      }
      if (!res) {
        this.router.navigate(['/', 'onboarding']);
      }
    } else {
      res = false;
    }
    return res;
  }
}
