import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AvailabilityStats, Charity, CharityStore, Contact, ENVIRONMENT, Environment } from '@domains';
import { Deserialize } from 'cerialize';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseScheduleApiService } from '../base-schedule-api.service';

@Injectable({
  providedIn: 'root',
})
export class StoresService extends BaseScheduleApiService<CharityStore> {
  constructor(
    @Inject(ENVIRONMENT) override config: Environment,
    override http: HttpClient,
  ) {
    super(config, http, 'stores', CharityStore, 'Store');
  }

  override deserialize(data: any): CharityStore {
    const res = new CharityStore(
      Deserialize(
        {
          ...data,
          charity: data.charity ? new Charity(Deserialize(data.charity, Charity)) : data.charity,
          primaryContact: data.primaryContact ? new Contact(Deserialize(data.primaryContact, Contact)) : data.primaryContact,
        },
        CharityStore,
      ),
    );
    res.setInitialValue();
    return res;
  }

  getAvailableStores(params: any): Observable<AvailabilityStats[]> {
    return this.http.get<AvailabilityStats[]>(this.config.urls.baseUrl + `/stores/availability`, { params }).pipe(
      map((response: any) => {
        return response?.map((r: any) => new AvailabilityStats(Deserialize(r, AvailabilityStats)));
      }),
    );
  }

  getClosestStores(id: any): Observable<CharityStore[]> {
    return this.http.get<AvailabilityStats[]>(this.config.urls.baseUrl + '/donations/' + id + '/closest_stores').pipe(
      map((response: any) => {
        return response?.map((r: any) => new CharityStore(Deserialize(r, CharityStore)));
      }),
    );
  }
}
