import { autoserialize, autoserializeAs } from 'cerialize';

import { BaseDTO, IBaseDTO } from './base-dto';
import { WebButtonPosition } from './web-button-position';
import { WidgetOwner } from './widget-owner';
import { WidgetType } from './widget-type';

export class WidgetMeta {
  public static INITIAL_WEB_BTN_BACKGROUND_COLOR = '#2c3c66';
  public static INITIAL_WEB_BTN_FONT_COLOR = '#ffffff';
  public static INITIAL_WEB_BTN_POSITION = WebButtonPosition.RIGHT;

  @autoserialize webBtnBackgroundColor: string;
  @autoserialize webBtnFontColor: string;
  @autoserialize webBtnPosition: WebButtonPosition;
  @autoserialize enabled: boolean;
  @autoserialize poweredBy: boolean;
  @autoserialize webBtnCollapsable: boolean;
  @autoserialize webBtnDismissible?: boolean;

  constructor(payload?: IWidgetMeta) {
    this.webBtnBackgroundColor = payload?.webBtnBackgroundColor || WidgetMeta.INITIAL_WEB_BTN_BACKGROUND_COLOR;
    this.webBtnFontColor = payload?.webBtnFontColor || WidgetMeta.INITIAL_WEB_BTN_FONT_COLOR;
    this.webBtnPosition = payload?.webBtnPosition || WidgetMeta.INITIAL_WEB_BTN_POSITION;
    this.enabled = payload?.enabled !== false;
    this.poweredBy = payload?.poweredBy !== false;
    this.webBtnCollapsable = payload?.webBtnCollapsable !== false;
    this.webBtnDismissible = !!payload?.webBtnDismissible;
  }
}

export interface IWidgetMeta {
  webBtnBackgroundColor?: string;
  webBtnFontColor?: string;
  webBtnPosition?: WebButtonPosition;
  enabled?: boolean;
  poweredBy?: boolean;
  webBtnCollapsable?: boolean;
  webBtnDismissible?: boolean;
}

export class Widget extends BaseDTO implements IWidget {
  @autoserialize id: string;
  @autoserializeAs('discarded_at') discardedAt?: string;
  @autoserialize meta: WidgetMeta;
  @autoserializeAs('widget_type') widgetType: WidgetType;
  @autoserializeAs('widget_code') widgetCode: string;
  @autoserializeAs('source_url') sourceUrl: string;
  @autoserializeAs('owner_type') ownerType: WidgetOwner;
  @autoserializeAs('owner_id') ownerId: string;

  constructor(payload: IWidget) {
    super(payload);
    this.id = payload?.id?.toString();
    this.discardedAt = payload?.discardedAt;
    this.meta = new WidgetMeta(payload?.meta);
    this.widgetCode = payload?.widgetCode;
    this.widgetType = payload?.widgetType as WidgetType;
    this.sourceUrl = payload?.sourceUrl;
    this.ownerId = payload?.ownerId?.toString();
    this.ownerType = payload?.ownerType;
  }
}

export interface IWidget extends IBaseDTO {
  id: string;
  discardedAt?: string;
  meta?: WidgetMeta;
  widgetType: WidgetType;
  widgetCode: string;
  sourceUrl: string;
  ownerType: WidgetOwner;
  ownerId: string;
}
