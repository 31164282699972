import { NgClass } from '@angular/common';
import { Component, Inject, Input, output } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ENVIRONMENT, Environment } from '@domains';
import { UploadFileService } from '@rspl-api';
import { catchError, finalize, take } from 'rxjs';

import { FxLayoutAlignDirective, FxLayoutDirective, FxLayoutGapDirective } from '../flex-layout';

@Component({
  selector: 'rspl-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  imports: [FxLayoutDirective, FxLayoutAlignDirective, NgClass, FxLayoutGapDirective, MatProgressSpinner],
})
export class FileUploadComponent {
  readonly uploadedFileUrl = output<{ url: string; type: string; name: string }>();
  readonly csvData = output<string[]>();
  @Input() public disabled = false;
  @Input() error = false;
  @Input() accept = '.csv, .pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
  isUploading = false;

  constructor(
    private uploadFileService: UploadFileService,
    private snackBar: MatSnackBar,
    @Inject(ENVIRONMENT) private environment: Environment,
  ) {}

  reset() {
    this.isUploading = false;
  }

  selectImage(input: any) {
    if (this.disabled) {
      return;
    }
    input.click();
    this.isUploading = false;
  }

  upload(event: any): void {
    const file: File = event.target.files[0];
    if (!file) return;
    if (file.type === 'text/csv') {
      this.handleCsv(event);
    }
    this.uploadFileService
      .uploadFile(file)
      .pipe(
        catchError((error) => {
          this.snackBar.open('UPLOAD ERROR: ' + error, 'x', {
            duration: 5000,
          });
          return error;
        }),
        finalize(() => (this.isUploading = false)),
        take(1),
      )
      .subscribe((response: any) => {
        this.uploadedFileUrl.emit({
          url:
            response.url.includes('http://') || response.url.includes('https://')
              ? response.url
              : this.environment.urls.baseUrl + response.url,
          type: file.type,
          name: file.name,
        });
        this.snackBar.open('File uploaded successfully', '', {
          duration: 5000,
          panelClass: 'success',
        });
      });
  }

  handleCsv($event: any): void {
    const input = $event.target;
    const reader = new FileReader();
    reader.readAsText(input.files[0]);

    reader.onload = () => {
      const csvData = reader.result;
      const csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
      this.csvData.emit(csvRecordsArray);
    };

    reader.onerror = function () {
      console.log('error is occured while reading file!');
    };
  }
}
