import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LocalStorageService } from '@rspl-api';
import { AuthenticationService } from '@rspl-auth';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as fromActions from './onboarding.actions';

@Injectable()
export class OnboardingEffects {
  createAccount = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.createAccount),
      switchMap((payload) => {
        return this.auth.signUp(payload.user).pipe(
          map((result) => {
            this.localStorage.setItem('rspl-user', JSON.stringify(result));
            return fromActions.createAccountSuccess({
              user: result,
            });
          }),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private auth: AuthenticationService,
    private localStorage: LocalStorageService,
  ) {}
}
