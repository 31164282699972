@if (widget && form) {
  <form [formGroup]="form" fxLayout="column" fxLayoutGap="30px" fxFlex="100">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px" fxFlex="100">
      <div fxLayout="column" fxLayoutGap="30px" fxFlex="100" fxFlex.md="50" fxFlex.gt-md="50">
        <rspl-card>
          <div fxLayout="column">
            <h2 class="card-title web-button" fxLayout="row">
              Web Button
              @if (isCaptain) {
                <mat-slide-toggle
                  #daySlideToggle
                  labelPosition="after"
                  [checked]="enabled.value"
                  (change)="enabled.setValue($event.checked)"
                ></mat-slide-toggle>
              }
            </h2>
            @if (isCaptain && widget) {
              <div fxLayout="row">
                <mat-form-field appearance="outline" fxFlex>
                  <mat-label>Button Type</mat-label>
                  <mat-select formControlName="widgetType" name="widgetType" (selectionChange)="widgetTypeChanged()">
                    @for (type of types; track type) {
                      <mat-option [value]="type.value">
                        {{ type.text }}
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            }
            <div fxLayout="column" formGroupName="meta">
              <mat-label>Position</mat-label>
              <mat-button-toggle-group fxFlex="100">
                <mat-button-toggle
                  fxFlex="50"
                  [checked]="webBtnPosition.value === positions.LEFT"
                  (click)="webBtnPosition.setValue(positions.LEFT); webBtnPositionChanged()"
                >
                  Left
                </mat-button-toggle>
                <mat-button-toggle
                  fxFlex="50"
                  [checked]="webBtnPosition.value === positions.RIGHT"
                  (click)="webBtnPosition.setValue(positions.RIGHT); webBtnPositionChanged()"
                >
                  Right
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <div fxLayout="row" formGroupName="meta" style="z-index: 3">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Background Color</mat-label>
                <mat-icon matPrefix class="color-picked" style="color: {{ webBtnBackgroundColor.value }}">circle</mat-icon>
                <input
                  formControlName="webBtnBackgroundColor"
                  matInput
                  [(colorPicker)]="widget.meta.webBtnBackgroundColor"
                  (colorPickerChange)="webBtnBackgroundColor.setValue($event)"
                  cpPosition="bottom"
                  [cpCloseClickOutside]="true"
                  (cpSliderDragStart)="disableScroll()"
                  (cpSliderDragEnd)="enableScroll()"
                  (change)="webBtnBackgroundColorChanged()"
                />
              </mat-form-field>
            </div>
            <div fxLayout="row" formGroupName="meta" style="z-index: 2">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Font Color</mat-label>
                <mat-icon matPrefix class="color-picked" style="color: {{ webBtnFontColor.value }}">circle</mat-icon>
                <input
                  formControlName="webBtnFontColor"
                  matInput
                  [(colorPicker)]="widget.meta.webBtnFontColor"
                  (colorPickerChange)="webBtnFontColor.setValue($event)"
                  cpPosition="bottom"
                  [cpCloseClickOutside]="true"
                  (cpSliderDragStart)="disableScroll()"
                  (cpSliderDragEnd)="enableScroll()"
                  (change)="webBtnFontColorChanged()"
                />
              </mat-form-field>
            </div>
            <div fxLayout="row" formGroupName="meta" style="z-index: 1">
              @if (isCaptain || isCharity) {
                <mat-slide-toggle labelPosition="after" [checked]="poweredBy.value" (change)="poweredBy.setValue($event.checked)">
                  Show Powered by Resupply
                </mat-slide-toggle>
              }
            </div>
            @if ([widgetTypes.BUTTON_V2, widgetTypes.BUTTON_V3].includes(widgetType.value) && isCaptain) {
              <div fxLayout="row" formGroupName="meta" style="z-index: 1">
                <mat-slide-toggle
                  labelPosition="after"
                  [checked]="webBtnCollapsable.value"
                  (change)="webBtnCollapsable.setValue($event.checked)"
                >
                  Collapsable
                </mat-slide-toggle>
              </div>
            }
            @if ([widgetTypes.BUTTON_V2, widgetTypes.BUTTON_V3].includes(widgetType.value) && isCaptain) {
              <div fxLayout="row" formGroupName="meta" style="z-index: 1">
                <mat-slide-toggle
                  labelPosition="after"
                  [checked]="webBtnDismissible.value"
                  (change)="webBtnDismissible.setValue($event.checked)"
                >
                  Dismissible
                </mat-slide-toggle>
              </div>
            }
            <div fxLayout="row" fxLayoutAlign="end end" fxFlex style="z-index: 1">
              <button mat-raised-button class="primary-btn" (click)="save()">Save</button>
            </div>
          </div>
        </rspl-card>
      </div>
      <div fxLayout="column" fxLayoutGap="30px" fxFlex="100" fxFlex.md="50" fxFlex.gt-md="50">
        @if (widgetType.value === widgetTypes.BUTTON_V1) {
          <rspl-card cardTitle="Preview">
            <div class="preview v1">
              <button
                id="rspl-partner-button"
                type="button"
                style="{{ webBtnPosition.value }}: 0; color: {{ webBtnFontColor.value }}; background-color: {{
                  webBtnBackgroundColor.value
                }};"
                [ngStyle]="{
                  'height.px': poweredBy.value ? (windowSize === windowSizes.XS ? 80 : 110) : windowSize === windowSizes.XS ? 50 : 70,
                }"
              >
                <div>
                  <div class="rspl-btn-label">
                    <span>Donation Pickup</span>
                    <mat-icon class="rspl-truck" svgIcon="truck-icon" />
                  </div>
                  @if (poweredBy.value) {
                    <div class="rspl-certified">
                      <span>Powered by</span>
                      <img src="https://widget.resupplyapp.com/{{ this.isProd ? 'prod' : 'stage' }}/Resupply-Logo-Final.png" />
                    </div>
                  }
                </div>
              </button>
            </div>
          </rspl-card>
        }
        @if (widgetType.value === widgetTypes.BUTTON_V2) {
          <rspl-card cardTitle="Preview">
            <div class="preview v2">
              <button
                id="rspl-partner-button"
                type="button"
                style="{{ webBtnPosition.value }}: 0; color: {{ webBtnFontColor.value }}; background-color: {{
                  webBtnBackgroundColor.value
                }};"
                [ngStyle]="{
                  'height.px': poweredBy.value ? (windowSize === windowSizes.XS ? 80 : 110) : windowSize === windowSizes.XS ? 50 : 70,
                }"
                [ngClass]="{ dismissible: webBtnDismissible.value }"
              >
                @if (webBtnCollapsable.value || webBtnDismissible.value) {
                  <button
                    type="button"
                    id="rspl-collapse-btn"
                    [ngClass]="{
                      left: webBtnPosition.value === positions.RIGHT,
                      right: webBtnPosition.value === positions.LEFT,
                    }"
                    (click)="collapseBtn()"
                  >
                    <span>✖</span>
                  </button>
                }
                <div>
                  <div class="rspl-btn-label">
                    <span>Donation Pickup</span>
                    <mat-icon class="rspl-truck" svgIcon="web-v3-truck-icon" />
                  </div>
                  @if (poweredBy.value) {
                    <div class="rspl-certified">
                      <span>Powered by</span>
                      <img src="https://widget.resupplyapp.com/{{ this.isProd ? 'prod' : 'stage' }}/Resupply-Logo-Final.png" />
                    </div>
                  }
                </div>
              </button>
            </div>
          </rspl-card>
        }
        @if (widgetType.value === widgetTypes.BUTTON_V3) {
          <rspl-card cardTitle="Preview">
            <div class="preview v3">
              <button
                id="rspl-partner-button"
                type="button"
                style="{{ webBtnPosition.value }}: 0; color: {{ webBtnFontColor.value }}; background-color: {{
                  webBtnBackgroundColor.value
                }};"
                [ngStyle]="{
                  'height.px': poweredBy.value ? (windowSize === windowSizes.XS ? 100 : 110) : windowSize === windowSizes.XS ? 58 : 70,
                }"
                [ngClass]="{
                  dismissible: webBtnDismissible.value,
                  poweredBy: poweredBy.value,
                }"
              >
                @if (webBtnCollapsable.value || webBtnDismissible.value) {
                  <button
                    type="button"
                    id="rspl-collapse-btn"
                    [ngClass]="{
                      left: webBtnPosition.value === positions.RIGHT,
                      right: webBtnPosition.value === positions.LEFT,
                    }"
                    (click)="collapseBtn()"
                  >
                    <span>✖</span>
                  </button>
                }
                <div>
                  <div class="rspl-btn-label">
                    <span>Donation Pickup</span>
                    <mat-icon class="rspl-truck" svgIcon="web-v3-truck-icon" />
                  </div>
                  @if (poweredBy.value) {
                    <div class="rspl-certified">
                      <span>Powered by</span>
                      <img src="https://widget.resupplyapp.com/{{ this.isProd ? 'prod' : 'stage' }}/Resupply-Logo-Final.png" />
                    </div>
                  }
                </div>
              </button>
            </div>
          </rspl-card>
        }
        <rspl-card>
          <h2 class="card-title web-button" fxLayout="row" fxLayoutAlign="space-between">
            Code Snippet
            <button class="primary-color" mat-button (click)="copy()">Copy</button>
          </h2>
          <textarea readonly class="snipet">{{ codeSnippet }}</textarea>
        </rspl-card>
      </div>
    </div>
  </form>
}
