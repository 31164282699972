<mat-form-field
  appearance="outline"
  fxFlex="100"
  class="pick-partner-wrapper"
  [ngClass]="{ 'mat-form-field-invalid': showError && ((!multiple && !selected) || (multiple && selected?.length === 0)) }"
>
  <mat-label>Select Truck{{ multiple ? 's' : '' }}</mat-label>
  @if (multiple) {
    <mat-icon matPrefix [ngStyle]="{ opacity: disabled ? 0.5 : 1 }">search</mat-icon>
  }
  <mat-select
    matInput
    [(value)]="selected"
    (selectionChange)="trucksSelected($event.value)"
    panelClass="pick-partner"
    disableOptionCentering="true"
    [disabled]="disabled"
    [multiple]="multiple"
    [required]="required"
    (openedChange)="focusFilter(filterInput, $event)"
  >
    <input
      type="text"
      placeholder="Search..."
      matInput
      class="picker-filter"
      autocomplete="off"
      name="partner-picker-filter"
      #filterInput
      [formControl]="filter"
      (input)="filterDelayed()"
      (keydown.space)="$event.stopPropagation()"
    />
    @if (multiple && visibleTrucks.length > 0) {
      <button mat-button class="select-all btn-block" (click)="toggleAll()">
        {{ selected.length !== visibleTrucks.length ? 'Select All' : 'Deselect All' }}
      </button>
    }
    @for (marketId of marketIds; track marketId) {
      @if (multiple && (marketsCount === 0 || markets[marketId])) {
        <div>
          @if (multiple && markets[marketId] && partnerIds[marketId].length > 0) {
            <p class="market">
              <mat-checkbox [checked]="isMarketSelected(marketId)" (change)="toggleMarket(marketId)">
                {{ markets && markets[marketId?.toString()] ? markets[marketId?.toString()].name : 'Unassigned' }}
              </mat-checkbox>
            </p>
          }
          @for (partnerId of partnerIds[marketId]; track partnerId) {
            <div>
              @if (multiple && truckIds[marketId][partnerId].length > 0) {
                <p class="partner">
                  <mat-checkbox [checked]="isPartnerSelected(marketId, partnerId)" (change)="togglePartner(marketId, partnerId)">
                    {{ partners && partners[partnerId] ? partners[partnerId].name : '' }}
                  </mat-checkbox>
                </p>
              }
              @for (option of truckIds[marketId][partnerId]; track option) {
                <mat-option [value]="option.id">
                  {{ option.name }}
                </mat-option>
              }
            </div>
          }
        </div>
      }
    }
  </mat-select>
  @if (!required && ((multiple && selected?.length > 0) || (!multiple && !!selected))) {
    <mat-icon matSuffix (click)="clear($event)">close</mat-icon>
  }
</mat-form-field>
