import { FindParams, Xmile } from '@domains';
import { createAction, props } from '@ngrx/store';

export enum XmileActionType {
  CreateXmile = '[Xmile] Create Xmile',
  CreateXmileSuccess = '[Xmile] Create Xmile Success',

  UpdateXmile = '[Xmile] Update Xmile',
  UpdateXmileSuccess = '[Xmile] Update Xmile Success',
  UpdateXmileFail = '[Xmile] Update Xmile Fail',

  ClearXmilesFindParams = '[Charity] Clear Xmiles Find Params',
  GetXmiles = '[Xmile] Get Xmiles',
  GetXmilesSuccess = '[Xmile] Get Xmiles Success',

  GetXmile = '[Xmile] Get Xmile',
  GetXmileSuccess = '[Xmile] Get Xmile Success',

  DeleteXmile = '[Xmile] Delete Xmile',
  DeleteXmileSuccess = '[Xmile] Delete Xmile Success',

  RestoreXmile = '[Xmile] Restore Xmile',
  RestoreXmileSuccess = '[Xmile] Restore Xmile Success',
}

export const createXmile = createAction(XmileActionType.CreateXmile, props<{ xmile: Xmile }>());
export const createXmileSuccess = createAction(XmileActionType.CreateXmileSuccess, props<{ xmile: Xmile }>());

export const updateXmile = createAction(XmileActionType.UpdateXmile, props<{ xmile: Xmile }>());
export const updateXmileSuccess = createAction(XmileActionType.UpdateXmileSuccess, props<{ xmile: Xmile }>());
export const updateXmileFail = createAction(XmileActionType.UpdateXmileFail, props<{ xmile: Xmile }>());

export const clearXmilesFindParams = createAction(XmileActionType.ClearXmilesFindParams);
export const getXmiles = createAction(XmileActionType.GetXmiles, props<{ findParams: FindParams; keep?: boolean }>());
export const getXmilesSuccess = createAction(XmileActionType.GetXmilesSuccess, props<{ xmiles: Array<Xmile>; resultsCount: number }>());

export const getXmile = createAction(XmileActionType.GetXmile, props<{ id: string; keep?: boolean; expand?: string[] }>());
export const getXmileSuccess = createAction(XmileActionType.GetXmileSuccess, props<{ xmile: Xmile }>());

export const deleteXmile = createAction(XmileActionType.DeleteXmile, props<{ xmileId: string }>());
export const deleteXmileSuccess = createAction(XmileActionType.DeleteXmileSuccess, props<{ xmileId: string }>());

export const restoreXmile = createAction(XmileActionType.RestoreXmile, props<{ xmileId: string }>());
export const restoreXmileSuccess = createAction(XmileActionType.RestoreXmileSuccess, props<{ xmileId: string }>());
