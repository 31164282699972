import { autoserialize, autoserializeAs } from 'cerialize';

import { Address, IAddress } from './address';
import { BaseDTO, IBaseDTO } from './base-dto';
import { CharityMeta, ICharityMeta } from './charity-meta';
import { Contact, IContact } from './contact';
import { IMarket, Market } from './market';
import { Screening } from './screening';
import { Sponsorship } from './sponsorship';
import { CharityStore, ICharityStore } from './store';
import { TaxReceiptSettings } from './tax-receipt-settings';

export class Charity extends BaseDTO implements ICharity {
  @autoserialize id?: string;
  @autoserializeAs('created_at') createdAt?: string | null;
  @autoserializeAs('updated_at') updatedAt?: string | null;
  @autoserializeAs('discarded_at') discardedAt?: string | null;
  @autoserializeAs(Market, 'market') market?: Market | null;
  @autoserializeAs('market_id') marketId?: string | null;
  @autoserialize override name: string;
  @autoserialize ein: string;
  @autoserialize logo?: string | null;
  @autoserialize references: any;
  @autoserializeAs(Address) address: Address;
  @autoserializeAs(Contact, 'primary_contact') primaryContact?: Contact | null;
  @autoserializeAs(Contact, 'website_contact') websiteContact?: Contact | null;
  @autoserializeAs(Contact, 'driver_contact') driverContact?: Contact | null;
  @autoserializeAs(Contact, 'social_media_contact')
  socialMediaContact?: Contact | null;
  @autoserialize stores?: Array<CharityStore> | null;
  @autoserializeAs(TaxReceiptSettings, 'tax_receipt')
  taxReceipt?: TaxReceiptSettings | null;
  @autoserialize meta?: CharityMeta | null;
  @autoserialize screening?: Screening | null;
  @autoserializeAs('blacklisted_partners') blacklistedPartners: string[];
  @autoserialize discount?: boolean | null;
  @autoserializeAs(Sponsorship, 'sponsorship') sponsorship?: Sponsorship | null;
  @autoserialize courier?: boolean | null;
  @autoserialize courier_donor_percentage?: number | null;

  public get zip(): string | undefined {
    return this.address?.zip;
  }

  public get state(): string | undefined {
    return this.address?.state;
  }

  public get phone(): string {
    return this.primaryContact?.phone || '';
  }

  public get fullAddress(): string {
    return this.address.fullAddress;
  }

  constructor(payload?: ICharity) {
    super(payload);
    this.id = payload?.id?.toString();
    this.createdAt = payload?.createdAt;
    this.updatedAt = payload?.updatedAt;
    this.discardedAt = payload?.discardedAt;
    this.market = payload?.market ? new Market(payload.market) : undefined;
    this.marketId = payload?.marketId?.toString() || this.market?.id;
    this.references = payload?.references;
    this.blacklistedPartners = payload?.blacklistedPartners?.map((b) => b.toString()) || [];
    this.address = new Address(payload?.address);
    this.primaryContact = payload?.primaryContact ? new Contact(payload?.primaryContact) : undefined;
    this.websiteContact = payload?.websiteContact ? new Contact(payload?.websiteContact) : undefined;
    this.driverContact = payload?.driverContact ? new Contact(payload?.driverContact) : undefined;
    this.socialMediaContact = payload?.socialMediaContact ? new Contact(payload?.socialMediaContact) : undefined;
    this.taxReceipt = new TaxReceiptSettings(payload?.taxReceipt);
    this.meta = new CharityMeta(payload?.meta);
    this.screening = payload?.screening ? new Screening(payload.screening) : undefined;
    this.name = payload?.name || '';
    this.ein = payload?.ein || '';
    this.logo = payload?.logo;
    this.stores = payload?.stores?.map((s) => new CharityStore(s));
    this.discount = payload?.discount;
    this.sponsorship = payload?.sponsorship ? new Sponsorship(payload.sponsorship) : undefined;
    this.courier = payload?.courier;
    this.courier_donor_percentage = payload?.courier_donor_percentage;
  }
}

export interface ICharity extends IBaseDTO {
  id?: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  discardedAt?: string | null;
  name?: string | null;
  ein?: string | null;
  logo?: string | null;
  references?: any | null;
  address: IAddress;
  primaryContact?: IContact | null;
  websiteContact?: IContact | null;
  driverContact?: IContact | null;
  socialMediaContact?: IContact | null;
  taxReceipt?: TaxReceiptSettings | null;
  meta?: ICharityMeta | null;
  market?: IMarket | null;
  marketId?: string | null;
  blacklistedPartners?: string[] | null;
  stores?: Array<ICharityStore> | null;
  screening?: Screening | null;
  discount?: boolean | null;
  sponsorship?: Sponsorship | null;
  courier?: boolean | null;
  courier_donor_percentage?: number | null;
}
