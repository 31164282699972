<div class="info-wrapper" [ngClass]="type">
  @if (header) {
    <span class="title">{{ header }}</span>
  }
  <div class="content">
    <p>
      {{ message }}
      <ng-content></ng-content>
    </p>
  </div>
  <mat-icon [ngClass]="{ warning: type === 'warning' || type === 'important', success: type === 'success' }">
    {{ type === 'warning' ? 'warning' : 'info' }}
  </mat-icon>
</div>
