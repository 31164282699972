import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, Environment, Market, Pricing } from '@domains';
import { Deserialize, Serialize } from 'cerialize';

import { BaseApiService } from '../base-api.service';

@Injectable({
  providedIn: 'root',
})
export class MarketsService extends BaseApiService<Market> {
  constructor(
    @Inject(ENVIRONMENT) override config: Environment,
    override http: HttpClient,
  ) {
    super(config, http, 'markets', Market, 'Market');
  }

  override deserialize(data: any): Market {
    const res = new Market(Deserialize(data, Market));
    res.setInitialValue();
    return res;
  }

  override serialize(item: Market) {
    return {
      ...Serialize({ ...item }, Market),
      ...(item.pricing
        ? {
            pricing: Serialize(item.pricing, Pricing),
          }
        : {}),
    };
  }
}
