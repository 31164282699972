import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NotificationsService } from '@rspl-api';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { AppState } from '../app-state.interface';
import * as fromActions from './notification.actions';

@Injectable()
export class NotificationsEffects {
  createNotification = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.createNotification),
      mergeMap((payload) =>
        this.service.create(payload.notification).pipe(
          map((result) => {
            return fromActions.createNotificationSuccess({
              notification: result,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  updateNotification = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updateNotification),
      mergeMap((payload) => {
        return this.service.update(payload.notification.id, payload.notification).pipe(
          map((result) => {
            return fromActions.updateNotificationSuccess({
              notification: result,
            });
          }),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  getNotifications = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getNotifications),
      switchMap((params) =>
        this.service.filter(params.findParams).pipe(
          map((response) => {
            return fromActions.getNotificationsSuccess({
              notifications: response.results,
              resultsCount: response.totalResults,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  getNotification = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getNotification),
      switchMap((params) =>
        this.service.find(params.id).pipe(
          map((result) => {
            return fromActions.getNotificationSuccess({
              notification: result,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  deleteNotification = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.deleteNotification),
      switchMap((params) =>
        this.service.delete(params.notificationId).pipe(
          map(() => {
            return fromActions.deleteNotificationSuccess({
              notificationId: params.notificationId,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  restoreNotification = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.restoreNotification),
      switchMap((params) =>
        this.service.restore(params.notificationId).pipe(
          map(() => {
            return fromActions.restoreNotificationSuccess({
              notificationId: params.notificationId,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  getHistory = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getNotificationHistory),
      switchMap((params) =>
        this.service.history(params.itemIds).pipe(
          map((result) => {
            return fromActions.getNotificationHistorySuccess({
              history: result,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private service: NotificationsService,
    private store: Store<AppState>,
  ) {}
}
