import { AuthenticationModel } from '@domains';
import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './onboarding.actions';

export interface OnboardingState {
  createdAccount?: AuthenticationModel;
}

const initialState: OnboardingState = {
  createdAccount: undefined,
};

const reducer = createReducer(
  initialState,

  on(fromActions.createAccountSuccess, (state, action: any) => ({ ...state, createdAccount: action.user })),
);

export function onboardingReducer(state: OnboardingState | undefined, action: Action) {
  return reducer(state, action);
}
