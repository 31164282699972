import { Pipe, PipeTransform } from '@angular/core';
import { render } from 'mustache';

@Pipe({
  name: 'parseContent',
  pure: false,
  standalone: true,
})
export class ParseContentPipe implements PipeTransform {
  transform(template: string, content: any): string {
    const result = render(template, content);
    return result;
  }
}
