<ng-template #Data let-change="change">
  <rspl-card [cardTitle]="change.user">
    <div fxLayout="column" class="history-data-wrapper">
      <table class="static-table full-width">
        <thead>
          <tr>
            <th>{{ change.oneValue ? '' : 'Changed' }}</th>
            @if (!change.oneValue) {
              <th>From</th>
            }
            @if (!change.oneValue) {
              <th>To</th>
            }
            @if (initialValue && initialEvent && change.event !== initialEvent) {
              <th class="initial"></th>
            }
            @if (change.oneValue) {
              <th>Value</th>
            }
            @if (initialValue && initialEvent && change.event !== initialEvent) {
              <th>Initial Value</th>
            }
          </tr>
        </thead>
        <tbody>
          @for (field of change.fields; track field) {
            <tr>
              <td>{{ field.name }}</td>
              @if (!change.oneValue) {
                <td>
                  <p [innerHTML]="field.oldValue"></p>
                </td>
              }
              <td>
                <p [innerHTML]="field.newValue"></p>
              </td>
              @if (initialValue && initialEvent && change.event !== initialEvent) {
                <td class="initial"></td>
              }
              @if (initialValue && initialEvent && change.event !== initialEvent) {
                <td>
                  <p [innerHTML]="initialValue[field.name]"></p>
                </td>
              }
            </tr>
          }
        </tbody>
      </table>
    </div>
  </rspl-card>
</ng-template>
@if (filterOptions) {
  <rspl-searchable-select [options]="filterOptions" (filterChange)="filterChange($event)" />
}
<div class="history-wrapper">
  <button
    mat-button
    (click)="changeSort()"
    [ngStyle]="{
      width: smallWindows.includes(windowSize) ? '100%' : 'auto',
      'margin-left.px': smallWindows.includes(windowSize) ? 0 : 159,
    }"
  >
    <span fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
      <mat-icon style="min-width: 15px; position: relative">{{ sort === 'asc' ? 'arrow_downward' : 'arrow_upward' }}</mat-icon>
    </span>
  </button>
  @if (smallWindows.includes(windowSize)) {
    <mat-accordion [multi]="true">
      @for (change of changes; track change) {
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ change.event }}
            </mat-panel-title>
            <mat-panel-description>
              {{ change.time }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <ng-container [ngTemplateOutlet]="Data" [ngTemplateOutletContext]="{ change: change }"></ng-container>
        </mat-expansion-panel>
      }
    </mat-accordion>
  }

  @if (!smallWindows.includes(windowSize)) {
    <div class="history">
      <div class="separator"></div>
      @for (change of sortedChanges; track change) {
        <div class="timeline-entry">
          <p class="date">{{ change.time }}</p>
          <p class="event">{{ change.event }}</p>
          <ng-container [ngTemplateOutlet]="Data" [ngTemplateOutletContext]="{ change: change }"></ng-container>
        </div>
      }
    </div>
  }
</div>
