import { createFeatureSelector, createSelector } from '@ngrx/store';

import { NotificationsState } from './notification.reducers';

// tslint:disable: max-line-length
export const notificationsFeatureStateSelector = createFeatureSelector<NotificationsState>('notification');

export const selectNotifications = createSelector(notificationsFeatureStateSelector, (state: NotificationsState) => state.notifications);
export const selectNotificationsTotalCount = createSelector(
  notificationsFeatureStateSelector,
  (state: NotificationsState) => state.notificationsTotalCount,
);
export const selectNotificationsFindParams = createSelector(
  notificationsFeatureStateSelector,
  (state: NotificationsState) => state.findParams,
);
export const selectNotificationHistory = createSelector(notificationsFeatureStateSelector, (state: NotificationsState) => state.history);

export const selectNotification = createSelector(notificationsFeatureStateSelector, (state: NotificationsState) => state.notification);
