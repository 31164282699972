import { AvailabilityStats, Schedule, Truck } from '@domains';
import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './truck.actions';

export interface TrucksState {
  trucks: Array<Truck>;
  stateTrucks: Array<Truck>;
  availabilityStats: Array<AvailabilityStats>;
  trucksTotalCount: number;
  truck: Truck;
  deletedTruckId: number;
  schedules: Array<Schedule>;
  schedulesTotalCount: number;
  schedule: Schedule;
  deletedScheduleId: number;
}

const initialState: TrucksState = {
  trucks: new Array<Truck>(),
  stateTrucks: new Array<Truck>(),
  availabilityStats: new Array<AvailabilityStats>(),
  trucksTotalCount: 0,
  truck: undefined,
  deletedTruckId: 0,

  schedules: new Array<Schedule>(),
  schedulesTotalCount: 0,
  schedule: new Schedule(),
  deletedScheduleId: 0,
};

const reducer = createReducer(
  initialState,

  on(fromActions.createTruckSuccess, (state, action: any) => ({ ...state, truck: action.truck })),
  on(fromActions.updateTruckSuccess, (state, action: any) => ({ ...state, truck: action.truck })),

  on(fromActions.getTrucks, (state) => {
    return { ...state, trucks: [] };
  }),
  on(fromActions.getTrucksSuccess, (state, action: any) => ({ ...state, trucks: action.trucks, trucksTotalCount: action.resultsCount })),
  on(fromActions.clearTrucksFindParams, (state) => {
    return { ...state };
  }),

  on(fromActions.getStateTrucks, (state) => ({ ...state, stateTrucks: [] })),
  on(fromActions.getStateTrucksSuccess, (state, action: any) => ({ ...state, stateTrucks: action.trucks })),

  on(fromActions.getTruckAvailabilityStats, (state) => ({ ...state, availabilityStats: [] })),
  on(fromActions.getTruckAvailabilityStatsSuccess, (state, action: any) => ({ ...state, availabilityStats: action.stats })),

  on(fromActions.getTruck, (state, { keep }) => ({ ...state, truck: keep ? state.truck : null })),
  on(fromActions.getTruckSuccess, (state, action: any) => ({ ...state, truck: action.truck })),

  on(fromActions.deleteTruckSuccess, (state, action: any) => ({ ...state, deletedTruckId: action.truckId })),

  on(fromActions.createTruckScheduleSuccess, (state, action: any) => ({ ...state, schedule: action.schedule })),
  on(fromActions.updateTruckScheduleSuccess, (state, action: any) => ({ ...state, schedule: action.schedule })),

  on(fromActions.getTruckSchedules, (state) => ({ ...state, schedules: [] })),

  on(fromActions.getTruckSchedulesSuccess, (state, action: any) => ({ ...state, schedules: action.schedules })),

  on(fromActions.getTruckScheduleSuccess, (state, action: any) => ({ ...state, schedule: action.schedule })),

  on(fromActions.deleteTruckScheduleSuccess, (state, action: any) => ({ ...state, deletedScheduleId: action.scheduleId })),
);

export function trucksReducer(state: TrucksState | undefined, action: Action) {
  return reducer(state, action);
}
