import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, Environment } from '@domains';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UploadFileService {
  public constructor(
    @Inject(ENVIRONMENT) private config: Environment,
    private http: HttpClient,
  ) {}

  uploadFile(file: any, keepName = false): Observable<{ url: string }> {
    const formData = new FormData();
    formData.append('file', file);
    if (keepName) {
      formData.append('keep_name', keepName.toString());
    }
    return this.http.post(this.config.urls.baseUrl + '/files', formData).pipe(
      map((f: any) => {
        return f;
      }),
    );
  }

  convertDataUrlToFile(dataUrl: string): File {
    const arr = dataUrl.split(',');
    const tmp = arr[0].match(/:(.*?);/);
    const mime = tmp ? tmp[1] : '';
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([new Blob([u8arr], { type: mime })], 'signature.png', { type: `image/png` });
  }
}
