import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogRef } from '@angular/material/dialog';

import { FxFlexDirective, FxLayoutAlignDirective, FxLayoutDirective, FxLayoutGapDirective } from '../../flex-layout';

@Component({
  selector: 'rspl-categorize-help-dialog',
  templateUrl: './discount-info-dialog.component.html',
  styleUrls: ['./discount-info-dialog.component.scss'],
  imports: [FxLayoutDirective, FxLayoutGapDirective, FxFlexDirective, MatDialogActions, FxLayoutAlignDirective],
})
export class DiscountInfoDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public reference: MatDialogRef<DiscountInfoDialogComponent>,
  ) {}
}
