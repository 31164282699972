import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppType, ENVIRONMENT, Environment } from '@domains';
import { LocalStorageService } from '@rspl-api';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

import { RSPL_APP_VERSION, RSPL_VERSION_CONFIG, RsplVersionConfig } from '../rspl-version-config';
import { VersionWarningComponent } from './public-api';

@Injectable({
  providedIn: 'root',
})
export class VersionCheckService {
  // this will be replaced by actual hash post-build.js
  private currentHash: string;
  private url = window.location.origin;
  private dialogRef?: MatDialogRef<VersionWarningComponent>;

  lastCheck?: number;

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private localStorage: LocalStorageService,
    @Inject(RSPL_VERSION_CONFIG) private config: RsplVersionConfig,
    @Inject(ENVIRONMENT) private environment: Environment,
  ) {
    this.currentHash = this.config.currentHash;
  }

  public initVersionCheck(frequency = this.environment.versionCheckInterval): Observable<boolean> {
    if (this.environment.production && this.environment.app !== AppType.TAX_RECEIPT)
      return this.checkVersion().pipe(
        tap(() => {
          setInterval(() => {
            this.checkVersion().pipe(take(1)).subscribe();
          }, frequency);
        }),
      );
    else return of(true);
  }

  public checkVersion(): Observable<boolean> {
    // timestamp these requests to invalidate caches
    return this.http.get(this.url + '/version.json?t=' + new Date().getTime()).pipe(
      switchMap((response: any) => {
        const hash = response.hash;
        this.localStorage.setItem(RSPL_APP_VERSION, response.version);
        const hashChanged = this.hasHashChanged(this.currentHash, hash);
        // If new version, do something
        if (hashChanged) {
          return this.versionChanged().pipe(
            map((confirmed) => {
              if (confirmed) {
                window.location.reload();
                location.reload();
                throw Error();
              }
              this.dialogRef = undefined;
              this.lastCheck = new Date().getTime();
              return true;
            }),
          );
        } else if (this.dialogRef) {
          this.dialogRef.close(false);
        }
        this.dialogRef = undefined;
        this.lastCheck = new Date().getTime();
        return of(true);
      }),
      take(1),
    );
  }

  private hasHashChanged(currentHash: string, newHash: string): boolean {
    if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
      return false;
    }
    return currentHash !== newHash;
  }

  private versionChanged(): Observable<any> {
    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(VersionWarningComponent, {
        width: '500px',
      });
    }
    return this.dialogRef.afterClosed();
  }
}
