// tslint:disable: max-line-length
import { Donation, HistoryEvent, Lead, Pricing } from '@domains';
import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './donation.actions';

export interface DonationState {
  donations: Array<Donation | Lead>;
  donationsTotalCount: number;
  pendingDonationsCount: number;
  donation: Donation | Lead;
  pricing: Pricing;
  history: Array<HistoryEvent>;
  declinedHistory: Array<HistoryEvent>;
  paymentReceipt: string;
}

const initialState: DonationState = {
  donations: new Array<Donation>(),
  donationsTotalCount: 0,
  pendingDonationsCount: 0,
  donation: undefined,
  pricing: null,
  history: new Array<HistoryEvent>(),
  declinedHistory: new Array<HistoryEvent>(),
  paymentReceipt: '',
};

const reducer = createReducer(
  initialState,

  on(fromActions.clearDonations, (state) => {
    return { ...state, donations: [], donationsTotalCount: 0 };
  }),
  on(fromActions.getDonations, (state, { keep }) => {
    return { ...state, donations: keep === true ? state.donations : [] };
  }),
  on(fromActions.getLeads, (state) => {
    return { ...state, donationsTotalCount: 0, donations: [] };
  }),
  on(fromActions.getDonationsSuccess, (state, { resultsCount, results }) => ({
    ...state,
    donationsTotalCount: resultsCount,
    donations: results,
  })),
  on(fromActions.setPendingDonationsCount, (state, { count }) => ({
    ...state,
    pendingDonationsCount: count,
  })),
  on(fromActions.getDonation, (state) => ({ ...state, donation: null })),
  on(fromActions.getLead, (state) => ({ ...state, donation: null })),
  on(fromActions.getDonationSuccess, (state, action: any) => ({ ...state, donation: action.donation })),
  on(fromActions.getDonationByCodeSuccess, (state, action: any) => ({ ...state, donation: action.donation })),
  on(fromActions.getPricingSuccess, (state, action: any) => ({ ...state, pricing: action.pricing })),
  on(fromActions.getHistorySuccess, (state, action: any) => ({ ...state, history: action.history })),
  on(fromActions.getDeclinedHistory, (state) => ({ ...state, declinedHistory: [] })),
  on(fromActions.getDeclinedHistorySuccess, (state, action: any) => ({ ...state, declinedHistory: action.history })),
  on(fromActions.getPaymentReceiptSuccess, (state, action: any) => ({ ...state, receiptUrl: action.url })),
);

export function donationReducer(state: DonationState | undefined, action: Action) {
  return reducer(state, action);
}
