import { autoserialize, autoserializeAs } from 'cerialize';

import { PartOfDay } from './part-of-day';

export class DonationStateByCodeDTO {
  @autoserializeAs('state_action') stateAction: string;
  @autoserialize gratuity?: number;
  @autoserializeAs('donor_signature') donorSignature?: string;
  @autoserialize date?: string;
  @autoserializeAs('part_of_day') partOfDay?: PartOfDay;
  @autoserializeAs('termination_reason') terminationReason?: string;
  @autoserializeAs('termination_note') terminationNote?: string;

  constructor(payload: IDonationByCodeState) {
    this.stateAction = payload?.stateAction;
    this.gratuity = payload?.gratuity;
    this.donorSignature = payload?.donorSignature;
    this.date = payload?.date;
    this.partOfDay = payload?.partOfDay;
    this.terminationReason = payload?.terminationReason;
    this.terminationNote = payload?.terminationNote;
  }
}

export interface IDonationByCodeState {
  stateAction: string;
  gratuity?: number;
  donorSignature?: string;
  date?: string;
  partOfDay?: PartOfDay;
  terminationReason?: string;
  terminationNote?: string;
}
