import { Component, HostBinding, Input, output } from '@angular/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import * as moment from 'moment';

import { FxFlexDirective } from '../flex-layout';

@Component({
  selector: 'rspl-date-picker',
  templateUrl: './date-picker.component.html',
  imports: [MatFormField, FxFlexDirective, MatLabel, MatInput, MatDatepickerInput, MatDatepickerToggle, MatSuffix, MatDatepicker],
})
export class DatePickerComponent {
  @HostBinding('style.display') display = 'flex';
  @Input() title?: string;
  @Input() dateToEdit?: string;
  readonly formattedDate = output<string>();
  @Input() disabled = false;

  public onDateSelected(e: { value: string }): void {
    this.formattedDate.emit(moment(e.value).format('YYYY-MM-DD'));
  }
}
