import { Injectable } from '@angular/core';
import { Partner } from '@domains';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PartnerService } from '@rspl-api';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as fromActions from './partner.actions';

@Injectable()
export class PartnerEffects {
  getPartners = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getPartners),
      switchMap((params) =>
        this.service.filter(params.findParams).pipe(
          map((results) => {
            return fromActions.getPartnersSuccess({
              resultsCount: results.totalResults,
              results: results.results.map((p) => new Partner(p)),
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  getPartner = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getPartner),
      switchMap((params) =>
        this.service.find(params.id, (params.expand?.length || 0) > 0, params.expand).pipe(
          map((result) => {
            return fromActions.getPartnerSuccess({
              partner: new Partner(result),
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  createPartner = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.createPartner),
      switchMap((payload) => {
        return this.service.create(payload.partner).pipe(
          map((result) => {
            return fromActions.createPartnerSuccess({
              partner: result,
            });
          }),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  updatePartner = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updatePartner),
      switchMap((payload) =>
        this.service.update(payload.partner.id, payload.partner).pipe(
          map((result) => {
            return fromActions.updatePartnerSuccess({
              partner: result,
            });
          }),
          catchError(() => [fromActions.updatePartnerFail()]),
        ),
      ),
    );
  });

  deletePartner = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.deletePartner),
      switchMap((params) =>
        this.service.delete(params.partnerId).pipe(
          map(() => {
            return fromActions.deletePartnerSuccess({
              partnerId: params.partnerId,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  restorePartner = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.restorePartner),
      switchMap((params) =>
        this.service.restore(params.partnerId).pipe(
          map(() => {
            return fromActions.restorePartnerSuccess({
              partnerId: params.partnerId,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  getPartnersForFeatureFlags = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getPartnersForFeatureFlags),
      switchMap((params) =>
        this.service.filter(params.findParams).pipe(
          map((results) => {
            return fromActions.getPartnersForFeatureFlagsSuccess({
              resultsCount: results.totalResults,
              results: results.results.map((p) => new Partner(p)),
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  updateForFeatureFlagsPartner = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updateForFeatureFlagsPartner),
      switchMap((payload) =>
        this.service.update(payload.partner.id, payload.partner).pipe(
          map((result) => {
            return fromActions.updateForFeatureFlagsPartnerSuccess({
              partner: result,
            });
          }),
          catchError(() => [
            fromActions.updateForFeatureFlagsPartnerFail({
              partner: payload.partner,
            }),
          ]),
        ),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private service: PartnerService,
  ) {}
}
