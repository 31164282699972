import { Component, Input, output, viewChild } from '@angular/core';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { Day } from '@domains';
import { CardComponent, WeekPickerComponent } from '@rspl-ui';
import * as moment from 'moment';

import { ScheduleConfig } from '../../components/schedule/schedule-config';
import { ScheduleFormComponent } from '../../components/schedule/schedule-form/schedule-form.component';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  imports: [CardComponent, MatTabGroup, MatTab, WeekPickerComponent, ScheduleFormComponent],
})
export class ScheduleComponent {
  @Input() config: ScheduleConfig;
  @Input() showMap = true;
  @Input() day: Day;
  readonly weekPicker = viewChild(WeekPickerComponent);
  readonly schedule = viewChild(ScheduleFormComponent);
  isDefault = true;
  readonly scheduleSaved = output();

  selectedIndexChange(index: number): void {
    if (index === 0) {
      this.isDefault = true;
      this.schedule().fetchData();
    } else {
      this.isDefault = false;
      this.schedule().fetchData(moment().week(), moment().year());
    }
  }
}
