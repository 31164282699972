import { CdkScrollable } from '@angular/cdk/scrolling';
import { Component, Inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { FxFlexDirective, FxLayoutAlignDirective, FxLayoutDirective } from '@rspl-ui';

import { ColumnConfig } from '../table/table-config';

@Component({
  selector: 'rspl-column-config',
  templateUrl: './column-config.component.html',
  styleUrls: ['./column-config.component.scss'],
  imports: [
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    FxLayoutDirective,
    FxFlexDirective,
    MatCheckbox,
    MatDialogActions,
    FxLayoutAlignDirective,
    MatButton,
  ],
})
export class ColumnConfigComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { config: { [key: string]: ColumnConfig }; columns: { field: string; index: number }[] },
    public reference: MatDialogRef<ColumnConfigComponent>,
  ) {}
}
