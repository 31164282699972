<ng-template #Estimate>
  <table class="static-table full-width {{ app }}" cellspacing="0">
    <thead>
      <tr>
        <th>Items</th>
        <th [ngStyle]="{ 'text-align': 'center' }">Quantity</th>
        @if (showPrices && showUnitPrices) {
          <th style="text-align: right">Unit price</th>
        }
        @if (showPrices) {
          <th style="text-align: right">Price</th>
        }
      </tr>
    </thead>
    <tbody>
      @if (showPrices) {
        <tr>
          <td>Base price</td>
          <td [ngStyle]="{ 'text-align': 'center' }">1</td>
          @if (showPrices && showUnitPrices) {
            <td style="text-align: right">
              {{ pricing.base | currency }}
            </td>
          }
          @if (showPrices) {
            <td style="text-align: right">
              {{ pricing.base | currency }}
            </td>
          }
        </tr>
      }
      @if (showZeros || initialSpecification.xlarge > 0) {
        <tr>
          <td>Extra Large items</td>
          <td [ngStyle]="{ 'text-align': 'center' }">
            @if (!editable) {
              <span>
                {{ specification.xlarge }}
              </span>
            }
            @if (editable) {
              <input
                type="tel"
                [(ngModel)]="specification.xlarge"
                (keyup)="specification.xlarge = checkValue($event); onKeyUp()"
                (focus)="focusInput($event)"
                (change)="updatePrice()"
              />
            }
          </td>
          @if (showPrices && showUnitPrices) {
            <td style="text-align: right">
              {{ pricing.xlarge | currency }}
            </td>
          }
          @if (showPrices) {
            <td style="text-align: right">
              {{ specification.xlarge * pricing.xlarge | currency }}
            </td>
          }
        </tr>
      }
      @if (showZeros || initialSpecification.large > 0) {
        <tr>
          <td>Large items</td>
          <td [ngStyle]="{ 'text-align': 'center' }">
            @if (!editable) {
              <span>
                {{ specification.large }}
              </span>
            }
            @if (editable) {
              <input
                type="tel"
                [(ngModel)]="specification.large"
                (keyup)="specification.large = checkValue($event); onKeyUp()"
                (focus)="focusInput($event)"
                (change)="updatePrice()"
              />
            }
          </td>
          @if (showPrices && showUnitPrices) {
            <td style="text-align: right">
              {{ pricing.large | currency }}
            </td>
          }
          @if (showPrices) {
            <td style="text-align: right">
              {{ specification.large * pricing.large | currency }}
            </td>
          }
        </tr>
      }
      @if (showZeros || initialSpecification.medium > 0) {
        <tr>
          <td>Medium items</td>
          <td [ngStyle]="{ 'text-align': 'center' }">
            @if (!editable) {
              <span>
                {{ specification.medium }}
              </span>
            }
            @if (editable) {
              <input
                type="tel"
                [(ngModel)]="specification.medium"
                (keyup)="specification.medium = checkValue($event); onKeyUp()"
                (focus)="focusInput($event)"
                (change)="updatePrice()"
              />
            }
          </td>
          @if (showPrices && showUnitPrices) {
            <td style="text-align: right">
              {{ pricing.medium | currency }}
            </td>
          }
          @if (showPrices) {
            <td style="text-align: right">
              {{ specification.medium * pricing.medium | currency }}
            </td>
          }
        </tr>
      }
      @if (
        showZeros ||
        initialSpecification.small > 0 ||
        (leadOrDonation.discount?.valid && leadOrDonation.discount?.maxSmallSpecification > 0)
      ) {
        <tr>
          <td>Bags/boxes of clothes/housewares</td>
          <td [ngStyle]="{ 'text-align': 'center' }">
            @if (!editable) {
              <span>
                {{ specification.small }}
              </span>
            }
            @if (editable) {
              <input
                type="tel"
                [(ngModel)]="specification.small"
                (keyup)="specification.small = checkValue($event); onKeyUp()"
                (focus)="focusInput($event)"
                (change)="updatePrice()"
              />
            }
          </td>
          @if (showPrices && showUnitPrices) {
            <td style="text-align: right">
              {{ pricing.small | currency }}
            </td>
          }
          @if (showPrices) {
            <td style="text-align: right">
              {{ specification.small * pricing.small | currency }}
            </td>
          }
        </tr>
      }
      @if (showZeros || initialSpecification.staircases > 0) {
        <tr>
          <td>Staircases</td>
          <td [ngStyle]="{ 'text-align': 'center' }">
            @if (!editable) {
              <span>
                {{ specification.staircases }}
              </span>
            }
            @if (editable) {
              <input
                type="tel"
                [(ngModel)]="specification.staircases"
                (keyup)="specification.staircases = checkValue($event); onKeyUp()"
                (focus)="focusInput($event)"
                (change)="updatePrice()"
              />
            }
          </td>
          @if (showPrices && showUnitPrices) {
            <td style="text-align: right">
              {{ pricing.staircases | currency }}
            </td>
          }
          @if (showPrices) {
            <td style="text-align: right">
              {{ specification.staircases * pricing.staircases | currency }}
            </td>
          }
        </tr>
      }
      @if (pricing.elevator !== null && (showZeros || initialSpecification.elevator > 0 || specification.elevator > 0)) {
        <tr>
          <td>Elevator</td>
          <td [ngStyle]="{ 'text-align': 'center' }">
            @if (!editable) {
              <span>
                {{ specification.elevator > 0 ? 1 : 0 }}
              </span>
            }
            @if (editable) {
              <input
                type="tel"
                [(ngModel)]="specification.elevator"
                (keyup)="specification.elevator = checkValue($event); onKeyUp()"
                (focus)="focusInput($event)"
                (change)="updatePrice()"
              />
            }
          </td>
          @if (showPrices && showUnitPrices) {
            <td style="text-align: right">
              {{ specification.elevator * pricing.elevator | currency }}
            </td>
          }
          @if (showPrices) {
            <td style="text-align: right">
              {{ specification.elevator * pricing.elevator | currency }}
            </td>
          }
        </tr>
      }
      @if (showZeros || initialSpecification.disassembly > 0) {
        <tr>
          <td>Disassembly</td>
          <td [ngStyle]="{ 'text-align': 'center' }">
            @if (!editable) {
              <span>
                {{ specification.disassembly }}
              </span>
            }
            @if (editable) {
              <input
                type="tel"
                [(ngModel)]="specification.disassembly"
                (keyup)="specification.disassembly = checkValue($event); onKeyUp()"
                (focus)="focusInput($event)"
                (change)="updatePrice()"
              />
            }
          </td>
          @if (showPrices && showUnitPrices) {
            <td style="text-align: right">
              {{ pricing.disassembly | currency }}
            </td>
          }
          @if (showPrices) {
            <td style="text-align: right">
              {{ specification.disassembly * pricing.disassembly | currency }}
            </td>
          }
        </tr>
      }
      @if (showZeros || specification.recycling > 0) {
        <tr>
          <td>Recycling</td>
          <td [ngStyle]="{ 'text-align': 'center' }">
            @if (!editable) {
              <span>
                {{ specification.recycling > 0 ? 1 : 0 }}
              </span>
            }
            @if (editable) {
              <input
                type="tel"
                [(ngModel)]="specification.recycling"
                (keyup)="specification.recycling = checkValue($event); onKeyUp()"
                (focus)="focusInput($event)"
                (change)="updatePrice()"
              />
            }
          </td>
          @if (showPrices && showUnitPrices) {
            <td style="text-align: right">
              {{ specification.recycling * pricing.recycling | currency }}
            </td>
          }
          @if (showPrices) {
            <td style="text-align: right">
              {{ specification.recycling * pricing.recycling | currency }}
            </td>
          }
        </tr>
      }
      @if (pricing.fuelFee || 0 > 0) {
        <tr>
          <td>Fuel Fee</td>
          @if (!showPrices || showUnitPrices) {
            <td></td>
          }
          @if (showPrices) {
            <td [ngStyle]="{ 'text-align': showUnitPrices ? 'right' : 'center' }">{{ pricing.fuelFee }}%</td>
          }
          @if (showPrices) {
            <td style="text-align: right">
              {{ fuelFee | currency }}
            </td>
          }
        </tr>
      }
      @if (showBookingFee === 'regular' && bookingFee) {
        <tr>
          @if (showUnitPrices) {
            <td colspan="3" [innerHTML]="bookingLabel"></td>
          }
          @if (!showUnitPrices) {
            <td colspan="2" [innerHTML]="bookingLabel"></td>
          }
          <td style="text-align: right">
            {{ bookingFee || 0 | currency }}
          </td>
        </tr>
      }
      @if (showPrices && gratuity !== undefined && gratuity > 0) {
        <tr>
          <td>Tip</td>
          @if (showUnitPrices) {
            <td style="text-align: right"></td>
          }
          <td style="text-align: right"></td>
          <td style="text-align: right">{{ gratuity | currency }}</td>
        </tr>
      }
      @if (showPrices && !collapsable && !showDiscount) {
        <tr class="total" class="primary-row top-border-row">
          @if (showUnitPrices) {
            <td colspan="3">{{ totalLabel }}</td>
          }
          @if (!showUnitPrices) {
            <td colspan="2">{{ totalLabel }}</td>
          }
          <td style="text-align: right">
            {{ (donationPrice || 0) + (includeBookingInTotal ? bookingFee || 0 : 0) | currency }}
          </td>
        </tr>
      }
      @if (showDiscount) {
        <tr class="total" class="primary-row top-border-row">
          @if (!showUnitPrices) {
            <td class="spaced-row" colspan="1">
              {{ totalLabel }}
              <img (click)="showDiscountInfoDialog()" class="discount-info-ic" src="/assets/images/info.svg" />
            </td>
          }
          @if (isCalculating) {
            <td colspan="2" style="text-align: right">
              <div fxLayout="row" fxLayoutAlign="end center">
                <mat-spinner [diameter]="20" />
              </div>
            </td>
          }
          @if (!isCalculating) {
            <td [attr.colspan]="showUnitPrices ? 4 : 2" style="text-align: right">
              <span>
                {{ (donationPrice || 0) - sponsoredAmount | currency }}
              </span>
              <span class="discounted-value">
                {{ donationPrice || 0 | currency }}
              </span>
            </td>
          }
        </tr>
      }
      @if (showBookingFee === 'title' && bookingFee) {
        <tr class="total" class="primary-row">
          @if (showUnitPrices) {
            <td colspan="3" [innerHTML]="bookingLabel"></td>
          }
          @if (!showUnitPrices) {
            <td colspan="2" [innerHTML]="bookingLabel"></td>
          }
          <td style="text-align: right">
            {{ bookingFee || 0 | currency }}
          </td>
        </tr>
      }
      @if (showBookingFee === 'title' && bookingFee) {
        <tr class="total" class="primary-row top-border-row bolded-row">
          @if (showUnitPrices) {
            <td colspan="3">Total Cost of Service</td>
          }
          @if (!showUnitPrices) {
            <td colspan="2">Total Cost of Service</td>
          }
          @if (isCalculating) {
            <td style="text-align: right">
              <div fxLayout="row" fxLayoutAlign="end center">
                <mat-spinner [diameter]="20" />
              </div>
            </td>
          } @else {
            <td style="text-align: right">
              {{ (donationPrice || 0) - sponsoredAmount + (bookingFee || 0) | currency }}
            </td>
          }
        </tr>
      }
      @if (showTodaysCharges) {
        <tr class="total" class="success-row">
          @if (showUnitPrices) {
            <td colspan="3">Today's Charges</td>
          }
          @if (!showUnitPrices) {
            <td colspan="2">Today's Charges</td>
          }
          <td style="text-align: right">
            {{ bookingFee || 0 | currency }}
          </td>
        </tr>
      }
    </tbody>
  </table>
</ng-template>
@if (!collapsable) {
  <ng-container [ngTemplateOutlet]="Estimate"></ng-container>
}
@if (collapsable) {
  <mat-accordion>
    <mat-expansion-panel #expansionPanel [hideToggle]="true" [disabled]="disabled">
      <mat-expansion-panel-header>
        <mat-panel-title fxLayout="column" fxLayoutAlign="center">
          <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span>{{ totalLabel }}:</span>
            <span fxLayout="row">
              @if (!disabled) {
                <div fxFlex fxLayout="row" fxLayoutAlign="center start">
                  <mat-icon>{{ expansionPanel.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
                </div>
              }
              {{ donationPrice || 0 | currency }}
            </span>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container [ngTemplateOutlet]="Estimate"></ng-container>
    </mat-expansion-panel>
  </mat-accordion>
}
