import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AvailabilityStats, ENVIRONMENT, Environment, Partner, Truck } from '@domains';
import { Deserialize } from 'cerialize';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseScheduleApiService } from '../base-schedule-api.service';

@Injectable({
  providedIn: 'root',
})
export class TrucksService extends BaseScheduleApiService<Truck> {
  constructor(
    @Inject(ENVIRONMENT) override config: Environment,
    override http: HttpClient,
  ) {
    super(config, http, 'vehicles', Truck, 'Vehicle', [], ['partner']);
  }

  override deserialize(data: any): Truck {
    const res = new Truck(
      Deserialize(
        {
          ...data,
          partner: data.partner ? new Partner(Deserialize(data.partner, Partner)) : data.partner,
        },
        Truck,
      ),
    );
    res.setInitialValue();
    return res;
  }

  getAvailableTrucks(params: any): Observable<AvailabilityStats[]> {
    return this.http.get<AvailabilityStats[]>(this.config.urls.baseUrl + `/vehicles/availability`, { params }).pipe(
      map((response: any) => {
        return response?.map((r: any) => new AvailabilityStats(Deserialize(r, AvailabilityStats)));
      }),
    );
  }
}
