import { createAction, props } from '@ngrx/store';

export enum TrpActionType {
  GetTrps = '[Trp] Get Trps',
}

export const getTrps = createAction(
  TrpActionType.GetTrps,
  props<{
    findParams: {
      date: string;
      trucks: string[];
      markets: string[];
      stores: string[];
      partnerState: [];
      donorState: [];
      charity: null;
      partner: null;
    };
  }>(),
);
