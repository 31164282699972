import { Action, createReducer, on } from '@ngrx/store';
import * as moment from 'moment';

import * as fromActions from './trp.actions';

export interface TrpsState {
  findParams: {
    date: string;
    trucks: string[];
    markets: string[];
    stores: string[];
    partnerState: [];
    donorState: [];
    charity: null;
    partner: null;
  };
}

const initialState: TrpsState = {
  findParams: localStorage.getItem('TrpFindParams')
    ? JSON.parse(localStorage.getItem('TrpFindParams'))
    : {
        date: moment().format('YYYY-MM-DD'),
        trucks: [],
        markets: [],
        stores: [],
        partnerState: [],
        donorState: [],
        charity: null,
        partner: null,
      },
};

const reducer = createReducer(
  initialState,

  on(fromActions.getTrps, (state, { findParams }) => {
    localStorage.setItem('TrpFindParams', JSON.stringify(findParams));
    return { ...state, findParams };
  }),
);

export function trpReducer(state: TrpsState | undefined, action: Action) {
  return reducer(state, action);
}
