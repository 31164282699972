<h2 mat-dialog-title>{{ data.title }}</h2>
<div mat-dialog-content>
  <p [innerHTML]="data.message"></p>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end">
  @if (!data.hideNo) {
    <button mat-raised-button (click)="onChoice(false)">{{ data.no || 'No' }}</button>
  }
  <button mat-raised-button (click)="onChoice(true)" [color]="data.color">{{ data.yes || 'Yes' }}</button>
</div>
