import { Component, viewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import {
  FxFlexDirective,
  FxLayoutDirective,
  FxLayoutGapDirective,
  HISTORY_CONFIGURATION,
  HistoryComponent,
  HistoryConfig,
  HistoryEntity,
} from '@rspl-ui';

import { BaseContainerComponent } from '../base-container/base-container.component';

@Component({
  selector: 'app-history-page',
  templateUrl: './history-page.component.html',
  styleUrls: ['./history-page.component.scss'],
  imports: [
    BaseContainerComponent,
    FormsModule,
    FxLayoutDirective,
    FxLayoutGapDirective,
    ReactiveFormsModule,
    FxFlexDirective,
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    MatInput,
    MatButton,
    HistoryComponent,
  ],
})
export class HistoryPageComponent {
  config?: HistoryConfig;
  readonly history = viewChild(HistoryComponent);

  entities = Object.values(HistoryEntity);
  form = new FormGroup({
    entity: new FormControl<HistoryEntity | null>(null, Validators.required),
    id: new FormControl<string | null>(null, Validators.required),
  });

  fetch() {
    if (this.id)
      this.config = {
        itemIds: [this.id],
        ...HISTORY_CONFIGURATION[this.entity],
      };
  }

  get entity(): HistoryEntity {
    return this.form.get('entity')?.value as HistoryEntity;
  }

  get id(): string | null | undefined {
    return this.form.get('id')?.value;
  }
}
