import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UsersService } from '@rspl-api';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as fromActions from './user.actions';

@Injectable()
export class UsersEffects {
  getUsers = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getUsers),
      switchMap((params) =>
        this.service.filter(params.findParams).pipe(
          map((results) => {
            return fromActions.getUsersSuccess({
              resultsCount: results.totalResults,
              results: results.results,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  getUser = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getUser),
      switchMap((params) =>
        this.service.find(params.id).pipe(
          map((result) => {
            return fromActions.getUserSuccess({
              user: result,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  createUser = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.createUser),
      switchMap((payload) => {
        return this.service.create(payload.user).pipe(
          map((result) => {
            return fromActions.createUserSuccess({
              user: result,
            });
          }),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  updateUser = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updateUser),
      switchMap((payload) => {
        return this.service.update(payload.user.id, payload.user).pipe(
          map((result) => {
            return fromActions.updateUserSuccess({
              user: result,
            });
          }),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  deleteUser = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.deleteUser),
      switchMap((params) =>
        this.service.delete(params.userId).pipe(
          map(() => {
            return fromActions.deleteUserSuccess({
              userId: params.userId,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  restoreUser = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.restoreUser),
      switchMap((params) =>
        this.service.restore(params.userId).pipe(
          map(() => {
            return fromActions.restoreUserSuccess({
              userId: params.userId,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private service: UsersService,
  ) {}
}
