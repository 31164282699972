<div fxLayout="row wrap" class="photos" fxFlex="100">
  <div class="upload" fxFlex="50">
    <button class="primary-btn block-btn" (click)="upload()">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
        <span>Add Photo</span>
        <mat-icon>camera_alt</mat-icon>
      </div>
    </button>
  </div>
  @for (photo of photos; track photo) {
    <div fxFlex="50" fxLayoutAlign="center center">
      <div>
        <img [src]="photo" (click)="select(photo)" />
      </div>
    </div>
  }
</div>
