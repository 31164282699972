import { createFeatureSelector, createSelector } from '@ngrx/store';

import { StoresState } from './charity-store.reducers';

// tslint:disable: max-line-length
export const storesFeatureStateSelector = createFeatureSelector<StoresState>('store');

export const selectStores = createSelector(storesFeatureStateSelector, (state: StoresState) => state.stores);
export const selectCharityStores = createSelector(storesFeatureStateSelector, (state: StoresState) => state.charityStores);
export const selectStateStores = createSelector(storesFeatureStateSelector, (state: StoresState) => state.stateStores);
export const selectClosestStores = createSelector(storesFeatureStateSelector, (state: StoresState) => state.closestStores);
export const selectStoresTotalCount = createSelector(storesFeatureStateSelector, (state: StoresState) => state.storesTotalCount);
export const selectStoreSchedule = createSelector(storesFeatureStateSelector, (state: StoresState) => state.schedules);

export const selectStore = createSelector(storesFeatureStateSelector, (state: StoresState) => state.store);
export const selectStoreAvailabilityStats = createSelector(storesFeatureStateSelector, (state: StoresState) => state.availabilityStats);
