import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';

import { LoaderInterceptorService } from './loader';

export const provideRsplUIProviders = (): EnvironmentProviders => {
  return makeEnvironmentProviders([
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true,
    },
    NgxImageCompressService,
  ]);
};
