import { DatePipe } from '@angular/common';
import { Component, output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { FxFlexDirective, FxLayoutAlignDirective, FxLayoutDirective } from '@rspl-ui';
import * as moment from 'moment';

@Component({
  selector: 'rspl-week-picker',
  templateUrl: './week-picker.component.html',
  styleUrls: ['./week-picker.component.scss'],
  imports: [FxLayoutDirective, FxLayoutAlignDirective, MatIcon, FxFlexDirective, DatePipe],
})
export class WeekPickerComponent {
  public weekStart: any;
  public weekEnd: any;
  public week: number;
  public year: number;
  readonly weekChanged = output<{ weekStart: any; weekEnd: any }>();

  constructor() {
    const currentDate = moment();
    this.weekStart = currentDate.clone().startOf('week');
    this.weekEnd = currentDate.clone().endOf('week');
    this.week = this.weekStart.week();
    this.year = this.weekStart.weekYear();
  }

  moveWeek(step: number): void {
    this.weekStart = moment(this.weekStart).add(step * 7, 'days');
    this.weekEnd = moment(this.weekEnd).add(step * 7, 'days');
    this.week = this.weekStart.week();
    this.year = this.weekStart.weekYear();
    this.weekChanged.emit({
      weekStart: this.weekStart,
      weekEnd: this.weekEnd,
    });
  }
}
