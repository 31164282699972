import { Directive, HostListener, Input, output } from '@angular/core';

@Directive({
  selector: '[rsplDisableDoubleClick]',
  standalone: true,
})
export class DisableDoubleClickDirective {
  clicked = false;
  @Input()
  debounceTime = 600;

  readonly oneClick = output();

  @HostListener('click', ['$event'])
  clickEvent(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    if (!this.clicked) {
      this.clicked = true;
      this.oneClick.emit(event);
      setTimeout(() => (this.clicked = false), this.debounceTime);
    }
  }
}
