@if (showItem && pricing !== null) {
  <div class="estimate-item" fxLayout="row" fxLayoutAlign="space-between center">
    <span class="label">
      {{ isSmallWindowSize ? label.replaceAll('/', ' / ') : label }}
      @if ((isCaptain || isDonor) && showPricing) {
        <span class="price">({{ pricing | currency }})</span>
      }
    </span>
    <div fxLayout="row" fxLayoutAlign="end center">
      @if (!readonly) {
        <button
          type="button"
          mat-icon-button
          (click)="removeItem()"
          [disabled]="quantity === 0 || disabledIfItems || isCalculatingSponsorship"
        >
          <mat-icon>remove</mat-icon>
        </button>
      }
      @if (readonly) {
        <span class="value">
          {{ quantity }}
        </span>
      }
      @if (!readonly) {
        <input
          (input)="handleQuantityChange($event)"
          (focus)="focusInput($event)"
          [ngModel]="quantity"
          [disabled]="isCalculatingSponsorship || disabledIfItems"
          name="secification-item-value"
          matInput
          type="tel"
          class="item-quantity"
        />
      }
      @if (!readonly) {
        <button type="button" mat-icon-button (click)="addItem()" [disabled]="disabledIfItems || isCalculatingSponsorship">
          <mat-icon>add</mat-icon>
        </button>
      }
      @if ((isCaptain || isDonor) && pricing && showPrices) {
        <span class="price-value">
          {{ pricing * quantity | currency }}
        </span>
      }
    </div>
  </div>
}
