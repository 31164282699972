import { CurrencyPipe, DatePipe } from '@angular/common';
import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, provideMomentDateAdapter } from '@angular/material-moment-adapter';
// import { NGX_MAT_DATE_FORMATS, NgxMatDateAdapter, NgxMatDateFormats } from '@angular-material-components/datetime-picker';
// import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { ENVIRONMENT, Environment } from '@domains';
import { NgxCurrencyInputMode, provideEnvironmentNgxCurrency } from 'ngx-currency';
import { MapService } from 'ngx-mapbox-gl';
import { provideNgxMask } from 'ngx-mask';

// import { CustomNgxDatetimeAdapter } from './custom-ngx-datetime-adapter';
import { BreakLinePipe } from './pipes/break-line.pipe';
import { ParseContentPipe } from './pipes/parse-content.pipe';
import { reducerProvider } from './store/app.reducers';

export const provideCoreProviders = (environment: Environment): EnvironmentProviders => {
  return makeEnvironmentProviders([
    reducerProvider,
    CurrencyPipe,
    BreakLinePipe,
    ParseContentPipe,
    DatePipe,
    MapService,
    { provide: ENVIRONMENT, useValue: environment },
    { provide: MAT_DATE_LOCALE, useValue: 'en' },
    provideMomentDateAdapter(
      {
        parse: {
          dateInput: 'MM/DD/YYYY',
        },
        display: {
          dateInput: 'MM/DD/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
          monthLabel: 'MMM',
        },
      },
      { useUtc: true },
    ),
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true },
    },
    provideNgxMask(),
    provideEnvironmentNgxCurrency({
      align: 'left',
      allowNegative: false,
      allowZero: true,
      decimal: '.',
      precision: 2,
      prefix: '$ ',
      suffix: '',
      thousands: ',',
      nullable: false,
      min: 0,
      inputMode: NgxCurrencyInputMode.Natural,
    }),
  ]);
};
