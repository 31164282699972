import { Zone } from '@domains';
import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './zone.actions';

export interface ZoneState {
  zones: Array<Zone>;
  zonesTotalCount: number;
  zone: Zone;
  deletedZoneId: number;
}

const initialState: ZoneState = {
  zones: new Array<Zone>(),
  zonesTotalCount: 0,
  zone: new Zone(),
  deletedZoneId: 0,
};

const reducer = createReducer(
  initialState,

  on(fromActions.createZoneSuccess, (state, action: any) => ({ ...state, zone: action.zone })),
  on(fromActions.updateZoneSuccess, (state, action: any) => ({ ...state, zone: action.zone })),

  on(fromActions.getZone, (state) => ({ ...state, zipsList: [] })),
  on(fromActions.getZonesSuccess, (state, action: any) => ({ ...state, zones: action.zones })),

  on(fromActions.getZoneSuccess, (state, action: any) => ({ ...state, zone: action.zone })),

  on(fromActions.deleteZoneSuccess, (state, action: any) => ({ ...state, deletedZoneId: action.zoneId })),
);

export function zoneReducer(state: ZoneState | undefined, action: Action) {
  return reducer(state, action);
}
