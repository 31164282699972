import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UsersState } from './user.reducers';

// tslint:disable: max-line-length
export const usersFeatureStateSelector = createFeatureSelector<UsersState>('user');

export const selectUsers = createSelector(usersFeatureStateSelector, (state: UsersState) => state.users);
export const selectUsersTotalCount = createSelector(usersFeatureStateSelector, (state: UsersState) => state.usersTotalCount);

export const selectUser = createSelector(usersFeatureStateSelector, (state: UsersState) => state.user);
