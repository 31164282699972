<div class="filters-wrapper" fxFlex="100">
  <mat-form-field class="form-field">
    <mat-icon matPrefix class="search-icon">search</mat-icon>
    <mat-label>Filter by property</mat-label>
    <input
      type="text"
      placeholder="Search..."
      matInput
      [formControl]="filterControl"
      [matAutocomplete]="auto"
      (change)="onInputChange($event)"
      (blur)="onBlur($event)"
    />
    <mat-autocomplete #auto="matAutocomplete">
      @for (option of filteredOptions | async; track option) {
        <mat-option (click)="selectFilter(option)" [value]="option" (mousedown)="$event.preventDefault()">{{ option }}</mat-option>
      }
    </mat-autocomplete>
    <mat-icon class="color-icon" matSuffix>keyboard_arrow_down</mat-icon>
    @if ((filterControl.value?.length || 0) > 0) {
      <mat-icon matSuffix (click)="clearSearch($event)">close</mat-icon>
    }
  </mat-form-field>
</div>
