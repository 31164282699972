import * as polyline from '@mapbox/polyline';
import { autoserialize, autoserializeAs } from 'cerialize';
import * as moment from 'moment';

import { BaseDTO, IBaseDTO } from './base-dto';
import { IJourneyCustomStop, JourneyCustomStop } from './journey-custom-stop';
import { IJourneyStop, JourneyStop } from './journey-stop';

export class Journey extends BaseDTO implements IJourney {
  @autoserialize id?: string;
  @autoserialize stops: Array<JourneyStop>;
  @autoserialize jobs: Array<JourneyCustomStop>;
  @autoserialize date?: string;
  @autoserialize duration?: number;
  @autoserialize distance?: number;
  @autoserialize optimizing?: boolean;
  @autoserializeAs('auto_optimize') autoOptimize: boolean;

  @autoserialize geometry?: string;
  geojson?: any;
  formattedDuration?: string;
  formattedDistance?: string;

  constructor(payload?: IJourney) {
    super(payload);
    this.id = payload?.id;
    this.stops = payload?.stops?.map((step) => new JourneyStop(step)) || [];
    this.jobs = payload?.jobs?.map((job) => new JourneyCustomStop(job)) || [];
    this.date = payload?.date;
    this.geometry = payload?.geometry;
    this.duration = payload?.duration;
    this.optimizing = payload?.optimizing;
    this.autoOptimize = payload?.autoOptimize;
    if (this.duration) {
      const tmp = moment.duration(payload?.duration, 'seconds');
      this.formattedDuration =
        (tmp.hours() >= 1 ? `${tmp.hours()}h ` : '') + `${Number(Math.round(tmp.minutes() / 10).toFixed(0)) * 10}min`;
    } else {
      this.formattedDuration = undefined;
    }
    this.distance = payload?.distance;
    if (this.distance) {
      const tmp = this.distance * 0.001;
      this.formattedDistance = tmp < 1 ? `${tmp.toFixed(2)}mi` : `${Math.round(tmp).toFixed(0)}mi`;
    } else {
      this.formattedDistance = '/';
    }
    if (this.geometry) {
      this.geojson = {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: polyline.decode(this.geometry).map((x) => [x[1], x[0]]) as [number, number][],
          },
        },
      };
    }
  }
}

export interface IJourney extends IBaseDTO {
  stops?: Array<IJourneyStop>;
  jobs?: Array<IJourneyCustomStop>;
  id?: string;
  date?: string;
  duration?: number;
  distance?: number;
  optimizing?: boolean;
  autoOptimize?: boolean;
  geometry?: string;
}
