import { SortDirection } from '@angular/material/sort';
import { Params } from '@angular/router';
import { FindParams, PageAction } from '@domains';
import { LocalStorageService } from '@rspl-api';
import { Observable } from 'rxjs';

import { RSPL_UI_SDK_TABLE_FIND_PARAMS } from './table.service';

export enum ColumnType {
  STRING = 'string',
  NUMBER = 'number',
  DECIMAL = 'decimal',
  DATE = 'date',
  DATE_TIME = 'dateTime',
  BOOLEAN = 'boolean',
  HTML = 'html',
  PHONE = 'phone',
  LINK = 'link',
  TOGGLE = 'toggle',
  SELECT = 'select',
  TEMPLATE = 'template',
  PRICE = 'price',
}

export class ColumnConfig {
  title?: string;
  index: number;
  type?: ColumnType;
  hidden?: boolean;
  value?: (x: any) => any;
  class?: string;
  sortable?: boolean;
  editable?: boolean;
  hideTitle?: boolean;
  description?: string;
  link?: (x: any) => string[];
  onChange?: (value: any, element: any) => void;
  disabled?: (x: any) => boolean;
  template?: string;
  templateContext?: (x: any) => any;
  selectConfig?: {
    placeholder: string;
    options: { value: any; text: string }[];
  };

  constructor(config?: ColumnConfig) {
    this.type = config?.type || ColumnType.STRING;
    this.hidden = config?.hidden || false;
    this.title = config?.title || '';
    this.value = config?.value;
    this.index = config?.index || 0;
    this.class = config?.class;
    this.description = config?.description;
    this.sortable = config?.sortable !== false;
    this.editable = config?.editable !== false;
    this.hideTitle = !!config?.hideTitle;
    this.link = config?.link;
    this.onChange = config?.onChange;
    this.disabled = config?.disabled;
    this.template = config?.template;
    this.templateContext = config?.templateContext;
    this.selectConfig = config?.selectConfig;
  }
}

export class ColumnAction {
  actionTitle: string;
  icon?: { name: string; svg?: boolean } | string;
  hidden?: (x?: any) => boolean;
  disabled?: (x?: any) => boolean;
  actionMethod?: (x: any) => void;
  link?: (x: any) => string[];
  queryParams?: (x: any) => Params;
  externalLink?: (x: any) => string;
  cssClass?: (x: any) => string;
  code?: string;

  constructor(action: ColumnAction) {
    this.actionTitle = action.actionTitle;
    this.icon = action.icon;
    this.hidden = action.hidden || (() => false);
    this.disabled = action.disabled || (() => false);
    this.actionMethod = action.actionMethod;
    this.link = action.link;
    this.queryParams = action.queryParams;
    this.externalLink = action.externalLink;
    this.cssClass = action.cssClass;
    this.code = action.actionTitle.replace(/\s/g, '-');
  }
}

export class SortConfig {
  field: string;
  direction: SortDirection;

  constructor(config?: SortConfig) {
    this.field = config?.field || 'created_at';
    this.direction = config?.direction || 'desc';
  }
}

export class PaginationConfig {
  pageIndex: number;
  pageSize: number;

  constructor(config?: PaginationConfig) {
    this.pageIndex = config?.pageIndex || 0;
    this.pageSize = config?.pageSize || 20;
  }
}

export class TableConfig {
  id: string;
  configId?: string;
  findAction: (findParams: FindParams) => void;
  listSelector: Observable<Array<any>>;
  countSelector: Observable<number>;
  columns: {
    [key: string]: ColumnConfig;
  };
  tableActions?: PageAction[];
  columnActions?: ColumnAction[];
  doubleClick?: (x: any) => void;
  refresh?: boolean;
  export?: boolean;
  configure?: boolean;
  hasFilters?: boolean;
  fixedFilters?: { [key: string]: any };
  localFilter?: (x: any) => boolean;
  sort?: SortConfig;
  pagination?: PaginationConfig;
  loadFilters?: boolean;
  pageSizes?: number[];
  expandable?: boolean;
  calculateHeight?: boolean;
  showPagination?: boolean;
  updateFilters?: boolean;

  constructor(config: ITableConfig, savedColumns: { [key: string]: ColumnConfig } = {}, localStorageService: LocalStorageService) {
    this.findAction = config?.findAction;
    this.listSelector = config?.listSelector;
    this.countSelector = config?.countSelector;
    this.doubleClick = config?.doubleClick;
    this.refresh = config?.refresh || false;
    this.export = config?.export || false;
    this.configure = config?.configure || !!config.id;
    this.hasFilters = config?.hasFilters || false;
    this.fixedFilters = config?.fixedFilters;
    this.pageSizes = config?.pageSizes || [20, 50, 100];
    this.showPagination = config?.showPagination !== false;
    this.columns = {};
    const combinedColumns = {
      ...(config.columns || []),
    };
    Object.keys(savedColumns || {}).forEach((k) => {
      if (combinedColumns[k]) {
        combinedColumns[k].index = savedColumns[k].index;
        combinedColumns[k].hidden = savedColumns[k].hidden;
      }
    });
    Object.keys(combinedColumns || {}).forEach((key) => {
      this.columns[key] = new ColumnConfig(combinedColumns[key]);
    });
    this.tableActions = config.tableActions?.map((x) => new PageAction(x));
    this.columnActions = config.columnActions?.map((x) => new ColumnAction(x));
    this.localFilter = config.localFilter;
    this.id = config.id;
    this.configId = TableConfig.getConfigId(config.id);
    const data = JSON.parse(localStorageService.getItem(RSPL_UI_SDK_TABLE_FIND_PARAMS) || '{}') || {};
    this.loadFilters = config.loadFilters !== false;
    let findParams;
    if (this.loadFilters) {
      findParams = data[this.configId] ? (data[this.configId] as FindParams) : null;
    }
    if (findParams) {
      this.sort = new SortConfig({
        field: findParams.order || 'created_at',
        direction: (findParams.order_direction || 'desc') as SortDirection,
      });
      this.pagination = new PaginationConfig({
        pageIndex: (findParams.page || 1) - 1,
        pageSize: findParams.per_page || 20,
      });
    } else {
      this.sort = new SortConfig(config?.sort);
      this.pagination = new PaginationConfig(config?.pagination);
    }
    this.expandable = config?.expandable;
    this.calculateHeight = config?.calculateHeight !== false;
    this.updateFilters = config?.updateFilters !== false;
  }

  public static getConfigId(id: string): string {
    return (
      id +
      '[' +
      window.location.pathname
        .replace('/', '')
        .replace(/\/\d*\/?/g, '_')
        .replace(/_$/, '') +
      ']'
    );
  }
}

export interface ITableConfig {
  id: string;
  export?: boolean;
  refresh?: boolean;
  configure?: boolean;
  hasFilters?: boolean;
  loadFilters?: boolean;
  expandable?: boolean;
  calculateHeight?: boolean;
  sort?: SortConfig;
  pagination?: PaginationConfig;
  pageSizes?: number[];
  findAction: (findParams: FindParams) => void;
  listSelector: Observable<Array<any>>;
  countSelector: Observable<number>;
  columns: {
    [key: string]: ColumnConfig;
  };
  columnActions?: ColumnAction[];
  doubleClick?: (x: any) => void;
  tableActions?: PageAction[];
  fixedFilters?: { [key: string]: any };
  localFilter?: (x: any) => any;
  showPagination?: boolean;
  updateFilters?: boolean;
}
