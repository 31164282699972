import { PhoneConfiguration } from '@domains';
import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './phone-configuration.actions';

export interface PhoneConfigurationState {
  phoneConfigurations: Array<PhoneConfiguration>;
  phoneConfigurationsTotalCount: number;
  phoneConfiguration: PhoneConfiguration;
  deletedPhoneConfigurationId: number;
}

const initialState: PhoneConfigurationState = {
  phoneConfigurations: new Array<PhoneConfiguration>(),
  phoneConfigurationsTotalCount: 0,
  phoneConfiguration: undefined,
  deletedPhoneConfigurationId: 0,
};

const reducer = createReducer(
  initialState,

  on(fromActions.createPhoneConfigurationSuccess, (state, action: any) => ({ ...state, phoneConfiguration: action.phoneConfiguration })),
  on(fromActions.updatePhoneConfigurationSuccess, (state, action: any) => ({ ...state, phoneConfiguration: action.phoneConfiguration })),

  on(fromActions.getPhoneConfiguration, (state) => ({ ...state, phoneConfiguration: undefined })),
  on(fromActions.getPhoneConfigurations, (state) => ({ ...state, phoneConfigurations: [] })),
  on(fromActions.getPhoneConfigurationsSuccess, (state, action: any) => ({
    ...state,
    phoneConfigurations: action.phoneConfigurations,
    phoneConfigurationsTotalCount: action.resultsCount,
  })),

  on(fromActions.getPhoneConfigurationSuccess, (state, action: any) => ({ ...state, phoneConfiguration: action.phoneConfiguration })),

  on(fromActions.deletePhoneConfigurationSuccess, (state, action: any) => ({
    ...state,
    deletedPhoneConfigurationId: action.phoneConfigurationId,
  })),
);

export function phoneConfigurationReducer(state: PhoneConfigurationState | undefined, action: Action) {
  return reducer(state, action);
}
