import { CdkScrollable } from '@angular/cdk/scrolling';
import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { YouTubePlayer } from '@angular/youtube-player';
import { FxLayoutAlignDirective, FxLayoutDirective } from '@rspl-ui';

import { Responsive, ResponsiveService } from '../../responsive';

@Component({
  selector: 'rspl-about-resupply-dialog',
  templateUrl: './about-resupply-dialog.component.html',
  styleUrl: './about-resupply-dialog.component.scss',
  imports: [
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    YouTubePlayer,
    MatDialogActions,
    FxLayoutDirective,
    FxLayoutAlignDirective,
    MatButton,
  ],
})
export class AboutResupplyDialogComponent extends Responsive {
  constructor(
    public override responsiveService: ResponsiveService,
    public reference: MatDialogRef<AboutResupplyDialogComponent>,
  ) {
    super(responsiveService);
  }

  get window() {
    return window;
  }
}
