import { autoserialize, autoserializeAs } from 'cerialize';

import { BaseDTO, IBaseDTO } from './base-dto';
import { Contact } from './contact';
import { IPartner, Partner } from './partner';
import { ITruckMeta, TruckMeta } from './truck-meta';

export class Truck extends BaseDTO implements ITruck {
  @autoserialize id?: string;
  @autoserializeAs('partner_id') partnerId?: string | null;
  @autoserializeAs('created_at') createdAt?: string | null;
  @autoserializeAs('updated_at') updatedAt?: string | null;
  @autoserializeAs('discarded_at') discardedAt?: string | null;
  @autoserializeAs('licence_plate') licensePlate: string;
  @autoserializeAs('vehicle_type') vehicleType: string;
  @autoserialize override name: string;
  @autoserialize make: string;
  @autoserialize model: string;
  @autoserialize size: string;
  @autoserialize meta?: TruckMeta | null;
  @autoserialize partner?: Partner | null;
  @autoserialize lat?: number | null;
  @autoserialize lng?: number | null;
  @autoserializeAs(Contact, 'driver_contact') driverContact?: Contact | null;

  constructor(payload?: ITruck) {
    super(payload);
    this.id = payload?.id?.toString();
    this.createdAt = payload?.createdAt;
    this.updatedAt = payload?.updatedAt;
    this.discardedAt = payload?.discardedAt;
    this.licensePlate = payload?.licensePlate || '';
    this.name = payload?.name || '';
    this.make = payload?.make || '';
    this.model = payload?.model || '';
    this.size = payload?.size || '';
    this.vehicleType = payload?.vehicleType || '';
    this.meta = payload?.meta ? new TruckMeta(payload.meta) : undefined;
    this.partner = payload?.partner ? new Partner(payload.partner) : undefined;
    this.lat = payload?.lat;
    this.lng = payload?.lng;
    this.partnerId = payload?.partnerId?.toString() || this.partner?.id;
    this.driverContact = payload?.driverContact ? new Contact(payload.driverContact) : undefined;
  }
}

export interface ITruck extends IBaseDTO {
  id?: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  licensePlate: string;
  name: string;
  make: string;
  model: string;
  size: string;
  vehicleType: string;
  meta?: ITruckMeta | null;
  partner?: IPartner | null;
  lat?: number | null;
  lng?: number | null;
  discardedAt?: string | null;
  partnerId?: string | null;
  driverContact?: Contact | null;
}
