import { Budget, FindParams } from '@domains';
import { createAction, props } from '@ngrx/store';

export enum BudgetActionType {
  CreateBudget = '[Budget] Create Budget',
  CreateBudgetSuccess = '[Budget] Create Budget Success',

  UpdateBudget = '[Budget] Update Budget',
  UpdateBudgetSuccess = '[Budget] Update Budget Success',
  UpdateBudgetFail = '[Budget] Update Budget Fail',

  ClearBudgetsFindParams = '[Charity] Clear Budgets Find Params',
  GetBudgets = '[Budget] Get Budgets',
  GetBudgetsSuccess = '[Budget] Get Budgets Success',

  GetBudget = '[Budget] Get Budget',
  GetBudgetSuccess = '[Budget] Get Budget Success',

  DeleteBudget = '[Budget] Delete Budget',
  DeleteBudgetSuccess = '[Budget] Delete Budget Success',

  RestoreBudget = '[Budget] Restore Budget',
  RestoreBudgetSuccess = '[Budget] Restore Budget Success',
}

export const createBudget = createAction(BudgetActionType.CreateBudget, props<{ budget: Budget }>());
export const createBudgetSuccess = createAction(BudgetActionType.CreateBudgetSuccess, props<{ budget: Budget }>());

export const updateBudget = createAction(BudgetActionType.UpdateBudget, props<{ budget: Budget }>());
export const updateBudgetSuccess = createAction(BudgetActionType.UpdateBudgetSuccess, props<{ budget: Budget }>());
export const updateBudgetFail = createAction(BudgetActionType.UpdateBudgetFail, props<{ budget: Budget }>());

export const clearBudgetsFindParams = createAction(BudgetActionType.ClearBudgetsFindParams);
export const getBudgets = createAction(BudgetActionType.GetBudgets, props<{ findParams: FindParams; keep?: boolean }>());
export const getBudgetsSuccess = createAction(
  BudgetActionType.GetBudgetsSuccess,
  props<{ budgets: Array<Budget>; resultsCount: number }>(),
);

export const getBudget = createAction(BudgetActionType.GetBudget, props<{ id: string; keep?: boolean; expand?: string[] }>());
export const getBudgetSuccess = createAction(BudgetActionType.GetBudgetSuccess, props<{ budget: Budget }>());

export const deleteBudget = createAction(BudgetActionType.DeleteBudget, props<{ budgetId: string }>());
export const deleteBudgetSuccess = createAction(BudgetActionType.DeleteBudgetSuccess, props<{ budgetId: string }>());

export const restoreBudget = createAction(BudgetActionType.RestoreBudget, props<{ budgetId: string }>());
export const restoreBudgetSuccess = createAction(BudgetActionType.RestoreBudgetSuccess, props<{ budgetId: string }>());
