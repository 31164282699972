import { AuthenticationModel, User } from '@domains';
import { createAction, props } from '@ngrx/store';

export enum OnboardingActionType {
  CreateAccount = '[Onboarding] Create Account',
  CreateAccountSuccess = '[Onboarding] Create Account Success',

  UpdateOnboardingStatus = '[Onboarding] Update onboarding status',
}

export const createAccount = createAction(OnboardingActionType.CreateAccount, props<{ user: User }>());
export const createAccountSuccess = createAction(OnboardingActionType.CreateAccountSuccess, props<{ user: AuthenticationModel }>());

export const updateOnboardingStatus = createAction(OnboardingActionType.UpdateOnboardingStatus, props<{ user: User }>());
