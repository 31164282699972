<mat-form-field appearance="outline" fxFlex="100" class="pick-charity">
  <mat-label>Select Stores</mat-label>
  <mat-icon matPrefix [ngStyle]="{ opacity: disabled ? 0.5 : 1 }">search</mat-icon>
  <mat-select
    matInput
    [(value)]="selected"
    (selectionChange)="charityStoresSelected($event.value)"
    panelClass="pick-charity"
    disableOptionCentering="true"
    [disabled]="disabled"
    multiple
    (openedChange)="focusFilter(filterInput, $event)"
  >
    <input
      type="text"
      placeholder="Search..."
      matInput
      class="picker-filter"
      autocomplete="off"
      name="charity-picker-filter"
      #filterInput
      [formControl]="filter"
      (input)="filterDelayed()"
      (keydown.space)="$event.stopPropagation()"
    />
    @if (visibleCharityStores.length > 0) {
      <button mat-button class="select-all btn-block" (click)="toggleAll()">
        {{ selected.length !== visibleCharityStores.length ? 'Select All' : 'Deselect All' }}
      </button>
    }
    @for (marketId of marketIds; track marketId) {
      @if (marketsCount === 0 || markets[marketId]) {
        <div>
          @if (markets[marketId] && charityIds[marketId].length > 0) {
            <p class="market">
              <mat-checkbox [checked]="isMarketSelected(marketId)" (change)="toggleMarket(marketId)">
                {{ markets && markets[marketId] ? markets[marketId].name : 'Unassigned' }}
              </mat-checkbox>
            </p>
          }
          @for (charityId of charityIds[marketId]; track charityId) {
            <div>
              @if (charityStoreIds[marketId][charityId].length > 0) {
                <p class="charity">
                  <mat-checkbox [checked]="isCharitySelected(marketId, charityId)" (change)="toggleCharity(marketId, charityId)">
                    {{ charities && charities[charityId] ? charities[charityId].name : '' }}
                  </mat-checkbox>
                </p>
              }
              @for (option of charityStoreIds[marketId][charityId]; track option) {
                <mat-option [value]="option.id">
                  {{ option.name }}
                </mat-option>
              }
            </div>
          }
        </div>
      }
    }
  </mat-select>
  @if (selected.length > 0) {
    <mat-icon matSuffix (click)="clear($event)">close</mat-icon>
  }
</mat-form-field>
