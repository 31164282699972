import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'rspl-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
  imports: [NgClass, MatIcon],
})
export class InfoBoxComponent {
  @Input() header!: string;
  @Input() message!: string;
  @Input() type: 'warning' | 'info' | 'important' | 'success' = 'info';
}
