import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon, MatIconRegistry } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, RouterLink } from '@angular/router';
import { AppType, ENVIRONMENT, Environment, LoginModel } from '@domains';
import { LocalStorageService } from '@rspl-api';
import {
  CardComponent,
  Destructible,
  DisableDoubleClickDirective,
  FxFlexDirective,
  FxLayoutAlignDirective,
  FxLayoutDirective,
  FxLayoutGapDirective,
} from '@rspl-ui';
import { LaddaModule } from 'angular2-ladda';
import { finalize, take } from 'rxjs/operators';

import { AFTER_LOGIN, AuthenticationService } from '../../authentication.service';

@Component({
  selector: 'rspl-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [
    NgTemplateOutlet,
    FxLayoutDirective,
    FxLayoutAlignDirective,
    FxLayoutGapDirective,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    MatIconButton,
    MatSuffix,
    MatIcon,
    LaddaModule,
    CardComponent,
    FxFlexDirective,
    MatFormField,
    MatLabel,
    MatInput,
    MatButton,
    DisableDoubleClickDirective,
    RouterLink,
  ],
})
export class LoginComponent extends Destructible implements OnInit {
  public form?: FormGroup<{
    email: FormControl<string | null>;
    password: FormControl<string | null>;
  }>;
  public app: AppType;
  public resetPasswordUrl: string;
  appTypes = AppType;
  isTPL: boolean;
  isDriver: boolean;
  support?: string;
  showPassword = false;
  isSubmitting = false;
  showErrors = false;

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private authService: AuthenticationService,
    private snackBar: MatSnackBar,
    private router: Router,
    @Inject(ENVIRONMENT) private config: Environment,
    private localStorage: LocalStorageService,
  ) {
    super();
    this.app = this.config.app === AppType.ZENDESK ? AppType.CAPTAIN : this.config.app;
    this.isTPL = this.config.app === AppType.TPL;
    this.isDriver = this.config.app === AppType.DRIVER;
    this.support = this.config.supportNumber;
    this.resetPasswordUrl = this.config.urls.resetPasswordUrl;

    iconRegistry.addSvgIcon('rspl-logo', sanitizer.bypassSecurityTrustResourceUrl('assets/images/new_logo_short.svg'));
  }

  ngOnInit(): void {
    if (!this.localStorage.getItem('LOGIN_FRESH')) {
      this.localStorage.setItem('LOGIN_FRESH', 'true');
      window.location.reload();
      return;
    }
    this.localStorage.removeItem('LOGIN_FRESH');
    document.getElementById('app-page-loader')?.remove();
    sessionStorage.clear();
    const afterLogin = this.localStorage.getItem(AFTER_LOGIN);
    this.localStorage.clear();
    if (afterLogin) {
      this.localStorage.setItem(AFTER_LOGIN, afterLogin);
    }
    this.initForm();
  }

  public login(): void {
    this.form?.updateValueAndValidity();
    this.form?.markAllAsTouched();
    this.showErrors = !!this.form?.invalid;
    if (this.showErrors) {
      return;
    }
    const formValue = this.form?.getRawValue();
    if (!formValue?.password || !formValue.email) return;
    this.isSubmitting = true;
    const user = new LoginModel({
      email: formValue?.email,
      password: formValue?.password,
    });
    this.authService
      .login(user)
      .pipe(
        finalize(() => (this.isSubmitting = false)),
        take(1),
      )
      .subscribe({
        next: () => {
          const afterLoginLocalStorage = this.localStorage.getItem(AFTER_LOGIN);
          const parts = afterLoginLocalStorage?.split('?');
          const afterLoginUrl = parts ? parts[0] : undefined;
          const params = parts && (parts?.length || 0) > 1 ? parts[1].split('&') : undefined;
          const queryParams = params?.reduce((a, v) => {
            const p = v.split('=');
            return { ...a, [p[0]]: p[1] };
          }, {});
          const afterLogin = afterLoginUrl?.split('/').filter((x) => x.trim().length > 0);

          const path =
            (this.config.dynamicAfterLoginRedirect && (afterLogin?.length || 0) > 0
              ? (afterLogin || [])[0] === '/'
                ? afterLogin
                : ['/', ...(afterLogin || [])]
              : this.config.loginRedirectionRoute) || this.config.loginRedirectionRoute;
          this.localStorage.removeItem(AFTER_LOGIN);
          this.router.navigate(path, { queryParams: queryParams });
        },
        error: () => this.handleError(),
      });
  }

  private initForm(): void {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });
  }

  handleError() {
    this.snackBar.open('Access Not Allowed', 'x', {
      duration: 5000,
      panelClass: 'error',
    });
  }

  get email(): FormControl<string> {
    return this.form?.get('email') as FormControl<string>;
  }

  get password(): FormControl<string> {
    return this.form?.get('password') as FormControl<string>;
  }
}
