import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, Input, output } from '@angular/core';
import { MatAnchor, MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { PageAction } from '@domains';

import { FxLayoutAlignDirective, FxLayoutDirective, FxLayoutGapDirective } from '../flex-layout';
import { Responsive, ResponsiveService } from '../responsive';

@Component({
  selector: 'rspl-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  imports: [
    MatButton,
    NgClass,
    MatAnchor,
    RouterLink,
    MatMenuItem,
    FxLayoutDirective,
    FxLayoutAlignDirective,
    FxLayoutGapDirective,
    MatIcon,
    MatTooltip,
    NgTemplateOutlet,
    MatIconButton,
    MatMenuTrigger,
    MatMenu,
  ],
})
export class CardComponent extends Responsive {
  @Input() cardTitleDescription?: string;
  @Input() cardTitle?: string;
  @Input() cardSubTitle?: string;
  @Input() cardTitleLink?: string[];
  readonly onTitleClick = output<void>();
  #actions: PageAction[] = [];
  @Input() set actions(actions: PageAction[]) {
    this.#actions = actions;
  }
  get actions(): PageAction[] {
    return this.#actions;
  }

  constructor(public override responsiveService: ResponsiveService) {
    super(responsiveService);
  }

  get visibleActionsCnt(): number {
    return this.actions?.filter((x) => !x.hidden || !x.hidden())?.length;
  }
}
