import { Routes } from '@angular/router';

import { UnauthGuard } from './guards/unauth.guard';

export const authRoutes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./components/login/login.component').then((m) => m.LoginComponent),
    canActivate: [UnauthGuard],
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];
