import { NgClass } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, output, TemplateRef, viewChild, viewChildren } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatOption, MatOptionSelectionChange } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { Address, ENVIRONMENT, Environment, Place } from '@domains';
import {
  Designable,
  DesignService,
  FxFlexDirective,
  FxLayoutAlignDirective,
  FxLayoutDirective,
  FxLayoutGapDirective,
  ResponsiveService,
} from '@rspl-ui';
import { LngLatBoundsLike, LngLatLike, MapboxOptions, Marker } from 'mapbox-gl';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { take } from 'rxjs';

@Component({
  selector: 'rspl-advanced-address-input',
  templateUrl: './advanced-address-input.component.html',
  styleUrls: ['./advanced-address-input.component.scss'],
  imports: [
    MatInput,
    NgClass,
    MatIcon,
    FxLayoutDirective,
    FxLayoutGapDirective,
    FxFlexDirective,
    MatFormField,
    MatLabel,
    FormsModule,
    MatAutocompleteTrigger,
    ReactiveFormsModule,
    MatAutocomplete,
    MatOption,
    FxLayoutAlignDirective,
    MatProgressSpinner,
    MatHint,
    NgxMapboxGLModule,
  ],
})
export class AdvancedAddressInputComponent extends Designable {
  readonly addressDialogTemplate = viewChild.required<TemplateRef<any>>('addressDialog');
  readonly inputEl = viewChild<any>('inputElement');
  readonly addrLis = viewChildren('li');
  readonly addressChange = output<Address>();
  readonly addressError = output<string>();
  @Input() label = 'Your address';
  @Input() readonly = false;
  @Input() showError = false;
  @Input() allowAdjustingPin = false;
  @Input() leadId?: string;
  @Input() set disabled(disabled: boolean) {
    if (disabled) this.street?.disable();
    else this.street?.enable();
  }
  @Input() types: string[] = ['address'];
  street = new FormControl<string | null>('', Validators.required);
  secondary = new FormControl<string | null>('');
  currentAddress?: Address;
  savedAddress?: Address;
  delay: any;
  fullAddress?: string;
  mapBoxAccessToken: string;

  lngLat: MapboxOptions['center'] = [-98.67092164138215, 39.38842857606963];
  pinLngLat?: [number, number];
  addressDialog?: MatDialogRef<any>;
  fitBounds: LngLatBoundsLike = [-125.0011, 24.9493, -66.9326, 49.5904];

  @Input() set address(address: Address | undefined) {
    if (address && (address?.street?.length || 0) > 0) {
      if ((address.street?.length || 0) > 0 && address.street !== this.street.value) {
        this.street.patchValue(address.street || null);
        this.getAddresses();
      } else {
        this.street.patchValue(address.street || null);
      }
      this.secondary.patchValue(address.secondary || null);
      this.fullAddress = `${address.street || ''}${
        address.secondary ? ' #' + address.secondary : ''
      } ${address.city || ''}, ${address.state || ''} ${address.zip || ''}, USA`;
      this.savedAddress = { ...address } as Address;
      this.currentAddress = { ...address } as Address;
      this.pinLngLat = this.lngLat =
        this.currentAddress?.lat && this.currentAddress.lng ? [this.currentAddress.lng, this.currentAddress.lat] : undefined;
    }
  }

  get address(): Address | undefined {
    return this.currentAddress;
  }

  addresses: Address[] = [];
  loading = false;
  userLngLat?: LngLatLike;
  loadingLocation = false;
  disableLocation = false;
  showPopupError = false;
  isAdjustingPin = false;

  constructor(
    private dialog: MatDialog,
    override designService: DesignService,
    override responsiveService: ResponsiveService,
    @Inject(ENVIRONMENT) private config: Environment,
    private http: HttpClient,
  ) {
    super(designService, responsiveService);
    this.mapBoxAccessToken = this.config.mapBoxAccessToken;
  }

  openAddress() {
    this.addressDialog = this.dialog.open(this.addressDialogTemplate(), {
      width: '100vw',
      maxWidth: '900px',
      maxHeight: '100vh',
      panelClass: 'advanced-address-input-dialog',
    });
    this.addressDialog
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        if (this.savedAddress) this.address = { ...this.savedAddress } as Address;
      });
  }

  getAddressesDelayed() {
    if (this.delay) {
      clearTimeout(this.delay);
    }
    this.delay = setTimeout(() => {
      this.getAddresses();
    }, 300);
  }

  async getAddresses() {
    this.addresses = [];
    if (this.street.value?.trim().length === 0) return;
    this.loading = true;

    this.http
      .get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${this.street.value}.json`, {
        params: {
          autocomplete: true,
          country: 'us',
          language: 'en',
          limit: 10,
          proximity: 'ip',
          types: this.types.join(','),
          access_token: this.config.mapBoxAccessToken,
        },
      })
      .pipe(take(1))
      .subscribe((res: { features?: Place[] }) => {
        this.addresses = [];
        res.features?.map((place) =>
          this.addresses.push({
            street: (place.address ? place.address + ' ' : '') + (place.text_en || place.text),
            city: place.context.find((c) => c.id.includes('place'))?.text_en || place.context.find((c) => c.id.includes('place'))?.text,
            state: place.context.find((c) => c.id.includes('region'))?.short_code.replace('US-', ''),
            zip:
              place.context.find((c) => c.id.includes('postcode'))?.text_en || place.context.find((c) => c.id.includes('postcode'))?.text,
            lat: place.center[1],
            lng: place.center[0],
          } as Address),
        );
        this.loading = false;
      });
  }

  formatAddress(address?: Address): string {
    return address && (address?.street?.length || 0) > 0
      ? `${address.street || ''}${address.secondary ? ' #' + address.secondary : ''} ${address.city || ''}, ${address.state || ''} ${
          address.zip || ''
        }, USA`
      : '';
  }

  select(addr: Address, event: MatOptionSelectionChange) {
    if (!event.isUserInput) return;
    this.currentAddress = addr;
    this.street.patchValue(this.currentAddress.street || null);
    this.shouldShowError();
    if (!this.showError) {
      this.currentAddress = new Address(addr);
      this.lngLat = this.pinLngLat =
        this.currentAddress?.lng && this.currentAddress?.lat ? [this.currentAddress.lng, this.currentAddress.lat] : undefined;
      if (this.lngLat) {
        this.fitBounds = [this.lngLat[0] - 0.005, this.lngLat[1] - 0.005, this.lngLat[0] + 0.005, this.lngLat[1] + 0.005];
      }
    }
  }

  confirmAddress() {
    this.street.markAsDirty();
    this.street.updateValueAndValidity();
    if (this.street.invalid) {
      this.setAddressError();
      return;
    }

    this.shouldShowError();
    if (this.showPopupError) {
      this.setAddressError();
      return;
    }
    this.addressChange.emit({
      ...this.currentAddress,
      secondary: this.secondary.value,
    } as Address);
    this.addressDialog?.close();
  }

  shouldShowError() {
    this.showPopupError =
      !this.currentAddress?.zip ||
      (this.currentAddress?.street?.length || 0) === 0 ||
      !this.currentAddress?.city ||
      !this.currentAddress?.state;
  }

  setAddressError() {
    this.addressError.emit(this.formatAddress(this.currentAddress));
  }

  onPinDrag(marker: Marker) {
    const lngLat = marker.getLngLat();
    this.lngLat = this.pinLngLat = [lngLat.lng, lngLat.lat];
  }

  cancelAdjustingPin() {
    this.isAdjustingPin = false;
    this.lngLat = this.pinLngLat =
      this.currentAddress && this.currentAddress.lng && this.currentAddress.lat
        ? [this.currentAddress.lng, this.currentAddress.lat]
        : undefined;
  }

  saveAdjustingPin() {
    this.isAdjustingPin = false;
    if (this.currentAddress && this.pinLngLat) {
      this.currentAddress.lng = this.pinLngLat[0];
      this.currentAddress.lat = this.pinLngLat[1];
    }
  }
}
