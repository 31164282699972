export enum FeeType {
  BracketsV1 = 'brackets_v1',
  BracketsV2 = 'brackets_v2',
  Flat = 'flat',
  Fixed = 'fixed',
}

export const FEE_TYPE_LIST = [
  // {
  //   value: FeeType.BracketsV1,
  //   text: 'Bucket V1 (<150$ | $150-$300 | >$300)',
  // },
  // {
  //   value: FeeType.BracketsV2,
  //   text: 'Bucket V2 (<150$ | $150-$500 | >$500)',
  // },
  // {
  //   value: FeeType.Flat,
  //   text: 'Flat Fee',
  // },
  {
    value: FeeType.Fixed,
    text: 'Fixed Fee',
  },
];
