import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField, MatLabel, MatPrefix, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSelect } from '@angular/material/select';
import { Truck } from '@domains';
import { TrucksService } from '@rspl-api';

import { FxFlexDirective, FxLayoutAlignDirective, FxLayoutDirective, FxLayoutGapDirective } from '../../flex-layout';
import { LookupComponent } from '../lookup.component';

@Component({
  selector: 'rspl-truck-lookup',
  templateUrl: '../lookup.component.html',
  styleUrls: ['../lookup.component.scss'],
  imports: [
    MatFormField,
    NgClass,
    MatLabel,
    MatIcon,
    MatPrefix,
    NgStyle,
    MatProgressSpinner,
    MatSelect,
    FormsModule,
    ReactiveFormsModule,
    MatInput,
    MatSuffix,
    FxLayoutDirective,
    MatButton,
    MatOption,
    NgTemplateOutlet,
    FxLayoutAlignDirective,
    FxLayoutGapDirective,
    FxFlexDirective,
  ],
})
export class TruckLookupComponent extends LookupComponent<Truck> {
  constructor(
    protected override apiService: TrucksService,
    protected override dialog: MatDialog,
  ) {
    super(apiService, dialog);
  }
}
