export const PARTNER_CANCEL_REASONS: Reason[] = [
  {
    reason: 'The donor declined the cost',
    children: [
      'The donor thought the booking fee was entire price',
      'The donor thought the service was free',
      'The donor declined upcharge for items not listed properly',
      'The donor disagreed with recycling fees',
      'The donor declined the staircases/disassembly charge',
      'The donor was given different price over the phone',
    ],
  },
  {
    reason: 'The donor was unresponsive',
    children: ['I arrived on site and the donor wasn’t there', 'The donor did not respond to call prior to the pickup'],
  },
  {
    reason: 'I could not accept these items',
    children: ['There was a specialty item', 'It was a small item pickup only', 'The charity won’t accept these items'],
  },
];

export const PARTNER_CPT_CANCEL_REASONS: Reason[] = [
  {
    reason: 'The donor declined the cost',
    children: [
      'The donor thought the booking fee was entire price',
      'The donor thought the service was free',
      'The donor declined upcharge for items not listed properly',
      'The donor disagreed with recycling fees',
      'The donor declined the staircases/disassembly charge',
      'The donor was given different price over the phone',
    ],
  },
  {
    reason: 'The donor was unresponsive',
    children: ['I arrived on site and the donor wasn’t there', 'The donor did not respond to call prior to the pickup'],
  },
  {
    reason: 'I could not accept these items',
    children: ['There was a specialty item', 'It was a small item pickup only', 'The charity won’t accept these items'],
  },
  {
    reason: 'This pickup is out of the partner zone',
    children: [
      'The donor is in zone but the charity is not in zone',
      'Both the donor and charity are out of zone',
      'The charity is in zone but the donor not in zone',
    ],
  },
  {
    reason: 'Partner is unresponsive',
  },
  {
    reason: 'This is a paused partnership',
  },
  {
    reason: 'The partner did not show up',
  },
  {
    reason: 'There were insurance complications',
    children: [
      'The partner doesn’t have the proper insurance for this type of job',
      'The partner doesn’t meet building insurance requirements',
      'There was an issue retrieving the partner’s COI',
    ],
  },
];

export const DONOR_CANCEL_REASONS: Reason[] = [
  {
    reason: 'The cost was too high',
    children: [
      'The fees were confusing and not explained well',
      'I was given a different price over the phone',
      'I disagreed with the recycling fees/upcharges',
      'I thought the service was free',
      'I thought the booking fee was the price for the pickup',
      'I declined the staircases/disassembly charges',
    ],
  },
  {
    reason: 'The available pickup dates do not meet my needs',
    children: ['I am unavailable to reschedule', 'I needed a different date for pickup', 'I needed a different pickup window (AM/PM)'],
  },
  {
    reason: 'I no longer need the items picked up',
    children: ['I gave away my items', 'My replacement items did not arrive', 'I had an emergency'],
  },
  {
    reason: 'The charity will not accept my items',
  },
  {
    reason: 'Other',
  },
];

export const DONOR_CPT_CANCEL_REASONS: Reason[] = [
  {
    reason: 'The cost was too high',
    children: [
      'The fees were confusing and not explained well',
      'I was given a different price over the phone',
      'I disagreed with the recycling fees/upcharges',
      'I thought the service was free',
      'I thought the booking fee was the price for the pickup',
      'I declined the staircases/disassembly charges',
    ],
  },
  {
    reason: 'The available pickup dates do not meet my needs',
    children: ['I am unavailable to reschedule', 'I needed a different date for pickup', 'I needed a different pickup window (AM/PM)'],
  },
  {
    reason: 'I no longer need the items picked up',
    children: ['I gave away my items', 'My replacement items did not arrive', 'I had an emergency'],
  },
  {
    reason: 'The charity will not accept my items',
  },
  {
    reason: 'This was an on-site cancellation',
    children: ['The donor did not show up', 'The donor was unresponsive'],
  },
  {
    reason: 'This is a duplicate submission',
  },
  {
    reason: 'Other',
  },
];

export const RESCHEDULE_REASONS: Reason[] = [
  {
    reason: 'I need to group more donations to optimize my route',
  },
  {
    reason: 'I am overbooked',
    children: ['I’m overbooked with Resupply jobs', 'I’m overbooked with my own jobs'],
  },
  {
    reason: 'The charity will be closed on the day of the pickup',
  },
  {
    reason: 'I have a truck/fleet issue',
    children: ['Truck Broke Down', 'Short Staffed'],
  },
  {
    reason: 'Other',
  },
];

export const DECLINE_REASONS: Reason[] = [
  {
    reason: 'This pickup is too small to be worth it',
    children: [
      'The estimate is too low for the distance I need to travel',
      'The estimate needs to be adjusted for specialty items',
      'There are only small items',
    ],
  },
  {
    reason: 'I can’t haul these items',
    children: ['There is a specialty item that I can’t haul away', 'I know the charity won’t accept these items'],
  },
  {
    reason: 'The pickup requires too much travel time',
    children: [
      'The charity drop-off location too far away',
      'The donor location is too far away',
      'Both the donor and charity drop-off are too far away',
    ],
  },
  {
    reason: 'The detailed pickup description doesn’t match the quote',
  },
  {
    reason: 'I am overbooked/away',
    children: ['I’m overbooked with Resupply jobs', 'I’m overbooked with my own jobs', 'I’m away on vacation'],
  },
  {
    reason: 'I have a truck/fleet issue',
    children: ['Truck Broke Down', 'Short Staffed'],
  },
  {
    reason: 'Other',
  },
];

export interface Reason {
  reason: string;
  children?: string[];
}
