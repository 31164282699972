import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from '@rspl-api';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AFTER_LOGIN, AuthenticationService } from '../authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private authService: AuthenticationService,
    private localStorage: LocalStorageService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.authData$.pipe(
      take(1),
      map((authData) => {
        if (authData?.authorization?.auth) {
          return true;
        } else {
          this.localStorage.setItem(AFTER_LOGIN, state.url);
          this.authService.logout();
          return false;
        }
      }),
    );
  }
}
