import { User } from '@domains';
import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './user.actions';

export interface UsersState {
  users: Array<User>;
  usersTotalCount: number;
  user: User;
  createdUser: User;
  updatedUser: User;
  deletedUserId: number;
}

const initialState: UsersState = {
  users: new Array<User>(),
  usersTotalCount: 0,
  user: undefined,
  createdUser: undefined,
  updatedUser: undefined,
  deletedUserId: 0,
};

const reducer = createReducer(
  initialState,

  on(fromActions.getUsers, (state) => {
    return { ...state, usersTotalCount: 0, users: [] };
  }),
  on(fromActions.getUsersSuccess, (state, { resultsCount, results }) => ({ ...state, usersTotalCount: resultsCount, users: results })),
  on(fromActions.clearUsersFindParams, (state) => {
    return { ...state };
  }),

  on(fromActions.clearUser, (state) => ({ ...state, user: null })),
  on(fromActions.getUser, (state) => ({ ...state, user: null })),
  on(fromActions.getUserSuccess, (state, action: any) => ({ ...state, user: action.user })),

  on(fromActions.createUserSuccess, (state, action: any) => ({ ...state, createdUser: action.user })),

  on(fromActions.updateUserSuccess, (state, action: any) => ({ ...state, updatedUser: action.user })),

  on(fromActions.deleteUserSuccess, (state, action: any) => ({ ...state, deletedUserId: action.userId })),
);

export function usersReducer(state: UsersState | undefined, action: Action) {
  return reducer(state, action);
}
