import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { FxFlexDirective, FxLayoutAlignDirective, FxLayoutDirective } from '@rspl-ui';

import { FxLayoutGapDirective } from '../../flex-layout/fx-layout-gap.directive';

@Component({
  selector: 'rspl-select-photo',
  templateUrl: './select-photo.component.html',
  styleUrls: ['./select-photo.component.scss'],
  imports: [FxLayoutDirective, FxFlexDirective, FxLayoutAlignDirective, FxLayoutGapDirective, MatIcon],
})
export class SelectPhotoComponent {
  constructor(
    public reference: MatDialogRef<SelectPhotoComponent>,
    @Inject(MAT_DIALOG_DATA) public photos: string[],
  ) {}

  public close(): void {
    this.reference.close(undefined);
  }

  public upload(): void {
    this.reference.close(null);
  }

  public select(image: string): void {
    this.reference.close(image);
  }
}
