import { createFeatureSelector, createSelector } from '@ngrx/store';

import { BudgetState } from './budget.reducers';

// tslint:disable: max-line-length
export const budgetsFeatureStateSelector = createFeatureSelector<BudgetState>('budget');

export const selectBudgets = createSelector(budgetsFeatureStateSelector, (state: BudgetState) => state.budgets);
export const selectBudgetsTotalCount = createSelector(budgetsFeatureStateSelector, (state: BudgetState) => state.budgetsTotalCount);

export const selectBudget = createSelector(budgetsFeatureStateSelector, (state: BudgetState) => state.budget);
