import { CurrencyPipe, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { Donation, ENVIRONMENT, Environment, Lead, Specification } from '@domains';

import { Designable, DesignService } from '../designable';
import { ResponsiveService } from '../responsive';

type ComponentConfig = {
  hideZeroes?: boolean;
  hidePrices?: boolean;
  hideUnitPrices?: boolean;
  showDiscount?: boolean;
  //
  showBookingFee?: boolean;
  includeBookingInTotal?: boolean;
  bookingLabel?: string;
  totalLabel?: string;
};

@Component({
  selector: 'rspl-donation-specification-table',
  templateUrl: './donation-specification-table.component.html',
  styleUrl: './donation-specification-table.component.scss',
  imports: [NgStyle, NgTemplateOutlet, CurrencyPipe],
})
export class DonationSpecificationTableComponent extends Designable {
  @Input() type: 'initial' | 'adjusted' = 'initial';
  @Input() data: Donation | Lead;

  #config?: ComponentConfig = {
    hideZeroes: false,
    hidePrices: false,
    hideUnitPrices: false,
    showDiscount: false,
    showBookingFee: false,
    includeBookingInTotal: false,
    bookingLabel: 'Booking Fee',
    totalLabel: 'Total',
  };
  @Input() set config(config: ComponentConfig) {
    this.#config = {
      ...{
        hideZeroes: false,
        hidePrices: false,
        hideUnitPrices: false,
        showDiscount: false,
        showBookingFee: false,
        includeBookingInTotal: false,
        bookingLabel: 'Booking Fee',
        totalLabel: 'Total',
      },
      ...config,
    };
  }

  get config(): ComponentConfig {
    return this.#config;
  }

  columnConfigs = {
    item: {
      title: 'Items',
      alignText: () => 'left',
      value: (rowConfig) => rowConfig.title,
    },
    quantity: {
      title: 'Quantity',
      alignText: () => (this.design2 ? 'center' : 'right'),
      value: (rowConfig) => rowConfig.quantity(),
    },
    unitPrice: {
      title: 'Unit price',
      alignText: () => 'right',
      value: (rowConfig) => rowConfig.unitPrice(),
    },
    price: {
      title: 'Price',
      alignText: () => 'right',
      value: (rowConfig) => rowConfig.price(),
    },
  };

  rowConfigs: {
    [key: string]: {
      title: string;
      quantity: () => string | number;
      unitPrice: () => string;
      price: () => string;
      hide: () => boolean;
    };
  } = {
    basePrice: {
      title: 'Base price',
      quantity: () => 1,
      unitPrice: () => this.currency.transform(this.data.pricing.base),
      price: () => this.currency.transform(this.data.pricing.base),
      hide: () => this.environment.isCharity || this.config.hidePrices,
    },
    xlarge: {
      title: 'Extra Large items',
      quantity: () => this.specification.xlarge,
      unitPrice: () => this.currency.transform(this.data.pricing.xlarge),
      price: () => this.currency.transform(this.data.pricing.xlarge * this.specification.xlarge),
      hide: () => !!this.config.hideZeroes && !this.specification.xlarge,
    },
    large: {
      title: 'Large items',
      quantity: () => this.specification.large,
      unitPrice: () => this.currency.transform(this.data.pricing.large),
      price: () => this.currency.transform(this.data.pricing.large * this.specification.large),
      hide: () => !!this.config.hideZeroes && !this.specification.large,
    },
    medium: {
      title: 'Medium items',
      quantity: () => this.specification.medium,
      unitPrice: () => this.currency.transform(this.data.pricing.medium),
      price: () => this.currency.transform(this.data.pricing.medium * this.specification.medium),
      hide: () => !!this.config.hideZeroes && !this.specification.medium,
    },
    small: {
      title: 'Bags/boxes of clothes/housewares',
      quantity: () => this.specification.small,
      unitPrice: () => this.currency.transform(this.data.pricing.small),
      price: () => this.currency.transform(this.data.pricing.small * this.specification.small),
      hide: () =>
        (!!this.config.hideZeroes && !this.specification.small) ||
        (this.config.showDiscount && this.type === 'adjusted' && !!this.donation.discountedSpecification),
    },
    donatableSmall: {
      title: 'Donatable Bags/boxes of clothes/housewares',
      quantity: () => this.donation.meta.donatableSmall,
      unitPrice: () => this.currency.transform(this.data.pricing.small),
      price: () => this.currency.transform(this.data.pricing.small * this.donation.meta.donatableSmall),
      hide: () =>
        !this.donation.discountedSpecification ||
        (!!this.config.hideZeroes && !this.donation.meta.donatableSmall) ||
        !this.config.showDiscount ||
        this.type !== 'adjusted',
    },
    nonDonatableSmall: {
      title: 'Non Donatable Bags/boxes of clothes/housewares',
      quantity: () => this.donation.meta.nonDonatableSmall,
      unitPrice: () => this.currency.transform(this.data.pricing.small),
      price: () => this.currency.transform(this.data.pricing.small * this.donation.meta.nonDonatableSmall),
      hide: () =>
        !this.donation.discountedSpecification ||
        (!!this.config.hideZeroes && !this.donation.meta.nonDonatableSmall) ||
        !this.config.showDiscount ||
        this.type !== 'adjusted',
    },
    staircases: {
      title: 'Staircases',
      quantity: () => this.specification.staircases,
      unitPrice: () => this.currency.transform(this.data.pricing.staircases),
      price: () => this.currency.transform(this.data.pricing.staircases * this.specification.staircases),
      hide: () => !!this.config.hideZeroes && !this.specification.staircases,
    },
    elevator: {
      title: 'Elevator',
      quantity: () => this.specification.elevator,
      unitPrice: () => this.currency.transform(this.data.pricing.elevator),
      price: () => this.currency.transform(this.data.pricing.elevator * this.specification.elevator),
      hide: () => !this.data.pricing.elevator || (!!this.config.hideZeroes && !this.specification.elevator),
    },
    disassembly: {
      title: 'Disassembly',
      quantity: () => this.specification.disassembly,
      unitPrice: () => this.currency.transform(this.data.pricing.disassembly),
      price: () => this.currency.transform(this.data.pricing.disassembly * this.specification.disassembly),
      hide: () => !!this.config.hideZeroes && !this.specification.disassembly,
    },
    recycling: {
      title: 'Recycling',
      quantity: () => this.specification.recycling,
      unitPrice: () => this.currency.transform(this.data.pricing.recycling),
      price: () => this.currency.transform(this.data.pricing.recycling * this.specification.recycling),
      hide: () => !!this.config.hideZeroes && !this.specification.recycling,
    },
    fuelFee: {
      title: 'Fuel Fee',
      quantity: () => '',
      unitPrice: () => this.data.pricing.fuelFee + '%',
      price: () => this.currency.transform(this.type === 'adjusted' ? this.donation.finalQuoteFuelFee : this.data.originalQuoteFuelFee),
      hide: () => this.environment.isCharity || !this.data.pricing.fuelFee,
    },
    tip: {
      title: 'Tip',
      quantity: () => '',
      unitPrice: () => '',
      price: () => this.currency.transform(this.donation.gratuity),
      hide: () => this.environment.isCharity || this.type === 'initial' || (!!this.config.hideZeroes && !this.donation.gratuity),
    },
  };

  constructor(
    protected override designService: DesignService,
    override responsiveService: ResponsiveService,
    private currency: CurrencyPipe,
    @Inject(ENVIRONMENT) protected environment: Environment,
  ) {
    super(designService, responsiveService);
  }

  get donation(): Donation {
    return this.data as Donation;
  }

  get lead(): Lead {
    return this.data as Lead;
  }

  get specification(): Specification {
    return this.type === 'adjusted' ? this.donation.adjustedSpecification : this.data.specification;
  }

  get columns(): ('item' | 'quantity' | 'unitPrice' | 'price')[] {
    if (!this.config) return [];
    const res: ('item' | 'quantity' | 'unitPrice' | 'price')[] = ['item', 'quantity'];
    if (!this.config.hidePrices && !this.config.hideUnitPrices && !this.environment.isCharity) {
      res.push('unitPrice');
    }
    if (!this.config.hidePrices && !this.environment.isCharity) {
      res.push('price');
    }
    return res;
  }

  get rows(): string[] {
    return Object.keys(this.rowConfigs);
  }

  get total(): number {
    return this.type === 'adjusted' ? this.donation.finalQuote : this.data.originalQuote;
  }

  get sponsoredAmount(): number {
    return this.type === 'adjusted' ? this.donation.finalSponsoredAmount : this.data.originalSponsoredAmount;
  }
}
