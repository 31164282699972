import { FindPhoneConfigurationParams, PhoneConfiguration } from '@domains';
import { createAction, props } from '@ngrx/store';

export enum PhoneConfigurationActionType {
  CreatePhoneConfiguration = '[PhoneConfiguration] Create PhoneConfiguration',
  CreatePhoneConfigurationSuccess = '[PhoneConfiguration] Create PhoneConfiguration Success',
  CreatePhoneConfigurationFail = '[PhoneConfiguration] Create PhoneConfiguration Fail',

  UpdatePhoneConfiguration = '[PhoneConfiguration] Update PhoneConfiguration',
  UpdatePhoneConfigurationSuccess = '[PhoneConfiguration] Update PhoneConfiguration Success',
  UpdatePhoneConfigurationFail = '[PhoneConfiguration] Update PhoneConfiguration Fail',

  GetPhoneConfigurations = '[PhoneConfiguration] Get PhoneConfigurations',
  GetPhoneConfigurationsSuccess = '[PhoneConfiguration] Get PhoneConfigurations Success',

  GetPhoneConfiguration = '[PhoneConfiguration] Get PhoneConfiguration',
  GetPhoneConfigurationSuccess = '[PhoneConfiguration] Get PhoneConfiguration Success',

  DeletePhoneConfiguration = '[PhoneConfiguration] Delete PhoneConfiguration',
  DeletePhoneConfigurationSuccess = '[PhoneConfiguration] Delete PhoneConfiguration Success',
  DeletePhoneConfigurationFail = '[PhoneConfiguration] Delete PhoneConfiguration Fail',

  RestorePhoneConfiguration = '[PhoneConfiguration] Restore PhoneConfiguration',
  RestorePhoneConfigurationSuccess = '[PhoneConfiguration] Restore PhoneConfiguration Success',
}

export const createPhoneConfiguration = createAction(
  PhoneConfigurationActionType.CreatePhoneConfiguration,
  props<{ phoneConfiguration: PhoneConfiguration }>(),
);
export const createPhoneConfigurationSuccess = createAction(
  PhoneConfigurationActionType.CreatePhoneConfigurationSuccess,
  props<{ phoneConfiguration: PhoneConfiguration }>(),
);
export const createPhoneConfigurationFail = createAction(PhoneConfigurationActionType.CreatePhoneConfigurationFail);

export const updatePhoneConfiguration = createAction(
  PhoneConfigurationActionType.UpdatePhoneConfiguration,
  props<{ phoneConfiguration: PhoneConfiguration }>(),
);
export const updatePhoneConfigurationSuccess = createAction(
  PhoneConfigurationActionType.UpdatePhoneConfigurationSuccess,
  props<{ phoneConfiguration: PhoneConfiguration }>(),
);
export const updatePhoneConfigurationFail = createAction(PhoneConfigurationActionType.UpdatePhoneConfigurationFail);

export const getPhoneConfigurations = createAction(
  PhoneConfigurationActionType.GetPhoneConfigurations,
  props<{ findParams: FindPhoneConfigurationParams }>(),
);
export const getPhoneConfigurationsSuccess = createAction(
  PhoneConfigurationActionType.GetPhoneConfigurationsSuccess,
  props<{ phoneConfigurations: Array<PhoneConfiguration>; resultsCount: number }>(),
);

export const getPhoneConfiguration = createAction(
  PhoneConfigurationActionType.GetPhoneConfiguration,
  props<{ phoneConfigurationId: string }>(),
);
export const getPhoneConfigurationSuccess = createAction(
  PhoneConfigurationActionType.GetPhoneConfigurationSuccess,
  props<{ phoneConfiguration: PhoneConfiguration }>(),
);

export const deletePhoneConfiguration = createAction(
  PhoneConfigurationActionType.DeletePhoneConfiguration,
  props<{ phoneConfigurationId: string }>(),
);
export const deletePhoneConfigurationSuccess = createAction(
  PhoneConfigurationActionType.DeletePhoneConfigurationSuccess,
  props<{ phoneConfigurationId: string }>(),
);
export const deletePhoneConfigurationFail = createAction(PhoneConfigurationActionType.DeletePhoneConfigurationFail);

export const restorePhoneConfiguration = createAction(
  PhoneConfigurationActionType.RestorePhoneConfiguration,
  props<{ phoneConfigurationId: string }>(),
);
export const restorePhoneConfigurationSuccess = createAction(
  PhoneConfigurationActionType.RestorePhoneConfigurationSuccess,
  props<{ phoneConfigurationId: string }>(),
);
