import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, Environment, User, UserMeta } from '@domains';
import { Deserialize, Serialize } from 'cerialize';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseApiService } from '../base-api.service';
import { LocalStorageService } from './local-storage';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends BaseApiService<User> {
  constructor(
    @Inject(ENVIRONMENT) override config: Environment,
    override http: HttpClient,
    private localStorage: LocalStorageService,
  ) {
    super(config, http, 'users', User, 'User', [], ['partner', 'charity']);
  }

  override deserialize(data: any): User {
    const res = new User(Deserialize(data, User), this.config.app);
    if (res.meta?.tableConfig && !res.meta?.tableConfig.startsWith('http')) {
      res.meta.tableConfig = this.config.urls.baseUrl + res.meta?.tableConfig;
    }
    res.setInitialValue();
    return res;
  }

  override serialize(item: User) {
    const user = { ...item };
    if (user?.meta) {
      const m = { ...user.meta } as any;
      delete m['appType'];
      user.meta = m;
    }
    return Serialize(user, User);
  }

  updateUserMeta(id: string, meta: UserMeta): Observable<User> {
    return this.http
      .put<User>(this.config.urls.baseUrl + `/users/` + id, {
        id,
        meta,
      })
      .pipe(
        map((response: User) => {
          const user = new User(Deserialize(response, User));
          if (this.localStorage.getItem('rspl-user')) {
            const u = JSON.parse(this.localStorage.getItem('rspl-user') || '');
            u.meta = meta;
            this.localStorage.setItem('rspl-user', JSON.stringify(u));
          }
          return user;
        }),
      );
  }

  getConfig(url: string): Observable<any> {
    return this.http.get((url.startsWith('http') ? '' : this.config.urls.baseUrl) + url + `?t=${new Date().getTime()}`);
  }
}
