import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AppType, Charity, ENVIRONMENT, Environment } from '@domains';
import { map, Observable, of, take } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthenticationService } from '../authentication.service';

@Injectable({ providedIn: 'root' })
export class CharityFeatureGuard {
  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private authService: AuthenticationService,
    @Inject(ENVIRONMENT) private config: Environment,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const features = route.data['features'] as Array<string>;
    const allowed = route.data['shouldAllowFeatures'] as boolean;
    return this.authService.authData$.pipe(
      take(1),
      switchMap((authData) => {
        if (authData) {
          if (this.config.app === AppType.CHARITY) {
            return this.authService.getCharity().pipe(map((charity) => this.canPass(features, allowed, charity)));
          }
          return of(true);
        } else {
          return of(false);
        }
      }),
    );
  }

  canPass(features: Array<string>, allowed: boolean, c?: Charity): boolean {
    for (const feature of features) {
      if (!!((c || {}) as any)[feature] === allowed) {
        return true;
      }
    }
    this.snackBar.open('Access Not Allowed', 'x', {
      duration: 5000,
      panelClass: 'error',
    });
    this.router.navigate(this.config.defaultRoute);
    return false;
  }
}
