import { ApplicationRef, Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, Environment, Theme } from '@domains';
import { LocalStorageService } from '@rspl-api';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  systemTheme: Theme = Theme.LIGHT;
  theme: BehaviorSubject<{ theme?: Theme; auto?: boolean }> = new BehaviorSubject({});
  colorSchemeMedia = window.matchMedia ? window.matchMedia('(prefers-color-scheme: dark)') : null;

  autoTheme: boolean;
  constructor(
    private ref: ApplicationRef,
    private localStorage: LocalStorageService,
    @Inject(ENVIRONMENT) protected environment: Environment,
  ) {
    this.autoTheme = this.localStorage.getItem('rsp-auto-theme') !== 'false';
    let theme = this.localStorage.getItem('rsp-theme');
    if (!theme) {
      theme = Theme.LIGHT;
      this.localStorage.setItem('rsp-theme', Theme.LIGHT);
    }
    if (!this.autoTheme && !!theme) {
      this.setTheme(theme as Theme);
    }
    if (this.colorSchemeMedia) {
      // Initially check if dark mode is enabled on system
      const darkModeOn = this.colorSchemeMedia.matches;

      // If dark mode is enabled then directly switch to the dark-theme
      if (darkModeOn) {
        this.systemTheme = Theme.DARK;
      }
      if (this.autoTheme) this.setTheme();

      // Watch for changes of the preference
      this.colorSchemeMedia.addEventListener('change', (e) => {
        const turnOn = e.matches;
        this.systemTheme = turnOn ? Theme.DARK : Theme.LIGHT;
        if (this.autoTheme) this.setTheme();

        // Trigger refresh of UI
        this.ref.tick();
      });
    } else {
      if (this.autoTheme) this.setTheme();
    }
  }

  setTheme(theme?: Theme) {
    let currentTheme;
    if (theme) {
      this.autoTheme = false;
      currentTheme = theme;
    } else {
      this.autoTheme = true;
      currentTheme = this.systemTheme;
    }
    this.theme.next({ theme: currentTheme, auto: this.autoTheme });

    const body = document.body;
    if (currentTheme === Theme.LIGHT) {
      if (!body.classList.contains(Theme.LIGHT)) {
        body.classList.add(Theme.LIGHT);
      }
      body.classList.remove(Theme.DARK);
    } else if (currentTheme === Theme.DARK) {
      if (!body.classList.contains(Theme.DARK)) {
        body.classList.add(Theme.DARK);
      }
      body.classList.remove(Theme.LIGHT);
    }

    this.localStorage.setItem('rsp-theme', currentTheme);
    this.localStorage.setItem('rsp-auto-theme', String(this.autoTheme));
  }
}
