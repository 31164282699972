import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Params, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BackService {
  history: string[] = [];

  constructor(private router: Router) {
    this.history = JSON.parse(sessionStorage.getItem('rspl-nav-history') ?? '[]');
    this.router.events.pipe(filter((x) => x instanceof NavigationEnd)).subscribe((event) => {
      this.history.push((event as NavigationEnd).url);
      if (this.history.length > 20) {
        this.history.splice(0, this.history.length - 20);
      }
      sessionStorage.setItem('rspl-nav-history', JSON.stringify(this.history));
    });
  }

  back(location: Location, route: ActivatedRoute, router: Router): void {
    const backPath = this.getBackPath(location, route);
    this.navigateTo(router, backPath);
  }

  getBackPath(location: Location, route: ActivatedRoute): string | undefined {
    if (this.history.length === 0) {
      this.history.push('/' + route.snapshot.url.map((x) => x.path).join('/'));
      sessionStorage.setItem('rspl-nav-history', JSON.stringify(this.history));
    }
    let backPaths: string[] = route.snapshot.data ? route.snapshot.data['backPaths'] : [];
    if (!((backPaths?.length ?? 0) > 0)) {
      return undefined; // history.go(-1);
    }
    backPaths = backPaths.map((b) => this.mapParams(route.snapshot, b));
    let navigated = false;
    if (this.history.length === 0) {
      return backPaths[0];
    } else {
      for (const h of [...this.history].reverse()) {
        if (backPaths?.includes(h) && !navigated) {
          navigated = true;
          return h;
        }
      }
      if (!navigated) {
        return backPaths[0];
      }
    }
    return undefined;
  }

  private mapParams(snapshot: ActivatedRouteSnapshot, backPath?: string): string {
    while (backPath?.includes(':')) {
      backPath = backPath.replace(/:[a-zA-Z]*/, snapshot.params[backPath.split(':')[1].split('/')[0]]);
    }
    return backPath || '';
  }

  navigateTo(router: Router, backPath?: string | null, queryParams?: Params): void {
    if (backPath) {
      router.navigate([backPath], { queryParams });
    }
  }
}
