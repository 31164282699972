<table class="static-table full-width {{ environment.app }}" cellspacing="0">
  <thead>
    <tr>
      @for (column of columns; track column) {
        <th [ngStyle]="{ 'text-align': columnConfigs[column].alignText() }">
          {{ columnConfigs[column].title }}
        </th>
      }
    </tr>
  </thead>
  <tbody>
    @for (row of rows; track row) {
      @if (!rowConfigs[row].hide()) {
        <tr>
          @for (column of columns; track column) {
            <td [ngStyle]="{ 'text-align': columnConfigs[column].alignText() }">
              {{ columnConfigs[column].value(rowConfigs[row]) }}
            </td>
          }
        </tr>
      }
    }
    <ng-container [ngTemplateOutlet]="Total"></ng-container>
  </tbody>
</table>

<ng-template #Total>
  @if (!environment.isCharity && !config.hidePrices) {
    <tr class="total" class="primary-row top-border-row">
      <td [attr.colspan]="!config.hideUnitPrices ? 3 : 2">
        {{ config.totalLabel }}
      </td>
      <td style="text-align: right">
        {{ (total || 0) + (config.includeBookingInTotal ? data.pricing.bookingFee || 0 : 0) | currency }}
      </td>
    </tr>
  }
  @if (environment.isCaptain && !config.hidePrices && config.showDiscount) {
    <tr class="total" class="primary-row">
      <td [attr.colspan]="!config.hideUnitPrices ? 3 : 2">Donor Pays</td>
      <td style="text-align: right">
        {{ (total || 0) + (config.includeBookingInTotal ? data.pricing.bookingFee || 0 : 0) - sponsoredAmount | currency }}
      </td>
    </tr>
  }
  @if (environment.isCaptain && !config.hidePrices && config.showDiscount) {
    <tr class="total" class="primary-row">
      <td [attr.colspan]="!config.hideUnitPrices ? 3 : 2">Charity Pays</td>
      <td style="text-align: right">
        {{ sponsoredAmount | currency }}
      </td>
    </tr>
  }
</ng-template>
