import { Component, HostBinding, OnInit } from '@angular/core';
import { Design } from '@domains';
import { takeUntil } from 'rxjs/operators';

import { Responsive, ResponsiveService } from '../responsive';
import { DesignService } from './design.service';

@Component({
  template: '',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class Designable extends Responsive implements OnInit {
  @HostBinding('class.design-1') public design1 = false;
  @HostBinding('class.design-2') public design2 = false;

  designs = Design;

  #design?: Design;
  set design(design: Design | undefined) {
    this.#design = design;
    this.design1 = this.design === Design.DESIGN_1;
    this.design2 = this.design === Design.DESIGN_2;
  }

  get design(): Design | undefined {
    return this.#design;
  }

  constructor(
    protected designService: DesignService,
    override responsiveService: ResponsiveService,
  ) {
    super(responsiveService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.designService.design$.pipe(takeUntil(this.destroy$)).subscribe((design) => (this.design = design));
  }
}
