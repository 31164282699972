export enum HistoryEntity {
  DONATION = 'DONATION',
  LEAD = 'LEAD',
  CHARITY = 'CHARITY',
  PARTNER = 'PARTNER',
  STORE = 'STORE',
  TRUCK = 'TRUCK',
  SCHEDULE = 'SCHEDULE',
  XMILE = 'EXTRA MILE',
  MARKET = 'MARKET',
  USER = 'USER',
  JOURNEY = 'JOURNEY',
  ORGANIZATION = 'ORGANIZATION',
  PHONE_CONFIGURATION = 'PHONE_CONFIGURATION',
}
