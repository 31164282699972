<div class="audio-controls" [ngClass]="{ suffix: isSuffix }">
  @if (audio && isSuffix) {
    <button type="button" mat-icon-button (click)="toggle($event)">
      <mat-icon>audio_file</mat-icon>
    </button>
  }
  @if (showControls || !isSuffix) {
    <div fxLayout="row">
      @if (audio) {
        <audio controls>
          <source [src]="audio" />
        </audio>
      }
      @if (isSuffix) {
        <div fxLayoutAlign="center center">
          <button type="button" mat-icon-button (click)="toggle($event)">
            <mat-icon>keyboard_double_arrow_right</mat-icon>
          </button>
        </div>
      }
    </div>
  }
</div>
