// tslint:disable: max-line-length
import { StripePayments } from '@domains';
import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './payment.actions';

export interface PaymentsState {
  payments: StripePayments;
}

const initialState: PaymentsState = {
  payments: null,
};

const reducer = createReducer(
  initialState,

  on(fromActions.getPayments, (state) => ({ ...state, payments: null })),
  on(fromActions.getPaymentsSuccess, (state, { results }) => ({ ...state, payments: results })),
);

export function paymentsReducer(state: PaymentsState | undefined, action: Action): PaymentsState {
  return reducer(state, action);
}
