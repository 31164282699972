import { autoserialize, autoserializeAs } from 'cerialize';

import { Address, IAddress } from './address';
import { BaseDTO, IBaseDTO } from './base-dto';
import { Charity, ICharity } from './charity';
import { Contact, IContact } from './contact';
import { IStoreMeta, StoreMeta } from './store-meta';

export class CharityStore extends BaseDTO implements ICharityStore {
  @autoserialize id?: string;
  @autoserializeAs('charity_id') charityId?: string | null;
  @autoserializeAs('created_at') createdAt?: string | null;
  @autoserializeAs('updated_at') updatedAt?: string | null;
  @autoserializeAs('discarded_at') discardedAt?: string | null;
  @autoserializeAs('donation_distance') donationDistance?: number | null;
  @autoserializeAs('dropoff_longitude') dropOffLng?: number | null;
  @autoserializeAs('dropoff_latitude') dropOffLat?: number | null;
  @autoserialize override name: string;
  @autoserialize instructions?: string | null;
  @autoserialize address: Address;
  @autoserializeAs('primary_contact') primaryContact: Contact;
  @autoserializeAs('courier_contact') courierContact: Contact;
  @autoserialize meta: StoreMeta;
  @autoserialize charity?: Charity | null;
  dropOffLngLat?: [number, number] | null;

  public get zip(): string | undefined {
    return this.address?.zip;
  }

  public get state(): string | undefined {
    return this.address?.state;
  }

  public get phone(): string {
    return this.primaryContact?.phone;
  }

  public get fullAddress(): string {
    return this.address.fullAddress;
  }

  constructor(payload?: ICharityStore) {
    super(payload);
    this.id = payload?.id?.toString();
    this.charity = payload?.charity ? new Charity(payload?.charity) : undefined;
    this.charityId = payload?.charityId?.toString() || this.charity?.id;
    this.createdAt = payload?.createdAt;
    this.updatedAt = payload?.updatedAt;
    this.name = payload?.name || '';
    this.address = new Address(payload?.address);
    this.primaryContact = new Contact(payload?.primaryContact);
    this.courierContact = payload?.courierContact ? new Contact(payload?.courierContact) : null;
    this.meta = new StoreMeta(payload?.meta);
    this.instructions = payload?.instructions;
    this.discardedAt = payload?.discardedAt;
    this.donationDistance = payload?.donationDistance;
    this.dropOffLng = payload?.dropOffLng;
    this.dropOffLat = payload?.dropOffLat;
    if (this.dropOffLng && this.dropOffLat) {
      this.dropOffLngLat = [this.dropOffLng, this.dropOffLat];
    }
  }
}

export interface ICharityStore extends IBaseDTO {
  id?: string;
  charityId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  name: string;
  address: IAddress;
  primaryContact: IContact;
  courierContact: IContact;
  meta?: IStoreMeta | null;
  instructions?: string | null;
  discardedAt?: string | null;
  charity?: ICharity | null;
  donationDistance?: number | null;
  dropOffLng?: number | null;
  dropOffLat?: number | null;
}
