import { CdkScrollable } from '@angular/cdk/scrolling';
import { Component, Inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';

import { FxLayoutAlignDirective, FxLayoutDirective } from '../flex-layout';

export interface ConfirmDialogData {
  title: string;
  yes?: string;
  no?: string;
  color: string;
  message: string;
  hideNo?: boolean;
}

@Component({
  selector: 'rspl-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatDialogActions, FxLayoutDirective, FxLayoutAlignDirective, MatButton],
})
export class ConfirmDialogComponent {
  public constructor(
    public reference: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
  ) {}

  public onChoice(choice: boolean): void {
    this.reference.close(choice);
  }
}
