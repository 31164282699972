<div class="content-wrapper map-wrapper {{ appType }}">
  <div>
    @if (!!theme && accessToken) {
      <mgl-map
        #map
        [style]="theme === themes.DARK ? 'mapbox://styles/mapbox/dark-v10' : 'mapbox://styles/mapbox/light-v9'"
        [fitBounds]="fitBounds"
        [center]="center"
        [zoom]="zoom"
        [accessToken]="accessToken"
        [dragPan]="mapFocused || !isSmallWindowSize"
        (mapMouseDown)="mapFocused = true"
        (mapMouseOut)="mapFocused = false"
        [fitBoundsOptions]="{ linear: !animations }"
      >
        @if (showZoom) {
          <mgl-control mglNavigation [showCompass]="false" position="bottom-right"></mgl-control>
        }

        @if (showZips) {
          @if (hoverableZips && hoveredZipId) {
            <mgl-popup #zipPopup [closeButton]="false" [closeOnClick]="false" [lngLat]="[zipPopupLngLat[0], zipPopupLngLat[1]]">
              <span class="zip-id">{{ hoveredZipId }}</span>
            </mgl-popup>
          }
          <mgl-vector-source
            id="zips"
            url="mapbox://jn1532.2z2q31r2"
            [promoteId]="{ 'zip5_topo_color-2bf335': 'ZIP5' }"
          ></mgl-vector-source>
          @if (showAllZips) {
            <mgl-layer
              id="zip"
              type="fill"
              source="zips"
              [paint]="disabled ? disabledPaint : paint"
              sourceLayer="zip5_topo_color-2bf335"
              (layerClick)="selectableZips ? zipClick($event) : undefined"
              (layerMouseMove)="hoverableZips ? zipMouseMove($event) : undefined"
              (layerMouseLeave)="hoverableZips ? zipMouseMove($event) : undefined"
            ></mgl-layer>
          }
          <mgl-layer
            #selectedLayer
            id="selected-zip"
            type="fill"
            source="zips"
            [paint]="selectedPaint"
            sourceLayer="zip5_topo_color-2bf335"
            [filter]="zipFilters"
          ></mgl-layer>
          @if (showTruckStoreHoverPopup && (hoveredTruckIds.length > 0 || hoveredStoreIds.length > 0)) {
            <mgl-popup #popup [closeButton]="false" [closeOnClick]="false" [lngLat]="truckStorePopupLngLat">
              @if (hoveredTruckIds.length > 0) {
                <div [ngClass]="{ 'preview-item': storeZipIds.length > 0 }">
                  @if (storeZipIds.length > 0) {
                    <label>TRUCKS:</label>
                  }
                  @for (t of hoveredTruckIds; track t) {
                    <p>
                      {{
                        hoveredTrucks[t].partner?.marketId && marketsMap[hoveredTrucks[t].partner?.marketId || '']
                          ? marketsMap[hoveredTrucks[t].partner?.marketId || ''].name + ' - '
                          : ''
                      }}{{ hoveredTrucks[t].partner?.name ? hoveredTrucks[t].partner?.name + ' - ' : '' }}{{ hoveredTrucks[t].name }}
                    </p>
                  }
                </div>
              }
              @if (hoveredStoreIds.length > 0) {
                <div [ngClass]="{ 'preview-item': truckZipIds.length > 0 }">
                  @if (truckZipIds.length > 0) {
                    <label>STORES:</label>
                  }
                  @for (r of hoveredStoreIds; track r) {
                    <p>
                      {{
                        hoveredStores[r].charity?.marketId && marketsMap[hoveredStores[r].charity?.marketId || '']
                          ? marketsMap[hoveredStores[r].charity?.marketId || ''].name + ' - '
                          : ''
                      }}{{ hoveredStores[r].charity?.name ? hoveredStores[r].charity?.name + ' - ' : '' }}{{ hoveredStores[r].name }}
                    </p>
                  }
                </div>
              }
            </mgl-popup>
          }
          @if ((selectableTruckZips && selectedTruck?.id) || (selectableStoreZips && selectedStore?.id)) {
            <mgl-layer
              id="selected-edit-zip"
              type="fill"
              source="zips"
              [paint]="truckStoreSelectedZipPaint"
              sourceLayer="zip5_topo_color-2bf335"
              [filter]="
                selectedTruck?.id ? truckZips[selectedTruck?.id || ''] : selectedStore?.id ? storeZips[selectedStore?.id || ''] : undefined
              "
            ></mgl-layer>
          }
          @for (truck of trucks; track truck; let i = $index) {
            @if (
              truck.id &&
              (truckZips[truck.id]?.length || 0) > 0 &&
              (!selectedTruckPaints || (truck.id && selectedTruckPaints[truck.id || '']))
            ) {
              <mgl-layer
                #selectedLayer
                id="selected-truck-zip-{{ truck.id }}"
                type="fill"
                source="zips"
                [paint]="
                  selectedTruckPaints && truck.id && selectedTruckPaints[truck.id || '']
                    ? selectedTruckPaints[truck.id || '']
                    : selectedTruckPaint
                "
                sourceLayer="zip5_topo_color-2bf335"
                [filter]="truckZips[truck.id]"
                (layerMouseMove)="hoverableTruckZips ? truckStoreZipsMouseMove($event, truck.id, 'truck') : undefined"
                (layerMouseLeave)="hoverableTruckZips ? truckStoreZipsMouseMove($event, truck.id, 'truck') : undefined"
                (layerClick)="selectableTruckZips && showTruckStoreHoverPopup ? openTruckStoreChoice($event, truck.id, 'truck') : undefined"
              ></mgl-layer>
            }
          }
          @for (store of stores; track store; let i = $index) {
            @if (store.id && (storeZips[store.id]?.length || 0) > 0) {
              <mgl-layer
                #selectedLayer
                id="selected-store-zip-{{ store.id }}"
                type="fill"
                source="zips"
                [paint]="selectedStorePaints"
                [paint]="
                  selectedStorePaints && store.id && selectedStorePaints[store.id || '']
                    ? selectedStorePaints[store.id || '']
                    : selectedStorePaint
                "
                sourceLayer="zip5_topo_color-2bf335"
                [filter]="storeZips[store.id]"
                (layerMouseMove)="hoverableStoreZips ? truckStoreZipsMouseMove($event, store.id, 'store') : undefined"
                (layerMouseLeave)="hoverableStoreZips ? truckStoreZipsMouseMove($event, store.id, 'store') : undefined"
                (layerClick)="selectableStoreZips && showTruckStoreHoverPopup ? openTruckStoreChoice($event, store.id, 'store') : undefined"
              ></mgl-layer>
            }
          }
        }

        @if (showDonations) {
          @for (donation of donations; track donation; let i = $index) {
            @if (donation?.address?.lng && donation?.address?.lat) {
              <mgl-marker
                [lngLat]="[donation?.address?.lng || 0, donation?.address?.lat || 0]"
                className="{{ selectedDonation?.id === donation.id ? 'selected-marker' : '' }} {{
                  hoveredDonation?.id === donation.id ? 'hovered-marker' : ''
                }}{{
                  donation.id && journeyDonationsMap[donation.id] && journeyDonationsMap[donation.id] < (nextStep || 0) ? 'success-pin' : ''
                }}"
              >
                <div
                  class="donation-pin"
                  fxLayoutAlign="center center"
                  fxLayout="row"
                  (click)="selectedDonation = selectedDonation?.id === donation.id ? undefined : donation"
                  (mouseover)="!isSmallWindowSize ? (hoveredDonation = donation) : undefined; showTruckStoreHoverPopup = false"
                  (mouseout)="!isSmallWindowSize ? (hoveredDonation = undefined) : undefined; showTruckStoreHoverPopup = true"
                  [ngClass]="
                    donation.id && donationClasses && donationClasses[donation.id || '']
                      ? donationClasses[donation.id || '']
                      : {
                          success: showDonationStateColor && donation.partnerState === partnerStates.completed,
                          warning:
                            showDonationStateColor &&
                            donation.donorState !== donorStates.canceled &&
                            ![partnerStates.completed, partnerStates.canceled].includes(donation.partnerState),
                          error:
                            showDonationStateColor &&
                            (donation.donorState === donorStates.canceled || donation.partnerState === partnerStates.canceled),
                          hovered: hoveredDonation?.id === donation.id,
                          selected: selectedDonation?.id === donation.id,
                          'has-number': (journey?.stops?.length || 0) > 0,
                        }
                  "
                >
                  <mat-icon class="donation-icon" svgIcon="parachute-icon" />
                </div>
                @if ((journey?.stops?.length || 0) > 0 && donation.id) {
                  <div
                    class="pin-number"
                    fxLayoutAlign="center center"
                    fxLayout="row"
                    (click)="selectedDonation = selectedDonation?.id === donation.id ? undefined : donation"
                    (mouseover)="!isSmallWindowSize ? (hoveredDonation = donation) : undefined"
                    (mouseout)="!isSmallWindowSize ? (hoveredDonation = undefined) : undefined"
                    [ngClass]="{
                      hovered: hoveredDonation?.id === donation.id,
                      selected: selectedDonation?.id === donation.id,
                      'out-of-journey': journeyDonationsMap[donation.id] === undefined,
                      success: donation.id && journeyDonationsMap[donation.id] && journeyDonationsMap[donation.id] < (nextStep || 0),
                    }"
                  >
                    {{
                      journeyDonationsMap[donation.id] === 0 || !!journeyDonationsMap[donation.id]
                        ? journeyDonationsMap[donation.id] + (skipFirstStep ? 0 : 1)
                        : ''
                    }}
                  </div>
                }
              </mgl-marker>
            }
          }
          @if (showDonationPopup && selectedDonation && selectedDonationLocation) {
            <mgl-popup
              [closeButton]="true"
              [closeOnClick]="false"
              [lngLat]="selectedDonationLocation"
              className="pin-popup"
              (popupClose)="selectedDonation = undefined"
            >
              <div class="pin-popup">
                <div fxLayout="row" fxLayoutGap="30px">
                  <div class="preview-item">
                    <label>Donation ID</label>
                    <p>
                      <a [routerLink]="['/', 'donations', selectedDonation.id]" class="donationCode">{{ selectedDonation.donationCode }}</a>
                    </p>
                  </div>
                  <div class="preview-item">
                    <label>Price</label>
                    <p>{{ selectedDonation.total | currency }}</p>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutGap="30px">
                  <div class="preview-item">
                    <label>Partner State</label>
                    <p>{{ selectedDonation.partnerState | uppercase }}</p>
                  </div>
                  <div class="preview-item">
                    <label>Donor State</label>
                    <p>{{ selectedDonation.donorState | uppercase }}</p>
                  </div>
                </div>
                <div class="preview-item">
                  <label>Partner</label>
                  <p>{{ selectedDonation.partner?.name || '/' }}</p>
                </div>
                <div class="preview-item">
                  <label>Charity</label>
                  <p>{{ selectedDonation.charity?.name || '/' }}</p>
                </div>
                @if (selectedDonation.partnerComment && isCaptain) {
                  <div class="preview-item">
                    <label>Declined Notes</label>
                    <p>{{ selectedDonation.partnerComment }}</p>
                  </div>
                }
                @if (selectedDonation.terminationReason && isCaptain) {
                  <div class="preview-item">
                    <label>Cancelled Notes</label>
                    <p>{{ selectedDonation.terminationReason }}</p>
                  </div>
                }
                @if (selectedDonation?.donationNotes && isCaptain) {
                  <div class="preview-item">
                    <label>Captain Notes</label>
                    <p>{{ selectedDonation.donationNotes }}</p>
                  </div>
                }
              </div>
            </mgl-popup>
          }
        }
        @if (showPartners) {
          @for (partner of partners; track partner; let i = $index) {
            @if (partner?.address?.lng && partner?.address?.lat) {
              <mgl-marker
                [lngLat]="[partner?.address?.lng || 0, partner?.address?.lat || 0]"
                className="{{ selectedPartner?.id === partner.id ? 'selected-marker' : '' }} {{
                  hoveredPartner?.id === partner.id ? 'hovered-marker' : ''
                }}"
              >
                <div
                  class="partner-pin"
                  fxLayoutAlign="center center"
                  (click)="selectedPartner = selectedPartner?.id === partner.id ? undefined : partner"
                  (mouseover)="!isSmallWindowSize ? (hoveredPartner = partner) : undefined; showTruckStoreHoverPopup = false"
                  (mouseout)="!isSmallWindowSize ? (hoveredPartner = undefined) : undefined; showTruckStoreHoverPopup = true"
                  fxLayout="row"
                  [ngClass]="{
                    hovered: hoveredPartner?.id === partner.id,
                    selected: selectedPartner?.id === partner.id,
                    'has-number':
                      !skipFirstStep && partner.id && (journeyPartnersMap[partner.id] === 0 || !!journeyPartnersMap[partner.id]),
                  }"
                >
                  @if (partnerPinTemplate) {
                    <ng-container [ngTemplateOutlet]="partnerPinTemplate"></ng-container>
                  }
                  @if (!partnerPinTemplate) {
                    <mat-icon class="partner-icon" svgIcon="truck-icon" />
                  }
                </div>
                @if (!skipFirstStep && partner.id && (journeyPartnersMap[partner.id] === 0 || !!journeyPartnersMap[partner.id])) {
                  <div
                    class="pin-number"
                    fxLayoutAlign="center center"
                    fxLayout="row"
                    (click)="selectedPartner = selectedPartner?.id === partner.id ? undefined : partner"
                    (mouseover)="!isSmallWindowSize ? (hoveredPartner = partner) : undefined"
                    (mouseout)="!isSmallWindowSize ? (hoveredPartner = undefined) : undefined"
                    [ngClass]="{
                      hovered: hoveredPartner?.id === partner.id,
                      selected: selectedPartner?.id === partner.id,
                    }"
                  >
                    {{ journeyPartnersMap[partner.id] + 1 }}
                  </div>
                }
              </mgl-marker>
            }
          }
          @if (showPartnerPopup && selectedPartner && selectedPartnerLocation) {
            <mgl-popup
              [closeButton]="true"
              [closeOnClick]="false"
              [lngLat]="selectedPartnerLocation"
              className="pin-popup"
              (popupClose)="selectedPartner = undefined"
            >
              <div class="pin-popup">
                <div class="preview-item">
                  <label>Partner</label>
                  <p>{{ selectedPartner.name }}</p>
                </div>
                <div class="preview-item">
                  <label>Address</label>
                  <p>{{ selectedPartner.fullAddress }}</p>
                </div>
                @if (marketsTotalCount > 0) {
                  <div class="preview-item">
                    <label>Market</label>
                    <p>
                      {{ (selectedPartner.marketId ? marketsMap[selectedPartner.marketId].name : '/') || '/' }}
                    </p>
                  </div>
                }
              </div>
            </mgl-popup>
          }
        }
        @if (showTrucks) {
          @for (truck of trucks; track truck; let i = $index) {
            @if ((truck.lat && truck.lng) || (truck?.partner?.address?.lng && truck?.partner?.address?.lat)) {
              <mgl-marker [lngLat]="[truck.lng || truck?.partner?.address?.lng || 0, truck.lat || truck?.partner?.address?.lat || 0]">
                <div
                  class="truck-pin"
                  fxLayoutAlign="center center"
                  (click)="selectTruck(truck)"
                  fxLayout="row"
                  (mouseover)="showTruckStoreHoverPopup = false"
                  (mouseout)="showTruckStoreHoverPopup = true"
                >
                  @if (truckPinTemplate) {
                    <ng-container [ngTemplateOutlet]="truckPinTemplate"></ng-container>
                  }
                  @if (!partnerPinTemplate) {
                    <mat-icon class="truck-icon" svgIcon="truck-icon" />
                  }
                </div>
              </mgl-marker>
            }
          }
          @if (showTruckPopup && selectedTruck && selectedTruckLocation) {
            <mgl-popup
              [closeButton]="true"
              [closeOnClick]="false"
              [lngLat]="selectedTruckLocation"
              className="pin-popup"
              (popupClose)="selectTruck(undefined)"
            >
              <div class="preview-item">
                <label>Partner</label>
                <p>{{ selectedTruck.partner?.name }}</p>
              </div>
              <div class="preview-item">
                <label>Truck</label>
                <p>{{ selectedTruck.name }}</p>
              </div>
            </mgl-popup>
          }
        }
        @if (showCharities) {
          @for (charity of charities; track charity; let i = $index) {
            @if (charity?.address?.lng && charity?.address?.lat) {
              <mgl-marker [lngLat]="[charity?.address?.lng || 0, charity?.address?.lat || 0]">
                <div
                  class="charity-pin"
                  fxLayoutAlign="center center"
                  (click)="selectCharity(charity)"
                  fxLayout="row"
                  (mouseover)="showTruckStoreHoverPopup = false"
                  (mouseout)="showTruckStoreHoverPopup = true"
                >
                  @if (charity.logo && !hideStoreLogo) {
                    <img class="charity-logo" [src]="charity.logo" />
                  }
                  @if (!charity.logo || hideStoreLogo) {
                    <mat-icon svgIcon="charity-icon" color="primary" />
                  }
                </div>
              </mgl-marker>
            }
          }
          @if (showCharityPopup && selectedCharity && selectedCharityLocation) {
            <mgl-popup
              [closeButton]="true"
              [closeOnClick]="false"
              [lngLat]="selectedCharityLocation"
              className="pin-popup"
              (popupClose)="selectCharity(undefined)"
            >
              <div class="pin-popup">
                <div class="preview-item">
                  <label>Charity Name</label>
                  <p>{{ selectedCharity.name }}</p>
                </div>
              </div>
              <div class="preview-item">
                <label>Address</label>
                <p>{{ selectedCharity.fullAddress }}</p>
              </div>
              @if (marketsTotalCount > 0) {
                <div class="preview-item">
                  <label>Market</label>
                  <p>
                    {{ (selectedCharity.marketId ? marketsMap[selectedCharity.marketId].name : '/') || '/' }}
                  </p>
                </div>
              }
            </mgl-popup>
          }
        }
        @if (showStores) {
          @for (store of stores; track store; let i = $index) {
            @if (store?.address?.lng && store?.address?.lat) {
              <mgl-marker
                [lngLat]="[store?.address?.lng || 0, store?.address?.lat || 0]"
                className="{{ selectedStore?.id === store.id ? 'selected-marker' : '' }} {{
                  hoveredStore?.id === store.id ? 'hovered-marker' : ''
                }} {{ store.id && journeyStoresMap[store.id] && journeyStoresMap[store.id] < (nextStep || 0) ? 'success-pin' : '' }}"
              >
                <div
                  class="store-pin"
                  fxLayoutAlign="center center"
                  (click)="selectedStore = selectedStore?.id === store.id ? undefined : store"
                  (mouseover)="!isSmallWindowSize ? (hoveredStore = store) : undefined; showTruckStoreHoverPopup = false"
                  (mouseout)="!isSmallWindowSize ? (hoveredStore = undefined) : undefined; showTruckStoreHoverPopup = true"
                  fxLayout="row"
                  [ngClass]="{
                    hovered: hoveredStore?.id === store.id,
                    selected: selectedStore?.id === store.id,
                    'has-number': store.id && (journeyStoresMap[store.id] === 0 || !!journeyStoresMap[store.id]),
                  }"
                >
                  @if (store.charity?.logo && !hideStoreLogo) {
                    <img class="store-logo" [src]="store.charity?.logo" />
                  }
                  @if (!store.charity?.logo || hideStoreLogo) {
                    <mat-icon svgIcon="charities-icon" color="primary" />
                  }
                  @if (
                    hoveredDonation?.storeId === store.id ||
                    (!hoveredDonation && selectedDonation?.storeId === store.id) ||
                    (showSelectedStoreFlag && selectedStore?.id === store.id)
                  ) {
                    <mat-icon class="donation-store" color="primary">check</mat-icon>
                  }
                </div>
                @if (store.id && (journeyStoresMap[store.id] === 0 || !!journeyStoresMap[store.id])) {
                  <div
                    class="pin-number"
                    fxLayoutAlign="center center"
                    fxLayout="row"
                    (click)="selectedStore = selectedStore?.id === store.id ? undefined : store"
                    (mouseover)="!isSmallWindowSize ? (hoveredStore = store) : undefined"
                    (mouseout)="!isSmallWindowSize ? (hoveredStore = undefined) : undefined"
                    [ngClass]="{
                      hovered: hoveredStore?.id === store.id,
                      selected: selectedStore?.id === store.id,
                      success: store.id && journeyStoresMap[store.id] && journeyStoresMap[store.id] < (nextStep || 0),
                    }"
                  >
                    {{ journeyStoresMap[store.id] + (skipFirstStep ? 0 : 1) }}
                  </div>
                }
              </mgl-marker>
            }
          }
          @if (showStorePopup && selectedStore && selectedStoreLocation) {
            <mgl-popup
              [closeButton]="true"
              [closeOnClick]="false"
              [lngLat]="selectedStoreLocation"
              className="pin-popup"
              (popupClose)="selectedStore = undefined"
            >
              <div class="preview-item">
                <label>Charity</label>
                <p>{{ selectedStore.charity?.name }}</p>
              </div>
              <div class="preview-item">
                <label>Store</label>
                <p>{{ selectedStore.name }}</p>
              </div>
              <div class="preview-item">
                <label>Address</label>
                <p>{{ selectedStore.fullAddress }}</p>
              </div>
              @if (marketsTotalCount > 0) {
                <div class="preview-item">
                  <label>Market</label>
                  <p>
                    {{ (selectedStore.charity?.marketId ? marketsMap[selectedStore.charity?.marketId || ''].name : '/') || '/' }}
                  </p>
                </div>
              }
            </mgl-popup>
          }
        }
        @if (showXmiles) {
          @for (xmile of xmiles; track xmile; let i = $index) {
            @if (xmile?.address?.lng && xmile?.address?.lat) {
              <mgl-marker [lngLat]="[xmile?.address?.lng || 0, xmile?.address?.lat || 0]">
                <div
                  class="xmile-pin"
                  fxLayoutAlign="center center"
                  (click)="selectXmile(xmile)"
                  fxLayout="row"
                  (mouseover)="showTruckStoreHoverPopup = false"
                  (mouseout)="showTruckStoreHoverPopup = true"
                >
                  <mat-icon color="primary">business</mat-icon>
                </div>
              </mgl-marker>
            }
          }
          @if (showXmilePopup && selectedXmile && selectedXmileLocation) {
            <mgl-popup
              [closeButton]="true"
              [closeOnClick]="false"
              [lngLat]="selectedXmileLocation"
              className="pin-popup"
              (popupClose)="selectXmile(undefined)"
            >
              <div class="pin-popup">
                <div class="preview-item">
                  <label>Extra Mile</label>
                  <p>{{ selectedXmile.name }}</p>
                </div>
                <div class="preview-item">
                  <label>Address</label>
                  <p>{{ selectedXmile.fullAddress }}</p>
                </div>
                @if (selectedXmile && day) {
                  <div class="preview-item">
                    <label>Working Hours</label>
                    <p>
                      {{ selectedXmile.getTime(day).opens }} -
                      {{ selectedXmile.getTime(day).closes }}
                    </p>
                  </div>
                }
                @if (marketsTotalCount > 0) {
                  <div class="preview-item">
                    <label>Market</label>
                    <p>
                      {{ (selectedXmile.marketId ? marketsMap[selectedXmile.marketId].name : '/') || '/' }}
                    </p>
                  </div>
                }
              </div>
            </mgl-popup>
          }
        }
        @if (showRoute) {
          @if (route && primaryColor) {
            <mgl-layer
              [id]="routeID"
              type="line"
              [source]="route"
              [layout]="{ 'line-join': 'round', 'line-cap': 'round' }"
              [paint]="{
                'line-color': primaryColor,
                'line-width': 3,
                'line-opacity': 0.8,
              }"
              #layer
            ></mgl-layer>
          }
        }
        @if (showEvents) {
          @for (event of events; track event; let i = $index) {
            @if (event.lat && event.lng) {
              <mgl-marker
                [lngLat]="[event.lng, event.lat]"
                className="event-marker {{ selectedEvent?.id === event.id ? 'selected-marker' : '' }} {{
                  hoveredEvent?.id === event.id ? 'hovered-marker' : ''
                }}"
              >
                <div
                  class="pin-number event-number"
                  fxLayoutAlign="center center"
                  fxLayout="row"
                  (click)="selectedEvent = selectedEvent?.id === event.id ? undefined : event"
                  (mouseover)="!isSmallWindowSize ? (hoveredEvent = event) : undefined; showTruckStoreHoverPopup = false"
                  (mouseout)="!isSmallWindowSize ? (hoveredEvent = undefined) : undefined; showTruckStoreHoverPopup = true"
                  [ngClass]="{
                    hovered: hoveredEvent?.id === event.id,
                    selected: selectedEvent?.id === event.id,
                  }"
                >
                  {{ i + 1 }}
                </div>
              </mgl-marker>
            }
          }
        }
        @if (journey?.jobs) {
          @for (stop of customStops; track stop; let i = $index) {
            @if (getJob(stop.id)) {
              <mgl-marker
                [lngLat]="[getJob(stop.id)?.lng || 0, getJob(stop.id)?.lat || 0]"
                className="custom-stop-marker {{ selectedCustomStop?.id === stop.id ? 'selected-marker' : '' }} {{
                  hoveredCustomStop?.id === stop.id ? 'hovered-marker' : ''
                }}"
              >
                <div
                  class="custom-stop-pin"
                  fxLayoutAlign="center center"
                  fxLayout="row"
                  (click)="selectedCustomStop = selectedCustomStop?.id === stop.id ? undefined : stop"
                  (mouseover)="!isSmallWindowSize ? (hoveredCustomStop = stop) : undefined; showTruckStoreHoverPopup = false"
                  (mouseout)="!isSmallWindowSize ? (hoveredCustomStop = undefined) : undefined; showTruckStoreHoverPopup = true"
                  [ngClass]="{
                    hovered: hoveredCustomStop?.id === stop.id,
                    selected: selectedCustomStop?.id === stop.id,
                    'has-number': (journey?.stops?.length || 0) > 0,
                  }"
                >
                  <mat-icon>location_on</mat-icon>
                </div>
                <div
                  class="pin-number custom-stop-number"
                  fxLayoutAlign="center center"
                  fxLayout="row"
                  (click)="selectedCustomStop = selectedCustomStop?.id === stop.id ? undefined : stop"
                  (mouseover)="!isSmallWindowSize ? (hoveredCustomStop = stop) : undefined; showTruckStoreHoverPopup = false"
                  (mouseout)="!isSmallWindowSize ? (hoveredCustomStop = undefined) : undefined; showTruckStoreHoverPopup = true"
                  [ngClass]="{
                    hovered: hoveredCustomStop?.id === stop.id,
                    selected: selectedCustomStop?.id === stop.id,
                  }"
                >
                  {{ stop.order + 1 }}
                </div>
              </mgl-marker>
            }
          }
        }
        @if (userLocation) {
          <mgl-marker class="user-location-marker" [lngLat]="userLocation">
            <div class="user-location" fxLayout="row"></div>
          </mgl-marker>
        }
        @if (address && address.lng && address.lat) {
          <mgl-marker [lngLat]="[address.lng, address.lat]"></mgl-marker>
        }
      </mgl-map>
    }
  </div>
  @if (showSettings) {
    <div class="map-buttons" fxLayout="column" fxLayoutGap="5px">
      <button
        mat-icon-button
        (click)="animations = !animations"
        [matTooltip]="'Animations ' + (animations ? 'On' : 'Off')"
        matTooltipPosition="left"
        matTooltipShowDelay="1000"
      >
        @if (animations) {
          <mat-icon>auto_fix_normal</mat-icon>
        }
        @if (!animations) {
          <mat-icon>auto_fix_off</mat-icon>
        }
      </button>
      <button
        mat-icon-button
        (click)="updateBounds(true, true)"
        matTooltip="Show all pins"
        matTooltipPosition="left"
        matTooltipShowDelay="1000"
      >
        <mat-icon>zoom_out_map</mat-icon>
      </button>
    </div>
  }
  @if (showAddressSearch) {
    <rspl-simple-address-input
      class="addr-input"
      [address]="address"
      (addressChange)="setAddress($event)"
      fxFlex="70"
      fxFlex.lt-lg="100"
      label="Search"
      [hasIcon]="true"
      dropdownPosition="right"
      [required]="false"
      [types]="['address', 'postcode']"
    ></rspl-simple-address-input>
  }
</div>
