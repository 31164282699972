<rspl-card class="schedule-wrapper">
  @if (!config.onlyDefault) {
    <mat-tab-group (selectedIndexChange)="selectedIndexChange($event)" #tabGroup>
      <mat-tab label="Default Schedule" />
      <mat-tab label="Weekly Schedule" [disabled]="isDefault && !schedule?.schedule?.id" />
    </mat-tab-group>
  }
  @if (!isDefault && !day) {
    <rspl-week-picker #weekPicker (weekChanged)="schedule.fetchData(weekPicker.week, weekPicker.year)" />
  }
  <app-schedule-form
    #schedule
    [config]="config"
    [isDefault]="isDefault"
    [week]="weekPicker()?.week"
    [day]="day"
    [year]="weekPicker()?.year"
    [showWarning]="!config.onlyDefault"
    [showMap]="showMap"
  ></app-schedule-form>
</rspl-card>
