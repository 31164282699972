import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Budget, ENVIRONMENT, Environment, Transfer } from '@domains';
import { Deserialize, Serialize } from 'cerialize';

import { BaseApiService } from '../base-api.service';

@Injectable({
  providedIn: 'root',
})
export class BudgetsService extends BaseApiService<Budget> {
  constructor(
    @Inject(ENVIRONMENT) override config: Environment,
    override http: HttpClient,
  ) {
    super(config, http, 'budgets', Budget, 'Budget');
  }

  override serialize(item: Budget) {
    return {
      ...Serialize(
        {
          ...item,
        },
        Budget,
      ),
    };
  }

  override deserialize(data: any): Budget {
    const res = new Budget({
      ...Deserialize(data, Budget),
      transfers: data.transfers ? data.transfers.map((t: any) => Deserialize(t, Transfer)) : [],
    });
    res.setInitialValue();
    return res;
  }
}
