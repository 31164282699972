import { autoserialize, autoserializeAs } from 'cerialize';

import { Address, IAddress } from './address';
import { BaseDTO, IBaseDTO } from './base-dto';
import { Contact, IContact } from './contact';
import { OrganizationKind } from './organization-kind';

export class Organization extends BaseDTO implements IOrganization {
  @autoserialize id?: string;
  @autoserialize override name: string;
  @autoserialize kind?: OrganizationKind;
  @autoserialize address: Address;
  @autoserialize logo: string;
  @autoserializeAs(Contact, 'primary_contact') primaryContact: Contact;
  @autoserializeAs('discarded_at') discardedAt?: string;
  @autoserializeAs('allowed_charities') allowedCharities?: string[];

  public get fullAddress(): string {
    return this.address.fullAddress;
  }

  constructor(payload?: IOrganization) {
    super(payload);
    this.id = payload?.id?.toString();
    this.name = payload?.name || '';
    this.logo = payload?.logo || '';
    this.kind = payload?.kind;
    this.discardedAt = payload?.discardedAt;
    this.address = new Address(payload?.address);
    this.primaryContact = new Contact(payload?.primaryContact);
    this.allowedCharities = payload?.allowedCharities?.map((id) => id.toString()) || [];
  }

  get formattedKind(): string {
    switch (this.kind) {
      case OrganizationKind.Captain:
        return 'Captain';
      case OrganizationKind.HardwareStore:
        return 'Hardware Store';
      case OrganizationKind.Charity:
        return 'Charity';
      case OrganizationKind.MoveManager:
        return 'Move Manager';
      case OrganizationKind.Municipality:
        return 'Municipality';
      case OrganizationKind.Realtor:
        return 'Realtor';
      case OrganizationKind.SeniorLiving:
        return 'Senior Living';
      case OrganizationKind.Territory:
        return 'Territory';
      default:
        return '';
    }
  }
}

export interface IOrganization extends IBaseDTO {
  id?: string;
  name: string;
  logo?: string;
  kind?: OrganizationKind;
  address: IAddress;
  primaryContact: IContact;
  discardedAt?: string;
  allowedCharities?: string[];
}
