import { autoserialize, autoserializeAs } from 'cerialize';

import { Address, IAddress } from './address';
import { PartOfDay } from './part-of-day';

export class JourneyCustomStop extends Address {
  @autoserialize id: string;
  @autoserialize completed: boolean;
  @autoserializeAs('part_of_day') partOfDay: PartOfDay;

  constructor(payload: IJourneyCustomStop) {
    super(payload);
    this.id = payload?.id.toString();
    this.completed = !!payload?.completed;
    this.partOfDay = payload?.partOfDay;
  }
}

export interface IJourneyCustomStop extends IAddress {
  id: string;
  completed: boolean;
  partOfDay: PartOfDay;
}
