import { Component, Inject, output } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { AppType, ENVIRONMENT, Environment } from '@domains';

@Component({
  selector: 'rspl-content-zoom',
  templateUrl: './content-zoom.component.html',
  styles: [
    'button {background-color: var(--white); color: var(--black)}',
    'button.app-3PL {background-color: var(--main);}',
    'button.app-3PL mat-icon {color: var(--text-on-primary);}',
    'button.app-Captain {background-color: var(--main);}',
    'button.app-Captain mat-icon {color: var(--text-on-primary);}',
  ],
  imports: [MatIconButton, MatIcon],
})
export class ContentZoomComponent {
  readonly zoomUpdated = output();
  app: AppType;

  constructor(@Inject(ENVIRONMENT) private config: Environment) {
    this.app = this.config.app;
  }

  public zoomPage(): void {
    this.zoomUpdated.emit();
  }
}
