import { AvailabilityStats, CharityStore, FindAvailabilityParams, FindScheduleParams, FindStoreParams, Schedule } from '@domains';
import { createAction, props } from '@ngrx/store';

export enum StoreActionType {
  CreateStore = '[Store] Create Store',
  CreateStoreSuccess = '[Store] Create Store Success',

  UpdateStore = '[Store] Update Store',
  UpdateStoreSuccess = '[Store] Update Store Success',

  ClearStores = '[Store] Clear Stores',
  GetStores = '[Store] Get Stores',
  GetStoresSuccess = '[Store] Get Stores Success',

  GetClosestStores = '[Store] Get Closest Stores',
  GetClosestStoresSuccess = '[Store] Get Closest Stores Success',

  GetStateStores = '[Store] Get State Stores',
  GetStateStoresSuccess = '[Store] Get State Stores Success',

  GetCharityStores = '[Store] Get Charity Stores',
  GetCharityStoresSuccess = '[Store] Get Charity Stores Success',

  GetStore = '[Store] Get Store',
  GetStoreSuccess = '[Store] Get Store Success',

  GetStoreAvailabilityStats = '[Store] Get Store Availability Stats',
  GetStoreAvailabilityStatsSuccess = '[Store] Get Store Availability Stats Success',

  DeleteStore = '[Store] Delete Store',
  DeleteStoreSuccess = '[Store] Delete Store Success',

  RestoreStore = '[Store] Restore Store',
  RestoreStoreSuccess = '[Store] Restore Store Success',

  CreateStoreSchedule = '[Store] Create Store Schedule',
  CreateStoreScheduleSuccess = '[Store] Create Store Schedule Success',

  UpdateStoreSchedule = '[Store Schedule] Update Store Schedule',
  UpdateStoreScheduleSuccess = '[Store Schedule] Update Store Schedule Success',

  GetStoreSchedules = '[Store Schedule] Get Store Schedules',
  GetStoreSchedulesSuccess = '[Store Schedule] Get Store Schedules Success',

  GetStoreSchedule = '[Store Schedule] Get Store Schedule',
  GetStoreScheduleSuccess = '[Store Schedule] Get Store Schedule Success',

  DeleteStoreSchedule = '[Store Schedule] Delete Store Schedule',
  DeleteStoreScheduleSuccess = '[Store Schedule] Delete Store Schedule Success',
}

export const createStore = createAction(StoreActionType.CreateStore, props<{ store: CharityStore }>());
export const createStoreSuccess = createAction(StoreActionType.CreateStoreSuccess, props<{ store: CharityStore }>());

export const updateStore = createAction(StoreActionType.UpdateStore, props<{ store: CharityStore }>());
export const updateStoreSuccess = createAction(StoreActionType.UpdateStoreSuccess, props<{ store: CharityStore }>());

export const clearStores = createAction(StoreActionType.ClearStores);
export const getStores = createAction(StoreActionType.GetStores, props<{ findParams: FindStoreParams }>());
export const getStoresSuccess = createAction(
  StoreActionType.GetStoresSuccess,
  props<{ stores: Array<CharityStore>; resultsCount: number }>(),
);

export const getCharityStores = createAction(StoreActionType.GetCharityStores, props<{ charityId: string; keep?: boolean }>());
export const getCharityStoresSuccess = createAction(StoreActionType.GetCharityStoresSuccess, props<{ stores: Array<CharityStore> }>());

export const getStateStores = createAction(StoreActionType.GetStateStores, props<{ findParams: FindStoreParams }>());
export const getStateStoresSuccess = createAction(StoreActionType.GetStateStoresSuccess, props<{ stores: Array<CharityStore> }>());

export const getStore = createAction(StoreActionType.GetStore, props<{ id: string; keep?: boolean }>());
export const getStoreSuccess = createAction(StoreActionType.GetStoreSuccess, props<{ store: CharityStore }>());

export const getClosestStores = createAction(StoreActionType.GetClosestStores, props<{ id: string }>());
export const getClosestStoresSuccess = createAction(StoreActionType.GetClosestStoresSuccess, props<{ stores: Array<CharityStore> }>());

export const getStoreAvailabilityStats = createAction(
  StoreActionType.GetStoreAvailabilityStats,
  props<{ findParams: FindAvailabilityParams }>(),
);
export const getStoreAvailabilityStatsSuccess = createAction(
  StoreActionType.GetStoreAvailabilityStatsSuccess,
  props<{ stats: Array<AvailabilityStats> }>(),
);

export const deleteStore = createAction(StoreActionType.DeleteStore, props<{ storeId: string }>());
export const deleteStoreSuccess = createAction(StoreActionType.DeleteStoreSuccess, props<{ storeId: string }>());

export const restoreStore = createAction(StoreActionType.RestoreStore, props<{ storeId: string }>());
export const restoreStoreSuccess = createAction(StoreActionType.RestoreStoreSuccess, props<{ storeId: string }>());

export const createStoreSchedule = createAction(StoreActionType.CreateStoreSchedule, props<{ parentId: string; schedule: Schedule }>());
export const createStoreScheduleSuccess = createAction(StoreActionType.CreateStoreScheduleSuccess, props<{ schedule: Schedule }>());

export const updateStoreSchedule = createAction(StoreActionType.UpdateStoreSchedule, props<{ parentId: string; schedule: Schedule }>());
export const updateStoreScheduleSuccess = createAction(StoreActionType.UpdateStoreScheduleSuccess, props<{ schedule: Schedule }>());

export const getStoreSchedules = createAction(
  StoreActionType.GetStoreSchedules,
  props<{ parentId: string; findParams: FindScheduleParams }>(),
);
export const getStoreSchedulesSuccess = createAction(StoreActionType.GetStoreSchedulesSuccess, props<{ schedules: Array<Schedule> }>());

export const getStoreSchedule = createAction(StoreActionType.GetStoreSchedule, props<{ storeId: string; scheduleId: string }>());
export const getStoreScheduleSuccess = createAction(StoreActionType.GetStoreScheduleSuccess, props<{ schedule: Schedule }>());

export const deleteStoreSchedule = createAction(StoreActionType.DeleteStoreSchedule, props<{ storeId: string; scheduleId: string }>());
export const deleteStoreScheduleSuccess = createAction(StoreActionType.DeleteStoreScheduleSuccess, props<{ scheduleId: string }>());
