<div fxLayout="column" fxLayoutGap="10px">
  <mat-form-field class="zone-input" appearance="outline" [ngClass]="{ invalid: !disabled && required && !(zips?.length > 0) }">
    <mat-label>{{ label }}</mat-label>
    <mat-chip-grid #chipList [disabled]="disabled" aria-label="Zip code selection">
      @for (zip of zips; track zip) {
        <mat-chip-row [removable]="true" (removed)="remove(zip)" [disabled]="disabled">
          {{ zip }}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      }
      <input
        [matChipInputFor]="chipList"
        [matChipInputAddOnBlur]="false"
        (matChipInputTokenEnd)="add($event)"
        (paste)="paste($event)"
        [readOnly]="disabled"
      />
    </mat-chip-grid>
    @if (zips?.length > 0 && windowSize !== windowSizes.XS) {
      <mat-icon (click)="copy()" class="copy" title="Copy zips to clipboard" matSuffix>content_copy</mat-icon>
    }
  </mat-form-field>
  @if (showErrorMessage && !disabled && required && !(zips?.length > 0)) {
    <p class="error" fxLayout="row">Zips are required</p>
  }
  <div fxLayout="row">
    @if (zips?.length > 0 && windowSize === windowSizes.XS) {
      <button
        class="primary-color"
        mat-button
        [ngClass]="{
          'block-btn': windowSize === windowSizes.XS,
        }"
        style="margin-bottom: 20px"
        (click)="copy()"
      >
        Copy zips to clipboard
      </button>
    }
  </div>
</div>
