import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { AuthenticationService } from './authentication.service';
import { JwtInterceptor } from './interceptor/jwt.interceptor';

export const provideRsplAuthProviders = (): EnvironmentProviders => {
  return makeEnvironmentProviders([
    AuthenticationService,
    JwtInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
  ]);
};
