import { Charity } from './charity';
import { Partner } from './partner';
import { Role } from './role';
import { UserPermissions } from './user-permissions';

export class SubMenuItem {
  icon?: string;
  svgIcon?: string;
  title: () => string;
  route?: string[];
  visible?: (data: {
    partner: Partner;
    charity: Charity;
    roles: Role[];
    onboardingCompleted: boolean;
    permissions: UserPermissions;
  }) => boolean;
  smallScreenDisabled?: boolean;
  externalLink?: string;
  counter?: string;
  subtitle?: {
    show: (data: { partner: Partner }) => boolean;
    value: string;
    isWarning: boolean;
  };

  constructor(payload: SubMenuItem) {
    this.icon = payload.icon;
    this.svgIcon = payload.svgIcon;
    this.title = payload.title;
    this.route = payload.route;
    this.visible = payload.visible;
    this.smallScreenDisabled = payload.smallScreenDisabled;
    this.externalLink = payload.externalLink;
    this.counter = payload.counter;
    this.subtitle = payload.subtitle;
  }
}
export class MenuItem extends SubMenuItem {
  children?: SubMenuItem[];
  expanded?: string[];

  constructor(payload: MenuItem) {
    super(payload);
    this.children = payload.children?.map((c) => new SubMenuItem(c));
    this.expanded = payload.expanded;
  }
}
