import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, Environment, Notification, NotificationTargetType, NotificationTrigger } from '@domains';
import { Observable } from 'rxjs';

import { BaseApiService } from '../base-api.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService extends BaseApiService<Notification> {
  constructor(
    @Inject(ENVIRONMENT) override config: Environment,
    override http: HttpClient,
  ) {
    super(config, http, 'notifications', Notification, 'Notification');
  }

  getPreview(trigger: string): Observable<any> {
    return this.http.get<any>(this.config.urls.baseUrl + `/hooks/preview`, {
      params: { event: trigger },
    });
  }

  manualTriggerNotification(
    params: {
      donation_id?: string;
      lead_id?: string;
      targets?: NotificationTargetType[];
    },
    trigger: NotificationTrigger,
  ): Observable<any> {
    return this.http.post(`${this.config.urls.baseUrl}/notifications/manual_trigger/${trigger}`, params);
  }
}
